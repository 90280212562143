import React, { useEffect, useState } from 'react';
import { Label, Modal, ModalBody, ModalHeader, Input, Form, FormFeedback } from 'reactstrap';
import nameImage from '../../assets/images/banner/Group 1410109339 (1).png';
import toast from 'react-hot-toast';
import { profileEdit } from '../../ApiConfigs/ApiConfig';
import axios from 'axios';

function ChangeName({ data, modalOpen, toggleModal, refetch }) {
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');

    useEffect(() => {
        setName(data?.name || '');
    }, [data]);

    const handleNameChange = (e) => {
        const value = e.target.value;
        setName(value);

        // Simple validation (optional)
        if (value.trim() === '') {
            setNameError('Name is required');
        } else {
            setNameError('');
        }
    };

    const handleProfileSubmit = async (e) => {
        e.preventDefault();

        // Additional validation if needed
        if (name.trim() === '') {
            setNameError('Name is required');
            return;
        }

        const payload = {
            name: name,
        }
        try {
            const response = await axios.put(profileEdit, payload);
            toast.success(response?.data?.result?.msg);
            toggleModal();
            refetch();
        } catch (error) {
            toast.error(error.response?.data?.msg);
        }
    };

    return (
        <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Edit Your Profile Name</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleProfileSubmit}>
                    <img
                        src={nameImage}
                        className='mobile-modal'
                        style={{ height: '200px', width: '200px', display: 'block', margin: 'auto' }}
                        alt="profile"
                    />
                    <Label for="name">Name</Label>
                    <Input
                        type="text"
                        id="name"
                        value={name}
                        onChange={handleNameChange}
                        invalid={!!nameError}
                        placeholder="Enter Your Name"
                    />
                    {nameError && <FormFeedback>{nameError}</FormFeedback>}
                    <button
                        type='submit'
                        className='mt-3'
                        style={{ width: '100%', backgroundColor: '#e4510b', border: 'none', padding: '8px', borderRadius: '7px', color: 'white' }}
                    >
                        Submit
                    </button>
                </Form>
            </ModalBody>
        </Modal>
    );
}

export default ChangeName;

import React, { useEffect, useRef, useState } from 'react';
import { PlusCircle, Trash2, Upload, X } from 'react-feather';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Button, Col, Form, FormFeedback, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import './index.css';
import { uploadImage } from '../../upload';
import { product, couponCode } from '../../../../ApiConfigs/ApiConfig';
import DatePicker from 'react-flatpickr';
import { startOfDay } from 'date-fns';


function CouponAdd({ open, handleAdd, offerData }) {
    // const [profileImageUrl, setProfileImageUrl] = useState('');
    const inputRef = useRef(null);
    const [getProductData, setGetProductData] = useState([]);
    const [loader, setLoader] = useState(false);
    // const [productCat, setProductCat] = useState("")
    const [profileImageUrl, setProfileImageUrl] = useState('');

    const formSchema = yup.object().shape({
        title: yup.string().required('Please Enter Coupon Title'),
        // img_url: yup.string().required('Please Upload Image'),
        couponType: yup.mixed().required('Please Select Coupon Type'),
        discountType: yup.mixed().required('Please Select Discount Type'),
        customerEligibility: yup.mixed().required('Please Select Customer Eligibility Type'),
        // cartQuantity: yup.string().required('Please Enter Cart Quantity'),
        code: yup.string().required('Please Enter Coupon Code'),
        discount: yup.string().required('Please Enter Discount Value'),
        minimumPurchase: yup.string().required('Please Enter Minimum Purchase Value'),
        description: yup.string().required('Please Enter Coupon Description'),
        usageLimitPerUser: yup.string().required('Please Enter Coupon Usage Limit'),
        usageLimitOverall: yup.string().required('Please Enter Coupon Overall Usage Limit'),
        instructions: yup.array().required('Please Enter Coupon Instruction'),
        startDate: yup.date()
            .required('Please select a Coupon Start Date.')
            .min(startOfDay(new Date()), 'The date must be today or in the future.'),
        expiryDate: yup.date()
            .required('Please select a Coupon Expiry Date.')
            .min(startOfDay(new Date()), 'The date must be today or in the future.'),
    });

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    // const selectedProductCategory = useWatch({
    //     control,
    //     name: 'productCategory',
    // });

    const handleReset = () => {
        reset({
            description: '',
            product: '',
            title: '',
            code: '',
            couponType: '',
            discountType: '',
            discount: null,
            minimumPurchase: null,
            freeShipping: null,
            startDate: '',
            expiryDate: '',
            customerEligibility: '',
            usageLimitPerUser: null,
            usageLimitOverall: null,
            instructions: [],
            isHidden: null,
            cartQuantity: ''

        });
        // setSelectedProduct('')
        setProfileImageUrl('')
    };

    const [couponType, setCouponType] = useState('');

    const handleCouponTypeChange = (selectedOption) => {
        setCouponType(selectedOption?.value || ''); // Update state with selected coupon type or reset if cleared
    };

    const customStyles = (isMulti) => ({
        control: (provided, state) => ({
            ...provided,
            height: 'auto', // Set the height to auto
            minHeight: isMulti ? '38px' : '40px', // Set a minimum height
            padding: '5px', // Add some padding
            overflow: 'hidden', // Prevent overflow
        }),
        multiValue: (provided) => ({
            ...provided,
            maxWidth: '100%', // Allow multi values to take full width
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            overflow: 'hidden',
            whiteSpace: 'nowrap', // Prevent text wrapping
            textOverflow: 'ellipsis', // Add ellipsis for overflow text
        }),
    });

    const discountTypeOptions = [
        {
            label: "Percentage",
            value: "percentage"
        },
        {
            label: "Flat",
            value: "flat"
        }
    ];

    const couponTypeOptions = [
        {
            label: "Product",
            value: "product"
        },
        {
            label: "Cart",
            value: "cart"
        },
        {
            label: "General",
            value: "general"
        }
    ];

    const customerEligibilityOptions = [
        {
            label: "New Customer",
            value: "new"
        },
        {
            label: "Old Customer",
            value: "old"
        },
        {
            label: "Both The Customers",
            value: "both"
        }
    ];

    const [selectedProductIds, setSelectedProductIds] = useState([]);

    const handleProductChange = (selectedOptions) => {
        // Extract the IDs from selected options
        const selectedIds = selectedOptions.map(option => option.value);
        setSelectedProductIds(selectedIds);
    };

    const handleProfileUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setLoader(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileImageUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setLoader(false)
            }
        }
    };

    const [image, setImage] = useState(null)

    const getProduct = async (id) => {
        try {
            const response = await axios.get(`${product}`);
            setGetProductData(response?.data?.result);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    };

    const getProductOptions = getProductData?.map((item) => ({
        label: item?.name,
        value: item?._id,
    }));

    const submitForm = async (data) => {
        const startDate = new Date(data?.startDate);
        const formattedstartDate = startDate.toISOString().split('T')[0];
        const expiryDate = new Date(data?.expiryDate);
        const formattedexpiryDate = expiryDate.toISOString().split('T')[0];
        const payload = {
            ...data,
            ...(data?.couponType?.value !== "general" && { product: selectedProductIds }),  // Conditional product field
            couponType: data?.couponType?.value,
            customerEligibility: data?.customerEligibility?.value,
            discountType: data?.discountType?.value,
            startDate: formattedstartDate,
            expiryDate: formattedexpiryDate,
            minimumPurchase: parseFloat(data?.minimumPurchase),
            discount: parseFloat(data?.discount),
            img_url: profileImageUrl,
            usageLimitOverall: parseFloat(data?.usageLimitOverall),
            usageLimitPerUser: parseFloat(data?.usageLimitPerUser),
        };
        try {
            const response = await axios.post(couponCode, payload);
            handleAdd();
            offerData();
            handleReset();
            toast.success(response?.data?.msg);
        } catch (error) {
            console.error("Error:", error);
            toast.error(
                error?.response?.data?.msg?.message ||
                error?.response?.data?.msg ||
                "An error occurred."
            );
        }
    };

    useEffect(() => {
        getProduct()
    }, [])

    return (
        <div className="modal-page">
            <Modal
                isOpen={open}
                toggle={handleAdd}
                size='lg'
                className="sidebar-lg"
                modalClassName="modal-slide-in sidebar-todo-modal"
                contentClassName="p-0"
            >
                <div
                    className="d-flex align-items-center justify-content-between mb-1 px-1"
                    style={{ backgroundColor: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottom: '1px solid #EEEEEE' }}
                >
                    <h4
                        className="modal-title"
                        style={{
                            marginTop: '23px',
                            marginBottom: '23px',
                            paddingLeft: '10px',
                            fontFamily: 'roboto',
                            color: 'black',
                        }}
                    >
                        Add Coupon
                    </h4>
                    <X
                        style={{ color: 'black', cursor: 'pointer' }}
                        className="addmodal-close fw-normal mt-25 me-2"
                        size={25}
                        onClick={handleAdd}
                    />
                </div>
                <ModalBody className="flex-grow-1">
                    <Form onSubmit={handleSubmit(submitForm)}>
                        <Row>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label"> Coupon Title <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="title"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                invalid={errors.title && true}
                                                placeholder="Enter Coupon Description"
                                            />
                                        )}
                                    />
                                    {errors.title && (
                                        <FormFeedback>{errors.title.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">Description <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="description"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                invalid={errors.description && true}
                                                placeholder="Enter Coupon Description"
                                            />
                                        )}
                                    />
                                    {errors.description && (
                                        <FormFeedback>{errors.description.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">Coupon Code <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="code"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                invalid={errors.code && true}
                                                placeholder="Enter Coupon Code"
                                            />
                                        )}
                                    />
                                    {errors.code && (
                                        <FormFeedback>{errors.code.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">
                                        Coupon Type<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name="couponType"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={couponTypeOptions}
                                                placeholder="Select Coupon Type"
                                                isClearable={true}
                                                onChange={(selectedOption) => {
                                                    field.onChange(selectedOption); // Update form state
                                                    handleCouponTypeChange(selectedOption); // Update local state
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.couponType && (
                                        <FormFeedback>{errors.couponType.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                {couponType === 'cart' && (
                                    <div className="mb-1">
                                        <Label className="modal-label">
                                            Cart Quantity
                                        </Label>
                                        <Controller
                                            name="cartQuantity"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Input
                                                    type="number"
                                                    {...field}
                                                    invalid={errors.cartQuantity && true}
                                                    placeholder="Enter Cart Quantity"
                                                />
                                            )}
                                        />
                                        {errors.cartQuantity && (
                                            <FormFeedback>{errors.cartQuantity.message}</FormFeedback>
                                        )}
                                    </div>
                                )}
                            </Col>
                            <Col lg={6} sm={12}>
                                {(couponType === 'product' || couponType === 'cart') && (
                                    <div className="mb-1 mt-3">
                                        <Label className="modal-label">
                                            Select Product
                                        </Label>
                                        <Select
                                            isMulti={true}
                                            styles={customStyles(true)}
                                            options={getProductOptions}
                                            onChange={handleProductChange}
                                        />
                                    </div>
                                )}
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">
                                        Discount Type<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name="discountType"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={discountTypeOptions}
                                                placeholder="Select Discount Type"
                                                isClearable={true}
                                            />
                                        )}
                                    />
                                    {errors.discountType && (
                                        <FormFeedback>{errors.discountType.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">Discount Value<span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="discount"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="number"
                                                {...field}
                                                invalid={errors.discount && true}
                                                placeholder="Enter Discount Value"
                                            />
                                        )}
                                    />
                                    {errors.discount && (
                                        <FormFeedback>{errors.discount.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">
                                        Customer Eligibility Type<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name="customerEligibility"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={customerEligibilityOptions}
                                                placeholder="Select Customer Eligibility Type"
                                                isClearable={true}
                                            />
                                        )}
                                    />
                                    {errors.customerEligibility && (
                                        <FormFeedback>{errors.customerEligibility.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">Minimum Purchase Value<span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="minimumPurchase"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="number"
                                                {...field}
                                                invalid={errors.minimumPurchase && true}
                                                placeholder="Enter Minimum Purchase Value"
                                            />
                                        )}
                                    />
                                    {errors.minimumPurchase && (
                                        <FormFeedback>{errors.minimumPurchase.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1 mt-1">
                                    <Label className="modal-label me-2">
                                        Is Free Shipping Available?{' '}
                                    </Label>
                                    <Controller
                                        name="freeShipping"
                                        control={control}
                                        defaultValue={false} // Default state for the switch
                                        render={({ field }) => (
                                            <Input
                                                type="switch"
                                                {...field}
                                                checked={field.value} // Bind value to form state
                                                onChange={(e) => field.onChange(e.target.checked)} // Update state on change
                                            />
                                        )}
                                    />
                                    {errors.freeShipping && (
                                        <FormFeedback>{errors.freeShipping.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">
                                        Start Date<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name="startDate"
                                        control={control}
                                        defaultValue={null} // No date selected by default
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                selected={field.value} // Bind the selected value to the field
                                                onChange={(date) => field.onChange(date)} // Update form state on date change
                                                minDate={new Date()} // Disable dates before today
                                                placeholderText="Select Coupon Start Date"
                                                className={`form-control ${errors.startDate ? 'is-invalid' : ''}`}
                                                dateFormat="yyyy-MM-dd" // Format the date as per your requirement
                                            />
                                        )}
                                    />
                                    {errors.startDate && (
                                        <FormFeedback>{errors.startDate.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">
                                        Expiry Date<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name="expiryDate"
                                        control={control}
                                        defaultValue={null} // No date selected by default
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                selected={field.value} // Bind the selected value to the field
                                                onChange={(date) => field.onChange(date)} // Update form state on date change
                                                minDate={new Date()} // Disable dates before today
                                                placeholderText="Select Coupon Expiry Date"
                                                className={`form-control ${errors.expiryDate ? 'is-invalid' : ''}`}
                                                dateFormat="yyyy-MM-dd" // Optional: Format the date as per your requirement
                                            />
                                        )}
                                    />
                                    {errors.expiryDate && (
                                        <FormFeedback>{errors.expiryDate.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">Usage Limit Per User<span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="usageLimitPerUser"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="number"
                                                {...field}
                                                invalid={errors.usageLimitPerUser && true}
                                                placeholder="Enter Usage Limit Per User"
                                            />
                                        )}
                                    />
                                    {errors.usageLimitPerUser && (
                                        <FormFeedback>{errors.usageLimitPerUser.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label"> Overall Usage Limit<span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="usageLimitOverall"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="number"
                                                {...field}
                                                invalid={errors.usageLimitOverall && true}
                                                placeholder="Enter Overall Usage Limit"
                                            />
                                        )}
                                    />
                                    {errors.usageLimitOverall && (
                                        <FormFeedback>{errors.usageLimitOverall.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1 mt-1">
                                    <Label className="modal-label me-2">
                                        Is Hidden?{' '}
                                    </Label>
                                    <Controller
                                        name="isHidden"
                                        control={control}
                                        defaultValue={false} // Default state for the switch
                                        render={({ field }) => (
                                            <Input
                                                type="switch"
                                                {...field}
                                                checked={field.value} // Bind value to form state
                                                onChange={(e) => field.onChange(e.target.checked)} // Update state on change
                                            />
                                        )}
                                    />
                                    {errors.isHidden && (
                                        <FormFeedback>{errors.isHidden.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='m-1'>
                                    <Label className="modal-label me-2">
                                        Instructions<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Row >
                                        <Col sm={12} md={12} lg={12}>
                                            <div className='mb-3'>
                                                <Controller
                                                    name='instructions'
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field }) => (
                                                        <div>
                                                            <Row>
                                                                {field.value.map((item, index) => (
                                                                    <Col key={index} sm={12} md={6} lg={6}>
                                                                        <div className='mb-3 d-flex flex-column'>
                                                                            <Label style={{ fontWeight: "500", color: '#262525' }}>
                                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                                    <div> Instruction {index + 1}</div>
                                                                                    <div className='justify-content-end ms-2 mt-1'
                                                                                        color='danger'
                                                                                        size='sm'
                                                                                        style={{ cursor: 'pointer', color: '#AF1B1B' }}
                                                                                        onClick={() => {
                                                                                            const updatedValues = [...field.value];
                                                                                            updatedValues.splice(index, 1);
                                                                                            field.onChange(updatedValues);
                                                                                        }}
                                                                                    >
                                                                                        <Trash2 />
                                                                                    </div>
                                                                                </div>
                                                                            </Label>
                                                                            <Input
                                                                                type="textarea" rows={5} cols={60}
                                                                                name={`instructions[${index}].description`}
                                                                                value={field.value[index]?.description || ''}
                                                                                invalid={errors.instructions && errors.instructions[index] && errors.instructions[index].description && true}
                                                                                placeholder={`Enter Instructions`}
                                                                                onChange={(e) => {
                                                                                    const updatedValues = [...field.value];
                                                                                    updatedValues[index] = { description: e.target.value };
                                                                                    field.onChange(updatedValues);
                                                                                }}
                                                                            />
                                                                            {errors.instructions && errors.instructions[index] && errors.instructions[index].description && (
                                                                                <FormFeedback>{errors.instructions[index].description.message}</FormFeedback>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                            <Button
                                                                className='overview-btn'
                                                                size='sm'
                                                                onClick={() => {
                                                                    field.onChange([...field.value, { description: '' }]);
                                                                }}
                                                            >
                                                                <PlusCircle /> Add Overview
                                                            </Button>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mb-1'>
                                    <Label className='modal-label'>
                                        Image
                                    </Label>
                                    <div className="mb-2 mt-2">
                                        <Controller
                                            name='img_url'
                                            id='img_url'
                                            control={control}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div>
                                                    <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                        <input
                                                            style={{ display: 'none' }}
                                                            id="img_url"
                                                            type="file"
                                                            {...field}
                                                            ref={inputRef}
                                                            onChange={(e) => handleProfileUpload(e)}
                                                        />
                                                        {loader ? (
                                                            <Spinner />
                                                        ) : (
                                                            <Button
                                                                style={{ backgroundColor: '#E4510B', border: 'none' }}
                                                                color="primary"
                                                                onClick={() => inputRef?.current?.click()}
                                                            >
                                                                <Upload size={15} />
                                                                <span className='ms-1'>Upload Image</span>
                                                            </Button>
                                                        )}
                                                        {errors.img_url && (
                                                            <FormFeedback>{errors.img_url.message}</FormFeedback>
                                                        )}
                                                    </div>
                                                    {profileImageUrl && (
                                                        <div className="mt-2 d-flex">
                                                            {image && (
                                                                <>
                                                                    <img
                                                                        alt="preview"
                                                                        src={profileImageUrl}
                                                                        style={{
                                                                            width: '200px',
                                                                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                                                                        }}
                                                                    />
                                                                    <div
                                                                        className="image-close ms-1"
                                                                        style={{ cursor: 'pointer', color: "#AF1B1B" }}
                                                                        onClick={() => setImage(null)}
                                                                    >
                                                                        <X />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className='d-bloick mt-3'>
                            <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                            <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}>Are you sure you want to Add Coupon</p>
                        </div>
                        <hr />
                        <div className='d-flex'>
                            <button style={{ border: '1px solid #E4510B', color: '#E4510B', background: 'white', borderRadius: '10px' }} className='mt-1 w-50' type='button' onClick={handleAdd}>Cancel</button>
                            <button className='mt-1 w-50 ms-2 add-modal-btn' type='submit'>Submit</button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default CouponAdd;

import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { PlusCircle, Trash2, Upload, X } from 'react-feather';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Button, Col, Form, FormFeedback, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import { uploadImage } from '../../upload';
import { product, couponCode } from '../../../../ApiConfigs/ApiConfig';
import DatePicker from 'react-flatpickr';
// import { startOfDay } from 'date-fns';
// import { json } from 'react-router-dom';

function CouponEdit({ open, handleEdit, editData, offerData }) {

    const inputRef = useRef(null);
    const [getProductData, setGetProductData] = useState([]);
    const [loader, setLoader] = useState(false);
    // const [productCat, setProductCat] = useState("")
    const [couponTypeValue, setCouponTypeValue] = useState("")
    // const [selectedProduct, setSelectedProduct] = useState([])
    const [profileImageUrl, setProfileImageUrl] = useState('');

    const formSchema = yup.object().shape({
        title: yup.string().required('Title is required'),
        description: yup.string().required('Description is required'),
        code: yup.string().required('Coupon code is required'),
        couponType: yup.object().required('Coupon type is required').nullable(),
        cartQuantity: yup.number().when('couponType', {
            is: (val) => val?.value === 'cart',
            then: () => yup.number().required('Cart quantity is required').min(1),
            otherwise: () => yup.number().nullable()
        }),
        discount: yup.number().required('Discount value is required').min(0),
        discountType: yup.object().required('Discount type is required').nullable(),
        customerEligibility: yup.object().required('Customer eligibility is required').nullable(),
        minimumPurchase: yup.number().required('Minimum purchase value is required').min(0),
        // startDate: yup.date().required('Start date is required'),
        // expiryDate: yup.date()
        // .required('Expiry date is required')
        // .min(yup.ref('startDate'), 'End date must be after start date'),
        usageLimitPerUser: yup.number().required('Usage limit per user is required').min(1),
        usageLimitOverall: yup.number().required('Overall usage limit is required').min(1),
        instructions: yup.array().of(
            yup.object().shape({
                description: yup.string().required('Instruction is required')
            })
        )
    });

    const {
        reset,
        control,
        handleSubmit,
        // setValue,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema),
        defaultValues: {
            title: editData?.title || "",
            description: editData?.description || "",
            code: editData?.code || "",
            couponType: null,
            cartQuantity: "",
            discount: "",
            discountType: null,
            customerEligibility: null,
            minimumPurchase: "",
            startDate: null,
            expiryDate: null,
            usageLimitPerUser: "",
            usageLimitOverall: "",
            instructions: [],
            freeShipping: false,
            isHidden: false
        }
    });

    const CustomSelect = forwardRef(({ ...props }, ref) => {
        return (
            <Select
                {...props}
                ref={ref}
                styles={{
                    ...props.styles,
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#E4510B' : '#ced4da',
                        '&:hover': {
                            borderColor: '#E4510B'
                        }
                    })
                }}
            />
        );
    });

    // const [couponType, setCouponType] = useState('');

    // const handleCouponTypeChange = (selectedOption) => {
    //     setCouponType(selectedOption?.value || ''); // Update state with selected coupon type or reset if cleared
    // };

    // const customStyles = (isMulti) => ({
    //     control: (provided, state) => ({
    //         ...provided,
    //         height: 'auto', // Set the height to auto
    //         minHeight: isMulti ? '38px' : '40px', // Set a minimum height
    //         padding: '5px', // Add some padding
    //         overflow: 'hidden', // Prevent overflow
    //     }),
    //     multiValue: (provided) => ({
    //         ...provided,
    //         maxWidth: '100%', // Allow multi values to take full width
    //     }),
    //     multiValueLabel: (provided) => ({
    //         ...provided,
    //         overflow: 'hidden',
    //         whiteSpace: 'nowrap', // Prevent text wrapping
    //         textOverflow: 'ellipsis', // Add ellipsis for overflow text
    //     }),
    // });

    const discountTypeOptions = useMemo(() => [
        {
            label: "Percentage",
            value: "percentage"
        },
        {
            label: "Flat",
            value: "flat"
        }
    ], []);

    const couponTypeOptions = useMemo(() => [
        {
            label: "Product",
            value: "product"
        },
        {
            label: "Cart",
            value: "cart"
        },
        {
            label: "General",
            value: "general"
        }
    ], []);

    const customerEligibilityOptions = useMemo(() => [
        {
            label: "New Customer",
            value: "new"
        },
        {
            label: "Old Customer",
            value: "old"
        },
        {
            label: "Both The Customers",
            value: "both"
        }
    ], []);

    // const [selectedProductIds, setSelectedProductIds] = useState([]);

    // const handleProductChange = (selectedOptions) => {
    //     // Extract the IDs from selected options
    //     const selectedIds = selectedOptions.map(option => option.value);
    //     // setSelectedProductIds(selectedIds);
    // };

    const handleProfileUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            // setImage(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setLoader(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileImageUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setLoader(false)
            }
        }
    };

    // const [image, setImage] = useState(null)

    const getProduct = async (id) => {
        try {
            const response = await axios.get(`${product}`);
            setGetProductData(response?.data?.result);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    };

    const getProductOptions = getProductData?.map((item) => ({
        label: item?.name,
        value: item?._id,
    }));

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    const submitForm = async (data) => {
        const startDate = new Date(data?.startDate);
        const formattedstartDate = formatDate(startDate);

        const expiryDate = new Date(data?.expiryDate);
        const formattedexpiryDate = formatDate(expiryDate);
        const product = data?.products?.map((product) => product?.value)
        const payload = {
            ...data,
            ...(data?.couponType?.value !== "general" && { products: product }),  // Conditional product field
            couponType: data?.couponType?.value,
            // products: product,
            customerEligibility: data?.customerEligibility?.value,
            discountType: data?.discountType?.value,
            expiryDate: formattedexpiryDate,
            startDate: formattedstartDate,
            minimumPurchase: parseFloat(data?.minimumPurchase),
            discount: parseFloat(data?.discount),
            img_url: profileImageUrl,
            usageLimitOverall: parseFloat(data?.usageLimitOverall),
            usageLimitPerUser: parseFloat(data?.usageLimitPerUser),
        };
        try {
            const response = await axios.put(`${couponCode}/${editData?._id}`, payload);
            handleEdit();
            offerData();
            // setGetData();
            toast.success(response?.data?.result?.msg);
            reset();
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    useEffect(() => {
        const selectedCouponType = couponTypeOptions.find(
            (option) => option.value === editData?.couponType
        );
        const selectedDiscountType = discountTypeOptions.find(
            (option) => option.value === editData?.discountType
        );
        const selectedCustomerEligibility = customerEligibilityOptions.find(
            (option) => option.value === editData?.customerEligibility
        );

        const selectedProducts = getProductOptions.filter((option) =>
            editData?.products?.some(product => product._id === option.value) // Compare by _id
        );

        reset({
            title: editData?.title,
            code: editData?.code,
            couponType: selectedCouponType || null,
            customerEligibility: selectedCustomerEligibility || null,
            discount: editData?.discount,
            cartQuantity: editData?.cartQuantity,
            discountType: selectedDiscountType,
            freeShipping: editData?.freeShipping ?? false,
            isHidden: editData?.isHidden ?? false,
            minimumPurchase: editData?.minimumPurchase,
            instructions: editData?.instructions?.map(item => ({ description: item.description })) || [],
            description: editData?.description,
            startDate: editData?.startDate ? new Date(editData.startDate) : null,
            expiryDate: editData?.expiryDate ? new Date(editData.expiryDate) : null,
            usageLimitOverall: editData?.usageLimitOverall,
            usageLimitPerUser: editData?.usageLimitPerUser,
            products: selectedProducts || [],
        });
        setProfileImageUrl(editData?.img_url);
        setCouponTypeValue(editData?.couponType);
    }, [
        editData,
        reset,
        couponTypeOptions,
        customerEligibilityOptions,
        discountTypeOptions,
        getProductOptions
    ]);

    useEffect(() => {
        getProduct()
    }, [])

    return (
        <div className='modal-page'>
            <Modal
                isOpen={open}
                toggle={handleEdit}
                size='lg'
                className='sidebar-lg'
                modalClassName='modal-slide-in sidebar-todo-modal'
                contentClassName='p-0'>
                <div
                    className="d-flex align-items-center justify-content-between mb-1 px-1"
                    style={{ backgroundColor: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottom: '1px solid #EEEEEE' }}
                >
                    <h4
                        className="modal-title"
                        style={{
                            marginTop: '23px',
                            marginBottom: '23px',
                            paddingLeft: '10px',
                            fontFamily: 'roboto',
                            color: 'black',
                        }}
                    >
                        Edit Coupon
                    </h4>
                    <X
                        style={{ color: 'black', cursor: 'pointer' }}
                        className="addmodal-close fw-normal mt-25 me-2"
                        size={25}
                        onClick={handleEdit}
                    />
                </div>
                <ModalBody className='flex-grow-1'>
                    <Form onSubmit={handleSubmit(submitForm)}>
                        <Row>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label"> Coupon Title <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="title"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                invalid={errors.title && true}
                                                placeholder="Enter Coupon Description"
                                            />
                                        )}
                                    />
                                    {errors.title && (
                                        <FormFeedback>{errors.title.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">Description <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="description"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                invalid={errors.description && true}
                                                placeholder="Enter Coupon Description"
                                            />
                                        )}
                                    />
                                    {errors.description && (
                                        <FormFeedback>{errors.description.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">Coupon Code <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="code"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                invalid={errors.code && true}
                                                placeholder="Enter Coupon Code"
                                            />
                                        )}
                                    />
                                    {errors.code && (
                                        <FormFeedback>{errors.code.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            {/* <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">
                                        Coupon Type<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name="couponType"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={couponTypeOptions}
                                                placeholder="Select Coupon Type"
                                                isClearable={true}
                                                onChange={(selectedOption) => {
                                                    field.onChange(selectedOption); // Update form state
                                                    handleCouponTypeChange(selectedOption); // Update local state
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.couponType && (
                                        <FormFeedback>{errors.couponType.message}</FormFeedback>
                                    )}
                                </div>
                            </Col> */}
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">
                                        Coupon Type<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name="couponType"
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { ref, ...field } }) => (
                                            <CustomSelect
                                                {...field}
                                                inputRef={ref}
                                                options={couponTypeOptions}
                                                placeholder="Select Coupon Type"
                                                isClearable={true}
                                                className={errors.couponType ? 'is-invalid' : ''}
                                                onChange={(selectedOption) => {
                                                    field.onChange(selectedOption);
                                                    setCouponTypeValue(selectedOption?.value)
                                                    // handleCouponTypeChange(selectedOption);
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.couponType && (
                                        <div className="invalid-feedback d-block">{errors.couponType.message}</div>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                {couponTypeValue === 'cart' && (
                                    <div className="mb-1">
                                        <Label className="modal-label">
                                            Cart Quantity
                                        </Label>
                                        <Controller
                                            name="cartQuantity"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Input
                                                    type="number"
                                                    {...field}
                                                    invalid={errors.cartQuantity && true}
                                                    placeholder="Enter Cart Quantity"
                                                />
                                            )}
                                        />
                                        {errors.cartQuantity && (
                                            <FormFeedback>{errors.cartQuantity.message}</FormFeedback>
                                        )}
                                    </div>
                                )}
                            </Col>
                            {/* <Col lg={6} sm={12}>
                                {(couponType === 'product' || couponType === 'cart') && (
                                    <div className="mb-1 mt-3">
                                        <Label className="modal-label">
                                            Select Product
                                        </Label>
                                        <Select
                                            isMulti={true}
                                            styles={customStyles(true)}
                                            options={getProductOptions}
                                            onChange={handleProductChange}
                                        />
                                    </div>
                                )}
                            </Col> */}
                            {(couponTypeValue === 'product' || couponTypeValue === 'cart') && (
                                <Col lg={6} sm={12}>
                                    <div className="mb-1 mt-3">
                                        <Label className="modal-label">
                                            Select Product
                                        </Label>
                                        <Controller
                                            name="products"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { ref, ...field } }) => (
                                                <CustomSelect
                                                    {...field}
                                                    inputRef={ref}
                                                    isMulti={true}
                                                    options={getProductOptions}
                                                    value={field.value || []}
                                                    onChange={(selected) => {
                                                        field.onChange(selected);
                                                        // handleProductChange(selected);
                                                    }}
                                                    className={errors.products ? 'is-invalid' : ''}
                                                />
                                            )}
                                        />
                                        {errors.products && (
                                            <div className="invalid-feedback d-block">{errors.products.message}</div>
                                        )}
                                    </div>
                                </Col>
                            )}
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">
                                        Discount Type<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name="discountType"
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { ref, ...field } }) => (
                                            <CustomSelect
                                                {...field}
                                                inputRef={ref}
                                                options={discountTypeOptions}
                                                placeholder="Select Discount Type"
                                                isClearable={true}
                                                className={errors.discountType ? 'is-invalid' : ''}
                                            />
                                        )}
                                    />
                                    {errors.discountType && (
                                        <div className="invalid-feedback d-block">{errors.discountType.message}</div>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">Discount Value<span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="discount"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="number"
                                                {...field}
                                                invalid={errors.discount && true}
                                                placeholder="Enter Discount Value"
                                            />
                                        )}
                                    />
                                    {errors.discount && (
                                        <FormFeedback>{errors.discount.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            {/* <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">
                                        Discount Type<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name="discountType"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={discountTypeOptions}
                                                placeholder="Select Discount Type"
                                                isClearable={true}
                                            />
                                        )}
                                    />
                                    {errors.discountType && (
                                        <FormFeedback>{errors.discountType.message}</FormFeedback>
                                    )}
                                </div>
                            </Col> */}
                            {/* <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">
                                        Customer Eligibility Type<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name="customerEligibility"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={customerEligibilityOptions}
                                                placeholder="Select Customer Eligibility Type"
                                                isClearable={true}
                                            />
                                        )}
                                    />
                                    {errors.customerEligibility && (
                                        <FormFeedback>{errors.customerEligibility.message}</FormFeedback>
                                    )}
                                </div>
                            </Col> */}
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">
                                        Customer Eligibility Type<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name="customerEligibility"
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { ref, ...field } }) => (
                                            <CustomSelect
                                                {...field}
                                                inputRef={ref}
                                                options={customerEligibilityOptions}
                                                placeholder="Select Customer Eligibility Type"
                                                isClearable={true}
                                                className={errors.customerEligibility ? 'is-invalid' : ''}
                                            />
                                        )}
                                    />
                                    {errors.customerEligibility && (
                                        <div className="invalid-feedback d-block">{errors.customerEligibility.message}</div>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">Minimum Purchase Value<span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="minimumPurchase"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="number"
                                                {...field}
                                                invalid={errors.minimumPurchase && true}
                                                placeholder="Enter Minimum Purchase Value"
                                            />
                                        )}
                                    />
                                    {errors.minimumPurchase && (
                                        <FormFeedback>{errors.minimumPurchase.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1 mt-4">
                                    <Label className="modal-label me-2">
                                        Is Free Shipping Available?{' '}
                                    </Label>
                                    <Controller
                                        name="freeShipping"
                                        control={control}
                                        defaultValue={false} // Default state for the switch
                                        render={({ field }) => (
                                            <Input
                                                type="switch"
                                                {...field}
                                                checked={field.value} // Bind value to form state
                                                onChange={(e) => field.onChange(e.target.checked)} // Update state on change
                                            />
                                        )}
                                    />
                                    {errors.freeShipping && (
                                        <FormFeedback>{errors.freeShipping.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">
                                        Start Date<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name="startDate"
                                        control={control}
                                        defaultValue={null} // No date selected by default
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                selected={field.value} // Bind the selected value to the field
                                                onChange={(date) => field.onChange(date)} // Update form state on date change
                                                minDate={new Date()} // Disable dates before today
                                                placeholderText="Select Coupon Start Date"
                                                className={`form-control ${errors.startDate ? 'is-invalid' : ''}`}
                                                dateFormat="yyyy-MM-dd" // Format the date as per your requirement
                                            />
                                        )}
                                    />
                                    {errors.startDate && (
                                        <FormFeedback>{errors.startDate.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">
                                        Expiry Date<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name="expiryDate"
                                        control={control}
                                        defaultValue={null} // No date selected by default
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                selected={field.value} // Bind the selected value to the field
                                                onChange={(date) => field.onChange(date)} // Update form state on date change
                                                minDate={new Date()} // Disable dates before today
                                                placeholderText="Select Coupon Expiry Date"
                                                className={`form-control ${errors.expiryDate ? 'is-invalid' : ''}`}
                                                dateFormat="yyyy-MM-dd" // Optional: Format the date as per your requirement
                                            />
                                        )}
                                    />
                                    {errors.expiryDate && (
                                        <FormFeedback>{errors.expiryDate.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label">Usage Limit Per User<span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="usageLimitPerUser"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="number"
                                                {...field}
                                                invalid={errors.usageLimitPerUser && true}
                                                placeholder="Enter Usage Limit Per User"
                                            />
                                        )}
                                    />
                                    {errors.usageLimitPerUser && (
                                        <FormFeedback>{errors.usageLimitPerUser.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1">
                                    <Label className="modal-label"> Overall Usage Limit<span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name="usageLimitOverall"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="number"
                                                {...field}
                                                invalid={errors.usageLimitOverall && true}
                                                placeholder="Enter Overall Usage Limit"
                                            />
                                        )}
                                    />
                                    {errors.usageLimitOverall && (
                                        <FormFeedback>{errors.usageLimitOverall.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-1 mt-4">
                                    <Label className="modal-label me-2">
                                        Is Hidden?{' '}
                                    </Label>
                                    <Controller
                                        name="isHidden"
                                        control={control}
                                        defaultValue={false} // Default state for the switch
                                        render={({ field }) => (
                                            <Input
                                                type="switch"
                                                {...field}
                                                checked={field.value} // Bind value to form state
                                                onChange={(e) => field.onChange(e.target.checked)} // Update state on change
                                            />
                                        )}
                                    />
                                    {errors.isHidden && (
                                        <FormFeedback>{errors.isHidden.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='m-1'>
                                    <Label className="modal-label me-2">
                                        Instructions<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Row >
                                        <Col sm={12} md={12} lg={12}>
                                            <div className='mb-3'>
                                                <Controller
                                                    name='instructions'
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field }) => (
                                                        <div>
                                                            <Row>
                                                                {field.value.map((item, index) => (
                                                                    <Col key={index} sm={12} md={6} lg={6}>
                                                                        <div className='mb-3 d-flex flex-column'>
                                                                            <Label style={{ fontWeight: "500", color: '#262525' }}>
                                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                                    <div> Instruction {index + 1}</div>
                                                                                    <div className='justify-content-end ms-2 mt-1'
                                                                                        color='danger'
                                                                                        size='sm'
                                                                                        style={{ cursor: 'pointer', color: '#AF1B1B' }}
                                                                                        onClick={() => {
                                                                                            const updatedValues = [...field.value];
                                                                                            updatedValues.splice(index, 1);
                                                                                            field.onChange(updatedValues);
                                                                                        }}
                                                                                    >
                                                                                        <Trash2 />
                                                                                    </div>
                                                                                </div>
                                                                            </Label>
                                                                            <Input
                                                                                type="textarea" rows={5} cols={60}
                                                                                name={`instructions[${index}].description`}
                                                                                value={field.value[index]?.description || ''}
                                                                                invalid={errors.instructions && errors.instructions[index] && errors.instructions[index].description && true}
                                                                                placeholder={`Enter Instructions`}
                                                                                onChange={(e) => {
                                                                                    const updatedValues = [...field.value];
                                                                                    updatedValues[index] = { description: e.target.value };
                                                                                    field.onChange(updatedValues);
                                                                                }}
                                                                            />
                                                                            {errors.instructions && errors.instructions[index] && errors.instructions[index].description && (
                                                                                <FormFeedback>{errors.instructions[index].description.message}</FormFeedback>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                            <Button
                                                                className='overview-btn'
                                                                size='sm'
                                                                onClick={() => {
                                                                    field.onChange([...field.value, { description: '' }]);
                                                                }}
                                                            >
                                                                <PlusCircle /> Add Overview
                                                            </Button>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mb-1'>
                                    <Label className='modal-label'>
                                        Image
                                    </Label>
                                    <div className="mb-2 mt-2">
                                        <Controller
                                            name='img_url'
                                            id='img_url'
                                            control={control}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div>
                                                    <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                        <input
                                                            style={{ display: 'none' }}
                                                            id="img_url"
                                                            type="file"
                                                            {...field}
                                                            ref={inputRef}
                                                            onChange={(e) => handleProfileUpload(e)}
                                                        />
                                                        {loader ? (
                                                            <Spinner />
                                                        ) : (
                                                            <Button
                                                                style={{ backgroundColor: '#E4510B', border: 'none' }}
                                                                color="primary"
                                                                onClick={() => inputRef?.current?.click()}
                                                            >
                                                                <Upload size={15} />
                                                                <span className='ms-1'>Upload Image</span>
                                                            </Button>
                                                        )}
                                                        {errors.img_url && (
                                                            <FormFeedback>{errors.img_url.message}</FormFeedback>
                                                        )}
                                                    </div>
                                                    {profileImageUrl && (
                                                        <div className="mt-2 d-flex">
                                                            {profileImageUrl && (
                                                                <>
                                                                    <img
                                                                        alt="preview"
                                                                        src={profileImageUrl}
                                                                        style={{
                                                                            width: '200px',
                                                                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                                                                        }}
                                                                    />
                                                                    <div
                                                                        className="image-close ms-1"
                                                                        style={{ cursor: 'pointer', color: "#AF1B1B" }}
                                                                        onClick={() => setProfileImageUrl(null)} // Clear the image
                                                                    >
                                                                        <X />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <hr></hr>
                        <div className='d-flex'>
                            <button style={{ border: '1px solid #E4510B', color: '#E4510B', background: 'white', borderRadius: '10px' }} className='mt-1 w-50' type='button' onClick={handleEdit}>Cancel</button>
                            <button className='mt-1 w-50 ms-2 add-modal-btn' type='submit'>Submit</button>
                        </div>
                    </Form >
                </ModalBody>

            </Modal>
        </div >
    )
}

export default CouponEdit;

import React from 'react'
import { Form, Modal, ModalBody, ModalHeader } from 'reactstrap';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

const Preview = ({ modal, toggle, cardImages }) => {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    // const images = cardImages;
    const images = Array.isArray(cardImages) ? cardImages : [];
    const firstImage = images;

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Preview</ModalHeader>
                <Form>
                    <ModalBody>
                        {images.length > 1 ? (
                            <Carousel
                                responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                showDots={true}
                                arrows={true}
                                autoPlaySpeed={2000}
                                infinite={true}
                            >
                                {images.map((image, index) => (
                                    <div
                                        key={index}
                                        style={{ display: 'flex', justifyContent: 'center' }}
                                    >
                                        {typeof image === 'string' && image.endsWith('.pdf') ? (
                                            <iframe
                                                src={image}
                                                style={{ width: '400px', height: '600px', border: 'none' }}
                                                title={`PDF ${index}`}
                                            />
                                        ) : (
                                            <img
                                                className="mb-3"
                                                style={{ width: '300px' }}
                                                alt={`Image ${index}`}
                                                src={image}
                                            />
                                        )}
                                    </div>
                                ))}
                            </Carousel>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {typeof firstImage === 'string' && firstImage.endsWith('.pdf') ? (
                                    <iframe
                                        src={firstImage}
                                        style={{ width: '400px', height: 'auto' }}
                                        title="PDF"
                                    />
                                ) : (
                                    <img
                                        className="mb-3 s"
                                        style={{ width: '300px', height: 'auto' }}
                                        alt=""
                                        src={firstImage || ''}
                                    />
                                )}
                            </div>
                        )}
                    </ModalBody>
                </Form>
            </Modal>
        </div>
    );
};

export default Preview;
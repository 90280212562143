import React, { useEffect, useState } from "react";
// import star from "../../assets/images/logo/Star 2.png"
// import star5 from "../../assets/images/logo/Star 5.png"
// import { FaAngleRight } from "react-icons/fa6";
import {
  Col,
  Row,
  Spinner,
} from "reactstrap";
import "./index.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  product,
  productCategory,
} from "../../ApiConfigs/ApiConfig";
import axios from "axios";
import FooterSection from "../footer";
import Navbar from "../navbar";
import { ArrowRight } from "react-feather";
import emptyCart from "../../assets/images/banner/2875607_8503 [Converted].png";
import toast from "react-hot-toast";
// import Demo_json from '../home/landingPage/Demo.json';
// import Lottie from 'react-lottie'
import ProductCard from "../homeNew/ProductCard";
import TitleComponent from "../homeNew/TitleComponent";

function ProductType() {
  const [loading, setLoading] = useState(false);
  const [dataa, setData] = useState([]);
  const { id } = useParams();
  // const [open, setOpen] = useState("1");
  const { pathname } = useLocation();

  // const toggleAccordion = (id) => {
  //   if (open === id) {
  //     setOpen();
  //   } else {
  //     setOpen(id);
  //   }
  // };

  const firstObject = dataa[0];

  const [categoryDataa, setCategorData] = useState();

  const categoryData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${productCategory}/${id}`);
      setCategorData(response?.data?.result);
    } catch (error) {
      toast.error(error?.response?.data?.msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    categoryData();
  }, []);

  const additionalData = async () => {
    try {
      setLoading(true);
      let url = product;
      const response = await axios.get(`${url}?productCategory=${id}`);
      setData(response?.data?.result);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    additionalData();
  }, []);

  useEffect(() => {
    additionalData();
  }, [pathname]);

  const navigate = useNavigate();

  // const navToPage = (dataa) => {
  //     const productName = dataa?.name.replace(/\s+/g, '-').toLowerCase(); // Replace spaces with hyphens and convert to lowercase
  //     const productId = dataa?._id;
  //     navigate(`/${productName}/${productId}`);
  // };
  // const navToPage = (product_url) => {
  //   navigate(`/${product_url}`);
  // };

  const navToHome = () => {
    navigate(`/`);
  };

  const navToProduct = () => {
    navigate("/products");
  };

  // const [showFullDescription, setShowFullDescription] = useState(false);

  // const toggleDescription = () => {
  //   setShowFullDescription(!showFullDescription);
  // };

  // const truncateDescription = (description, maxLength) => {
  //   return description.length > maxLength
  //     ? `${description.slice(0, maxLength)}...`
  //     : description;
  // };

  // const truncateTitle = (title) => {
  //   return title.length > 70 ? `${title.slice(0, 70)}...` : title;
  // };

  // const navToViewPage = (product_url) => {
  //   navigate(`/${product_url}`);
  // };

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: Demo_json,
  // };

  return (
    <>
      <Navbar />
      {loading ? (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner color="primary">Loading...</Spinner>
        </div>
      ) : (
        <div
          className="pt-3"
          style={{ paddingLeft: "5%", paddingRight: "5%", paddingBottom: '10%' }}
        >
          <p className="pt-3" style={{ cursor: "pointer", color: '#333333', opacity: '0.8' }}>
            <span onClick={navToHome}>Home</span> /{" "}
            <span onClick={navToProduct}>Products</span> /{" "}
            <span style={{ color: '#E4510B', opacity: '1' }}>{firstObject?.productCategory?.name}</span>{" "}
          </p>
          {/* <div className="d-flex align-items-center mt-5">
            <h1 className="product-overall-title">
              {" "}
              {firstObject?.productCategory?.name}
            </h1>
            <hr className='ms-3 call2' style={{ height: '2px', width: '70%', background: 'black' }} />
          </div> */}
          {dataa.length === 0 ? (
            <div>
              <h2 className="text-center pt-3 mb-5" style={{ color: "grey" }}>
                "Oops! It seems like we're out of stock in this category. <br />{" "}
                Explore other categories or visit us again soon."
              </h2>
              <div className="d-flex justify-content-center">
                <img src={emptyCart} style={{ height: "250px" }} alt="empty" />
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Link to="/products">
                  <button className="success-modal-btn">
                    Continue Shopping <ArrowRight size={17} />
                  </button>
                </Link>
              </div>
            </div>
          ) : (
            <>
              {/* <div style={{ width: '100%' }} className="d-flex align-items-center mt-5">
                <h1 className="product-overall-title a">
                  {" "}
                  {firstObject?.productCategory?.name}
                </h1>
                <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
              </div> */}
              <div className="mb-3">
                <TitleComponent title={firstObject?.productCategory?.name} isDesc={true} desc={`Explore a wide range of categories featuring top-quality products tailored to your needs!`} />
              </div>
              <div className="product-card-con">
                <Row>
                  {dataa?.map((eachItem, index) => (
                    <Col sm={12} md={6} lg={4} xl={3} key={index}>
                      <ProductCard data={eachItem} />
                    </Col>
                  ))}
                </Row>
              </div>
              {/* <div className="d-flex flex-column justify-content-center align-items-center ">
                <h1 className="fs-3 mb-4 lg-mb-5 text-center">Discover How to Customize Your Design Like a Pro!</h1>
                <div className="animate-video-width mb-5">
                  <div>
                    <Lottie style={{ borderRadius: '15px' }} options={defaultOptions} className="w-full" />
                  </div>
                </div>
              </div> */}
            </>
          )}
        </div >
      )
      }

      <div>
        {/* <InteractiveHome /> */}
        <FooterSection />
      </div>
    </>
  );
}

export default ProductType;

import React from 'react'
import './index.css'
import { Input, Label } from 'reactstrap';
function ProductTool(props) {
    const { data, selectedColorIndex, handleColorSelect, handleOptionChange } = props
    const option = JSON.parse(localStorage.getItem('selectedOptions'));

    return (
        <div>
            <div>
                <h1 className='tool-title'>PRODUCT</h1>
                <h1 className='tool-sub-title'>Manage Your Product</h1>
                <p className='tool-desc'>You can select the product specification.</p>
                <div className='tool-bar-line'></div>
            </div>
            <div className='tool-product-con p-3 shadow'>
                <div className='d-flex justify-content-between gap-2'>
                    <h1 className='tool-product-name'>{data?.name}</h1>
                    <p className='tool-change-product cursor-action'>Change product</p>
                </div>
                <div className='d-flex gap-2'>
                    <div className='tool-product-img-con'>
                        <img className='img-fluid tool-product-img' src={data?.customizeImages?.[selectedColorIndex]?.images?.[0]?.image} alt='product-image' />
                    </div>
                    <div className='d-flex flex-wrap gap-2 mt-2'>
                        {data?.customizeImages?.map((color, index) => (
                            <div
                                key={index}
                                className={`${index === selectedColorIndex ? 'clr-con-active' : 'clr-con'}`}
                                onClick={() => handleColorSelect(index)}
                            >
                                <div
                                    style={{ backgroundColor: color.colourCode, width: '100%', height: '100%', borderRadius: '50%' }}></div>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    {data?.fieldType?.map((eachItem, i) => (
                        <div key={i} className='mt-3'>
                            <Label className='field-type-label'>{eachItem?.name?.name}</Label>
                            <Input
                                type='select'
                                className='field-option-select'
                                name={eachItem?.name?._id}
                                defaultValue={option?.[i]?.option || ""}
                                onChange={(e) =>
                                    handleOptionChange(eachItem?.name?._id, e.target.value)
                                }
                            >
                                {eachItem?.options?.map((each, k) => (
                                    <option className='field-option-label' key={k} value={each?._id}>
                                        {each?.name}
                                    </option>
                                ))}
                            </Input>
                        </div>
                    ))}
                </div>
            </div>
        </div >
    )
}

export default ProductTool

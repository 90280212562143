import React from 'react'
import { AlertCircle, X } from 'react-feather'
import namenumber from "../../../../assets/images/banner/nameNumber.png"
import { Input } from 'reactstrap'

function NameNumberTool(props) {
    const { handleActiveToolsTab, isNameNumberTool, setIsNameNumberTool, handleAddName, handleAddNumber, handleSideSwitch, activeSide, nameNumberValues, isNameAdded, isNumberAdded, handleFontSizeChange, handleFontColorChange, handleFontFamilyChange, handleEnterNameClick, sizeQuantities, nextbtnError } = props

    return (
        <div>
            <div>
                <h1 className='tool-title'>NAMES & NUMBERS</h1>
                <div className='d-flex justify-content-between align-items-center'>
                    <h1 className='tool-sub-title'>{isNameNumberTool ? 'Names & Numbers Tools' : 'Add Names & Numbers'}</h1>
                    {isNameNumberTool ? (
                        <span onClick={() => setIsNameNumberTool(false)} className='cursor-action'><X /></span>
                    ) : (
                        <span onClick={() => handleActiveToolsTab('product')} className='cursor-action'><X /></span>
                    )}
                </div>
                {!isNameNumberTool && (
                    <p className='tool-desc'>Use personalized Names & Numbers for projects like team jerseys where you need a unique name and/or number for each item.</p>
                )}
                <div className='tool-bar-line mt-2'></div>
                {!isNameNumberTool && (
                    <div className='mt-4'>
                        <img className='img-fluid' src={namenumber} alt='name&number' />
                        <button onClick={() => setIsNameNumberTool(true)} className='text-add-btn mt-4'>Add Names & Numbers</button>
                    </div>
                )}
                {isNameNumberTool && (
                    <div className='mt-4'>
                        <div className="name-number-tool">
                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <label className='text-editor-label' style={{ width: '100px' }}>Step 1 </label>
                                {/* Add Name Checkbox */}
                                <div className='d-flex'>
                                    <Input
                                        type="checkbox"
                                        checked={isNameAdded}
                                        onChange={(e) => handleAddName(e.target.checked)}
                                    />
                                    <label style={{ fontWeight: '600' }} className='ms-2 mt-1'>Add Name</label>
                                </div>

                                {/* Add Number Checkbox */}
                                <div className='d-flex ms-2'>
                                    <Input
                                        type="checkbox"
                                        checked={isNumberAdded}
                                        onChange={(e) => handleAddNumber(e.target.checked)}
                                    />
                                    <label style={{ fontWeight: '600' }} className='ms-2 mt-1'>Add Number</label>
                                </div>
                            </div>
                            <div className='tool-bar-line mb-3'></div>
                            {/* Font Family */}
                            <div className='mb-3 d-flex align-items-center gap-3'>
                                <label className='text-editor-label'>Font</label>
                                <Input type='select' name="fontFamily" value={nameNumberValues.fontFamily} onChange={(e) => handleFontFamilyChange(e.target.value)}>
                                    <option value="Interstate" style={{ fontFamily: 'Interstate' }}>Interstate</option>
                                    {/* <option value="Collegiate">Collegiate</option> */}
                                    <option value="Arial, sans-serif" style={{ fontFamily: 'Arial, sans-serif' }}>Arial (Standard)</option>
                                    <option value="Verdana, sans-serif" style={{ fontFamily: 'Verdana, sans-serif' }}>Verdana (Clear & Simple)</option>
                                    <option value='"Times New Roman", serif' style={{ fontFamily: '"Times New Roman", serif' }}>Times New Roman (Classic)</option>
                                    <option value="Impact" style={{ fontFamily: 'Impact' }}>Impact (Bold & Strong)</option>
                                    <option value='"Bebas Neue", sans-serif' style={{ fontFamily: '"Bebas Neue", sans-serif' }}>Bebas Neue (Bold Block)</option>
                                    <option value="Futura, sans-serif" style={{ fontFamily: 'Futura, sans-serif' }}>Futura (Retro)</option>
                                    <option value="Helvetica, sans-serif" style={{ fontFamily: 'Helvetica, sans-serif' }}>Helvetica (Minimalistic)</option>
                                    <option value="Rockwell, serif" style={{ fontFamily: 'Rockwell, serif' }}>Rockwell (Vintage)</option>
                                    <option value='"Streetbrush", cursive' style={{ fontFamily: '"Streetbrush", cursive' }}>Streetbrush (Graffiti)</option>
                                    <option value="Teko, sans-serif" style={{ fontFamily: 'Teko, sans-serif' }}>Teko (Modern 3D)</option>
                                    <option value="Pacifico, cursive" style={{ fontFamily: 'Pacifico, cursive' }}>Pacifico (Cursive)</option>
                                    <option value="Varsity, sans-serif" style={{ fontFamily: 'Varsity, sans-serif' }}>Varsity (Collegiate)</option>
                                    <option value='"Neon 80s", sans-serif' style={{ fontFamily: '"Neon 80s", sans-serif' }}>Neon 80s (Glow)</option>
                                    <option value="Montserrat, sans-serif" style={{ fontFamily: 'Montserrat, sans-serif' }}>Montserrat (Geometric)</option>
                                    <option value='"Playfair Display", serif' style={{ fontFamily: '"Playfair Display", serif' }}>Playfair Display (Elegant)</option>
                                    <option value="Raleway, sans-serif" style={{ fontFamily: 'Raleway, sans-serif' }}>Raleway (Light & Sleek)</option>
                                    <option value="Lobster, cursive" style={{ fontFamily: 'Lobster, cursive' }}>Lobster (Cursive & Fun)</option>
                                    <option value="Cinzel, serif" style={{ fontFamily: 'Cinzel, serif' }}>Cinzel (Classic Engraving)</option>
                                    <option value="Oswald, sans-serif" style={{ fontFamily: 'Oswald, sans-serif' }}>Oswald (Condensed)</option>
                                    <option value="Anton, sans-serif" style={{ fontFamily: 'Anton, sans-serif' }}>Anton (Bold Modern)</option>
                                    <option value='"Brush Script MT", cursive' style={{ fontFamily: '"Brush Script MT", cursive' }}>Brush Script MT (Handwritten)</option>
                                    <option value='"Comic Sans MS", cursive' style={{ fontFamily: '"Comic Sans MS", cursive' }}>Comic Sans MS (Playful)</option>
                                    <option value='"Dancing Script", cursive' style={{ fontFamily: '"Dancing Script", cursive' }}>Dancing Script (Playful & Elegant)</option>
                                    <option value="Creepster, cursive" style={{ fontFamily: 'Creepster, cursive' }}>Creepster (Spooky)</option>
                                    <option value='"Luckiest Guy", cursive' style={{ fontFamily: '"Luckiest Guy", cursive' }}>Luckiest Guy (Comic Style)</option>
                                    <option value='"Amatic SC", cursive' style={{ fontFamily: '"Amatic SC", cursive' }}>Amatic SC (Hand-drawn)</option>
                                    <option value="Roboto, sans-serif" style={{ fontFamily: 'Roboto, sans-serif' }}>Roboto (Modern Sans-Serif)</option>
                                    <option value="Blackout Midnight, sans-serif" style={{ fontFamily: 'Blackout Midnight, sans-serif' }}>Blackout Midnight (Futuristic)</option>
                                </Input>
                            </div>
                            <div className='tool-bar-line mb-3'></div>
                            {/* Font Color */}
                            <div className='mb-3 d-flex align-items-center gap-3'>
                                <label className='text-editor-label'>Color</label>
                                <Input
                                    id="fill"
                                    name="fill"
                                    type="color"
                                    value={nameNumberValues.fill}
                                    onChange={(e) => handleFontColorChange(e.target.value)}
                                />
                            </div>
                            {/* <div className='tool-bar-line mb-3'></div>
                          
                            <div className='mb-3 d-flex justify-content-between align-items-center gap-3'>
                                <label className='text-editor-label'>Size</label>
                                <div className="font-size-options">
                                    <button
                                        onClick={() => handleFontSizeChange('Small')}
                                        className={nameNumberValues.fontSize === 'Small' ? 'size-btn size-btn-active' : 'size-btn'}
                                    >
                                        SMALL
                                    </button>
                                    <button
                                        onClick={() => handleFontSizeChange('Large')}
                                        className={nameNumberValues.fontSize === 'Large' ? 'size-btn size-btn-active ms-3' : 'size-btn ms-3'}
                                    >
                                        LARGE
                                    </button>
                                </div>
                            </div> */}
                            <div className='tool-bar-line mb-3'></div>
                            <button onClick={handleEnterNameClick} disabled={!isNameAdded && !isNumberAdded} className={`text-add-btn mt-4 ${!isNameAdded && !isNumberAdded ? 'text-add-btn-unactive' : ''}`}>
                                {(!isNameAdded && !isNumberAdded)
                                    ? 'Enter Name/Number'
                                    : isNameAdded && isNumberAdded
                                        ? 'Enter Name/Number'
                                        : isNameAdded
                                            ? 'Enter Name'
                                            : 'Enter Number'}
                            </button>
                            {nextbtnError && (
                                <p className='text-danger mt-1'>*{nextbtnError}</p>
                            )}
                        </div>

                        {sizeQuantities?.length > 0 && (
                            <div className='mt-4 mb-2'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S. No</th>
                                            <th>Name</th>
                                            <th>Number</th>
                                            <th>Size</th>
                                        </tr>
                                    </thead>
                                    {sizeQuantities?.length > 0 && (
                                        <tbody>
                                            {sizeQuantities.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name || '-'}</td>
                                                    <td>{item.number || '-'}</td>
                                                    <td>{item.size || '-'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                                {sizeQuantities?.length === 0 && (
                                    <p className='text-center'><AlertCircle size={20} /> No Names & Numbers</p>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div >
    )
}

export default NameNumberTool

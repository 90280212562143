import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar'
// import FooterSection from '../../footer'
import axios from 'axios';
import { paymentOptions1, paymentOptionStatus } from '../../../ApiConfigs/ApiConfig';
import toast from 'react-hot-toast';
import { Col, Row } from 'reactstrap';
// import CourierList from './CourierList';
import TitleComponent from '../../homeNew/TitleComponent';
import { FiChevronRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

function Payment() {
    // const [loading, setLoading] = useState(false);
    const [data, setData] = useState()
    // const [isNameNumber, setIsNameNumber] = useState(false)
    // const [modal, setModal] = useState(false);
    const navigate = useNavigate()

    // const toggle = () => setModal(!modal)

    const getdata = async () => {
        try {
            // setLoading(true);
            const response = await axios.get(`${paymentOptions1}`)
            setData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        } finally {
            // setLoading(false);
        }
    }

    const updateStatus = async (id) => {

        try {
            const response = await axios.post(`${paymentOptionStatus}/${id}`,);
            getdata()
            // productsrtat()
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    };

    useEffect(() => {
        getdata()
    }, [])

    // const sendApi = async () => {
    //     const payload = {
    //         deliveryType: 'manual',
    //     }
    //     try {
    //         const response = await axios.post(deliveryType, payload)
    //         getdata()
    //         toast.success(response?.data?.msg)
    //     } catch (error) {
    //         toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg)
    //     }
    // }

    return (
        <div>
            <Navbar />
            <div className='pt-5 s' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '10%', backgroundColor: '#F8F8F8' }}>
                <TitleComponent
                    title={`Payment Management`}
                    isDesc={true}
                    desc={`Simplify and streamline your payment operations. Manage transactions, monitor payment statuses, and ensure secure and seamless payment processing for a smooth customer experience.`}
                />
                <div>
                    <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}>
                        <span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span>
                        <span style={{ opacity: '0.6' }}><FiChevronRight /></span>
                        <span style={{ color: '#E4510B' }}>Payment Management</span>
                    </p>
                </div>
                <div style={{ width: '100%' }} className='d-flex align-items-center'>
                    <h1 className='product-overall-title' style={{ font: 'Manrope' }}>Payment Management</h1>
                </div>
                <div className='mt-5'>
                    <Row className='d-flex'>
                        {data?.map((payment, i) => (
                            <Col sm={12} md={6} lg={4} key={payment._id}>
                                <div style={{ border: '1px solid grey', borderStyle: 'dashed', borderRadius: '8px', height: '150px' }} className='w-100 p-3 text-capitalize d-flex justify-content-between gap-3'>
                                    <div>
                                        <h1 className='fs-5'>{payment?.name}</h1>
                                        <p className='p-0 m-0'>{payment?.description}
                                        </p>
                                    </div>
                                    <div>
                                        <div className="switch d-flex align-items-center">
                                            <label className="switch-label">
                                                <input
                                                    type="checkbox"
                                                    className="switch-input"
                                                    id="switch-success"
                                                    name="success"
                                                    // onChange={async (e) => {
                                                    //     // Directly update the status without confirmation
                                                    //     await updateStatus(row._id);
                                                    // }}
                                                    onChange={async (e) => {
                                                        const result = window.confirm(
                                                            `Are you sure you want to make as ${payment.status === 'active' ? 'Inactive' : 'Active'}`
                                                        );
                                                        if (result) {
                                                            await updateStatus(payment._id);
                                                        } else {

                                                            e.target.checked = !e.target.checked;
                                                        }
                                                    }}
                                                    defaultChecked={payment.status === 'active'}
                                                />

                                                <span className="slider"></span>
                                            </label>
                                            <span
                                                className="ms-2 d-flex"
                                                style={{
                                                    color: payment.status === 'active' ? 'green' : 'red',
                                                    fontWeight: '400',
                                                    fontSize: '14px',
                                                    flexDirection: 'row' // Ensure horizontal alignment
                                                }}
                                            >
                                                {payment.status === 'active' ? 'Active' : 'Inactive'}
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            {/* <FooterSection /> */}
        </div>
    )
}

export default Payment

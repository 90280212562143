import React from 'react'
import BannerSectionNew from './BannerSectionNew'
import Navbar from '../navbar'
import { HtmlHeaderElements } from '../common/CommonLayout'

function HomeNewIndex() {
    return (
        <>
            <HtmlHeaderElements
                title=" High-Quality Online Printing Services in Bangalore | Business Cards,
      Flyers, Banners, Invitations"
            />
            <div>
                <Navbar />
                <BannerSectionNew />
            </div>
        </>
    )
}

export default HomeNewIndex

import React, { useRef } from 'react';
import { X } from 'react-feather';
import './index.css';
import { fileUpload } from '../../../../ApiConfigs/ApiConfig';
import axios from 'axios';
import toast from 'react-hot-toast';
import { FormGroup, Input } from 'reactstrap';

function UploadTool({ handleActiveToolsTab, onImageAdd, images, setImages, deleteImageFromCanvas, isEdit, unselect }) {
    const fileInputRef = useRef(null);

    const handleFileChange = async (e) => {
        const file = e.target.files[0]; // Only one file is allowed
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            try {
                // Upload the image to the API
                const response = await axios.post(fileUpload, formData);
                const uploadedImgUrl = response?.data?.result[0]?.location;

                if (uploadedImgUrl) {
                    // Add the uploaded image to the canvas via parent
                    onImageAdd(uploadedImgUrl);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            }
        }
    };

    const handleFileButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleDeleteImage = (id) => {
        // Remove the image from the canvas via parent
        deleteImageFromCanvas(id);
    };



    return (
        <div>
            <div>
                <h1 className='tool-title'>UPLOAD ART</h1>
                <div className='d-flex justify-content-between align-items-center'>
                    <h1 className='tool-sub-title'>{isEdit ? "Edit Your Artwork" : "Choose file to upload"}</h1>
                    {isEdit ? (
                        <span
                            onClick={unselect}
                            className='cursor-action'
                        >
                            <X />
                        </span>
                    ) : (
                        <span
                            onClick={() => handleActiveToolsTab('product')}
                            className='cursor-action'
                        >
                            <X />
                        </span>
                    )}
                </div>
                {isEdit ? (
                    <p className="tool-desc">
                        Our design professionals will select ink colors for you or tell us your preferred colors at checkout.
                    </p>
                ) : (
                    <p className="tool-desc">
                        Upload <strong className='upload-img-label'>.png</strong>,{' '}
                        <strong className='upload-img-label'>.jpg</strong>,{' '}
                        <strong className='upload-img-label'>.jpeg</strong>, and files
                        less than <strong className='upload-img-label'>5MB</strong>.
                    </p>
                )}
                <div className='tool-bar-line'></div>
            </div>
            {!isEdit && (
                <>
                    <div className='mt-4 tool-upload-con p-4'>
                        <h1 className='tool-upload-label'>
                            Drag & Drop Artwork Files or
                        </h1>
                        <button
                            className='tool-upload-btn'
                            onClick={handleFileButtonClick}
                        >
                            Choose File
                        </button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            accept=".png,.jpg,.jpeg"
                        />
                    </div>
                    {images.length > 0 && (
                        <div className='uploaded-images-list mt-4'>
                            <h1 className='tool-upload-label'>Uploaded Images</h1>
                            <div className='d-flex gap-3 flex-wrap mt-4'>
                                {images.map((image) => (
                                    <div className='image-con-cus shadow'>
                                        <img src={image.url} alt="Uploaded" className='img-cus' />
                                        <div onClick={() => handleDeleteImage(image.id)} className='image-con-cus-cancel'>
                                            <span><X size={20} /></span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            )}
            {isEdit && (
                <>
                    <div className='mt-4'>
                        <div className='mb-3 d-flex justify-content-between align-items-center gap-3'>
                            <label className='text-editor-label'>Remove Background</label>
                            <FormGroup switch>
                                <Input style={{ width: '50px' }} type="switch" role="switch" />
                            </FormGroup>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default UploadTool;

import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import './index.css';
import cardImage from "../../assets/images/landing/image 120.png";
import rightImg from "../../assets/images/landing/Group 33811.png";
// import strikerPng from "../../assets/images/landing/Group 33812.png";
import cardImage1 from "../../assets/images/landing/Frame 1597886239.png";
// import slideImg1 from "../../assets/images/landing/marque/Group 33807.png";
// import slideImg2 from "../../assets/images/landing/marque/image.png";
// import slideImg3 from "../../assets/images/landing/marque/image (1).png";
import banner1 from "../../assets/images/landing/Group 33813.png"
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import { useNavigate } from 'react-router-dom';
import { categories, product, productCategory } from '../../ApiConfigs/ApiConfig';
import toast from 'react-hot-toast';
import axios from 'axios';
import adBannerNew1 from "../../assets/images/landing/Frame 1597886309.png"
import adBanner2 from "../../assets/images/landing/Frame 1597886309 (1).png"
import adban1 from "../../assets/images/landing/Group 33839.png"
import adban2 from "../../assets/images/landing/Group 33838.png"
import subcriberImg from "../../assets/images/landing/image (8).png"
import FooterSection from '../footer';
import ChooseProductModal from '../home/landingPage/ChooseProductModal';
import mobileBanner from "../../assets/images/landing/Group 1597883165.png"
import TopProductSection from './TopProductSection';
import OfferProductSection from './OfferProductSection';
import SliderSection from './sliderSections/SliderOne';

function BannerSectionNew() {
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [dataa, setData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [sliderData, setSliderData] = useState([]);
    const [isStartPrinting, setIsStartPrinting] = useState(false)
    const carouselCatRef = useRef(null);
    const navigate = useNavigate()

    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(productCategory);
            setData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    };
    const productDataGet = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${product}`);
            const productsWithOffer = response?.data?.result?.filter(product => product.offer);
            setProductData(response?.data?.result);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setLoading(false);
        }
    }
    const sliderDataGET = async () => {
        try {
            const response = await axios.get(`${categories}`)
            setSliderData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }
    useEffect(() => {
        additionalData();
        productDataGet();
        sliderDataGET();
    }, []);

    useEffect(() => {
        setSelectedId(dataa[0]?._id)
    }, [dataa])

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 8
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const responsiveCards = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const responsiveTestimonial = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <>
            <div className="home-section-con pad-right">
                <Row>
                    {/* Left Section */}
                    <Col sm={12} lg={7}>
                        <div className="home-sec-left pt-4 pb-4">
                            <h1 className="home-sec-left-title">
                                Quality You Can Trust, <br />
                                <span className="home-sec-left-title-bold">Customization</span> You’ll Love!
                            </h1>
                            <Row>
                                <Col sm={12} lg={8}>
                                    <div className="create-design-card d-none d-lg-block">
                                        <div className="d-flex justify-content-between align-items-center gap-2">
                                            <p className="create-design-card-title">
                                                Create Something Truly Yours with Our Easy Product Customization Options!
                                            </p>
                                            <img className="create-design-card-img" src={cardImage} alt="Card" />
                                        </div>
                                        <button onClick={() => setIsStartPrinting(true)} className="create-design-card-btn">Create your Design</button>
                                    </div>
                                </Col>
                                <Col sm={12} lg={4}>
                                    <Carousel
                                        responsive={responsiveTestimonial}
                                        autoPlay={true}
                                        swipeable={true}
                                        draggable={true}
                                        showDots={false}
                                        arrows={false}
                                        autoPlaySpeed={2000}
                                        infinite={true}
                                        dotListClass="custom-dot-list-product"
                                        itemClass="carousel-item-padding-40-px"
                                        className='mt-4 mt-lg-0'
                                    >
                                        <img onClick={() => navigate('/products')} className="create-design-card-ad cursor-pointer w-100" draggable={false} src={cardImage1} alt="Ad" />
                                    </Carousel>
                                </Col>
                            </Row>

                        </div>
                    </Col>

                    {/* Right Section */}
                    <Col sm={12} lg={5}>
                        <img alt='right' className='w-100 h-100 img-fluid d-none d-lg-block' draggable={false} src={rightImg} />
                    </Col>
                </Row>
            </div>
            <div>
                <img alt='right' className='w-100 h-100 img-fluid  d-lg-none' draggable={false} src={rightImg} />
            </div>
            <ChooseProductModal isOpen={isStartPrinting} toggle={() => setIsStartPrinting(!isStartPrinting)} />
            <div className='container-custom py-4'>
                <div className='position-relative'>
                    <Carousel
                        ref={carouselCatRef}
                        responsive={responsive}
                        autoPlay={true}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        arrows={false}
                        autoPlaySpeed={2000}
                        infinite={true}
                        dotListClass="custom-dot-list-product"
                        itemClass="carousel-item-padding-40-px"
                        className=''
                    >
                        {dataa?.map((item, i) => (
                            <div onClick={() => navigate(`/${item?.category_url}/all`)} key={i} className='m-2 cursor-pointer'>
                                <img style={{ borderRadius: '30px' }} alt='icon' className='img-fluid w-100' draggable={false} src={item?.icon} />
                                <p className='text-center p-0 m-0 mt-2 cat-title'>{item?.name}</p>
                            </div>
                        ))}
                    </Carousel>
                    <img alt='frame' onClick={() => carouselCatRef.current.previous()} className='left-arrow-cat cursor-pointer' height={40} src='https://printontesting.s3.ap-south-1.amazonaws.com/printon/common_20241121173320239/Frame%201597886257.png' />
                    <img alt='frame' onClick={() => carouselCatRef.current.next()} className='right-arrow-cat cursor-pointer' height={40} src='https://printontesting.s3.ap-south-1.amazonaws.com/printon/common_20241121173320239/Frame%201597886258.png' />
                </div>
            </div>
            <div className='w-100 d-md-none'>
                <img alt='mobile banner' onClick={() => navigate('/category')} draggable={false} className='w-100 img-fluid cursor-pointer' src={mobileBanner} />
            </div>
            <div className='w-100 d-none d-md-block'>
                <img alt='banner' onClick={() => navigate('/category')} draggable={false} className='w-100 img-fluid cursor-pointer' src={banner1} />
            </div>
            <div>
                <TopProductSection />
            </div>
            <div>
                <OfferProductSection />
            </div>
            <div className='py-5 container-custom'>
                <Row>
                    <Col sm={12} lg={6}>
                        <img alt='addbanner' onClick={() => navigate('/products')} className='img-fluid cursor-pointer mb-3 mb-lg-0' draggable={false} src={adBannerNew1} />
                    </Col>
                    <Col sm={12} lg={6}>
                        <img alt='add banner' onClick={() => navigate('/products')} className='img-fluid cursor-pointer' draggable={false} src={adBanner2} />
                    </Col>
                </Row>
            </div>
            {/* Slider Section One */}
            <div>
                <SliderSection data={sliderData?.[0]} isBg={true} />
            </div>
            {/* Slider Section Two */}
            <div>
                <SliderSection data={sliderData?.[1]} />
            </div>
            <div className='py-5 container-custom'>
                <div className='bg-add p-4 testimonial-con'>
                    <Row>
                        <Col sm={12} lg={7}>
                            <div className='cat-and-prod-con'>
                                <Row>
                                    <Col sm={12} lg={4}>
                                        <img alt='image1' className='img-fluid' src='https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241119174325245/image%20%281%29.png' />
                                    </Col>
                                    <Col sm={12} lg={8}>
                                        <div className='d-flex flex-column justify-content-between align-items-start h-100 mt-3 mt-lg-0'>
                                            <div>
                                                <div className='d-flex justify-content-between align-items-center gap-2'>
                                                    <h1 className='fs-5'>T-Shirts</h1>
                                                    <div className='d-flex align-items-center gap-1'>
                                                        <img alt='star' height={16} src={`https://printontesting.s3.ap-south-1.amazonaws.com/printon/common_20241118164415138/Star%205.png`} />
                                                        <p className='p-0 m-0'>4.1</p>
                                                    </div>
                                                </div>
                                                <p className='cat-and-prod-para mt-1'>Premium T-shirts offering comfort, style, and customizable designs for every occasion!</p>
                                                <div className='d-flex flex-wrap gap-3'>
                                                    <div className='product-list-con-new'>
                                                        <img alt='' className='product-list-img' src={`https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241120101845543/image%20%288%29.png`} />
                                                        <p className='p-0 m-0 product-list-name'>Crop Top</p>
                                                    </div>
                                                    <div className='product-list-con-new'>
                                                        <img alt='' className='product-list-img' src={`https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241120101845543/image%20%288%29.png`} />
                                                        <p className='p-0 m-0 product-list-name'>Short Sleeve T-Shirt</p>
                                                    </div>
                                                    <div className='product-list-con-new'>
                                                        <img alt='' className='product-list-img' src={`https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241120101845543/image%20%288%29.png`} />
                                                        <p className='p-0 m-0 product-list-name'>Long Sleeve T-Shirt</p>
                                                    </div>
                                                    <div className='product-list-con-new'>
                                                        <img alt='' className='product-list-img' src={`https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241120101845543/image%20%288%29.png`} />
                                                        <p className='p-0 m-0 product-list-name'>Pocket T-Shirts</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={() => navigate('/products')} className='explore-btn mt-3'>Explore All</button>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='d-none d-md-block'>
                                    <Row className='mt-5'>
                                        {dataa?.map((item, i) => (
                                            <Col key={i} sm={6} md={3} lg={2}>
                                                <div key={i} onClick={() => setSelectedId(item?._id)} className={`cursor-pointer mb-2 position-relative para-car-card-con ${selectedId === item?._id ? 'para-car-card-con-active' : ''} `}>
                                                    <img alt='' className='img-fluid' draggable={false} src={item?.image} />
                                                    <div className='position-absolute bottom-0 para-car-card'>
                                                        <p className='p-0 m-0 mt-2 '>{item?.name}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} lg={5}>
                            <div className='testimonial-con h-100 mt-5 mt-lg-0'>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <h1 className='text-center testimonial-title'>What Our Customers Say</h1>
                                    <p className='text-center testimonial-para mt-1'>Hear from our happy customers about their experiences.</p>
                                </div>
                                <div>
                                    <Carousel
                                        responsive={responsiveTestimonial}
                                        autoPlay={true}
                                        swipeable={true}
                                        draggable={true}
                                        showDots={false}
                                        arrows={true}
                                        autoPlaySpeed={2000}
                                        infinite={true}
                                        dotListClass="custom-dot-list-product"
                                        itemClass="carousel-item-padding-40-px"
                                        className=''
                                    >
                                        <div className='testimonial-card w-100 h-100 p-4'>
                                            <img alt='' className='testimonial-profile' src='https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241120174757416/image%20%289%29.png' />
                                            <h1 style={{ color: '#333333' }} className='fs-5 p-0 m-0 mt-3 mb-1'>Robin SD</h1>
                                            <img alt='' height={20} src='https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241120180241172/Frame%201597886350.png' />
                                            <div className='w-100 testimonial-review-con'>
                                                <img alt='' height={30} src='https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241120180241172/%E2%80%9C.png' />
                                                <div className='testimonial-review-con' >
                                                    <p style={{ color: '#434343' }} className='text-center p-0 m-0'>Absolutely loved the quality and customization options! Quick delivery and excellent service made the experience even better!</p>
                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                    <img alt='' height={30} src='https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241120180241172/%E2%80%9C.png' />
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div>
                <SliderSection data={sliderData?.[2]} />
            </div>
            <div>
                <SliderSection data={sliderData?.[3]} />
            </div>
            <div className='py-5 container-custom'>
                <Row>
                    <Col sm={12} lg={6}>
                        <img alt='' onClick={() => navigate('/products')} className='img-fluid cursor-pointer mb-3 mb-lg-0' draggable={false} src={adban1} />
                    </Col>
                    <Col sm={12} lg={6}>
                        <img alt='' onClick={() => navigate('/products')} className='img-fluid cursor-pointer' draggable={false} src={adban2} />
                    </Col>
                </Row>
            </div>
            <div>
                <SliderSection data={sliderData?.[4]} />
            </div>
            <div className='py-5 container-custom'>
                <div className='bg-add subscriber-letter-con'>
                    <Row>
                        <Col sm={12} lg={7}>
                            <div className='p-4 p-md-5'>
                                <h1 className='subscriber-title'>Stay Ahead with Printon!– Subscribe Now!</h1>
                                <p className='subscriber-para mt-2 mt-md-3'>Exclusive Updates, Deals, and News – Delivered Straight to Your Inbox</p>
                                <div className='d-flex align-items-center gap-1 gap-md-3 mt-3 mt-md-5'>
                                    <input className='subscribe-input' type='text' placeholder='Enter Your Email ID' />
                                    <button className='subscribe-btn'>Subscribe</button>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} lg={5}>
                            <img alt='' className='img-fluid s' draggable={false} src={subcriberImg} />
                        </Col>
                    </Row>
                </div>
            </div>
            <FooterSection />
        </>
    );
}

export default BannerSectionNew;

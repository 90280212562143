import React from 'react'
import { X } from 'react-feather'

function ClipartTool(props) {
    const { handleActiveToolsTab, clipArtData, libraryData, addImageToCanvas } = props

    return (
        <div>
            <div>
                <h1 className='tool-title'>CLIPART</h1>
                <div className='d-flex justify-content-between align-items-center'>
                    <h1 className='tool-sub-title'>Add Clipart</h1>
                    <span onClick={() => handleActiveToolsTab('product')} className='cursor-action'><X /></span>
                </div>
                <p className='tool-desc'>Choose an amazing Clipart Designs! <br /> or <span onClick={() => handleActiveToolsTab('upload')} className='upload-img-label'>Upload Your Own Image</span></p>
                <div className='tool-bar-line'></div>
                <div className='mt-4'>
                    {libraryData?.map((item, i) => (
                        <div key={i} className='mb-4'>
                            <p>{item?.name}</p>
                            <div className='d-flex flex-wrap gap-3'>
                                {item?.images?.map((eachItem, j) => (
                                    <img
                                        onClick={() => addImageToCanvas(eachItem?.img_url)}
                                        className='clipart-images'
                                        src={`${eachItem?.img_url}?cacheBust=${new Date().getTime()}`}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='mt-4'>
                    {clipArtData?.map((item, i) => (
                        <div key={i} className='mb-4'>
                            <p>{item?.name}</p>
                            <div className='d-flex flex-wrap gap-3'>
                                {item?.images?.map((eachItem, j) => (
                                    <img
                                        className='clipart-images'
                                        src={`${eachItem?.img_url}?cacheBust=${new Date().getTime()}`}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ClipartTool

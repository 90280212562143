import React, { useCallback, useEffect, useState } from 'react'
// import { Plus } from 'react-feather'
// import AddImages from './AddImages'
import { customizeDesign } from '../../../../ApiConfigs/ApiConfig'
import toast from 'react-hot-toast'
import axios from 'axios'
import { GrPowerReset } from 'react-icons/gr'
import { FaPlus } from 'react-icons/fa'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { BiEditAlt } from 'react-icons/bi'
import { GoTrash } from 'react-icons/go'
import AddClipArt from './AddClipArt'

function ClipArtTab() {
    const [modal, setModal] = useState(false)
    // const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [editData, setEditData] = useState();
    const [data, setData] = useState();

    const toggle = () => {
        setEditData(null)
        setModal(!modal)
    }

    const additionalData = useCallback(async () => {
        try {
            const response = await axios.get(`${customizeDesign}?search=${searchText}&sortBy=${sortBy}`);
            setData(response?.data?.result);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    }, [searchText, sortBy]); // Adding searchText and sortBy as dependencies here

    useEffect(() => {
        additionalData();
    }, [additionalData]); // Now additionalData will be stable and won't change on each render

    useEffect(() => {
        additionalData();
    }, [additionalData]);

    const refresh = () => {
        setSearchText('')
        setSortBy('')
        additionalData()
    }

    const editEnable = (item) => {
        setEditData(item)
        setModal(true)
    }

    const clearData = () => {
        setEditData('')
    }

    const [optionDelete, setOptionDelete] = useState(false)
    const [deleteOptionData, setDeleteOptionData] = useState();

    const openOptionDeleteModal = (data) => {
        setDeleteOptionData(data);
        setOptionDelete(true);
    }

    const closeOptionDeleteModal = () => {
        setOptionDelete(false);
    }

    const handleConfirmOptionDelete = async () => {
        try {
            await axios.delete(`${customizeDesign}/${deleteOptionData?._id}`)
            toast.success('Deleted Successfully')
            additionalData()
            closeOptionDeleteModal()

        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    }

    return (
        <div>
            <div className='pb-4 d-block g' style={{ display: 'flex', marginBottom: '-30px', background: 'white', border: '1px solid #DFDFDF', borderRadius: '12px', height: '160px' }}>
                <div style={{ borderBottom: '1px solid #DFDFDF' }}>
                    <div className='d-flex align-items-center justify-content-between p-3'>
                        <div className='d-flex align-items-center'>
                            <div className='red-line me-2'></div>
                            <h1 className='p-0 m-0 h5'>Clip Art</h1>
                        </div>
                    </div>
                </div>
                <div className='d-flex p-4 gap-3'>
                    <div className='d-flex flex-column'>
                        <label style={{ color: '#333333' }} className='fw-medium'>Search</label>
                        <input
                            type='text'
                            className='search-order'
                            placeholder='Search by name'
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                    <div className='d-flex flex-column'>
                        <label style={{ color: '#333333' }} className='fw-medium'>Sort by</label>
                        <select
                            className='select-order'
                            value={sortBy}
                            onChange={(e) => setSortBy(e.target.value)}
                        >
                            <option value=''>---Select---</option>
                            <option value='latest'>Latest</option>
                            <option value='oldest'>Oldest</option>
                        </select>
                    </div>
                    <div onClick={refresh} style={{ marginRight: "15px", marginTop: '27px', color: '#e4510b' }}>
                        <GrPowerReset size={25}
                        // onClick={handleReset}
                        />
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <button className='productCategoryBtn mt-3' onClick={() => setModal(true)}><FaPlus /> Add Clip Art</button>
                    </div>
                </div>

            </div>
            <div>
                {data?.map((field, index) => (
                    <div
                        key={field?._id} // Ensure to provide a unique key for each element
                        className='mt-5 d-block'
                        style={{
                            display: 'flex',
                            marginBottom: '-30px',
                            background: 'white',
                            border: '1px solid #DFDFDF',
                            borderRadius: '12px',
                        }}
                    >
                        <div className='d-flex' style={{ width: '100%' }}>
                            <div
                                className='p-4 d-flex justify-content-center align-items-start'
                                style={{ borderRight: '1px solid #DFDFDF' }}
                            >
                                <p style={{ fontSize: '20px', fontFamily: 'Lato', fontWeight: '500', color: '#333333' }}>
                                    {(index + 1).toString().padStart(2, '0')}
                                </p> {/* Adding serial number */}
                            </div>
                            <div style={{ flex: 1 }}>
                                <div style={{ borderBottom: '1px solid #DFDFDF' }} className='d-flex justify-content-between p-3'>
                                    <div className='d-flex'>
                                        <p style={{ color: '#333333', opacity: '0.5', fontSize: '18px', fontFamily: 'Lato', fontWeight: '500' }}>
                                            Menu :
                                        </p>
                                        <p style={{ color: '#333333', fontSize: '18px', fontFamily: 'Lato', fontWeight: '600' }} className='ms-3'>
                                            {field?.name}
                                        </p>
                                    </div>
                                    <div>
                                        <Button onClick={() => editEnable(field)} style={{ background: '#EEEEEE', border: 'none' }} >
                                            <BiEditAlt color='#4E4E4E' size={25} />
                                        </Button>
                                        <Button onClick={() => openOptionDeleteModal(field)} className='ms-2' style={{ background: '#D5383826', border: 'none' }} >
                                            <GoTrash color='#E42525' size={25} />
                                        </Button>
                                    </div>
                                </div>
                                <div className='d-flex p-3'>
                                    <div className='d-flex flex-wrap gap-5'>
                                        {field?.images?.map((item, i) => (
                                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                                <img height={150} src={item?.img_url} alt='icon' />
                                                <p className='fw-medium mt-1'>{item?.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <AddClipArt modal={modal} toggle={toggle} editData={editData} clear={clearData} refetch={additionalData} />
            <Modal isOpen={optionDelete} toggle={closeOptionDeleteModal} className="modal-dialog-centered modal-xs">
                <ModalHeader style={{ paddingLeft: '20px', fontFamily: 'italic', textAlign: 'center' }} toggle={closeOptionDeleteModal}>
                    <h5>Confirmation</h5>
                </ModalHeader>
                <ModalBody style={{ fontSize: '20px', paddingTop: '30px', fontWeight: '400' }}>
                    <div className='d-bloick mt-3'>
                        <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                        <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}>Please confirm that you want to delete this {deleteOptionData?.name}. This action will permanently remove the {deleteOptionData?.name} from the “Clip Art”, and it cannot be undone. Ensure that this {deleteOptionData?.name} is no longer required before proceeding</p>
                    </div>
                </ModalBody>
                <div style={{ borderTop: '1px solid #EEEEEE' }} className="d-flex p-3">
                    <Button className='w-50' style={{ color: '#E4510B', border: '1px solid #E4510B', background: 'white' }} onClick={closeOptionDeleteModal} >
                        Cancel
                    </Button>
                    <Button className='w-50 ms-2' style={{ backgroundColor: "#EA5455", border: 'none' }} onClick={handleConfirmOptionDelete} >
                        Delete
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export default ClipArtTab

import React, { useEffect, useState } from 'react'
import Navbar from '../navbar'
import FooterSection from '../footer'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { product } from '../../ApiConfigs/ApiConfig';
import { Col, Row, Spinner } from 'reactstrap';
import './index.css';
import TitleComponent from '../homeNew/TitleComponent';
import ProductCard from '../homeNew/ProductCard';

function OfferTargets() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate()
    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(product);
            const productsWithOffer = response?.data?.result?.filter(product => product.offer);
            setData(productsWithOffer)

        } catch (error) {

        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        additionalData()
    }, [])

    const navToHome = () => {
        navigate('/')
    }

    return (
        <div>
            <Navbar />
            {loading ? (
                <div style={{ height: "80vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner
                        color="primary"

                    >
                        Loading...
                    </Spinner>
                </div>
            ) : (
                <div className='pt-3' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%' }}>
                    <p className='pt-3' style={{ cursor: 'pointer', color: '#333333', opacity: '0.8' }}><span onClick={navToHome}>Home</span> / <span style={{ color: '#E4510B' }}>Offer Products</span></p>
                    <div className=''>
                        <div className="mb-3">
                            <TitleComponent title={`Offer Products`} isDesc={true} desc={`Explore a wide range of categories featuring top-quality products tailored to your needs!`} />
                        </div>
                        <Row className="">
                            {data?.map((eachItem, i) => (
                                <Col sm={12} md={6} lg={4} xl={3} key={i}>
                                    <ProductCard data={eachItem} />
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            )}

            <FooterSection />
        </div>
    )
}

export default OfferTargets
import React, { useCallback, useEffect, useRef, useState } from 'react'
// import * as yup from "yup";
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
import { Label, Button, Row, Col, Modal, ModalHeader, ModalBody, Collapse, Spinner, Card } from 'reactstrap'
import toast from 'react-hot-toast';
import axios from 'axios';
import { notification, orderNotes, orderNotesStatusUpate, orderPost } from '../../../ApiConfigs/ApiConfig';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar, { timeAgo } from '../../navbar';
import FooterSection from '../../footer';
import { FaRegFileLines } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { AlertCircle, ChevronDown, Plus, X } from 'react-feather';
import { BiCheck } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";
import step2 from "../../../assets/images/banner/step2.png";
import step1 from "../../../assets/images/banner/step1.png"
import './index.css'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import PrintoutPage from './printout';
import ChangestatusPage from './changestatus';
import Preview from '../../myOrder/preview';
import { multiImageUpload } from '../upload';
import { RiImageAddFill } from 'react-icons/ri';

export const getDeliveryStatus = (status) => {
    switch (status) {
        case 'ordercompleted':
            return (
                <div className='disc-container-1 disc-con-clr-4 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-4'></div>
                    <p className='p-0 m-0'>New Order</p>
                </div>
            );
        case 'process':
            return (
                <div className='disc-container-1 disc-con-clr-1 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-1'></div>
                    <p className='p-0 m-0'>On Progress</p>
                </div>
            );
        case 'shipped':
            return (
                <div className='disc-container-1 disc-con-clr-5 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-5'></div>
                    <p className='p-0 m-0'>Shipped</p>
                </div>
            );
        case 'delivered':
            return (
                <div className='disc-container-1 disc-con-clr-2 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-2'></div>
                    <p className='p-0 m-0'>Delivered</p>
                </div>
            );
        case 'cancelled':
            return (
                <div className='disc-container-1 disc-con-clr-3 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-3'></div>
                    <p className='p-0 m-0'>Cancelled</p>
                </div>
            );
        default:
            return (
                <div className='disc-container-1 disc-con-clr-1 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-1'></div>
                    <p className='p-0 m-0'>In Progress</p>
                </div>
            );
    }
};


function DashBoardViewPage() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pdffile, setpdffile] = useState('');
    const navigate = useNavigate();
    const [profileImages, setProfileImages] = useState([]);
    const inputRef1 = useRef(null);
    const [addModal, setAddModal] = useState(false);
    const [openNoteModal, setOpenNoteModal] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [modal, setModal] = useState(false);
    const [noteText, setNoteText] = useState('');
    // const [noteData, setNoteData] = useState([]);
    const [customizeImages, setCustomizeImages] = useState([]);

    const handlechange = () => setAddModal(!addModal);
    const handleNoteModal = () => setOpenNoteModal(!openNoteModal);

    // const formSchema = yup.object().shape({});
    // const { } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    const status = data?.status;

    const [openAccordion1, setOpenAccordion1] = useState(null);

    const toggleAccordion1 = (id) => {
        setOpenAccordion1(openAccordion1 === id ? null : id);
    };
    const handleProfileUpload = async (e) => {
        if (e.target.files) {
            try {
                setLoading(true);
                const formData = new FormData();

                for (let i = 0; i < e.target.files.length; i++) {
                    formData.append('files', e.target.files[i]);
                }

                const uploadData = await multiImageUpload(formData);

                if (uploadData && uploadData.result && uploadData.result.length) {
                    const uploadedImages = uploadData.result.map(file => file.location);

                    setProfileImages(prevImages => [...prevImages, ...uploadedImages]);
                }
            } catch (error) {
                toast.error('Something went wrong...');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleRemoveImage = (index) => {
        const updatedImages = [...profileImages];
        updatedImages.splice(index, 1);
        setProfileImages(updatedImages);
        // setSelectedImageIndex(null);
    };

    const getdata = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${orderPost}/${id}`);
            setData(response?.data?.result);
            setpdffile(response?.data?.result?.invoice);
            setLoading(false);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    }, [id]);

    const getNotesdata = useCallback(async () => {
        try {
            await axios.get(`${notification}?type=notes&order_id=${id}`);
            // setNoteData(response?.data?.result);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        getdata();
        getNotesdata();
    }, [getdata, getNotesdata]);

    const orderNotesApi = async () => {
        const payload = {
            notes: noteText,
            img_url: profileImages
        };
        try {
            const response = await axios.post(`${orderNotes}/${id}`, payload);
            handleNoteModal();
            setNoteText('');
            getdata();
            getNotesdata();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    };

    const orderNotesUpdate = async (value, id1) => {
        const payload = {
            status: value
        };
        try {
            const response = await axios.post(`${orderNotesStatusUpate}/${id1}`, payload);
            // handleNoteModal();
            setNoteText('');
            getdata();
            getNotesdata();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    };

    const toggle = () => setModal(!modal);

    // const viewCustom = (item) => {
    //     if (item?.customizeImages?.length > 0) {
    //         setCustomizeImages(item?.customizeImages);
    //     } else {
    //         setCustomizeImages(item?.file);
    //     }
    //     toggle();
    // };
    const viewCustom = (item) => {
        // Log the item for debugging
        // Check if item is defined and if required properties exist
        if (item) {
            // Handle condition when 'customizeImages' array has more than 1 item
            if (Array.isArray(item?.customizeImages) && item?.customizeImages.length > 1) {
                setCustomizeImages(item?.customizeImages?.map((customImage) => customImage?.img_url)); // Set 'customizeImages' state
                toggle(); // Toggle modal or action
            }
            // Handle condition when 'file' array has items
            else if (Array.isArray(item?.file) && item?.file.length > 0) {
                setCustomizeImages(item?.file); // Set 'customizeImages' state
                toggle(); // Toggle modal or action
            }
            // Handle condition when 'product.image' is available
            else if (item?.product?.image) {
                setCustomizeImages([item?.product?.image]); // Ensure it's in an array
                toggle(); // Toggle modal or action
            }
            else {
                // Fallback if no images or files are found
            }
        } else {
            // Handle case where 'item' is undefined or null
            console.error("Invalid item passed to viewCustom");
        }
    };

    // const handleDownload = async () => {
    //     const files = [...(data?.products[0]?.file || []),
    //     ...(data?.products[0]?.customizeImages || []),
    //     ...(data?.products[0]?.dependencyImages?.mainImages || []),
    //     ...(data?.products[0]?.dependencyImages?.otherImages || []),
    //     ];

    //     if (files.length === 0) {
    //         alert("No files available to download.");
    //         return;
    //     }

    //     const zip = new JSZip();

    //     for (let i = 0; i < files.length; i++) {
    //         const fileUrl = files[i];
    //         try {
    //             const response = await fetch(fileUrl, { mode: 'cors' });
    //             if (!response.ok) throw new Error(`Failed to fetch ${fileUrl}`);
    //             const blob = await response.blob();
    //             const fileName = fileUrl.split('/').pop() || `file_${i}`;
    //             zip.file(fileName, blob);
    //         } catch (error) {
    //             console.error(`Error downloading ${fileUrl}:`, error);
    //             alert(`Download failed for file: ${fileUrl}`);
    //         }
    //     }

    //     const productName = data?.orderId || 'product';
    //     const currentDate = new Date(); // Format date as yyyy-mm-dd
    //     // const currentDate = new Date().toISOString().split('T')[0]; // Format date as yyyy-mm-dd
    //     const zipFileName = `${productName}_${currentDate}.zip`;

    //     zip.generateAsync({ type: 'blob' })
    //         .then((content) => {
    //             saveAs(content, zipFileName);
    //         })
    //         .catch((err) => {
    //             console.error('Error generating zip file:', err);
    //             alert('Failed to generate zip file. Please try again.');
    //         });
    // };

    // const handleDownload = async () => {
    //     setIsDownloading(true)
    //     // Combine all image arrays and ensure URLs are valid
    //     const files = [
    //         ...(data?.products[0]?.file || []),
    //         ...(data?.products[0]?.customizeImages || []),
    //         ...(data?.products[0]?.dependencyImages?.mainImages || []),
    //         ...(data?.products[0]?.dependencyImages?.otherImages || [])
    //     ].filter(url => url && typeof url === 'string' && url.trim() !== '');

    //     if (files.length === 0) {
    //         alert("No valid files available to download.");
    //         return;
    //     }

    //     try {
    //         const zip = new JSZip();
    //         let successCount = 0;
    //         let failedUrls = [];

    //         const downloadImage = (url) => {
    //             return new Promise((resolve, reject) => {
    //                 const img = new Image();
    //                 img.crossOrigin = 'Anonymous';  // This is important for CORS

    //                 img.onload = () => {
    //                     // Create a canvas to convert the image to blob
    //                     const canvas = document.createElement('canvas');
    //                     canvas.width = img.width;
    //                     canvas.height = img.height;

    //                     const ctx = canvas.getContext('2d');
    //                     ctx.drawImage(img, 0, 0);

    //                     // Convert the canvas to blob
    //                     canvas.toBlob((blob) => {
    //                         if (blob) {
    //                             resolve(blob);
    //                         } else {
    //                             reject(new Error('Failed to convert image to blob'));
    //                         }
    //                     }, 'image/png');
    //                 };

    //                 img.onerror = () => {
    //                     // If direct loading fails, try with proxy
    //                     fetch(`/api/proxy-image?url=${encodeURIComponent(url)}`)  // You'll need to implement this endpoint
    //                         .then(response => response.blob())
    //                         .then(resolve)
    //                         .catch(reject);
    //                 };

    //                 // Add timestamp to bypass cache
    //                 const timestamp = new Date().getTime();
    //                 img.src = `${url}${url.includes('?') ? '&' : '?'}t=${timestamp}`;
    //             });
    //         };

    //         // Show progress indicator
    //         const totalFiles = files.length;
    //         let processedFiles = 0;

    //         // Process files in smaller batches to prevent overwhelming the browser
    //         const batchSize = 3;
    //         for (let i = 0; i < files.length; i += batchSize) {
    //             const batch = files.slice(i, i + batchSize);

    //             const batchPromises = batch.map((fileUrl) =>
    //                 (async () => {
    //                     try {
    //                         const blob = await downloadImage(fileUrl);
    //                         let fileName = fileUrl.split('/').pop() || `image_${i + 1}.png`;
    //                         fileName = fileName.replace(/[^a-zA-Z0-9._-]/g, '_');

    //                         zip.file(fileName, blob);
    //                         successCount++;
    //                     } catch (error) {
    //                         console.error(`Failed to download ${fileUrl}:`, error);
    //                         failedUrls.push(fileUrl);
    //                     }

    //                     processedFiles++;
    //                     const progress = Math.round((processedFiles / totalFiles) * 100);
    //                 })()
    //             );
    //         }


    //         if (successCount === 0) {
    //             throw new Error('No files were downloaded successfully');
    //         }

    //         const currentDate = new Date().toISOString().split('T')[0];
    //         const productName = data?.orderId || 'product';
    //         const zipFileName = `${productName}_${currentDate}.zip`;

    //         const content = await zip.generateAsync({
    //             type: 'blob',
    //             compression: 'DEFLATE',
    //             compressionOptions: {
    //                 level: 6
    //             }
    //         });

    //         saveAs(content, zipFileName);

    //         alert(`Downloaded ${successCount} files successfully.${failedUrls.length > 0 ? `\n${failedUrls.length} files failed.` : ''}`);
    //     } catch (error) {
    //         console.error('Error in download process:', error);
    //         alert('Failed to download files. Please check console for details.');
    //     } finally {
    //         setIsDownloading(false)
    //     }
    // };

    const handleDownload = async () => {
        setIsDownloading(true);

        const files = {
            "files": data?.products[0]?.file || [],
            "main-images": data?.products[0]?.dependencyImages?.mainImages || [],
            "dependency-images": data?.products[0]?.dependencyImages?.otherImages || [],
            "customize-images": data?.products[0]?.customizeImages || []
        };

        // Validate if there are any files to download
        const totalFiles = Object.values(files).flat().filter(url => url && typeof url === 'string' && url.trim() !== '').length;

        if (totalFiles === 0) {
            alert("No valid files available to download.");
            setIsDownloading(false);
            return;
        }

        try {
            const zip = new JSZip();
            let successCount = 0;
            let failedUrls = [];
            // let processedFiles = 0;

            const downloadImage = (url) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.crossOrigin = 'Anonymous'; // Handle CORS

                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        canvas.width = img.width;
                        canvas.height = img.height;

                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0);

                        canvas.toBlob((blob) => {
                            blob ? resolve(blob) : reject(new Error('Failed to convert image to blob'));
                        }, 'image/png');
                    };

                    img.onerror = () => {
                        fetch(`/api/proxy-image?url=${encodeURIComponent(url)}`)
                            .then(response => response.blob())
                            .then(resolve)
                            .catch(reject);
                    };

                    const timestamp = new Date().getTime();
                    img.src = `${url}${url.includes('?') ? '&' : '?'}t=${timestamp}`;
                });
            };

            for (const [folderName, urls] of Object.entries(files)) {
                const folder = zip.folder(folderName);

                // Check if the folder is successfully created
                if (!folder) {
                    console.error(`Failed to create folder: ${folderName}`);
                    continue;
                }

                for (let i = 0; i < urls.length; i++) {
                    const fileUrl = urls[i];
                    if (!fileUrl || typeof fileUrl !== 'string' || !fileUrl.trim()) continue;

                    try {
                        const blob = await downloadImage(fileUrl);
                        let fileName = fileUrl.split('/').pop() || `image_${i + 1}.png`;
                        fileName = fileName.replace(/[^a-zA-Z0-9._-]/g, '_');
                        folder.file(fileName, blob); // Add image to folder
                        successCount++;
                    } catch (error) {
                        console.error(`Failed to download ${fileUrl}:`, error);
                        failedUrls.push(fileUrl);
                    }

                    // processedFiles++;
                    // const progress = Math.round((processedFiles / totalFiles) * 100);
                }
            }

            if (successCount === 0) {
                throw new Error('No files were downloaded successfully');
            }

            const currentDate = new Date().toISOString().split('T')[0];
            const productName = data?.orderId || 'product';
            const zipFileName = `${productName}_${currentDate}.zip`;

            const content = await zip.generateAsync({
                type: 'blob',
                compression: 'DEFLATE',
                compressionOptions: { level: 6 }
            });

            saveAs(content, zipFileName);

            alert(`Downloaded ${successCount} files successfully.${failedUrls.length > 0 ? `\n${failedUrls.length} files failed.` : ''}`);
        } catch (error) {
            console.error('Error in download process:', error);
            alert('Failed to download files. Please check console for details.');
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <>
            <Navbar />
            <div style={{ background: '#F8F8F8', paddingBottom: '10%' }}>
                <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                    <div>
                        <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ opacity: '0.6' }} onClick={() => navigate('/admin/order-management')}>Order Management</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Action</span></p>

                    </div>
                    <div className='d-flex cent'>
                        <h3 style={{ fontSize: '28px' }}>Order Details</h3>
                        <hr className='ms-3 hrrr1' style={{ height: '2px', width: '85%', background: 'black' }} />
                    </div>
                </div>
                <span className='d-none'><PrintoutPage data={data} /></span>
                <div className='container mt-5' style={{
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    color: '#000',
                    borderRadius: '10px',
                    border: ' 1px solid #DFDFDF',
                    padding: '30px'
                }}>
                    {loading ? (
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: To add a semi-transparent background
                                zIndex: 1050, // Ensure it overlays other elements
                            }}
                        >
                            <Spinner>
                                Loading......
                            </Spinner>
                        </div>
                    ) : (
                        <>
                            <div style={{ marginLeft: '10px' }}>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex'>
                                        <p style={{ fontSize: '20px', fontWeight: '500', font: 'Manrope' }}>Order ID :</p>
                                        <div className='ms-3 mt-1'>
                                            <p style={{ color: "#262525", fontSize: '20px', fontWeight: '500' }}>{data?.orderId}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <p style={{ fontSize: '20px', fontWeight: '500', font: 'Manrope' }}>Order Status :</p>
                                        <div className='ms-3'>
                                            {getDeliveryStatus(data?.status)}
                                        </div>
                                    </div>
                                    {data?.status !== "ordercompleted" &&
                                        <div className='d-flex'>
                                            <Button style={{ background: '#E4510B', color: 'white', border: 'none' }}>
                                                <a target='_blank' rel="noreferrer" href={pdffile} style={{ background: '#E4510B', color: 'white', border: 'none' }} className='text-center text-decoration-none'>Download Invoice <FaRegFileLines size={20} /></a>
                                            </Button>
                                        </div>
                                    }

                                </div>
                                {data?.status === "ordercompleted" &&
                                    <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                        <div style={{ paddingLeft: '5%', paddingRight: '3%', width: '100%' }} className='d-flex mt-4'>
                                            <div>
                                                <p className='d-flex justify-content-center align-items-center mt-1 stage1' >1</p>
                                            </div>
                                            <img className='d-flex proces mt-3' src={step1} alt="" />
                                            <div>
                                                <p className='d-flex justify-content-center align-items-center mt-1 stage2' >2</p>
                                            </div>
                                            <img className='d-flex proces mt-3' src={step1} alt="" />
                                            <div>
                                                <p className='d-flex justify-content-center align-items-center mt-1 stage3' >3</p>
                                            </div>
                                            <hr />
                                        </div>
                                        <div style={{ paddingLeft: '2%', paddingRight: '2%' }} className='d-flex justify-content-between'>
                                            {/* <div>
                                                <Button className='statusUpdate1' onClick={handlechange}>
                                                    Update Status as Process
                                                </Button>
                                                <ChangestatusPage open={addModal} handlechange={handlechange} getdata={getdata} dataId={id} status={status} />
                                            </div> */}
                                            <p className='statusUpdate1'>Process</p>
                                            {/* <Button className='statusUpdate2'>Update Status as Shipped</Button> */}
                                            <p className='statusUpdate2'>Shipped</p>
                                            {/* <Button className='statusUpdate3'>Update Status as Delivered</Button> */}
                                            <p className='statusUpdate3'>Delivered</p>
                                        </div>
                                    </div>
                                }

                                {data?.status === "process" &&
                                    <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                        <div style={{ paddingLeft: '5%', paddingRight: '3%', width: '100%' }} className='d-flex mt-4'>
                                            <div>
                                                <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                            </div>
                                            <img className='d-flex proces mt-3' src={step2} alt="" />
                                            <div>
                                                <p className='d-flex justify-content-center align-items-center mt-1 stage1' >2</p>
                                            </div>
                                            <img className='d-flex proces mt-3' src={step1} alt="" />
                                            <div>
                                                <p className='d-flex justify-content-center align-items-center mt-1 stage3' >3</p>
                                            </div>
                                            <hr />
                                        </div>
                                        <div style={{ paddingLeft: '2%', paddingRight: '2%' }} className='d-flex justify-content-between'>
                                            <div className='text-center' style={{ width: '200px', marginLeft: '-50px' }}>
                                                <p className='papa'>
                                                    Processed<br />
                                                    on {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleDateString()}
                                                    <br />
                                                    {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                </p>
                                            </div>
                                            {/* <div>
                                                <Button onClick={handlechange} className='statusUpdate1 ms-3'>Update Status as Shipped</Button>
                                                
                                                <ChangestatusPage open={addModal} handlechange={handlechange} getdata={getdata} dataId={id} status={status} address={data?.shipping} courier={data?.courierDetails} deliveryDate1={data?.expectedDeliveryDate?.split('T')[0]} /> 
                                        </div> */}
                                            <p style={{ marginLeft: '-40px' }} className='statusUpdate1'>Shipped</p>
                                            {/* <Button className='statusUpdate3'>Update Status as Delivered</Button> */}
                                            <p className='statusUpdate3'>Delivered</p>
                                        </div>
                                    </div>
                                }

                                {data?.status === "shipped" &&
                                    <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                        <div style={{ paddingLeft: '5%', paddingRight: '3%', width: '100%' }} className='d-flex mt-4'>
                                            <div>
                                                <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                            </div>
                                            <img className='d-flex proces mt-3' src={step2} alt="" />
                                            <div>
                                                <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                            </div>
                                            <img className='d-flex proces mt-3' src={step2} alt="" />
                                            <div>
                                                <p className='d-flex justify-content-center align-items-center mt-1 stage1' >3</p>
                                            </div>
                                            <hr />
                                        </div>
                                        <div style={{ paddingLeft: '2%', paddingRight: '2%' }} className='d-flex justify-content-between'>
                                            <div className='text-center' style={{ width: '200px', marginLeft: '-45px' }}>
                                                <p className='papa'>Processed<br />on {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleDateString()}
                                                    <br />
                                                    {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                            </div>
                                            <div className='text-center' style={{ width: '200px', marginLeft: '-45px' }}>
                                                <p className='papa'>Shipped<br />on {new Date(data?.tracking?.find(status => status.status === 'shipped')?.date).toLocaleDateString()}
                                                    <br />
                                                    {new Date(data?.tracking?.find(status => status.status === 'shipped')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>

                                            </div>
                                            {/* <div>
                                                <Button style={{ marginRight: '-70px' }} onClick={handlechange} className='statusUpdate1'>Update Status as Delivered</Button>
                                                <ChangestatusPage open={addModal} handlechange={handlechange} getdata={getdata} dataId={id} status={status} />
                                            </div> */}
                                            <p className='statusUpdate1'>Delivered</p>
                                        </div>
                                    </div>
                                }

                                {data?.status === "delivered" &&
                                    <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                        <div style={{ paddingLeft: '5%', paddingRight: '3%', width: '100%' }} className='d-flex mt-4'>
                                            <div>
                                                <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                            </div>
                                            <img className='d-flex proces mt-3' src={step2} alt="" />
                                            <div>
                                                <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                            </div>
                                            <img className='d-flex proces mt-3' src={step2} alt="" />
                                            <div>
                                                <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                            </div>
                                            <hr />
                                        </div>
                                        <div style={{ paddingLeft: '2%', paddingRight: '2%' }} className='d-flex justify-content-between'>
                                            <div className='text-center' style={{ width: '200px', marginLeft: '-45px' }}>
                                                <p className='papa'>Processed<br />on {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleDateString()}
                                                    <br />
                                                    {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                            </div>
                                            <div className='text-center' style={{ width: '200px', marginLeft: '-10px' }}>
                                                <p className='papa'>Shipped<br />on {new Date(data?.tracking?.find(status => status.status === 'shipped')?.date).toLocaleDateString()}
                                                    <br />
                                                    {new Date(data?.tracking?.find(status => status.status === 'shipped')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>

                                            </div>
                                            <div className='text-center' style={{ width: '200px', marginRight: '-60px' }}>
                                                <p className='papa'>Delivered<br />on {new Date(data?.tracking?.find(status => status.status === 'delivered')?.date).toLocaleDateString()}
                                                    <br />
                                                    {new Date(data?.tracking?.find(status => status.status === 'delivered')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>

                                            </div>
                                        </div>
                                    </div>
                                }
                                {data?.status === "delivered" ? (null) : (
                                    <div style={{ marginRight: '70px' }} className='d-flex justify-content-end'>
                                        <Button style={{ marginRight: '-70px' }} onClick={handlechange} className='overview-btn'>Update Status</Button>
                                        <ChangestatusPage open={addModal} handlechange={handlechange} getdata={getdata} dataId={id} status={status} address={data?.shipping} courier={data?.courierDetails} deliveryDate1={data?.expectedDeliveryDate?.split('T')[0]} />
                                        {/* <ChangestatusPage open={addModal} handlechange={handlechange} getdata={getdata} dataId={id} status={status} /> */}
                                    </div>
                                )}

                            </div>
                            <hr></hr>
                            <div id="invoice-container" >

                                <div style={{ marginLeft: '10px', marginTop: '30px' }} >
                                    <Row>
                                        <Col lg={12}>
                                            <p style={{ color: '#333333', fontSize: '20px', fontWeight: '700', fontFamily: 'Lato' }}>Order info</p>
                                        </Col>
                                        <Col lg={4} >
                                            <div className='mb-1 d-flex' >
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '150px' }}>Name<span ></span></p>
                                                        <p className='title1'>: {data?.shipping?.firstName} {data?.shipping?.lastName}</p>
                                                    </div>
                                                </Label>
                                            </div>
                                            <div className='mb-1 d-flex' >
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '150px' }}>Email ID<span ></span></p>
                                                        <p className='title1'>: {data?.shipping?.email} </p>
                                                    </div>
                                                </Label>
                                            </div>
                                            <div className='mb-1 d-flex' >
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '150px' }}>Mobile Number<span ></span></p>
                                                        <p className='title1'>: {data?.shipping?.mobile}</p>
                                                    </div>
                                                </Label>
                                            </div>
                                            <div className='mb-1 d-flex' >
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '150px' }}>Alternate Number<span ></span></p>
                                                        <p className='title1'>: {data?.shipping?.alternateMobile}</p>
                                                    </div>
                                                </Label>
                                            </div>


                                        </Col>
                                        <Col lg={4} >
                                            <div >
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '90px' }}>Address</p>
                                                        <p className='ms-4 title1' >: {data?.shipping?.address}, {data?.shipping?.landmark},{data?.shipping?.city},{data?.shipping?.state}</p>
                                                    </div>
                                                </Label>
                                            </div>
                                            <div >
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '90px' }}>Land Mark<span ></span></p>
                                                        <p className='title1'>: {data?.shipping?.landmark}</p>
                                                    </div>
                                                </Label>
                                            </div>
                                            <div>
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '90px' }}>Pincode<span ></span></p>
                                                        <p className='title1'>: {data?.shipping?.pincode}</p>
                                                    </div>
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col className='mt-2' lg={4} >
                                            <div >
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '190px' }}>GST Number<span ></span></p>
                                                        <p className='title1'>: {data?.gstDetails?.gstNo}</p>
                                                    </div>
                                                    {/* <span style={{ color: 'gray', fontSize: '15px' }}> </span>
                                        <span>  </span> */}
                                                </Label>
                                            </div>
                                            <div >
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '190px' }}>HSN<span ></span></p>
                                                        <p className='title1'>
                                                            :{data?.products?.length > 0 ? data.products[0].product?.hsnCode : "HSN Code not available"}
                                                        </p>

                                                    </div>
                                                    {/* <span style={{ color: 'gray', fontSize: '15px' }}> </spa
                                        <span>  </span> */}
                                                </Label>
                                            </div>
                                            <div>
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '190px' }}>Ordered Date<span ></span></p>
                                                        <p className='title1'>: {new Date(data?.createdAt).toLocaleDateString()}</p>
                                                    </div>
                                                </Label>
                                            </div>
                                            <div >
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '190px' }}>Expected Delievery Date<span ></span></p>
                                                        <p className='title1'>: {new Date(data?.expectedDeliveryDate).toLocaleDateString()}</p>
                                                    </div>
                                                </Label>
                                            </div>

                                        </Col>

                                    </Row>
                                </div>
                                <hr></hr>
                                <Col lg={12}>
                                    <p style={{ color: '#333333', fontSize: '20px', fontWeight: '700', fontFamily: 'Lato' }}>Product</p>
                                </Col>
                                <div>
                                    <Row>
                                        <Col lg={8}>
                                            <div style={{ border: '1px solid #F0F0F0', borderRadius: '15px', padding: '20px', height: 'auto' }} className="d-block">
                                                {data?.products && data.products.length > 0 ? (
                                                    <>
                                                        <div>
                                                            <p style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Lato', color: '#2B2B2D' }}>
                                                                {data.products[0]?.product?.name}
                                                            </p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-block'>
                                                                <div className="d-flex">
                                                                    <div>
                                                                        {data?.products[0]?.customizeImages?.[0] ? (
                                                                            <img
                                                                                alt={`product`}
                                                                                src={data?.products[0]?.customizeImages[0]?.img_url}
                                                                                className='produ-img'
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                {data?.products[0]?.customizeImages?.length === 0 && data?.products[0]?.file?.length === 0 ?
                                                                                    (
                                                                                        <img
                                                                                            alt={`product`}
                                                                                            src={data?.products[0]?.product?.image}
                                                                                            className='produ-img'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            alt={`product`}
                                                                                            src={data?.products[0]?.file[0]?.endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : data?.products[0]?.file[0]}
                                                                                            className='produ-img'
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </>
                                                                            // <a href={eachItem?.file?.[0]} target='_blank'>

                                                                            // </a>
                                                                        )}
                                                                    </div>
                                                                    <div className='d-block'>
                                                                        <div className='d-flex'>
                                                                            <div className="d-block ms-3">
                                                                                {data.products[0]?.field?.map((field, i) => (
                                                                                    <div style={{ marginTop: i !== 0 ? '-10px' : '0' }} key={i}>
                                                                                        <div className="d-flex">
                                                                                            <p className="title" style={{ width: '120px', fontWeight: '400' }}>
                                                                                                {field?.fieldType?.name}
                                                                                            </p>
                                                                                            <p className="title1">: <span className="ms-2">{field?.option?.name}</span></p>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            <div className="d-block ms-5">
                                                                                <div className="d-flex">
                                                                                    <p className="title" style={{ width: '120px', fontWeight: '400' }}>Quantity</p>
                                                                                    <p className="title1">: <span className="ms-2">{data.products[0]?.quantity}</span></p>
                                                                                </div>
                                                                                <div style={{ marginTop: '-10px' }}>
                                                                                    <div className="d-flex">
                                                                                        <p className="title" style={{ width: '120px', fontWeight: '400' }}>Total Amount</p>
                                                                                        <p className="title1">: <span className="ms-2">{data.products[0]?.total}</span></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='ms-3'>
                                                                            {data?.products[0]?.sizeWithQuantity?.length > 0 && (
                                                                                <>
                                                                                    {data?.products[0]?.sizeWithQuantity[0]?.quantity ? (
                                                                                        <div className='mt-2'>
                                                                                            <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                                                                <div className='d-flex' onClick={() => toggleAccordion1(data?.products[0]?._id)}>
                                                                                                    <h6 style={{ color: '#858585', fontWeight: '400' }} className=' p-0'>Size with Quantity</h6>
                                                                                                    <span className='ms-2 p-0'><ChevronDown /></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <Collapse isOpen={openAccordion1 === data?.products[0]?._id}>
                                                                                                <div className='accordion-body'>
                                                                                                    <div className='mt-2 mb-2'>
                                                                                                        <table className="table">
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th>S. No</th>
                                                                                                                    <th>Size</th>
                                                                                                                    <th>Quantity</th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                {data?.products[0]?.sizeWithQuantity?.map((item, index) => (
                                                                                                                    <tr key={index}>
                                                                                                                        <td>{index + 1}</td>
                                                                                                                        <td>{item.size || 'N/A'}</td>
                                                                                                                        <td>{item.quantity || 'N/A'}</td>
                                                                                                                    </tr>
                                                                                                                ))}
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                    {/* <div>
                                                                                                {data?.products[0]?.sizeWithQuantity?.map((eachDetail, k) => (
                                                                                                    <div className='d-flex justify-content-between' key={k}>
                                                                                                        <p className='text-secondary'><ChevronRight /> {eachDetail?.size}</p>
                                                                                                        <p className='pe-5 text-dark' style={{ fontWeight: '500' }}>- {eachDetail?.quantity}</p>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div> */}
                                                                                                </div>
                                                                                            </Collapse>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className='mt-2'>
                                                                                            <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                                                                <div className='d-flex' onClick={() => toggleAccordion1(data?.products[0]?._id)}>
                                                                                                    <h6 style={{ color: '#858585', fontWeight: '400' }} className=' p-0'>Names & Numbers</h6>
                                                                                                    <span className='ms-2 p-0'><ChevronDown /></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <Collapse isOpen={openAccordion1 === data?.products[0]?._id}>
                                                                                                <div className='accordion-body'>
                                                                                                    <div className='mt-2 mb-2'>
                                                                                                        <table className="table">
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th>S. No</th>
                                                                                                                    <th>Name</th>
                                                                                                                    <th>Number</th>
                                                                                                                    <th>Size</th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                {data?.products[0]?.sizeWithQuantity?.map((item, index) => (
                                                                                                                    <tr key={index}>
                                                                                                                        <td>{index + 1}</td>
                                                                                                                        <td>{item.name || 'N/A'}</td>
                                                                                                                        <td>{item.number || 'N/A'}</td>
                                                                                                                        <td>{item.size || 'N/A'}</td>
                                                                                                                    </tr>
                                                                                                                ))}
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Collapse>
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='d-block ms-5'>
                                                                <div>
                                                                    {isDownloading ? (
                                                                        <div style={{ height: "auto", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <Spinner
                                                                                color="primary"
                                                                            >
                                                                                Loading...
                                                                            </Spinner>
                                                                        </div>
                                                                    ) : (
                                                                        <Button style={{ color: 'white', background: '#E4510B', border: 'none' }} onClick={handleDownload}>
                                                                            Download <MdOutlineFileDownload size={25} style={{ color: 'white' }} />
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <Button
                                                                        className="mt-2"
                                                                        style={{
                                                                            color: '#E4510B',
                                                                            background: 'white',
                                                                            border: '1px solid #E4510B',
                                                                            fontWeight: '400',
                                                                        }}
                                                                        onClick={() => {
                                                                            // Retrieve file URL from data, prioritizing `file` over `customizeImages`
                                                                            const fileUrl =
                                                                                data?.products?.[0]?.file?.[0] ||
                                                                                data?.products?.[0]?.product?.image ||
                                                                                data?.products?.[0]?.customizeImages?.[0];

                                                                            // Check if fileUrl is a valid string
                                                                            if (typeof fileUrl === 'string') {
                                                                                if (fileUrl.endsWith('.pdf')) {
                                                                                    // Open PDF in a new tab
                                                                                    window.open(fileUrl, '_blank', 'noopener,noreferrer');
                                                                                } else {
                                                                                    // View custom data for non-PDF files
                                                                                    viewCustom(data?.products[0]);
                                                                                }
                                                                            } else {
                                                                                // Display an alert if no valid file URL is found
                                                                                alert('No valid file available to view.');
                                                                            }
                                                                        }}
                                                                    >
                                                                        View <MdOutlineRemoveRedEye style={{ color: '#E4510B' }} />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <p>No product data available.</p>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={4} >
                                            <div style={{ border: '1px solid #F0F0F0', borderRadius: '15px', padding: '20px' }}>
                                                <Col lg={12}>
                                                    <p style={{ color: '#333333', fontSize: '20px', fontWeight: '700', fontFamily: 'Lato' }}>Payment info</p>
                                                </Col>
                                                <div className='mt-4' style={{ marginLeft: '10px' }}>
                                                    <div style={{ marginTop: '-15px' }}>
                                                        <Label>
                                                            <div className='d-flex'>
                                                                <p className='title' style={{ width: '130px', fontWeight: '400' }}>Payment Method<span ></span></p>
                                                                <p className='title1'>: <span className='ms-2'>{data?.payment?.mode}</span></p>
                                                            </div>
                                                        </Label>
                                                    </div>
                                                    {data?.payment?.mode !== "cash" &&
                                                        <>
                                                            <div style={{ marginTop: '-15px' }}>
                                                                <Label>
                                                                    <div className='d-flex'>
                                                                        <p className='title' style={{ width: '130px', fontWeight: '400' }}>Transaction Id<span ></span></p>
                                                                        <p className='title1'>: <span className='ms-2'>{data?.payment?.paymentId?.bank_transaction_id}</span></p>
                                                                    </div>
                                                                </Label>
                                                            </div>
                                                            <div style={{ marginTop: '-15px' }}>
                                                                <Label>
                                                                    <div className='d-flex'>
                                                                        <p className='title' style={{ width: '130px', fontWeight: '400' }}>Paid On<span ></span></p>
                                                                        <p className='title1'>: <span className='ms-2'>{new Date(data?.payment?.paymentId?.paidOn).toLocaleDateString()}</span></p>
                                                                    </div>
                                                                </Label>
                                                            </div>
                                                        </>
                                                    }
                                                    <div style={{ marginTop: '-15px' }}>
                                                        <Label>
                                                            <div className='d-flex'>
                                                                <p className='title' style={{ width: '130px', fontWeight: '400' }}>Product Amount<span ></span></p>
                                                                <p className='title1'>:  <span className='ms-2'>{data?.totalGrossAmount ? data?.totalGrossAmount.toLocaleString("en-IN", {
                                                                    style: "currency",
                                                                    currency: "INR",
                                                                }) : '0.00'}</span></p>
                                                            </div>
                                                        </Label>
                                                    </div>
                                                    <div style={{ marginTop: '-15px' }}>
                                                        <Label>
                                                            <div className='d-flex'>
                                                                <p className='title' style={{ width: '130px', fontWeight: '400' }}>Discount<span ></span></p>
                                                                <p style={{ color: '#308505' }} className='title1'>:<span className='ms-2'>- {data?.totalDiscount ? data?.totalDiscount.toLocaleString("en-IN", {
                                                                    style: "currency",
                                                                    currency: "INR",
                                                                }) : '0.00'}</span></p>
                                                            </div>
                                                        </Label>
                                                    </div>
                                                    <div style={{ marginTop: '-15px' }}>
                                                        <Label>
                                                            <div className="d-flex">
                                                                <p className="title" style={{ width: '130px', fontWeight: '400' }}>
                                                                    Coupon Discount<span></span>
                                                                </p>
                                                                <p style={{ color: '#308505' }} className="title1">
                                                                    :
                                                                    <span className="ms-2">
                                                                        - {Array.isArray(data?.products) && data?.products[0]?.couponDiscount
                                                                            ? data.products[0].couponDiscount.toLocaleString("en-IN", {
                                                                                style: "currency",
                                                                                currency: "INR",
                                                                            })
                                                                            : '0.00'}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </Label>
                                                    </div>
                                                    <div style={{ marginTop: '-15px' }}>
                                                        <Label>
                                                            <div className="d-flex">
                                                                <p className="title" style={{ width: '130px', fontWeight: '400' }}>
                                                                    Delivery Fee<span></span>
                                                                </p>
                                                                <p className="title1">
                                                                    : <span className="ms-2" style={{ color: data?.shippingCharge === 0 ? '#308505' : 'inherit' }}>
                                                                        {data?.shippingCharge === 0
                                                                            ? 'Free'
                                                                            : data?.shippingCharge?.toLocaleString("en-IN", {
                                                                                style: "currency",
                                                                                currency: "INR",
                                                                            }) || '0.00'}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </Label>
                                                    </div>
                                                    <div style={{ marginTop: '-15px' }}>
                                                        <Label>
                                                            <div className='d-flex'>
                                                                <p className='title' style={{ width: '130px', fontWeight: '400' }}>Total Amount<span ></span></p>
                                                                <p className='title1'>:  <span className='ms-2'>{data?.totalAmount ? data?.totalAmount.toLocaleString("en-IN", {
                                                                    style: "currency",
                                                                    currency: "INR",
                                                                }) : '0.00'}</span></p>
                                                            </div>
                                                        </Label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className='mt-3 d-flex gap-2 flex-column flex-lg-row justify-content-between align-items-center'>
                                                <p className='p-0 m-0' style={{ color: '#333333', fontSize: '20px', fontWeight: '700', fontFamily: 'Lato' }}>Notes {data?.status === "ordercompleted" ? (
                                                    <span className='fw-normal fs-6'>(Add Notes for your customer about the product/process)</span>
                                                ) : (
                                                    <span className='fw-normal fs-6'>(Added Notes for your customer about the product/process)</span>
                                                )}</p>
                                                {data?.status === "ordercompleted" &&
                                                    <Button onClick={handleNoteModal} style={{ color: 'white', background: '#E4510B', border: 'none' }}>
                                                        {/* Print <IoPrintOutline size={25} style={{ color: 'white' }} /> */}
                                                        Add Notes <Plus size={20} style={{ color: 'white' }} />
                                                    </Button>
                                                }
                                            </div>
                                            <div className='mt-5'>
                                                <Row>
                                                    {data?.orderNotes?.map((item, i) => (
                                                        <Col key={i} sm={12} md={6} lg={4}>
                                                            <div style={{ border: '1px solid gray' }} className='mb-3 p-3 text-secondary rounded-1'>
                                                                <div>
                                                                    <p className='text-secondary'><span className='text-danger'><AlertCircle size={18} /></span>  {item?.notes}</p>
                                                                    <img style={{ width: '100px' }} src={item?.img_url} alt="" />

                                                                    <div className='d-flex justify-content-end align-item-between'>
                                                                        {timeAgo(item?.createdAt)}
                                                                    </div>

                                                                    {data?.status === "ordercompleted" && item?.createdBy === "customer" &&
                                                                        <>
                                                                            <hr />
                                                                            {item?.approvalStatus === "pending" ?
                                                                                (
                                                                                    <div>
                                                                                        <Button onClick={() => (orderNotesUpdate("approved", item?._id))} style={{ color: 'white', background: '#E4510B', border: 'none' }}>
                                                                                            {/* Print <IoPrintOutline size={25} style={{ color: 'white' }} /> */}
                                                                                            Approve
                                                                                        </Button>
                                                                                        <Button className='ms-2' onClick={() => (orderNotesUpdate("rejected", item?._id))} style={{ color: 'white', background: '#E4510B', border: 'none' }}>
                                                                                            {/* Print <IoPrintOutline size={25} style={{ color: 'white' }} /> */}
                                                                                            Reject
                                                                                        </Button>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div>
                                                                                        <p>Status:{item?.approvalStatus}</p>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div >
                        </>
                    )}
                    <Preview modal={modal} toggle={toggle} cardImages={customizeImages} />
                    <Modal isOpen={openNoteModal} toggle={handleNoteModal} className='sidebar-lg' modalClassName='modal-slide-in sidebar-todo-modal' contentClassName='p-0' size='md' >
                        <ModalHeader toggle={handleNoteModal}>
                            <div className='d-flex align-items-center justify-content-between mb-1 px-1' style={{ backgroundColor: '#ffff' }}>
                                <p className='modal-title' style={{ fontFamily: 'Lato', fontSize: '20px', fontWeight: '400' }}>
                                    <b>Add Note</b>
                                </p>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <Label style={{ fontWeight: "500", color: '#262525' }}>
                                    <div className='d-flex'>
                                        <div>Notes</div>
                                    </div>
                                </Label>
                                <textarea onChange={(e) => setNoteText(e.target.value)} style={{ width: '100%', height: '100px', padding: '10px', borderRadius: '4px', border: '1px solid gray' }} placeholder='Enter Notes Here'>

                                </textarea>
                            </div>
                            <Label style={{ fontWeight: "500", color: '#262525' }}>
                                <div className='d-flex'>
                                    <div>Reference images:</div>
                                </div>
                            </Label>
                            <Card className='p-3' style={{ display: 'flex', border: '1px dashed #E4510B', width: "100%", justifyContent: 'center', alignItems: 'center' }}>

                                <div className="">
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {profileImages.map((image, index) => (
                                            <div key={index} className="m-2" style={{ position: 'relative' }}>
                                                <img
                                                    alt={`preview ${index + 1}`}
                                                    src={image}
                                                    style={{
                                                        width: '100px',
                                                        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                        cursor: 'pointer',
                                                    }}
                                                // onClick={() => setSelectedImageIndex(index)}
                                                />
                                                <div className="cursor-pointer image-close" style={{ position: 'absolute', top: '1px', right: '1px', cursor: 'pointer' }} onClick={() => handleRemoveImage(index)}>
                                                    <X style={{ width: '16px', height: '16px' }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                                        <input style={{ display: 'none' }} type="file" ref={inputRef1} accept=".jpeg,.png,.jpg,.jfif" onChange={handleProfileUpload} multiple />
                                        {loading ? (
                                            <Spinner />
                                        ) : (
                                            <div className='text-center text-primary' onClick={() => inputRef1?.current?.click()}>
                                                {profileImages.length === 0 ? (
                                                    <>
                                                        <h1> <RiImageAddFill /></h1>
                                                        <p>*Upload your Gallery Images</p>
                                                        <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h1> <RiImageAddFill /></h1>
                                                        <p>*Add more images</p>
                                                        <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Card>
                            <div className='d-bloick mt-2'>
                                <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                                <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}>Are you sure you want to update the Note to 'Customer'?</p>
                            </div>
                            <div className='d-flex gap-2 justify-content-end'>
                                <Button onClick={handleNoteModal} style={{ borderColor: '#E4510B', color: '#E4510B', backgroundColor: 'white' }}>Cancel</Button>
                                <Button onClick={orderNotesApi} disabled={!noteText} style={{ backgroundColor: '#E4510B', border: 'none' }}>Submit</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                </div >
            </div >
            <FooterSection />
        </>
    )
}

export default DashBoardViewPage

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { X } from 'react-feather'
import { Label, Modal, ModalBody } from 'reactstrap'
import { product, productCategory, topProductsAdd } from '../../../ApiConfigs/ApiConfig';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { customStylesSelect } from '../shipping/CourierList';

function AddTopProduct({ open, handleAdd, refetch }) {
    const [productCat, setProductCat] = useState("")
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [getProductData, setGetProductData] = useState([]);
    const [getData, setGetData] = useState([]);

    const getproductCategory = async () => {
        try {
            const response = await axios.get(productCategory);
            setGetData(response?.data?.result);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    };

    const getOptions = getData?.map((item) => ({
        label: item?.name,
        value: item?._id,
    }));

    const getProduct = async (id) => {
        try {
            const response = await axios.get(`${product}?topProducts=no&productCategory=${id}`);
            setGetProductData(response?.data?.result);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    };

    const getProductOptions = getProductData?.map((item) => ({
        label: item?.name,
        value: item?._id,
    }));

    useEffect(() => {
        getproductCategory();
    }, []);

    useEffect(() => {
        getProduct(productCat)
    }, [productCat])

    const finalSubmit = async () => {
        const payload = {
            products: selectedProduct
        }
        if (selectedProduct.length > 0) {
            try {
                const response = await axios.post(topProductsAdd, payload)
                handleAdd()
                refetch()
                toast.success(response?.data?.msg)
            } catch (error) {
                toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg)
            }
        } else {
            toast.error("Please select Products")
        }

    }

    return (
        <div className="modal-page">
            <Modal
                isOpen={open}
                toggle={handleAdd}
                className="sidebar-lg"
                modalClassName="modal-slide-in sidebar-todo-modal"
                contentClassName="p-0"
            >
                <div
                    className="d-flex align-items-center justify-content-between mb-1 px-1"
                    style={{ backgroundColor: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottom: '1px solid #EEEEEE' }}
                >
                    <h4
                        className="modal-title"
                        style={{
                            marginTop: '23px',
                            marginBottom: '23px',
                            paddingLeft: '10px',
                            fontFamily: 'roboto',
                            color: 'black',
                        }}
                    >
                        Add Top Product
                    </h4>
                    <X
                        style={{ color: 'black', cursor: 'pointer' }}
                        className="addmodal-close fw-normal mt-25 me-2"
                        size={25}
                        onClick={handleAdd}
                    />
                </div>
                <ModalBody className="flex-grow-1">
                    <div>
                        <div className="mb-1 mt-1">
                            <Label className="modal-label">Select Category  <span style={{ color: 'red' }}>*</span></Label>
                            <Select
                                type="select"
                                options={getOptions}
                                onChange={(e) => setProductCat(e.value)}
                            />
                        </div>
                    </div>
                    <div className="mb-1 mt-3">
                        <Label className="modal-label">Select Products</Label>
                        {productCat ? (
                            <Select
                                type="select"
                                options={getProductOptions}
                                isMulti // Enable multi-select
                                onChange={(selectedOptions) =>
                                    setSelectedProduct(selectedOptions.map((option) => option.value))
                                }
                                styles={customStylesSelect}
                            />
                        ) : (
                            <p style={{ color: 'gray', fontSize: '14px' }}>Please select a category first.</p>
                        )}
                    </div>
                    <div className='mt-3'>
                        <div className='d-flex'>
                            <button style={{ border: '1px solid #E4510B', color: '#E4510B', background: 'white', borderRadius: '10px' }} className='mt-1 w-50' type='button' onClick={handleAdd}>Cancel</button>
                            <button onClick={finalSubmit} className='mt-1 w-50 ms-2 add-modal-btn'>Submit</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default AddTopProduct

import React, { useEffect, useRef, useState } from 'react'
import { PlusCircle, Trash2, X } from 'react-feather';
import toast from 'react-hot-toast';
import { Button, Card, Col, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { uploadImage } from '../../upload';
import { RiImageAddFill } from 'react-icons/ri';
// import { idText } from 'typescript';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { customizeDesign } from '../../../../ApiConfigs/ApiConfig';

function AddClipArt({ modal, toggle, editData, refetch }) {
    const { id } = useParams()
    const [colorProducts, setColorProducts] = useState([
        {
            colourCode: '',
            images: [{ name: '', image: '' }]
        }
    ]);
    const [loaders, setloaders] = useState(false)
    const imageRefs = useRef([]);

    const handleCustomImageUpload = async (e, colorIndex, imageIndex) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const localImageUrl = URL.createObjectURL(file); // Create a local URL for the image

            try {
                setloaders(true);
                const formData = new FormData();
                formData.append('file', file);

                // Perform the image upload to your server
                const uploadData = await uploadImage(formData);

                if (uploadData && uploadData.result && uploadData.result.length) {
                    const uploadedImageUrl = uploadData.result[0].location;

                    // Set both the local image URL and the uploaded image URL
                    const updatedColorProducts = [...colorProducts];
                    updatedColorProducts[colorIndex].images[imageIndex] = {
                        name: updatedColorProducts[colorIndex].images[imageIndex]?.name || '',
                        image: uploadedImageUrl,
                        localImageUrl, // Use localImageUrl to preview before upload completes
                    };

                    setColorProducts(updatedColorProducts);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setloaders(false);
            }
        }
    }

    const finalSubmit = async () => {
        const payload = {
            productCategory: id,
            name: colorProducts[0].colourCode,
            images: colorProducts[0].images.map(imageData => ({
                name: imageData.name,
                img_url: imageData.image
            }))
        }

        if (editData) {
            try {
                await axios.put(`${customizeDesign}/${editData?._id}`, payload);  // No need to assign the response
                toast.success('Saved Successfully');
                refetch();
                toggle();
                setColorProducts([
                    {
                        colourCode: '',
                        images: [{ name: '', image: '' }]
                    }
                ]);
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            }
        } else {
            try {
                await axios.post(`${customizeDesign}`, payload);  // No need to assign the response
                toast.success('Submitted Successfully');
                refetch();
                toggle();
                setColorProducts([
                    {
                        colourCode: '',
                        images: [{ name: '', image: '' }]
                    }
                ]);
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            }
        }
    }

    useEffect(() => {
        if (editData) {
            setColorProducts([
                {
                    colourCode: editData.name || '',  // Set default value for colourCode
                    images: editData.images.map(img => ({
                        name: img.name || '',  // Set image name
                        image: img.img_url || ''  // Set image URL
                    })) || [{ name: '', image: '' }]  // Fallback for images if no data exists
                }
            ]);
        }
    }, [editData]);

    const toggleModal = () => {
        setColorProducts([
            {
                colourCode: '',
                images: [{ name: '', image: '' }]
            }
        ])
        toggle()
    }

    return (
        <Modal isOpen={modal} toggle={toggleModal}>
            <div className="d-flex align-items-center justify-content-between mb-1 px-1" style={{ backgroundColor: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottom: '1px solid #EEEEEE' }}>
                <h4 className="modal-title" style={{ marginTop: '23px', marginBottom: '23px', paddingLeft: '10px', fontFamily: 'roboto', color: '#2B2B2D' }}>
                    {editData ? 'Edit Clip Art' : 'Add Clip Art'}
                </h4>
                <X style={{ color: 'black', cursor: 'pointer' }} className="addmodal-close fw-normal mt-25 me-2" size={25} onClick={toggleModal} />
            </div>
            <ModalBody className='flex-grow-1'>
                <div>
                    {colorProducts.map((colorProduct, colorIndex) => (
                        <div key={colorIndex} className='mt-3'>
                            <Row className='mb-3'>
                                <Col sm={12} md={12} lg={12}>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Menu Name</Label>
                                    <div className='d-flex'>
                                        <Input
                                            type="text"
                                            style={{ height: '44px', width: '100%' }}
                                            value={colorProduct.colourCode}
                                            onChange={(e) => {
                                                const updatedColorProducts = [...colorProducts];
                                                updatedColorProducts[colorIndex].colourCode = e.target.value;
                                                setColorProducts(updatedColorProducts);
                                            }}
                                            placeholder='Enter your menu name'
                                        />
                                        {/* <div
                                            className='ms-2'
                                            style={{ cursor: 'pointer', color: "#AF1B1B" }}
                                            onClick={() => {
                                                const updatedColorProducts = colorProducts.filter((_, i) => i !== colorIndex);
                                                setColorProducts(updatedColorProducts);
                                            }}
                                        >
                                            <Trash2 />
                                        </div> */}
                                    </div>
                                </Col>
                            </Row>
                            {colorProduct.images.map((imageData, imageIndex) => (
                                <div key={imageIndex} className='mt-3'>
                                    <Row className='d-flex justify-content-center'>
                                        <Col sm={12} md={5} lg={6}>
                                            <div className='mb-3'>
                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Name</Label>
                                                <Input
                                                    type='text'
                                                    style={{ height: '44px' }}
                                                    value={imageData.name}
                                                    placeholder='Enter image name'
                                                    onChange={(e) => {
                                                        const updatedColorProducts = [...colorProducts];
                                                        updatedColorProducts[colorIndex].images[imageIndex].name = e.target.value;
                                                        setColorProducts(updatedColorProducts);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={12} md={5} lg={4}>
                                            <div className='mb-3'>
                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Image</Label>
                                                <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                    <input
                                                        style={{ display: 'none' }}
                                                        type="file"
                                                        accept=".jpeg,.png,.jpg,.jfif"
                                                        ref={el => (imageRefs.current[colorIndex * 100 + imageIndex] = el)}
                                                        onChange={(e) => handleCustomImageUpload(e, colorIndex, imageIndex)}
                                                    />
                                                    {loaders ?
                                                        (
                                                            <Spinner color='primary' />
                                                        ) : (
                                                            <Card
                                                                style={{ width: "150px", height: "150px", justifyContent: "center", alignItems: "center" }}
                                                                onClick={() => imageRefs.current[colorIndex * 100 + imageIndex]?.click()}
                                                            >
                                                                {imageData.image ? (
                                                                    <>
                                                                        <img
                                                                            alt="uploaded"
                                                                            src={imageData.image}
                                                                            style={{ width: '60px', objectFit: 'cover' }}
                                                                        />
                                                                        <div
                                                                            className='image-remove'
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '5px',
                                                                                right: '1px',
                                                                                cursor: 'pointer',
                                                                                color: 'red',
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation(); // Prevent triggering the file input click
                                                                                const updatedColorProducts = [...colorProducts];
                                                                                updatedColorProducts[colorIndex].images[imageIndex].image = '';
                                                                                setColorProducts(updatedColorProducts);
                                                                            }}
                                                                        >
                                                                            <X />
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className='text-primary d-flex align-items-center w-75 h-75'>
                                                                        <div className='text-center'>
                                                                            <h1 style={{ color: "primary" }}><RiImageAddFill size={20} /></h1>
                                                                            <p style={{ fontSize: '12px' }}>*Upload your Custom Image</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Card>
                                                        )}
                                                </div>
                                            </div>
                                        </Col>

                                        <Col sm={2} md={2} lg={2}>
                                            <div
                                                className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3'
                                                style={{ cursor: 'pointer', color: "#AF1B1B" }}
                                                onClick={() => {
                                                    const updatedColorProducts = [...colorProducts];
                                                    updatedColorProducts[colorIndex].images = updatedColorProducts[colorIndex].images.filter((_, i) => i !== imageIndex);
                                                    setColorProducts(updatedColorProducts);
                                                }}
                                            >
                                                <Trash2 />
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                </div>
                            ))}

                            <div className='d-flex'>
                                <Button
                                    type="button"
                                    className='overview-btn'
                                    onClick={() => {
                                        const updatedColorProducts = [...colorProducts];
                                        updatedColorProducts[colorIndex].images.push({ name: '', image: '' });
                                        setColorProducts(updatedColorProducts);
                                    }}
                                >
                                    <PlusCircle /> Add Image
                                </Button>
                            </div>
                            <hr />
                        </div>
                    ))}

                    {/* <div className='d-flex mt-3 s'>
                        <Button
                            type="button"
                            className='overview-btn'
                            onClick={() => setColorProducts([...colorProducts, { colourCode: '', images: [{ name: '', image: '' }] }])}
                        >
                            <PlusCircle /> Add Color Product
                        </Button>
                    </div> */}

                    <div className='d-flex'>
                        <button style={{ border: '1px solid #E4510B', color: '#E4510B', background: 'white', borderRadius: '10px' }} className='mt-1 w-50' type='button' onClick={toggleModal}>Cancel</button>
                        <button className='mt-1 w-50 ms-2 add-modal-btn' onClick={finalSubmit}>{editData ? 'Save Changes' : 'Submit'}</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default AddClipArt

import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import Select from 'react-select'
import toast from 'react-hot-toast'
import { shippingCharge, state } from '../../../ApiConfigs/ApiConfig'
import * as yup from 'yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios'
import { PlusCircle, Trash2 } from 'react-feather'

export const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        minHeight: '50px', // increase height for more space
    }),
    multiValue: (provided) => ({
        ...provided,
        margin: '2px',
        maxWidth: '100%', // Ensures each selected item stays within container width
        overflow: 'hidden',
        whiteSpace: 'normal', // Enables text wrapping
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        whiteSpace: 'normal', // Enables text wrapping
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        cursor: 'pointer',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
}

function ShippingCharge({ toggle, modal, refetch, list }) {

    const [data, setData] = useState()
    const formSchema = yup.object().shape({
        fields: yup.array().of(
            yup.object().shape({
                state: yup
                    .object()
                    .nullable()
                    .required('Please select a state'),
                chargePer500gm: yup.string().required('Please enter charges'),
                additionalCharge: yup
                    .string()
                    .required('Please enter additional charges'),
            })
        ),
    });

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema),
        defaultValues: {
            fields: [],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'fields',
    });

    const fetchStates = async () => {
        try {
            const response = await axios.get(`${state}`);
            setData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    useEffect(() => {
        fetchStates();
    }, []);

    const options = data?.map((item) => ({
        value: item?._id,
        label: item?.state,
    })) || [];

    const selectedStates = watch('fields')?.map((field) => field.state?.value) || [];

    const getFilteredOptions = (currentIndex) => {
        return options.filter(
            (option) =>
                !selectedStates.includes(option.value) ||
                option.value === selectedStates[currentIndex]
        );
    };

    useEffect(() => {
        if (list) {
            const preloadedFields = list?.map((item) => ({
                state: {
                    value: item?.state._id,
                    label: item?.state.state,
                },
                chargePer500gm: item?.chargePer500gm?.toString(),
                additionalCharge: item.additionalCharge?.toString(),
            }));
            setValue('fields', preloadedFields);
        }
    }, [list, setValue]);

    const submitForm = async (data) => {
        const payload = {
            shippingCharges: data?.fields?.map((field) => ({
                state: field?.state?.value,
                chargePer500gm: field?.chargePer500gm,
                additionalCharge: field?.additionalCharge,
            })),
        };
        try {
            const response = await axios.post(shippingCharge, payload);
            toggle();
            refetch();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg);
        }
    };



    return (
        <Modal isOpen={modal} size="lg" toggle={toggle}>
            <ModalHeader toggle={toggle}>Shipping Charges</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(submitForm)}>
                    {fields.map((field, index) => (
                        <div key={field.id}>
                            <Row>
                                <Col lg={3}>
                                    <Label style={{ fontWeight: '500' }}>Select State</Label>
                                    <Controller
                                        name={`fields[${index}].state`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={getFilteredOptions(index)}
                                                placeholder="Select State"
                                                value={field.value}
                                                onChange={(selected) => field.onChange(selected)}
                                                styles={customStylesSelect}
                                            />
                                        )}
                                    />
                                    {errors.fields?.[index]?.state && (
                                        <FormFeedback>{errors.fields[index].state.message}</FormFeedback>
                                    )}
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-1">
                                        <Label className="modal-label">Charge Per 500gm</Label>
                                        <Controller
                                            name={`fields[${index}].chargePer500gm`}
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    type="text"
                                                    placeholder="Enter Charges"
                                                    invalid={errors.fields?.[index]?.chargePer500gm && true}
                                                />
                                            )}
                                        />
                                        {errors.fields?.[index]?.chargePer500gm && (
                                            <FormFeedback>{errors.fields[index].chargePer500gm.message}</FormFeedback>
                                        )}
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-1">
                                        <Label className="modal-label">Additional Charges</Label>
                                        <Controller
                                            name={`fields[${index}].additionalCharge`}
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    type="text"
                                                    placeholder="Enter Additional Charges"
                                                    invalid={errors.fields?.[index]?.additionalCharge && true}
                                                />
                                            )}
                                        />
                                        {errors.fields?.[index]?.additionalCharge && (
                                            <FormFeedback>{errors.fields[index].additionalCharge.message}</FormFeedback>
                                        )}
                                    </div>
                                </Col>
                                <Col lg={1}>
                                    <div
                                        className="mt-5"
                                        style={{ cursor: 'pointer', color: '#AF1B1B' }}
                                        onClick={() => remove(index)}
                                    >
                                        <Trash2 />
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    ))}
                    <div className="d-flex mt-4">
                        <Button
                            type="button"
                            className='overview-btn'
                            onClick={() =>
                                append({ state: null, chargePer500gm: '', additionalCharge: '' })
                            }
                        >
                            <PlusCircle /> Add Field
                        </Button>
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSubmit(submitForm)}>
                    Confirm
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ShippingCharge

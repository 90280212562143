import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PlusCircle, Trash2, Upload, X } from 'react-feather'
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Button, Col, Form, FormFeedback, Input, Label, Row, Spinner } from 'reactstrap'
import { toast } from 'react-hot-toast';
import "../addModal/index.css";
import { productCategoryAdmin, productCategoryApi, textPosition, viewPosition } from '../../../../ApiConfigs/ApiConfig';
import { uploadImage } from '../../upload';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../../navbar';
import Select from 'react-select';



function ProductCategoryEdit() {

    const inputRef = useRef(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const inputRef1 = useRef(null);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [profileIconUrl, setProfileIconUrl] = useState('');
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState(null)
    const [editData, setEditData] = useState(null)
    const [icon, setIcon] = useState(null)
    const [isNameNumber, setIsNameNumber] = useState(false)

    const formSchema = yup.object().shape({
        name: yup.string().required('Please Enter Categoty Name'),
        description: yup.string().required('Please Enter Your description'),
        hsnCode: yup.string().required('Please Enter Your HSN Code'),  // Correct casing
        minimumPrice: yup.number().required('Please Enter Your Starting Price'),
        metaTitle: yup.string().required('Please Enter Your Starting Price'),
        metaDescription: yup.string().required('Please Enter Your Starting Price'),
        metaKeywords: yup.string().required('Please Enter Your Starting Price'),
        category_url: yup.string().required('Please Enter Your Category URL'),
    });
    const {
        reset,
        control,
        handleSubmit,
        // setValue,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema),
        defaultValues: {
            name: editData?.name || "",
            description: editData?.description || "",
            hsnCode: editData?.hsnCode || "",
            minimumPrice: editData?.minimumPrice || "",
            category_url: editData?.category_url || "",
            metaTitle: editData?.metaTitle || "",
            metaDescription: editData?.metaDescription || "",
            metaKeywords: editData?.metaKeywords || "",
            positions: editData?.positions || []
        }
    });

    const getProduct = useCallback(async () => {
        try {
            const response = await axios.get(`${productCategoryAdmin}/${id}`);
            setEditData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    }, [id]); // Add 'id' as a dependency if it changes

    useEffect(() => {
        getProduct();
    }, [getProduct]);

    const { fields: fieldTypesFields, append: appendFieldType, remove: removeFieldType } = useFieldArray({
        control,
        name: "positions",
    });

    const [getOptionsFieldType, setOptionsFieldType] = useState([]);
    const [selectedPositions, setSelectedPositions] = useState({});
    const [fieldOptions, setFieldOptions] = useState([]);
    // Fetch options for the Position dropdown

    const getFieldType = useCallback(async () => {
        try {
            const response = await axios.get(viewPosition);
            const optionsFieldType = response?.data?.result || [];
            const filteredOptions = optionsFieldType.filter(item => {
                return !Object.values(selectedPositions).includes(item._id);
            });
            setOptionsFieldType(filteredOptions);
        } catch (error) {
            console.error("Error fetching field types:", error);
            toast.error(error?.response?.data?.msg || "Error fetching field types.");
        }
    }, [selectedPositions, viewPosition]); // Include 'selectedPositions' and 'viewPosition' as dependencies if they change

    useEffect(() => {
        getFieldType();
    }, [getFieldType]);

    const getFieldOption = async (id) => {
        try {
            const response = await axios.get(`${textPosition}?viewPosition=${id}`);
            setFieldOptions(response?.data?.result || []);
        } catch (error) {
            console.error("Error fetching TextOptions:", error);
            toast.error(error?.response?.data?.msg || "Error fetching TextOptions.");
        }
    };
    const customSide = getOptionsFieldType.map(item => ({
        label: item?.name,
        value: item?._id,
    })
    );
    const fieldOption = fieldOptions.map(item => ({
        label: item?.name,
        value: item?._id,
    })
    );

    const handlePositionChange = (selectedOption, index) => {
        const updatedSelectedPositions = { ...selectedPositions, [index]: selectedOption.value };
        setSelectedPositions(updatedSelectedPositions);
        getFieldOption(selectedOption.value);
    };
    const getFilteredOptions = (index) => {
        return customSide.filter(option => !Object.values(selectedPositions).includes(option.value) || selectedPositions[index] === option.value);
    };

    const customStyles = (isMulti) => ({
        control: (provided, state) => ({
            ...provided,
            height: 'auto', // Set the height to auto
            minHeight: isMulti ? '38px' : '40px', // Set a minimum height
            padding: '5px', // Add some padding
            overflow: 'hidden', // Prevent overflow
        }),
        multiValue: (provided) => ({
            ...provided,
            maxWidth: '100%', // Allow multi values to take full width
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            overflow: 'hidden',
            whiteSpace: 'nowrap', // Prevent text wrapping
            textOverflow: 'ellipsis', // Add ellipsis for overflow text
        }),
    });

    const submitForm = async (data) => {
        // const image = profileImageUrl;
        // const icon = profileIconUrl;
        const hsnCode = data?.hsnCode;
        const position = {
            position: data?.positions?.map((position) => ({
                viewPosition: position?.viewPosition?.value,
                textPositions: position?.textPositions?.map((textPosition) => textPosition?.value),
            })),
        };
        const payload = {
            ...data,
            image: profileImageUrl,
            icon: profileIconUrl,
            hsnCode, // Ensure the correct `hsnCode` is sent 
            customizedPrinting: isNameNumber,
            positions: position?.position,
            minimumPrice: parseFloat(data?.minimumPrice)
        }

        try {
            const response = await axios.put(`${productCategoryApi}/${editData._id}`, payload);
            // handleEdit();
            // additionalData();
            // statsFetch();
            navigate('/admin/productCategory');
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    // useEffect(() => {
    //     reset({
    //         name: editData?.name,
    //         description: editData?.description,
    //         hsnCode: editData?.hsnCode,
    //         minimumPrice: editData?.minimumPrice,
    //         category_url: editData?.category_url,
    //         metaTitle: editData?.metaTitle,
    //         metaDescription: editData?.metaDescription,
    //         metaKeywords: editData?.metaKeywords,
    //         positions: editData?.positions
    //     });
    //     setProfileImageUrl(editData?.image);
    //     setProfileIconUrl(editData?.icon);
    //     setImage(editData?.image);
    //     setIcon(editData?.icon);
    //     setIsNameNumber(editData?.customizedPrinting);
    //     setSelectedPositions(editData?.positions?.map((eachPosition) => eachPosition?.viewPosition?._id))
    // }, [editData, reset, setValue]);

    // Set form values when edit data is loaded
    useEffect(() => {
        if (editData) {
            // Set basic form fields
            reset({
                name: editData?.name || "",
                description: editData?.description || "",
                hsnCode: editData?.hsnCode || "",
                minimumPrice: editData?.minimumPrice || "",
                category_url: editData?.category_url || "",
                metaTitle: editData?.metaTitle || "",
                metaDescription: editData?.metaDescription || "",
                metaKeywords: editData?.metaKeywords || "",
                // Transform positions data to match form structure
                positions: editData?.positions?.map(position => ({
                    viewPosition: {
                        label: position?.viewPosition?.name,
                        value: position?.viewPosition?._id
                    },
                    textPositions: position?.textPositions?.map(text => ({
                        label: text?.name,
                        value: text?._id
                    }))
                })) || []
            });

            // Set other state values
            setProfileImageUrl(editData?.image);
            setProfileIconUrl(editData?.icon);
            setImage(editData?.image);
            setIcon(editData?.icon);
            setIsNameNumber(editData?.customizedPrinting);

            // Set selected positions
            const positionsMap = {};
            editData?.positions?.forEach((position, index) => {
                positionsMap[index] = position?.viewPosition?._id;
            });
            setSelectedPositions(positionsMap);

            // Load text options for each position
            editData?.positions?.forEach(position => {
                if (position?.viewPosition?._id) {
                    getFieldOption(position.viewPosition._id);
                }
            });
        }
    }, [editData, reset]);

    const handleProfileUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setLoader(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileImageUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setLoader(false)
            }
        }
    };

    const handleIconUpload = async (e) => {

        if (e.target.files && e.target.files[0]) {
            setIcon(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setLoading(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileIconUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setLoading(false)
            }
        }
    };


    return (
        <>
            <Navbar />

            <div className='pt-5 pb-5' style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                <div className='d-flex fw-medium' style={{ color: '#E4510B' }} ><p style={{ cursor: 'pointer', color: '#333333' }} onClick={() => navigate('/admin/productCategory')}>Product Category {`>`}</p> Edit Product Category</div>
                <h1 className='mb-5'>Edit Product Category</h1>
                <div className='modal-page'>
                    <Form onSubmit={handleSubmit(submitForm)}>
                        <Row>
                            <Col lg={6} sm={12}>
                                <div className='mb-1 mt-1'>
                                    <Label className='modal-label'>Product Category Name <span className='text-danger'>*</span></Label>
                                    <Controller
                                        name='name'
                                        id='name'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input type="text" {...field} invalid={errors.name && true} placeholder="Enter Categoty Name"
                                        />)} />
                                    {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mb-1'>
                                    <Label className='modal-label'>Product Category Description <span className='text-danger'>*</span></Label>
                                    <Controller
                                        name='description'
                                        id='description'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input type="text" {...field} invalid={errors.description && true} placeholder="Enter Your Description" />)} />
                                    {errors.description && <FormFeedback>{errors.description.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mb-1'>
                                    <Label className='modal-label'>Product Category Basic Price <span className='text-danger'>*</span></Label>
                                    <Controller
                                        name='minimumPrice'
                                        id='minimumPrice'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input type="number" {...field} invalid={errors.description && true} placeholder="Enter Your Starting Price"
                                            onInput={(e) => {
                                                let inputValue = e.target.value;
                                                inputValue = inputValue.replace(/^0+/, '');
                                                e.target.value = inputValue.replace(/[^0-9]/g, '');
                                            }}
                                        />)} />
                                    {errors.minimumPrice && <FormFeedback>{errors.minimumPrice.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mb-1'>
                                    <Label className='modal-label'>
                                        HSN Code <span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name='hsnCode'
                                        id='hsnCode'
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: 'HSN code is required',
                                            pattern: {
                                                value: /^[0-9]{6}$/, // Ensures exactly 6 digits
                                                message: 'HSN code must be exactly 6 digits',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                style={{ height: '44px' }}
                                                type="text" // Use text to control input length easily
                                                maxLength={6} // Restrict the user to typing 6 digits
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                                                }}
                                                invalid={!!errors.hsnCode}
                                                placeholder="Enter Your HSN code"
                                            />
                                        )}
                                    />
                                    {errors.hsnCode && (
                                        <FormFeedback>{errors.hsnCode.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mt-3'>
                                    <Label className='modal-label me-2'>
                                        Need Customization Name/Number?{'  '}
                                    </Label>
                                    <Input
                                        type="switch"
                                        checked={isNameNumber}
                                        onClick={() => {
                                            setIsNameNumber(!isNameNumber);
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mb-1'>
                                    <Label className='modal-label'>Product Category URL <span className='text-danger'>*</span></Label>
                                    <Controller
                                        name='category_url'
                                        id='category_url'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input type="text" {...field} invalid={errors.category_url && true} placeholder="Enter Your Category URL" />)} />
                                    {errors.category_url && <FormFeedback>{errors.category_url.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={12} sm={12}>
                                <div className="mt-3">
                                    <Label className="modal-label me-2">Custom Position</Label>
                                    {fieldTypesFields.map((field, index) => (
                                        <div key={field.id} className="mb-4">
                                            <Row>
                                                <Col lg={5} sm={12}>
                                                    <Label style={{ fontWeight: 500, color: "#262525", display: "block" }}>Position</Label>
                                                    <Controller
                                                        name={`positions[${index}].viewPosition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                isSearchable={true}
                                                                styles={customStyles(true)}
                                                                id={`positions-${index}`}
                                                                options={getFilteredOptions(index)}
                                                                placeholder="Select the position"
                                                                onChange={(selectedOption) => {
                                                                    handlePositionChange(selectedOption, index);
                                                                    field.onChange(selectedOption);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.positions?.[index]?.viewPosition && (
                                                        <FormFeedback>{errors.positions[index]?.viewPosition.message}</FormFeedback>
                                                    )}
                                                </Col>
                                                <Col lg={6} sm={11}>
                                                    <Label style={{ fontWeight: 500, color: "#262525", display: "block" }}>TextOptions</Label>
                                                    <Controller
                                                        name={`positions[${index}].textPositions`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                isSearchable={true}
                                                                styles={customStyles(true)}
                                                                options={fieldOption}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                placeholder="Select the text positions"
                                                            />
                                                        )}
                                                    />
                                                    {errors.positions?.[index]?.textPositions && (
                                                        <FormFeedback>{errors.positions[index]?.textPositions.message}</FormFeedback>
                                                    )}
                                                </Col>
                                                <Col lg={1} sm={1}>
                                                    <div
                                                        className="ms-3 mt-5 d-flex align-items-center justify-content-center"
                                                        style={{ cursor: "pointer", color: "#AF1B1B" }}
                                                        onClick={() => {
                                                            removeFieldType(index);
                                                            setSelectedPositions((prev) => {
                                                                const newSelected = { ...prev };
                                                                delete newSelected[index];
                                                                return newSelected;
                                                            });
                                                        }}
                                                    >
                                                        <Trash2 />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    <div className="d-flex">
                                        <Button
                                            type="button"
                                            className="overview-btn"
                                            onClick={() => appendFieldType({ viewPosition: "", textPositions: [] })}
                                        >
                                            <PlusCircle /> Add Field
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-5 mt-3">
                                    <Label className='modal-label'>Product Category Image <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name='image'
                                        id='image'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div>
                                                <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                    <input style={{ display: 'none' }} id="image" type="file" {...field} invalid={errors.image && true} ref={inputRef} onChange={handleProfileUpload} />
                                                    {loader ?
                                                        <Spinner />
                                                        :
                                                        <Button
                                                            style={{ backgroundColor: '#E4510B', border: 'none' }}
                                                            color="primary"
                                                            onClick={() => {
                                                                inputRef?.current?.click();
                                                            }}
                                                        >
                                                            <Upload size={15} />
                                                            <span className='ms-1'>Upload Image</span>
                                                        </Button>

                                                    }

                                                    {errors.image && <FormFeedback>{errors.image.message}</FormFeedback>}
                                                </div>
                                                {profileImageUrl && (
                                                    <div className="mt-2 d-flex">
                                                        {image && (
                                                            <>
                                                                <img
                                                                    alt="preview"
                                                                    src={profileImageUrl}
                                                                    style={{ width: '200px', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
                                                                />
                                                                <div className="image-close ms-1" style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={() => setImage(null)}>
                                                                    <X />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="mb-5 mt-3">
                                    <Label className='modal-label'>Product Category Icon <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name='icon'
                                        id='icon'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div>
                                                <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                    <input style={{ display: 'none' }} id="icon" type="file" {...field} invalid={errors.icon && true} ref={inputRef1} onChange={handleIconUpload} />
                                                    {loading ?
                                                        <Spinner />
                                                        :
                                                        <Button
                                                            style={{ backgroundColor: '#E4510B', border: 'none' }}
                                                            color="primary"
                                                            onClick={() => {
                                                                inputRef1?.current?.click();
                                                            }}
                                                        >
                                                            <Upload size={15} />
                                                            <span className='ms-1'>Upload icon</span>
                                                        </Button>

                                                    }

                                                    {errors.icon && <FormFeedback>{errors.icon.message}</FormFeedback>}
                                                </div>
                                                {profileIconUrl && (
                                                    <div className="mt-2 d-flex">
                                                        {icon && (
                                                            <>
                                                                <img
                                                                    alt="preview icon"
                                                                    src={profileIconUrl}
                                                                    style={{ width: '200px', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
                                                                />
                                                                <div className="image-close ms-1" style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={() => setIcon(null)}>
                                                                    <X />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Title <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name='metaTitle'
                                        id='metaTitle'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="text" {...field} invalid={errors.metaTitle && true} placeholder="Enter your Meta Title" />)} />
                                    {errors.metaTitle && <FormFeedback>{errors.metaTitle.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Description <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name='metaDescription'
                                        id='metaDescription'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '70px' }} type="textarea" {...field} invalid={errors.metaDescription && true} placeholder="Enter your Meta Descritpion" />)} />
                                    {errors.metaDescription && <FormFeedback>{errors.metaDescription.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Keywords <span style={{ color: 'red' }}>*</span></Label>
                                    <div className=" w-100">
                                        <Controller
                                            name='metaKeywords'
                                            id='metaKeywords'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (<Input style={{ height: '70px' }} type="textarea" {...field} invalid={errors.metaKeywords && true} placeholder="Enter your Meta Keywords" />)} />
                                        {errors.metaKeywords && <FormFeedback>{errors.metaKeywords.message}</FormFeedback>}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <div className='d-flex fixed-button justify-content-end mt-2'>
                            <Button
                                className='overview-btn' type='submit'>Update Category</Button>
                        </div>
                    </Form >
                </div >
            </div>
        </>
    )
}

export default ProductCategoryEdit

import React, { useEffect, useState } from 'react'
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Select from 'react-select'
import toast from 'react-hot-toast'
import { courierList, deliveryPartner } from '../../../ApiConfigs/ApiConfig'
import axios from 'axios'

export const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        minHeight: '50px', // increase height for more space
    }),
    multiValue: (provided) => ({
        ...provided,
        margin: '2px',
        maxWidth: '100%', // Ensures each selected item stays within container width
        overflow: 'hidden',
        whiteSpace: 'normal', // Enables text wrapping
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        whiteSpace: 'normal', // Enables text wrapping
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        cursor: 'pointer',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
}

function CourierList({ toggle, modal, refetch, list }) {
    const [selectedOptions, setSelectedOptions] = useState([])
    const [data, setData] = useState()

    const getdata = async () => {
        try {
            const response = await axios.get(`${courierList}`)
            setData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    useEffect(() => {
        getdata()
    }, [])

    const options = data?.rows?.map((item) => (
        { value: item?._id, label: item?.name }
    ))

    const handleSelectChange = (options) => {
        setSelectedOptions(options)
    }



    const sendApi = async () => {
        const payload = {
            // deliveryType: 'shiprocket',
            deliveryPartner: selectedOptions?.map((item) => (
                item?.value
            ))
        }
        try {
            const response = await axios.post(deliveryPartner, payload)
            toggle()
            refetch()
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg)
        }
    }

    useEffect(() => {
        const lists = list?.map((item) => (
            { value: item?._id, label: item?.name }
        ))
        setSelectedOptions(lists)
    }, [list])

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
            <ModalBody>
                <Label style={{ fontWeight: '500' }}>Select Courier Services</Label>
                <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    <Select
                        isMulti
                        options={options}
                        value={selectedOptions}
                        onChange={handleSelectChange}
                        placeholder="Select couriers"
                        styles={customStylesSelect}
                        menuPortalTarget={document.body}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={sendApi}>
                    Confirm
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default CourierList

import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import { Label, Modal, ModalBody } from "reactstrap";
import { categoriesAdd, productCategory } from "../../../ApiConfigs/ApiConfig";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import toast from "react-hot-toast";

function AddSliderSection({ open, handleAdd, refetch, data }) {
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [getData, setGetData] = useState([]);
    // const [errorMessage, setErrorMessage] = useState("");

    // Fetch product categories
    const getproductCategory = async () => {
        try {
            const response = await axios.get(productCategory);
            setGetData(response?.data?.result);
        } catch (error) {
            // setErrorMessage("Failed to fetch product categories.");
        }
    };

    // Convert fetched data into select options
    const getOptions = getData?.map((item) => ({
        label: item?.name,
        value: item?._id,
    }));

    // Fetch categories on component mount
    useEffect(() => {
        getproductCategory();
    }, []);

    // Handle form submission
    const finalSubmit = async () => {
        if (selectedProduct.length !== 5) {
            // setErrorMessage("You must select exactly 5 options.");
            return;
        }

        const payload = {
            categories: selectedProduct.map((option) => option.value),
        };

        try {
            const response = await axios.post(categoriesAdd, payload);
            handleAdd();
            refetch();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg);
        }
    };

    // Initialize selected options from provided data
    useEffect(() => {
        if (data) {
            const selectedIds = data.map((item) => ({
                value: item?._id,
                label: item?.name,
            }));
            setSelectedProduct(selectedIds);
        }
    }, [data]);

    return (
        <div className="modal-page">
            <Modal
                isOpen={open}
                toggle={handleAdd}
                className="sidebar-lg"
                modalClassName="modal-slide-in sidebar-todo-modal"
                contentClassName="p-0"
            >
                <div
                    className="d-flex align-items-center justify-content-between mb-1 px-1"
                    style={{
                        backgroundColor: "white",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        borderBottom: "1px solid #EEEEEE",
                    }}
                >
                    <h4
                        className="modal-title"
                        style={{
                            marginTop: "23px",
                            marginBottom: "23px",
                            paddingLeft: "10px",
                            fontFamily: "roboto",
                            color: "black",
                        }}
                    >
                        Add/Edit Slider List
                    </h4>
                    <X
                        style={{ color: "black", cursor: "pointer" }}
                        className="addmodal-close fw-normal mt-25 me-2"
                        size={25}
                        onClick={handleAdd}
                    />
                </div>
                <ModalBody className="flex-grow-1">
                    <div className="mb-1 mt-1">
                        <Label className="modal-label">
                            Select Category <span style={{ color: "red" }}>*</span>
                        </Label>
                        <MultiSelect
                            options={getOptions}
                            value={selectedProduct}
                            onChange={setSelectedProduct}
                            hasSelectAll={false} // Disable "Select All" option
                            overrideStrings={{
                                selectSomeItems: "Select up to 5 categories...",
                                allItemsAreSelected: "All items are selected.",
                                selectAll: "Select All",
                                search: "Search...",
                            }}
                        />
                        {selectedProduct.length > 5 && (
                            <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                                You can only select a maximum of 5 options.
                            </p>
                        )}
                        {selectedProduct.length < 5 && selectedProduct.length > 0 && (
                            <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                                You must select exactly 5 options.
                            </p>
                        )}
                    </div>
                    <div className="mt-3">
                        <div className="d-flex">
                            <button
                                style={{
                                    border: "1px solid #E4510B",
                                    color: "#E4510B",
                                    background: "white",
                                    borderRadius: "10px",
                                }}
                                className="mt-1 w-50"
                                type="button"
                                onClick={handleAdd}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={finalSubmit}
                                className="mt-1 w-50 ms-2 add-modal-btn"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default AddSliderSection;

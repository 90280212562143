import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Form, FormFeedback, Label, Input, Button, Collapse, Modal, ModalBody, Spinner } from 'reactstrap';
import { cart, cartCount, removeCart, profile, placeOrder, shippingAddress, payId, GstTotal, pincode1, couponCode, paymentOptions1 } from '../../ApiConfigs/ApiConfig';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import logo from "../../assets/images/logo/printon logo-01.png"
import { BiEditAlt } from "react-icons/bi";
import './index.css';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaChevronDown, FaTag } from "react-icons/fa";
import { FaAngleLeft, FaPlus } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import visa from '../../assets/images/logo/Visa-Logo.png';
import master from '../../assets/images/logo/MasterCard_Logo.svg.png';
import paypl from '../../assets/images/logo/068BjcjwBw0snwHIq0KNo5m-15.webp';
import america from '../../assets/images/logo/American_Express-4876c7d0db694256968485f3085dfa78.png';
import whatsapp from "../../assets/images/logo/whatsapp.png"
import step2 from "../../assets/images/banner/step2.png"
import step1 from "../../assets/images/banner/step1.png"
import sucess from "../../assets/images/banner/sucess.png"
import { X, Check, ChevronDown, ChevronRight, Eye } from 'react-feather'
import { Controller, useForm } from 'react-hook-form';
import { BiCheck } from "react-icons/bi";
import { Tooltip } from 'react-tooltip';
import { RiDeleteBin6Line } from "react-icons/ri";
import toast from 'react-hot-toast';
import Preview from '../myOrder/preview';

function PlaceOrderIndex() {
    const [getProfile, setProfile] = useState('')
    const [dataa, setData] = useState([]);
    const [couponCodeData, setCouponCodeData] = useState([]);
    const [socialMedias, setSocialMedias] = useState({})
    const [shippingCharges, setShippingCharge] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const [expandedCoupon, setExpandedCoupon] = useState(null);
    const location = useLocation();
    // const selectedProductIds = ;
    const [selectedProductIds, setSelectedProductIds] = useState(location.state?.selectedProductIds || []);
    const [gst, setGst] = useState();
    const [selectedCartProductIds, setSelectedCartProductIds] = useState([]);
    const products = dataa?.products || [];
    const [couponCode1, setCouponCode] = useState(''); // State to track input value

    const handleInputChange = (event) => {
        const uppercaseValue = event.target.value.toUpperCase(); // Convert input to uppercase
        setCouponCode(uppercaseValue);
    };
    const selectedTotalAmount = products
        .filter(product => selectedProductIds.includes(product._id)) // Filter only selected products
        .reduce((total, product) => total + product.amount, 0);
    const filtteredProducts = dataa?.products?.filter((product) => selectedProductIds.includes(product?._id)) || [];
    const filteredProducts1 = dataa?.products?.filter(
        (eachItem) => !selectedProductIds.includes(eachItem?._id)
    ) || [];
    const [orderDetails, setOrderDetails] = useState({
        personal: {
            name: getProfile?.name,
            mobile: getProfile?.mobile,
            email: getProfile?.email
        },
        shipping: {
            address: '',
            state: '',
            city: '',
            locality: '',
            pincode: ''
        },
        payment: {
            mode: ''
        }
    });

    const navigate = useNavigate()

    // State for form validation
    // const [validationError, setValidationError] = useState('');
    const [loading, setLoading] = useState(false);
    const [openAccordion, setOpenAccordion] = useState(null);
    const [CouponCodeValue, setCouponCodeValue] = useState('');
    const [openAccordion1, setOpenAccordion1] = useState(null);
    const { cartId } = useParams()
    const [modal, setModal] = useState(false);


    // const toggle = () => {
    //     setModal(!modal);
    //     navigate('/myOrder')
    // }

    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${cart}`)
            setData(response?.data?.result)

        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const couponData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${couponCode}`)
            setCouponCodeData(response?.data?.result?.rows)

        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        additionalData()
        couponData()
        myProfileDatails()
    }, [])

    const myProfileDatails = async () => {
        try {
            const response = await axios.get(profile)
            setProfile(response?.data?.result)
        } catch (error) {

        }
    }

    useEffect(() => {
        setOrderDetails((prevDetails) => ({
            ...prevDetails,
            personal: {
                name: getProfile?.name || '',
                mobile: getProfile?.mobile || '',
                email: getProfile?.email || ''
            }
        }));
    }, [getProfile]);

    const [load, setLoad] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [pincodeSuggestions, setPincodeSuggestions] = useState([]);
    const [invalidPincodeError, setInvalidPincodeError] = useState(null);

    const handlePincodeChange = async (e, field) => {
        const pincode = e.target.value;

        // Update the field value
        field.onChange(e);

        if (pincode.length < 6) {  // Assuming 6 is the minimum valid pincode length
            setValue('state', '');
            setValue('city', '');
            setValue('areaName', '');
        }

        // Check if pincode is 6 digits
        if (pincode.length === 6) {
            setLoad(true); // Set loading state
            setInvalidPincodeError(null); // Reset any previous error

            try {
                const response = await axios.get(`${pincode1}?search=${pincode}`);
                const results = response.data.result || [];

                // If no results, show invalid pincode error
                if (results.length === 0) {
                    setInvalidPincodeError('Invalid pincode. Please try again.');
                    setPincodeSuggestions([]); // Clear suggestions
                } else {
                    setPincodeSuggestions(results); // Set valid suggestions
                }

                setLoad(false); // Stop loading
                setApiError(null); // Clear any previous API errors
            } catch (error) {
                console.error('Error fetching pincode data:', error);
                setLoad(false);
                setApiError('Failed to fetch pincode details. Please try again.');
            }
        }
    };


    // const handleSelectSuggestion = (suggestion) => {
    //     // Populate the input with selected suggestion or perform any action
    //     setValue('pincode', suggestion.pincode);
    //     setPincodeSuggestions([]); // Clear suggestions after selection
    // };


    const handleSelectSuggestion = (suggestion) => {
        // Set the selected values to respective fields
        setValue('pincode', suggestion.pincode);
        setValue('areaName', suggestion.officeName);
        setValue('city', suggestion.district);
        setValue('state', suggestion.stateName);

        clearErrors('pincode');

        // Clear suggestions after selection
        setPincodeSuggestions([]);
    };

    const SignupSchema = Yup.object().shape({
        firstName: Yup.string().required('Enter your first name'),
        lastName: Yup.string().required('Enter your last name'),
        state: Yup.string().required('Enter your state'),
        areaName: Yup.string().required('Enter your Area Name'),
        pincode: Yup.string().required('Pincode is required').matches(/^[0-9]{6}$/, 'Invalid Pincode'),
        email: Yup
            .string()
            .email('Please Enter a Vaild Email')  // Checks for general email format
            .matches(
                /^[^\s@]+@(gmail\.com|hotmail\.com|yahoo\.com|[^\s@]+\.(co\.in|in))$/,
                'Please Enter a Vaild Email'
            )
            .required('Enter Your Email'),
        city: Yup.string().required('Enter your city'),
        address: Yup.string().required('Enter your address'),
        landmark: Yup.string().required('Enter your landmark'),
        // pincode: Yup.string().required('Enter your pincode'),
        mobile: Yup.string().required('Enter your mobile number'),
        alternateMobile: Yup.string(),
        gstNo: Yup.string(),
        businessName: Yup.string(),
        isGst: Yup.boolean(),
        defaultAddress: Yup.boolean(),
    });

    const {
        handleSubmit,
        setValue,
        clearErrors,
        watch,
        control,
        formState: { errors }, reset
    } = useForm({
        mode: 'onChange', resolver: yupResolver(SignupSchema), defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            state: '',
            city: '',
            landmark: '',
            address: '',
            pincode: '',
            mobile: '',
            alternateMobile: '',
            gstNo: '',
            businessName: '',
            isGst: false,
            defaultAddress: false,
        }
    });
    const [selectedAddress, setSelectedAddress] = useState(null);

    useEffect(() => {
        if (selectedAddress) {
            setValue('firstName', selectedAddress.firstName || '');
            setValue('lastName', selectedAddress.lastName || '');
            setValue('email', selectedAddress.email || '');
            setValue('areaName', selectedAddress.areaName || '');
            setValue('state', selectedAddress.state || '');
            setValue('city', selectedAddress.city || '');
            setValue('landmark', selectedAddress.landmark || '');
            setValue('address', selectedAddress.address || '');
            setValue('pincode', selectedAddress.pincode || '');
            setValue('mobile', selectedAddress.mobile || '');
            setValue('alternateMobile', selectedAddress.alternateMobile || '');
            setValue('gstNo', selectedAddress.gstNo || '');
            setValue('businessName', selectedAddress.businessName || '');
            setValue('isGst', selectedAddress.isGst || false);
            setValue('defaultAddress', selectedAddress.defaultAddress || false);
        } else {
            reset(); // Reset the form when adding a new address
        }
    }, [selectedAddress, setValue, reset]);

    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };

    const toggleAccordion1 = (id) => {
        setOpenAccordion1(openAccordion1 === id ? null : id);
    };

    const openEditModal = (address) => {
        setSelectedAddress(address);
        setAddAddressModal(true); // Assuming this is the state to open the modal
    };

    const submitForm = async (data) => {
        const payload = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            areaName: data?.areaName,
            state: data.state,
            city: data.city,
            address: data.address,
            landmark: data.landmark,
            pincode: data.pincode,
            mobile: data.mobile,
            alternateMobile: data.alternateMobile,
            businessName: data.businessName,
            gstNo: data.gstNo,
            isGst: data.isGst ? true : false,
            defaultAddress: data.defaultAddress ? true : false
        };

        try {
            if (selectedAddress) {
                // Edit existing address
                const response = await axios.put(
                    `${shippingAddress}/${getProfile?._id}`,
                    payload,
                    {
                        params: { address_id: selectedAddress?._id }
                    }
                );
                toast.success("Address updated successfully!");
                totalGstCheck();
                // shipping()
            } else {
                // Add new address
                const response = await axios.post(`${shippingAddress}/${getProfile?._id}`, payload);
                toast.success("Address added successfully!");
                totalGstCheck()
                // shipping()
            }

            closeAddAddressModal();

            myProfileDatails(); // Refresh the profile details
        } catch (error) {
            toast.error("Failed to submit address");
        }
    };


    const handleAddressDelete = async (addressId) => {
        try {
            const response = await axios.delete(`${shippingAddress}/${getProfile?._id}`, {
                params: {
                    address_id: addressId
                }
            });
            additionalData();
            myProfileDatails();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg || "Failed to delete address");
        }
    };

    // const handleEdit = (index) => {
    //     // Handle the edit action for the address at the given index
    // };


    const [showAll, setShowAll] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedIndex1, setSelectedIndex1] = useState('Cash on Delivery');
    // const [selectedIndex2, setSelectedIndex2] = useState(0);
    // const [selectedIndex3, setSelectedIndex3] = useState();
    const [addAddressModal, setAddAddressModal] = useState(false);
    const [addProductModal, setAddProductModal] = useState(false);
    const [addCouponCodeModal, setAddCouponCodeModal] = useState(false);
    const displayedAddresses = showAll ? getProfile?.shippingAddress : getProfile?.shippingAddress?.slice(0, 3);
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [couponCodeError, setCouponCodeError] = useState(null);

    const totalGstCheck = async (coupon = CouponCodeValue, isCOD = selectedIndex1 === "Cash on Delivery") => {
        if (selectedProductIds?.length > 0) {
            const payload = {
                products: selectedProductIds,
                shipping: selectedAddressId,
                coupon,
                cod: !isCOD, // False if COD is selected
            };

            try {
                setLoading(true);
                const response = await axios.post(`${GstTotal}`, payload);
                setGst(response?.data?.result);
                setCouponCodeValue(coupon); // Set the applied coupon value
                closeCouponCodeModal();
            } catch (error) {
                setCouponCodeError(error?.response?.data?.msg);
                console.error('Error fetching GST:', error);
            } finally {
                setLoading(false);
            }
        }
    };
    useEffect(() => {

        totalGstCheck(); // call the GST check function when the page loads
    }, [selectedProductIds, selectedAddressId, GstTotal]);

    // Move the shipping function outside of useEffect so it can be reused
    const shipping = async (id) => {
        if (selectedAddressId?.length > 0) {
            try {
                setLoading(true);
                const response = await axios.get(`${cart}?shipping_id=${selectedAddressId}`);
                setShippingCharge(response?.data?.result?.products);
                // setGst(response?.data?.result);
            } catch (error) {
                console.error('Error fetching GST:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        shipping(); // call the GST check function when the page loads
    }, [selectedAddressId, cart]);

    const handleRadioChange = (index, id) => {
        setSelectedIndex(index);
        setSelectedAddressId(id);
        shipping(); // call shipping when a radio button is selected
    };


    useEffect(() => {
        if (displayedAddresses?.length > 0 && selectedAddressId === null) {
            // Find the address with defaultAddress set to true
            const defaultAddressIndex = displayedAddresses.findIndex((address) => address.defaultAddress === true);

            if (defaultAddressIndex !== -1) {
                // If a default address is found, set it as the selected address
                setSelectedAddressId(displayedAddresses[defaultAddressIndex]._id);
                setSelectedIndex(defaultAddressIndex);
            } else {
                // If no default address, select the first address
                const defaultIndex = 0;
                setSelectedAddressId(displayedAddresses[defaultIndex]._id);
                setSelectedIndex(defaultIndex);
            }
        }
    }, [displayedAddresses, selectedAddressId]);

    // const defaultAddressIndex = displayedAddresses.findIndex((address) => address.defaultAddress === true);



    // const id = [displayedAddresses.findIndex((address) => address.defaultAddress)]._id;
    // const id = selectedAddressId;
    // const shipping = async (id) => {
    //     try {
    //         setLoading(true);
    //         const response = await axios.get(`${cart}?shipping_id=${id}`)
    //         setShippingCharge(response?.data?.result?.products);

    //     } catch (error) {

    //     } finally {
    //         setLoading(false);
    //     }
    // }

    const handleRadioChange1 = (value) => {
        setSelectedIndex1(value); // Update the selected index
        totalGstCheck(value);    // Pass the selected value to totalGstCheck
    };


    // const handleRadioChange2 = (index) => {
    //     setSelectedIndex2(index);
    // };
    // const handleRadioChange3 = (index) => {
    //     setSelectedIndex3(index);
    // };

    // const orderNowFunc = () => {

    //     // navigate(`/placeOrder/${cartId}`)

    // }



    const openAddAddressModal = () => {
        setSelectedAddress(null);
        setAddAddressModal(true);
    }
    const closeAddAddressModal = () => {
        reset();
        setSelectedAddress(null);
        setAddAddressModal(false);
    };
    const openAddProductModal = () => {
        setAddProductModal(true);
    }
    const closeAddProductModal = () => {
        setAddProductModal(false);
    };

    const openCouponCodeModal = () => {
        setAddCouponCodeModal(true);
    }
    const closeCouponCodeModal = () => {
        setAddCouponCodeModal(false);
        setCouponCodeError(null);
    };


    // const numberOfItem = dataa?.products?.length;

    const [customizeImages, setCustomizeImages] = useState([]);
    const [deleteData, setDeleteData] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [isEdit, setIsEdit] = useState({});
    const [productNewId, setId] = useState('')
    const [productQ, setQuantity] = useState()
    const [editedQuantity, setEditedQuantity] = useState({});

    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);

    const viewCustom = (item) => {
        // Log the item for debugging

        // Check if item is defined and if required properties exist
        if (item) {
            // Handle condition when 'customizeImages' array has more than 1 item
            if (Array.isArray(item?.customizeImages) && item?.customizeImages.length > 1) {
                setCustomizeImages(item?.customizeImages?.map((customImage) => customImage?.img_url)); // Set 'customizeImages' state
                toggle1(); // Toggle modal or action
            }
            // Handle condition when 'file' array has items
            else if (Array.isArray(item?.file) && item?.file.length > 0) {
                setCustomizeImages(item?.file); // Set 'customizeImages' state
                toggle1(); // Toggle modal or action
            }
            // Handle condition when 'product.image' is available
            else if (item?.product?.image) {
                setCustomizeImages([item?.product?.image]); // Ensure it's in an array
                toggle1(); // Toggle modal or action
            }
            else {
                // Fallback if no images or files are found
            }
        } else {
            // Handle case where 'item' is undefined or null
            console.error("Invalid item passed to viewCustom");
        }
    };

    // const navToProductView = (id) => {
    //     navigate(`/${id}`)
    // }

    const openDeleteModal = (id) => {
        // setDeleteData(id);

        // setDeleteModal(true);
        setSelectedProductIds(prevSelectedProductIds => prevSelectedProductIds.filter(productId => productId !== id));
    }

    const closeDeleteModal = () => {
        setDeleteModal(false);
    }

    const handleConfirmDelete = async () => {
        try {
            const response = await axios.delete(`${removeCart}/${cartId}?product=${deleteData}`)
            closeDeleteModal()
            additionalData()
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }

    }

    const handleQuantityChange = async (e) => {
        const value = e.target.value;
        if (!value.includes('+') && !value.includes('-')) {
            setEditedQuantity((prevEditedQuantity) => ({
                ...prevEditedQuantity,
                [productNewId]: value,
            }));
        };
    }
    const confirmQuantity = async () => {
        const payload = {
            product: productNewId,
            quantity: editedQuantity[productNewId] || productQ,
        };

        try {
            const response = await axios.post(`${cartCount}/${cartId}`, payload);
            setIsEdit((prevIsEdit) => ({
                ...prevIsEdit,
                [productNewId]: false,
            }));
            additionalData();
            toast.success(response?.data?.msg);
        } catch (error) {
        }
    };

    const handleCOD = async () => {
        setIsLoading(true); // Set loading state to true
        try {
            const payload = {
                shipping: selectedAddressId,
                products: selectedProductIds,
                ...(CouponCodeValue !== '' &&
                    { coupon: CouponCodeValue })
            };

            try {
                const response = await axios.post(`${placeOrder}`, payload);
                setIsAddress(false);
                setIsPreview(false);
                setIsPayment(false);
                setIsSucess(true);
            }
            catch (error) {
            }
        } finally {
            setIsLoading(false); // Set loading state back to false after process completes
        }

    }

    const handlePay = async () => {
        setIsLoading(true); // Set loading state to true
        try {
            const payload = {
                shipping: selectedAddressId,
                products: selectedProductIds,
                ...(CouponCodeValue !== '' &&
                    { coupon: CouponCodeValue })
                // totalAmount: gst?.finalAmount,
            };
            try {
                const response = await axios.post(`${payId}`, payload);
                // window.open(response?.data?.result?.paymentLink, '_blank');
                window.location.href = response?.data?.result?.paymentLink;
                // toast.success(response?.data?.msg);
            }
            catch (error) {
            }
        } finally {
            setIsLoading(false); // Set loading state back to false after process completes
        }
    }

    // const handleRazorPay = async () => {
    //     // setIsLoading(true); // Set loading state to true
    //     try {
    //         const payload = {
    //             products: selectedProductIds,
    //             shipping: selectedAddressId,
    //             coupon: value
    //         };
    //         try {
    //             const response = await axios.post(`https://devapi.printon.co.in/order/razorpay`, payload);
    //             // window.open(response?.data?.result?.paymentLink, '_blank');
    //             // window.location.href = response?.data?.result?.paymentLink;
    //             // toast.success(response?.data?.msg);
    //         }
    //         catch (error) {
    //         }
    //     } finally {
    //         setIsLoading(false); // Set loading state back to false after process completes
    //     }
    // }  

    const handleRazorPay = async (e) => {
        // e.prevenstDefault();

        const token = localStorage.getItem('token');
        const mobile = localStorage.getItem('mobile');

        const payload = {
            data: {
                products: selectedProductIds,
                shipping: selectedAddressId,
                ...(CouponCodeValue !== '' &&
                    { coupon: CouponCodeValue })
            },
            mobile: mobile,
        };

        try {
            const response = await fetch("https://devapi.printon.co.in/order/razorpay", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload.data),
            });

            const orderData = await response.json();

            const options = {
                key: "rzp_test_JK53ooxXnfKY8B", // Enter the Key ID generated from the Dashboard
                amount: orderData?.result?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                currency: "INR",
                name: "PRINTON", // Your business name
                description: "Payment to Printon",
                image:
                    "https://dev.printon.co.in/static/media/printon%20logo-01.781fdc05dc1bb3adcf96.png",
                order_id: orderData?.result?.id,
                handler: function (response) {
                    fetch("https://devapi.printon.co.in/callback/razorpay", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(response),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            if (data?.success) {
                                window.location.href = "https://dev.printon.co.in/MyOrder";
                            } else {
                                window.location.href = "https://dev.printon.co.in/MyOrder";
                            }
                        });
                },
                prefill: {
                    name: "Printon",
                    email: "mail.printon@gmail.com",
                    contact: payload.mobile,
                },
                notes: {
                    address: "1/A, 5th A Cross, 24th Main, 2nd Phase, JP Nagar, Bangalore 560 078",
                },
                theme: {
                    color: "#e4510b",
                },
            };

            const rzp1 = new window.Razorpay(options);

            // Add an event listener for payment failures
            rzp1.on("payment.failed", function (response) {
                fetch("https://devapi.printon.co.in/callback/razorpay", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(response.error),
                })
                    .then((res) => res.json())
                    .then(() => {
                        window.location.href = "https://dev.printon.co.in/myCart";
                    });
            });

            rzp1.open();
        } catch (error) {
            console.error("Error during payment initiation:", error);
        }
    };

    // const editQuantity = (id, quantity) => {
    //     setIsEdit((prevIsEdit) => ({
    //         ...prevIsEdit,
    //         [id]: true,
    //     }));
    //     setId(id);
    //     setQuantity(quantity);
    // };

    const closeInput = () => {
        setIsEdit(false)
    }


    const [isAddress, setIsAddress] = useState(true);
    const [isPreview, setIsPreview] = useState(false);
    const [isPayment, setIsPayment] = useState(false);
    const [isSucess, setIsSucess] = useState(false);

    const handleAddressPage = () => {
        setIsAddress(true);
        setIsPreview(false);
        setIsPayment(false);
    }
    const handlePreviewPage = () => {
        setIsAddress(false);
        setIsPreview(true);
        setIsPayment(false);
    }
    const handlePaymentPage = () => {
        setIsAddress(false);
        setIsPreview(false);
        setIsPayment(true);
    }

    const handlebBackaddress = () => {
        setIsAddress(true);
        setIsPreview(false);
        setIsPayment(false);
    }

    const handlebBackPreview = () => {
        setIsAddress(false);
        setIsPreview(true);
        setIsPayment(false);
    }
    const [paymentOptions, setPaymentOptions] = useState([])

    const paymentData = async () => {
        try {
            // setLoading(true);
            const response = await axios.get(`${paymentOptions1}`)
            setPaymentOptions(response?.data?.result)

        } catch (error) {

        } finally {
            // setLoading(false);
        }
    }


    useEffect(() => {
        paymentData()
    }, [])




    // const paymentOptions = [
    //     // {
    //     //     name: 'UPI',
    //     //     description: 'Complete your payment instantly and securely using UPI (Unified Payments Interface)',
    //     //     icon: upi, // Replace `upi` with the actual import or path to your icon
    //     // },
    //     // {
    //     //     name: 'Credit or Debit card',
    //     //     description: 'Complete your payment instantly and securely using your credit or debit card.',
    //     // },
    //     {
    //         name: 'Online Payment (UPI, Credit/Debit Card, Net Banking)',
    //         description: 'Securely complete your transaction using your preferred UPI, Credit/Debit Card or net banking service.',
    //     },
    //     {
    //         name: 'Cash on Delivery',
    //         description: 'Pay conveniently with cash when your order is delivered right to your doorstep.',
    //     },
    // ];

    // const [captcha, setCaptcha] = useState('');
    // const [userInput, setUserInput] = useState('');
    // const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

    // const generateCaptcha = () => {
    //     const charsArray = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%&*?';
    //     const length = 6; // CAPTCHA length
    //     let captcha = '';
    //     for (let i = 0; i < length; i++) {
    //         const index = Math.floor(Math.random() * charsArray.length);
    //         captcha += charsArray[index];
    //     }
    //     setCaptcha(captcha);
    // };

    // useEffect(() => {
    //     setIsCaptchaVerified(userInput === captcha);
    // }, [userInput, captcha]);

    // const handleInputChange = (e) => {
    //     const value = e.target.value;
    //     setUserInput(value);
    //     setIsCaptchaVerified(value === captcha);
    // };

    // // Generate a CAPTCHA when the component mounts
    // useEffect(() => {
    //     generateCaptcha();
    // }, []);

    // const [cardNumber, setCardNumber] = useState('');
    // const [expiryMonth, setExpiryMonth] = useState('');
    // const [expiryYear, setExpiryYear] = useState('');
    // const [cvv, setCvv] = useState('');

    // // Generate an array of years (current year - 10 to current year)
    // const currentYear = new Date().getFullYear();
    // const years = Array.from({ length: 16 }, (_, index) => currentYear + 0 + index);

    // const handleCheckboxChange = (productId) => {
    //     setSelectedProductIds(prevSelectedIds => {
    //         if (prevSelectedIds.includes(productId)) {
    //             // If product ID is already selected, remove it from the list
    //             return prevSelectedIds.filter(id => id !== productId);
    //         } else {
    //             // If product ID is not selected, add it to the list
    //             return [...prevSelectedIds, productId];
    //         }
    //     });
    // };

    const handleCheckboxChange = (id) => {
        if (selectedCartProductIds.includes(id)) {
            // If the ID is already in the list, remove it
            setSelectedCartProductIds(selectedCartProductIds.filter((productId) => productId !== id));
        } else {
            // Add the ID to the list
            setSelectedCartProductIds([...selectedCartProductIds, id]);
        }
    };

    const handleApply = () => {
        // Merge existing selected product IDs with the new selected cart product IDs
        setSelectedProductIds(prevSelectedProductIds => [
            ...new Set([...prevSelectedProductIds, ...selectedCartProductIds])
        ]);
        // Clear the temporary cart product IDs
        setSelectedCartProductIds([]);
        // Close the modal after applying
        closeAddProductModal();
    };

    // Clear selected cart products on cancel
    const handleCancel = () => {
        // Clear the temporary selection
        setSelectedCartProductIds([]);
        // Close the modal
        closeAddProductModal();
    };

    return (
        <>
            {/* <Navbar /> */}
            <div className='d-flex align-items-center ' style={{ background: '#FFEFE6 ', paddingLeft: '5%', paddingRight: '5%', height: '80px' }}>
                <Link to='/'>
                    <img style={{ width: '200px', cursor: 'pointer' }} src={logo} alt="" />
                </Link>
            </div>
            <h3 className='d-flex justify-content-center mt-3'>Check Out</h3>
            {/*Selecting,Adding, Editing Address Page*/}
            {isAddress && (
                <div>
                    <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                        <div style={{ paddingLeft: '5%', paddingRight: '3%', width: '100%' }} className='d-flex mt-4'>
                            <div>
                                <p className='d-flex justify-content-center align-items-center mt-1 stage1' >1</p>
                            </div>
                            <img className='d-flex proces mt-3' src={step1} alt="" />
                            <div>
                                <p className='d-flex justify-content-center align-items-center mt-1 stage2' >2</p>
                            </div>
                            <img className='d-flex proces mt-3' src={step1} alt="" />
                            <div>
                                <p className='d-flex justify-content-center align-items-center mt-1 stage3' >3</p>
                            </div>
                            <hr />
                        </div>
                        <div style={{ paddingLeft: '2%', paddingRight: '3%' }} className='d-flex justify-content-between'>
                            <p className='stage-con1'>Delivery Address</p>
                            <p className='stage-con2' >Preview Product</p>
                            <p className='stage-con3' >Payment</p>
                        </div>
                    </div>


                    <div className='d-flex' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '5%' }}>
                        <Row>
                            <Col lg={8} md={12} sm={12} >
                                {getProfile?.shippingAddress?.length > 0 ? (
                                    <div className='mt-3' style={{ paddingRight: '2%' }}>
                                        <div style={{ paddingLeft: '3%', paddingRight: '3%', paddingTop: '3%', border: '1px solid #F0F0F0', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.05)' }}>
                                            <div style={{ borderBottom: '2px solid #F0F0F0', paddingBottom: '20px' }}>
                                                <p style={{ color: '#2B2B2D', fontSize: '20px', lineHeight: '24px', fontWeight: '700' }}>Delivery Address</p>
                                            </div>
                                            {displayedAddresses?.map((address, index) => (
                                                <div key={address?._id} style={{ borderBottom: '1px solid #F0F0F0' }}>
                                                    <div className='d-flex pe-4 mt-4'>
                                                        <div>
                                                            <input
                                                                className='radio mt-1 me-3'
                                                                type="radio"
                                                                name="address"
                                                                checked={selectedIndex === index}
                                                                onChange={() => handleRadioChange(index, address._id)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <h5>{address?.firstName} {address?.lastName}, {address?.mobile}</h5>
                                                            <p className=' d-flex align-items-center' style={{ fontSize: '16px', fontWeight: '400', lineHeight: '20px' }}>
                                                                {address?.address}, {address?.landmark}, {address?.city}, {address?.state} - {address?.pincode}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-end mb-2'>
                                                        <button
                                                            style={{ border: 'none', background: 'white', color: '#E4510B', marginTop: '-10px' }}
                                                            onClick={() => openEditModal(address)}
                                                        >
                                                            <BiEditAlt size={25} style={{ color: '#E4510B' }} />
                                                        </button>
                                                        <button
                                                            style={{ border: 'none', background: 'white', color: '#E4510B', marginTop: '-10px' }}
                                                            onClick={() => handleAddressDelete(address?._id)}
                                                        >
                                                            <RiDeleteBin6Line size={25} style={{ color: '#E4510B' }} />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}

                                            {getProfile?.shippingAddress?.length > 3 && (
                                                <div className='d-flex align-items-center mt-3 ms-2'>
                                                    <p style={{ color: '#E4510B', cursor: 'pointer', fontSize: '20px', fontWeight: '500' }} onClick={() => setShowAll(!showAll)}>
                                                        <FaChevronDown style={{ color: '#E4510B' }} className='me-5' />
                                                        {showAll ? 'Hide addresses' : 'View all addresses'}
                                                    </p>
                                                </div>
                                            )}

                                        </div>
                                        <div className='mt-4 d-flex ' style={{ border: '1px solid #F0F0F0', borderRadius: '15px', paddingLeft: '3%', height: '60px' }}>
                                            <div style={{ cursor: 'pointer' }} className='mt-3' onClick={openAddAddressModal}>
                                                <p style={{ fontSize: '20px', color: '#E4510B', fontWeight: '500', lineHeight: '20px' }}><FaPlus style={{ color: '#E4510B' }} className='me-4' /> Add a new address</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className='mt-3' style={{ paddingRight: '2%' }}>
                                            <div style={{ paddingLeft: '3%', paddingRight: '3%', paddingTop: '3%', border: '1px solid #F0F0F0', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.05)' }}>
                                                <div style={{ borderBottom: '1px solid #F0F0F0', paddingBottom: '10px' }}>
                                                    <p style={{ color: '#2B2B2D', fontSize: '20px', lineHeight: '24px', fontWeight: '700' }}>Add Delivery Address</p>
                                                </div>
                                                <div className='mt-3 mb-3'>
                                                    <Form onSubmit={handleSubmit(submitForm)}>
                                                        <div className='ps-3 pe-3'>
                                                            <div className='d-flex justify-content-between'>
                                                                <div className='mb-2 w-50'>
                                                                    <Label>First Name <span style={{ color: 'red' }}>*</span> </Label>
                                                                    <Controller
                                                                        name='firstName'
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <Input
                                                                                type="text"
                                                                                {...field}
                                                                                invalid={errors.firstName && true}
                                                                                placeholder="Enter Your First Name"
                                                                            />
                                                                        )}
                                                                    />
                                                                    {errors.firstName && <FormFeedback>{errors.firstName.message}</FormFeedback>}
                                                                </div>
                                                                <div className='mb-2 w-50 ms-3'>
                                                                    <Label>Last Name <span style={{ color: 'red' }}>*</span> </Label>
                                                                    <Controller
                                                                        name='lastName'
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <Input
                                                                                type="text"
                                                                                {...field}
                                                                                invalid={errors.lastName && true}
                                                                                placeholder="Enter Your Last Name"
                                                                            />
                                                                        )}
                                                                    />
                                                                    {errors.lastName && <FormFeedback>{errors.lastName.message}</FormFeedback>}
                                                                </div>
                                                            </div>
                                                            <div className='mb-2'>
                                                                <Label>Email  <span style={{ color: 'red' }}>*</span></Label>
                                                                <Controller
                                                                    name='email'
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <Input
                                                                            type="text"
                                                                            {...field}
                                                                            invalid={errors.email && true}
                                                                            placeholder="Enter Your Email"
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.email && <FormFeedback>{errors.email.message}</FormFeedback>}
                                                            </div>
                                                            <div className='mb-2'>
                                                                <Label>Landmark  <span style={{ color: 'red' }}>*</span></Label>
                                                                <Controller
                                                                    name='landmark'
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <Input
                                                                            type="text"
                                                                            {...field}
                                                                            invalid={errors.landmark && true}
                                                                            placeholder="Enter Landmark"
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.landmark && <FormFeedback>{errors.landmark.message}</FormFeedback>}
                                                            </div>
                                                            <div className='mb-2'>
                                                                <Label>Address  <span style={{ color: 'red' }}>*</span></Label>
                                                                <Controller
                                                                    name='address'
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <Input
                                                                            type="text"
                                                                            {...field}
                                                                            invalid={errors.address && true}
                                                                            placeholder="Enter Your Address"
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.address && <FormFeedback>{errors.address.message}</FormFeedback>}
                                                            </div>
                                                            <div className='mb-2 position-relative'>
                                                                <Label>
                                                                    Pincode <span style={{ color: 'red' }}>*</span>
                                                                </Label>
                                                                <Controller
                                                                    name='pincode'
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <Input
                                                                                type="text"
                                                                                {...field}
                                                                                onChange={(e) => handlePincodeChange(e, field)}
                                                                                invalid={!!errors.pincode}
                                                                                placeholder="Enter Pincode"
                                                                            />
                                                                            {errors.pincode && <FormFeedback>{errors.pincode.message}</FormFeedback>}
                                                                        </>
                                                                    )}
                                                                />

                                                                {/* Dropdown suggestions */}
                                                                {pincodeSuggestions.length > 0 && (
                                                                    <div className="pincode-dropdown">
                                                                        {pincodeSuggestions.map((suggestion, index) => (
                                                                            <div
                                                                                key={index}
                                                                                className="pincode-suggestion"
                                                                                onClick={() => handleSelectSuggestion(suggestion)}
                                                                            >
                                                                                <span>
                                                                                    <i className="fa fa-map-marker" aria-hidden="true"></i> {suggestion.pincode}
                                                                                </span>
                                                                                <div>
                                                                                    {suggestion.officeName}, {suggestion.district}, {suggestion.stateName}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}

                                                                {/* Loading spinner */}
                                                                {load && <div><Spinner></Spinner></div>}

                                                                {/* Invalid pincode message */}
                                                                {invalidPincodeError && <div className="text-danger">{invalidPincodeError}</div>}
                                                            </div>
                                                            <div className='mb-2'>
                                                                <Label>State  <span style={{ color: 'red' }}>*</span></Label>
                                                                <Controller
                                                                    name='state'
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <Input
                                                                            type="text"
                                                                            {...field}
                                                                            readOnly
                                                                            invalid={errors.state && true}
                                                                            placeholder="Enter Your State"
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.State && <FormFeedback>{errors.State.message}</FormFeedback>}
                                                            </div>
                                                            <div className='mb-2'>
                                                                <Label>City/Town  <span style={{ color: 'red' }}>*</span></Label>
                                                                <Controller
                                                                    name='city'
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <Input
                                                                            type="text"
                                                                            {...field}
                                                                            readOnly
                                                                            invalid={errors.city && true}
                                                                            placeholder="Enter Your City"
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.city && <FormFeedback>{errors.city.message}</FormFeedback>}
                                                            </div>
                                                            <div className='mb-2'>
                                                                <Label>Area<span style={{ color: 'red' }}>*</span></Label>
                                                                <Controller
                                                                    name='areaName'
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <Input
                                                                            type="text"
                                                                            {...field}
                                                                            readOnly
                                                                            invalid={errors.areaName && true}
                                                                            placeholder="Enter Your Area Name"
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.areaName && <FormFeedback>{errors.areaName.message}</FormFeedback>}
                                                            </div>
                                                            <div className='mb-2'>
                                                                <Label>Phone <span style={{ color: 'red' }}>*</span> </Label>
                                                                <Controller
                                                                    name='mobile'
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <Input
                                                                            type="text"
                                                                            {...field}
                                                                            invalid={errors.mobile && true}
                                                                            placeholder="Enter Mobile Number"
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.mobile && <FormFeedback>{errors.mobile.message}</FormFeedback>}
                                                            </div>
                                                            <div className='mb-2'>
                                                                <Label>Alternate Phone </Label>
                                                                <Controller
                                                                    name='alternateMobile'
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <Input
                                                                            type="text"
                                                                            {...field}
                                                                            invalid={errors.alternateMobile && true}
                                                                            placeholder="Enter Alternate Mobile Number"
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.alternateMobile && <FormFeedback>{errors.alternateMobile.message}</FormFeedback>}
                                                            </div>
                                                            <div className='mb-2 mt-3 d-flex'>
                                                                <Controller
                                                                    name='isGst'
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <Input
                                                                            type="checkbox"
                                                                            {...field}
                                                                            invalid={errors.isGst && true}
                                                                            checked={field.value}
                                                                        />
                                                                    )}
                                                                />
                                                                <Label className='mt-2 ms-3'>Do you have a GSTIN</Label>
                                                                {errors.isGst && <FormFeedback>{errors.isGst.message}</FormFeedback>}
                                                            </div>

                                                            {watch('isGst') && (
                                                                <>
                                                                    <div className='mb-2'>
                                                                        <Label>GSTIN Number </Label>
                                                                        <Controller
                                                                            name='gstNo'
                                                                            control={control}
                                                                            defaultValue=""
                                                                            render={({ field }) => (
                                                                                <Input
                                                                                    type="text"
                                                                                    {...field}
                                                                                    invalid={errors.gstNo && true}
                                                                                    placeholder="Enter GSTIN Number"
                                                                                />
                                                                            )}
                                                                        />
                                                                        {errors.gstNo && <FormFeedback>{errors.gstNo.message}</FormFeedback>}
                                                                    </div>

                                                                    <div className='mb-2'>
                                                                        <Label>
                                                                            Business Name
                                                                            {/* The eye icon with the tooltip */}
                                                                            <span
                                                                                id="businessNameTooltip"  // Give this element an ID to associate the tooltip
                                                                                style={{ marginLeft: '5px', cursor: 'pointer' }}
                                                                            >
                                                                                &#9432;  {/* This represents an eye or info icon */}
                                                                            </span>
                                                                            <Tooltip
                                                                                anchorId="businessNameTooltip"  // Use the anchorId to connect the tooltip
                                                                                place="right"
                                                                                effect="solid"
                                                                                style={{ width: '300px', height: '200px', background: 'white', color: 'black', border: '1px solid black' }}
                                                                            >
                                                                                Provide the full legal entity name as per your GST Certificate or as you want to appear on the invoice. If you are a Sole Proprietor or unregistered business, provide the name you conduct business under. If you do not have a business name, enter your full name.
                                                                            </Tooltip>
                                                                        </Label>
                                                                        <Controller
                                                                            name='businessName'
                                                                            control={control}
                                                                            defaultValue=""
                                                                            render={({ field }) => (
                                                                                <Input
                                                                                    type="text"
                                                                                    {...field}
                                                                                    invalid={errors.businessName && true}
                                                                                    placeholder="Enter Business Name"
                                                                                />
                                                                            )}
                                                                        />
                                                                        {errors.businessName && <FormFeedback>{errors.businessName.message}</FormFeedback>}
                                                                    </div>
                                                                </>
                                                            )}
                                                            <div className='mb-2 mt-3 d-flex'>
                                                                <Controller
                                                                    name='defaultAddress'
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <Input
                                                                            type="checkbox"
                                                                            {...field}
                                                                            invalid={errors.defaultAddress && true}
                                                                            checked={field.value}
                                                                        />
                                                                    )}
                                                                />
                                                                <Label className='mt-2 ms-3'>Set as default shipping address </Label>
                                                                {errors.defaultAddress && <FormFeedback>{errors.defaultAddress.message}</FormFeedback>}
                                                            </div>
                                                        </div>
                                                        <div style={{ justifyContent: 'center' }} className="modal-footer d-flex">
                                                            <Button className='ms-3 mt-2 me-2' type='submit' style={{ backgroundColor: "#E4510B", border: 'none', width: '100%' }} >
                                                                Save Address
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                )}
                            </Col>
                            <Col className='mt-3 check' sm={12} md={12} lg={4}>
                                <Card style={{ border: '1px solid #F0F0F0', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.05)' }} className='mt-4 mt-lg-0 '>
                                    <div className=' p-3'>
                                        <h5>Order Summary</h5>
                                        <hr className='p-0 m-0 mb-3' />
                                        <div className='d-flex justify-content-between'>
                                            <p className='cart-summary-para'>Item Subtotal</p>
                                            <p>{gst?.totalAfterGst?.toLocaleString('en-IN', {
                                                maximumFractionDigits: 2,
                                                style: 'currency',
                                                currency: 'INR'
                                            })}</p>
                                        </div>
                                        {/* <div className='d-flex justify-content-between'>
                                            <p className='cart-summary-para'>GST Value</p>
                                            <p>{gst?.totalGst?.toLocaleString('en-IN', {
                                                maximumFractionDigits: 2,
                                                style: 'currency',
                                                currency: 'INR'
                                            })}</p>
                                        </div> */}
                                        <div className='d-flex justify-content-between'>
                                            <p className='cart-summary-para'>Delivery Fee</p>
                                            <p>
                                                {gst?.shippingCharges === 0
                                                    ? 'Free'
                                                    : gst?.shippingCharges?.toLocaleString('en-IN', {
                                                        maximumFractionDigits: 2,
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}
                                            </p>
                                        </div>
                                        <hr className='p-0 m-0 mb-3' />
                                        <div className='d-flex justify-content-between'>
                                            <h6>Estimated Total <span style={{ color: '#888888' }}>(inclusive of all tax)</span></h6>
                                            <h5 style={{ color: '#37A003' }} className='fw-bold ms-3'>{gst?.finalAmount?.toLocaleString('en-IN', {
                                                maximumFractionDigits: 2,
                                                style: 'currency',
                                                currency: 'INR'
                                            })}</h5>
                                        </div>
                                        <div className='mt-4'>
                                            <button
                                                className={`order-now-btn ${gst?.error ? 'disabled-btn' : ''}`} // Add 'disabled-btn' class when disabled
                                                onClick={() => {
                                                    if (gst?.deliveryAvailable) {
                                                        handlePreviewPage(); // Proceed to preview if delivery is available
                                                    } else {
                                                        toast.error(gst?.error); // Show error toast if delivery is not available
                                                    }
                                                }}
                                                disabled={gst?.error} // Disable button if delivery is not available
                                            >
                                                Continue to Preview Product <FaAngleRight />
                                            </button>

                                            {gst?.error &&
                                                <p className="text-danger mt-2">{gst?.error}</p>
                                            }
                                        </div>

                                    </div>
                                </Card>
                                <div className='mt-3' style={{ background: '#FFE6E6', borderRadius: '15px' }}>
                                    <div className='p-3'>
                                        <h4>Is the address correct !</h4>
                                        <p>Please double-check the address you entered. If everything’s correct, continue to Preview Product.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div >
                </div>
            )}
            {/*Preview Product Page*/}
            {isPreview && (
                <div>
                    <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                        <div style={{ paddingLeft: '5%', paddingRight: '3%' }} className='d-flex mt-4'>
                            <div>
                                <p style={{ cursor: 'pointer' }} className='d-flex justify-content-center align-items-center mt-1 stage4' onClick={handleAddressPage} ><BiCheck style={{ color: 'white' }} /></p>
                            </div>
                            <img className='d-flex proces mt-3' src={step2} alt="" />
                            <div>
                                <p className='d-flex justify-content-center align-items-center mt-1 stage1' >2</p>
                            </div>
                            <img className='d-flex proces mt-3' src={step1} alt="" />
                            <div>
                                <p className='d-flex justify-content-center align-items-center mt-1 stage3' >3</p>
                            </div>
                            <hr />
                        </div>
                        <div style={{ paddingLeft: '2%', paddingRight: '3%' }} className='d-flex justify-content-between'>
                            <p style={{ color: '#262525' }} className='stage-con1'>Delivery Address</p>
                            <p style={{ color: '#E4510B' }} className='stage-con2' >Preview Product</p>
                            <p className='stage-con3' >Payment</p>
                        </div>
                    </div>
                    <div>
                        <div style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '5%' }}>
                            <Row>
                                <Col sm={12} md={12} lg={8}>
                                    {
                                        loading ? (
                                            <div style={{ height: '310px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Spinner
                                                    color="primary"
                                                    size=""
                                                >
                                                    Loading...
                                                </Spinner>
                                            </div>
                                        ) :
                                            (<>
                                                <div className='mt-5' style={{ borderRadius: '15px', border: '1px solid #FFFFFF' }}>
                                                    <Col sm={12} lg={12}>
                                                        <div style={{ border: '1px solid #F0F0F0', borderRadius: '15px' }}>
                                                            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                                                <p className='mt-3' style={{ fontSize: '20px', fontWeight: '700', color: '#2B2B2D', lineHeight: '25px' }}>Preview Product</p>
                                                            </div>
                                                            <hr style={{ height: '1px', width: '100%', color: '#EEEEEE' }} />
                                                            <Card className='mb-3' style={{ border: 'none', background: 'white', paddingRight: '5%', paddingLeft: '5%' }}>
                                                                {/* <h5 style={{ backgroundColor: 'rgb(237, 237, 237)' }} className='p-3 cart-title m-0'>All Jobs - {numberOfItem} items</h5> */}
                                                                {filtteredProducts?.map((eachItem, i) => (
                                                                    <div style={{ border: 'none', background: 'white', borderBottom: i !== dataa.products.length - 1 ? '1px solid #F0F0F0' : 'none' }} key={i} className='cart-card mt-4 p-3'>
                                                                        <div className='cart-left'>
                                                                            {/* <div className='cart-img d-flex align-items-center justify-content-center'>
                                                                                {eachItem?.customizeImages?.[0] ? (
                                                                                    <img
                                                                                        alt={`product`}
                                                                                        src={eachItem?.customizeImages?.[0]}
                                                                                        className='produ-img'
                                                                                    />
                                                                                ) : (
                                                                                    // <a href={eachItem?.file?.[0]} target='_blank'>
                                                                                    <img
                                                                                        alt={`product`}
                                                                                        src={eachItem?.file[0].endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : eachItem?.file[0]}
                                                                                        className='produ-img'
                                                                                    />
                                                                                    // </a>
                                                                                )}
                                                                            </div> */}
                                                                            <div className='cart-img d-flex align-items-center justify-content-center'>
                                                                                {eachItem?.customizeImages[0] ? (
                                                                                    <img
                                                                                        alt={`product`}
                                                                                        src={eachItem?.customizeImages[0]?.img_url}
                                                                                        className='produ-img'
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        {eachItem?.customizeImages?.length === 0 && eachItem?.file?.length === 0 ?
                                                                                            (
                                                                                                <img
                                                                                                    alt={`product`}
                                                                                                    src={eachItem?.product?.image}
                                                                                                    className='produ-img'
                                                                                                />
                                                                                            ) : (
                                                                                                <img
                                                                                                    alt={`product`}
                                                                                                    src={eachItem?.file[0].endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : eachItem?.file[0]}
                                                                                                    className='produ-img'
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                    // <a href={eachItem?.file?.[0]} target='_blank'>

                                                                                    // </a>
                                                                                )}

                                                                            </div>
                                                                            <div className='d-flex mt-2' style={{ alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                                                                <span style={{ cursor: 'pointer' }} onClick={() => viewCustom(eachItem)}><Eye style={{ color: '#E4510B' }} /> Preview</span>
                                                                            </div>
                                                                        </div>

                                                                        <div className='cart-right  '>
                                                                            <div className='d-flex justify-content-between me-3 mt-2'>
                                                                                <div className='d-flex'>
                                                                                    <h5 className='mt-1'>{eachItem?.product?.name}</h5>
                                                                                </div>
                                                                                <div className='d-flex justify-content-center align-items-center' style={{ color: '#E4510B', fontSize: '20px', fontWeight: '500', lineHeight: '24px', cursor: 'pointer' }} onClick={() => openDeleteModal(eachItem?._id)}>
                                                                                    {/* <span><IoTrashOutline style={{ color: '#E42525' }} size={25} /></span> */}
                                                                                    Remove <RiDeleteBin6Line className='ms-2' size={25} style={{ color: '#E4510B' }} />
                                                                                </div>
                                                                            </div>

                                                                            <div className='d-flex justify-content-between'>
                                                                                {isEdit[eachItem?._id] ? (
                                                                                    <>
                                                                                        <div style={{ marginTop: '-10px', marginBottom: '15px' }} className='mt-2'>
                                                                                            <label style={{ color: '#262525', fontSize: '16px', fontWeight: '400' }} className=''>Quantity:</label>
                                                                                            <input
                                                                                                className='ms-2 editQuantityInput'
                                                                                                name='editQuantity'
                                                                                                value={editedQuantity[eachItem?._id] || productQ}
                                                                                                onChange={handleQuantityChange}
                                                                                                placeholder='Enter Your Quantity'
                                                                                                type="number"
                                                                                                pattern="[0-9]+"
                                                                                                title="Please enter only digits (0-9)"
                                                                                            />

                                                                                            <span className='ms-2' style={{ cursor: 'pointer', color: 'green' }} onClick={confirmQuantity}><Check /></span>
                                                                                            <span className='text-danger ms-2' style={{ cursor: 'pointer' }} onClick={closeInput}><X /></span>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <p style={{ color: '#262525' }} className=' cart-quantity mt-2'>
                                                                                        Quantity: <span className='text-dark'>{eachItem?.quantity}</span>
                                                                                        {/* <span style={{ color: '#e6703a', cursor: 'pointer' }} onClick={() => editQuantity(eachItem?._id, eachItem?.quantity)}>
                                                                                            <Edit3 />
                                                                                        </span> */}
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                            {!eachItem?.sizeWithQuantity?.length > 0 &&
                                                                                <>
                                                                                    {eachItem?.size ? (
                                                                                        <div>
                                                                                            <p style={{ color: '#262525' }} className=' cart-quantity'>
                                                                                                Size: <span className='text-dark'>{eachItem?.size}</span>
                                                                                            </p>
                                                                                        </div>
                                                                                    ) : (null)}
                                                                                </>
                                                                            }
                                                                            {eachItem?.sizeWithQuantity?.length > 0 &&
                                                                                <>
                                                                                    {eachItem?.sizeWithQuantity[0]?.quantity ? (
                                                                                        <div className='mt-2'>
                                                                                            <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                                                                <div className='d-flex' onClick={() => toggleAccordion1(eachItem?._id)}>
                                                                                                    <h6 className=' p-0'>Size with Quantity</h6>
                                                                                                    <span className='ms-2 p-0'><ChevronDown /></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <Collapse isOpen={openAccordion1 === eachItem?._id}>
                                                                                                <div className='accordion-body'>
                                                                                                    <div className='mt-2 mb-2'>
                                                                                                        <table className="table">
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th>S. No</th>
                                                                                                                    <th>Size</th>
                                                                                                                    <th>Quantity</th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                {eachItem?.sizeWithQuantity?.map((item, index) => (
                                                                                                                    <tr key={index}>
                                                                                                                        <td>{index + 1}</td>
                                                                                                                        <td>{item.size || 'N/A'}</td>
                                                                                                                        <td>{item.quantity || 'N/A'}</td>
                                                                                                                    </tr>
                                                                                                                ))}
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Collapse>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className='mt-2'>
                                                                                            <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                                                                <div className='d-flex' onClick={() => toggleAccordion1(eachItem?._id)}>
                                                                                                    <h6 className=' p-0'>Names & Numbers</h6>
                                                                                                    <span className='ms-2 p-0'><ChevronDown /></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <Collapse isOpen={openAccordion1 === eachItem?._id}>
                                                                                                <div className='accordion-body'>
                                                                                                    <div className='mt-2 mb-2'>
                                                                                                        <table className="table">
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th>S. No</th>
                                                                                                                    <th>Name</th>
                                                                                                                    <th>Number</th>
                                                                                                                    <th>Size</th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                {eachItem?.sizeWithQuantity?.map((item, index) => (
                                                                                                                    <tr key={index}>
                                                                                                                        <td>{index + 1}</td>
                                                                                                                        <td>{item.name || 'N/A'}</td>
                                                                                                                        <td>{item.number || 'N/A'}</td>
                                                                                                                        <td>{item.size || 'N/A'}</td>
                                                                                                                    </tr>
                                                                                                                ))}
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Collapse>
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            }
                                                                            <div className='mt-2'>
                                                                                <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                                                    <div className='d-flex' onClick={() => toggleAccordion(eachItem?._id)}>
                                                                                        <h6 className=' p-0'>Product Specifications</h6>
                                                                                        <span className='ms-2 p-0'><ChevronDown /></span>
                                                                                    </div>
                                                                                </div>
                                                                                <Collapse isOpen={openAccordion === eachItem?._id}>
                                                                                    <div className='accordion-body'>
                                                                                        <div>
                                                                                            {/* <p style={{ color: '#e6703a' }}>Edit Details</p> */}

                                                                                            {eachItem?.field?.map((eachDetail, k) => (
                                                                                                <div className='d-flex justify-content-between' key={k}>
                                                                                                    <p className='text-secondary'><ChevronRight /> {eachDetail?.fieldType?.name}</p>
                                                                                                    <p className='pe-5 text-dark' style={{ fontWeight: '500' }}>- {eachDetail?.option?.name}</p>
                                                                                                </div>
                                                                                            ))}

                                                                                        </div>
                                                                                    </div>
                                                                                </Collapse>
                                                                            </div>
                                                                            <hr />
                                                                            <div className='d-flex justify-content-between'>
                                                                                <p className='fw-bold'>Item Total</p>
                                                                                <p style={{ color: '#37A003' }} className='fw-bold'>{eachItem?.amount?.toLocaleString('en-IN', {
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'INR'
                                                                                })}</p>

                                                                            </div>
                                                                            <div className='d-flex justify-content-between'>
                                                                                <p className='fw-bold'>Delivery Fee</p>
                                                                                <p style={{ color: '#37A003' }} className='fw-bold'>{shippingCharges[i]?.shippingCharge?.toLocaleString('en-IN', {
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'INR'
                                                                                })}</p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </Card>
                                                        </div>
                                                        <div className='mt-4 d-flex ' style={{ border: '1px solid #F0F0F0', borderRadius: '15px', paddingLeft: '3%', height: '60px' }}>
                                                            <div style={{ cursor: 'pointer' }} className='mt-3' onClick={() => openAddProductModal()}>
                                                                <p className='addcartproduct'><FaPlus style={{ color: '#E4510B' }} className='me-4' /> Add more Product from my cart</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </>)

                                    }
                                </Col>
                                <Col className='mt-5 check' sm={12} md={12} lg={4}>
                                    <Card style={{ border: '1px solid #F0F0F0', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.05)' }} className='mt-4 mt-lg-0 '>
                                        <div className=' p-3'>
                                            <h5>Order Summary</h5>
                                            <hr className='p-0 m-0 mb-3' />
                                            <div className='d-flex justify-content-between'>
                                                <p className='cart-summary-para'>Item Subtotal</p>
                                                <p>{gst?.totalAfterGst?.toLocaleString('en-IN', {
                                                    maximumFractionDigits: 2,
                                                    style: 'currency',
                                                    currency: 'INR'
                                                })}</p>
                                            </div>
                                            {/* <div className='d-flex justify-content-between'>
                                                <p className='cart-summary-para'>GST Value</p>
                                                <p>{gst?.totalGst?.toLocaleString('en-IN', {
                                                    maximumFractionDigits: 2,
                                                    style: 'currency',
                                                    currency: 'INR'
                                                })}</p>
                                            </div> */}
                                            <div className='d-flex justify-content-between'>
                                                <p className='cart-summary-para'>Delivery Fee</p>
                                                <p>
                                                    {gst?.shippingCharges === 0
                                                        ? 'Free'
                                                        : gst?.shippingCharges?.toLocaleString('en-IN', {
                                                            maximumFractionDigits: 2,
                                                            style: 'currency',
                                                            currency: 'INR'
                                                        })}
                                                </p>
                                            </div>
                                            <hr className='p-0 m-0 mb-3' />
                                            <div className='d-flex justify-content-between'>
                                                <h6>Estimated Total <span style={{ color: '#888888' }}>(inclusive of all tax)</span></h6>
                                                <h5 style={{ color: '#37A003' }} className='fw-bold ms-3'>{gst?.finalAmount?.toLocaleString('en-IN', {
                                                    maximumFractionDigits: 2,
                                                    style: 'currency',
                                                    currency: 'INR'
                                                })}</h5>
                                            </div>
                                            <div className='mt-4 d-flex'>
                                                <button className='' style={{ color: '#e4510b', background: 'white', border: '1px solid #e4510b', width: '150px' }} onClick={handlebBackaddress}>
                                                    <FaAngleLeft /> Previous
                                                </button>
                                                <button
                                                    className={`order-now-btn ms-2 ${gst?.error ? 'disabled-btn' : ''}`}
                                                    //  onClick={handlePaymentPage}>
                                                    onClick={() => {
                                                        if (gst?.deliveryAvailable) {
                                                            handlePaymentPage(); // Proceed to preview if delivery is available
                                                        } else {
                                                            toast.error(gst?.error); // Show error toast if delivery is not available
                                                        }
                                                    }}
                                                    disabled={gst?.error}
                                                >
                                                    Continue to Payment process <FaAngleRight />
                                                </button>
                                                {/* <div className='mt-4'>
                                            <button
                                                className={`order-now-btn ${gst?.error ? 'disabled-btn' : ''}`} // Add 'disabled-btn' class when disabled
                                                onClick={() => {
                                                    if (gst?.deliveryAvailable) {
                                                        handlePreviewPage(); // Proceed to preview if delivery is available
                                                    } else {
                                                        toast.error(gst?.error); // Show error toast if delivery is not available
                                                    }
                                                }}
                                                disabled={gst?.error} // Disable button if delivery is not available
                                            >
                                                Continue to Preview Product <FaAngleRight />
                                            </button>

                                            {gst?.error &&
                                                <p className="text-danger mt-2">{gst?.error}</p>
                                            }
                                        </div> */}
                                            </div>
                                            {gst?.error &&
                                                <p className="text-danger mt-2">{gst?.error}</p>
                                            }
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div >
            )}
            {/*Payment Page*/}
            {isPayment && (
                <div>
                    <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                        <div style={{ paddingLeft: '5%', paddingRight: '3%' }} className='d-flex mt-4'>
                            <div>
                                <p style={{ cursor: 'pointer' }} className='d-flex justify-content-center align-items-center mt-1 stage4' onClick={handleAddressPage}  ><BiCheck style={{ color: 'white' }} /></p>
                            </div>
                            <img className='d-flex proces mt-3' src={step2} alt="" />
                            <div>
                                <p style={{ cursor: 'pointer' }} className='d-flex justify-content-center align-items-center mt-1 stage4' onClick={handlePreviewPage}  ><BiCheck style={{ color: 'white' }} /></p>
                            </div>
                            <img className='d-flex proces mt-3' src={step2} alt="" />
                            <div>
                                <p className='d-flex justify-content-center align-items-center mt-1 stage1' >3</p>
                            </div>
                            <hr />
                        </div>
                        <div style={{ paddingLeft: '2%', paddingRight: '3%' }} className='d-flex justify-content-between'>
                            <p style={{ color: '#262525' }} className='stage-con1'>Delivery Address</p>
                            <p style={{ color: '#262525' }} className='stage-con2' >Preview Product</p>
                            <p style={{ color: '#E4510B' }} className='stage-con3' >Payment</p>
                        </div>
                    </div>
                    <div>
                        <div style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '5%' }}>
                            <Row>
                                <Col sm={12} md={12} lg={8}>
                                    <div className='mt-5' style={{ borderRadius: '15px', border: '1px solid #FFFFFF' }}>
                                        <Col sm={12}>
                                            <div style={{ border: '1px solid #F0F0F0', borderRadius: '15px' }}>
                                                <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                                    <p className='mt-3' style={{ fontSize: '20px', fontWeight: '700', color: '#2B2B2D', lineHeight: '25px' }}>
                                                        Payment Methods
                                                    </p>
                                                </div>
                                                <hr style={{ height: '1px', width: '100%', color: '#F0F0F0' }} />
                                                <Card className='mb-3' style={{ border: 'none', background: 'white', paddingRight: '5%', paddingLeft: '5%' }}>
                                                    <div>
                                                        {paymentOptions.map((option, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    borderBottom: index !== paymentOptions.length - 1 ? '1px solid #EEEEEE' : 'none'
                                                                }}
                                                                className='d-flex pe-4 mt-4'
                                                                disabled={option.name !== ""}
                                                            >
                                                                <div>
                                                                    <input
                                                                        className="radio me-3"
                                                                        type="radio"
                                                                        name="payment"
                                                                        checked={selectedIndex1 === option?.name} // Check if the option matches selectedIndex1
                                                                        onChange={() => handleRadioChange1(option?.name)} // Pass the selected option's name to the handler
                                                                    />
                                                                </div>
                                                                <div className='d-block'>
                                                                    <div className='d-flex'>
                                                                        <p
                                                                            className="d-flex align-items-center"
                                                                            style={{ fontSize: '16px', fontWeight: '400', lineHeight: '20px' }}
                                                                        >
                                                                            {option.name}  {option?.name === "Cash on Delivery" && (
                                                                                <>
                                                                                    {gst?.msg && <span className='ms-2' color='#e4510b'>({gst?.msg})</span>}
                                                                                </>
                                                                            )}
                                                                        </p>
                                                                        {option.icon && (
                                                                            <img className='mt-1' style={{ width: '15px', height: '15px' }} src={option.icon} alt="" />
                                                                        )}
                                                                    </div>
                                                                    <p style={{ fontSize: '14px', fontWeight: '400', lineHeight: '16px', color: '#262525', opacity: '0.5' }}>
                                                                        {option.description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Card>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                                <Col className='mt-5' sm={12} md={12} lg={4}>
                                    <Card style={{ border: '1px solid #F0F0F0', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.05)' }} className='mt-4 mt-lg-0'>
                                        <div className='p-3'>
                                            <h5>Order Summary</h5>
                                            <hr className='p-0 m-0 mb-3' />
                                            <div className='d-flex justify-content-between'>
                                                <p className='cart-summary-para'>Item Subtotal</p>
                                                <p>{gst?.totalAfterGst?.toLocaleString('en-IN', {
                                                    maximumFractionDigits: 2,
                                                    style: 'currency',
                                                    currency: 'INR'
                                                })}</p>
                                            </div>
                                            {/* <div className='d-flex justify-content-between'>
                                                <p className='cart-summary-para'>GST Value</p>
                                                <p>{gst?.totalGst?.toLocaleString('en-IN', {
                                                    maximumFractionDigits: 2,
                                                    style: 'currency',
                                                    currency: 'INR'
                                                })}</p>
                                            </div> */}
                                            <div className='d-flex justify-content-between'>
                                                <p className='cart-summary-para'>Delivery Fee</p>
                                                <p>
                                                    {gst?.shippingCharges === 0
                                                        ? 'Free'
                                                        : gst?.shippingCharges?.toLocaleString('en-IN', {
                                                            maximumFractionDigits: 2,
                                                            style: 'currency',
                                                            currency: 'INR'
                                                        })}
                                                </p>
                                            </div>
                                            {gst?.couponPrice > 0 &&
                                                <div className='d-flex justify-content-between'>
                                                    <p className='cart-summary-para'>Coupon Price</p>
                                                    <p>{gst?.couponPrice?.toLocaleString('en-IN', {
                                                        maximumFractionDigits: 2,
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}</p>
                                                </div>
                                            }
                                            <hr className='p-0 m-0 mb-3' />
                                            <div className='d-flex justify-content-between'>
                                                <h6>Estimated Total <span style={{ color: '#888888' }}>(inclusive of all tax)</span></h6>
                                                <h5 style={{ color: '#37A003' }} className='fw-bold ms-3'>
                                                    {gst?.finalAmount?.toLocaleString('en-IN', {
                                                        maximumFractionDigits: 2,
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}
                                                </h5>
                                            </div>
                                            <div className='d-flex justify-content-between coupon-tag'>
                                                <h6><span style={{ color: '#888888' }}><FaTag /> {couponCodeData?.length} Coupon available</span></h6>
                                                <button href="" onClick={openCouponCodeModal} className='coupon'>Apply Coupon</button>
                                            </div>
                                            <div className='mt-4 d-flex'>
                                                <button
                                                    className=''
                                                    style={{ color: '#e4510b', background: 'white', border: '1px solid #e4510b', width: '150px' }}
                                                    onClick={handlebBackPreview}
                                                >
                                                    <FaAngleLeft /> Previous
                                                </button>

                                                {selectedIndex1 === "Cash on Delivery" ? (
                                                    <button
                                                        className='order-now-btn ms-2'
                                                        type='submit'
                                                        disabled={isLoading} // Disable the button while loading
                                                        onClick={handleCOD}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        ) : (
                                                            'Confirm Order'
                                                        )}
                                                    </button>
                                                ) : (
                                                    <button
                                                        className='order-now-btn ms-2'
                                                        disabled={isLoading} // Disable the button while loading
                                                        onClick={() => {
                                                            if (selectedIndex1 === "Phonepe (UPI, Credit/Debit Card, Net Banking)") {
                                                                handlePay();
                                                            } else {
                                                                handleRazorPay();
                                                            }
                                                        }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        ) : (
                                                            `Pay ${gst?.finalAmount?.toLocaleString('en-IN', {
                                                                maximumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'INR'
                                                            })}`
                                                        )}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                    </div>
                </div>
            )}
            {isSucess && (
                <div>
                    <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                        <div style={{ width: '100%' }} className='d-flex mt-4'>
                            <div>
                                <p style={{ cursor: 'pointer' }} className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                            </div>
                            <img className='d-flex proces mt-3' src={step2} alt="" />
                            <div>
                                <p style={{ cursor: 'pointer' }} className='d-flex justify-content-center align-items-center mt-1 stage4'  ><BiCheck style={{ color: 'white' }} /></p>
                            </div>
                            <img className='d-flex proces mt-3' src={step2} alt="" />
                            <div>
                                <p style={{ cursor: 'pointer' }} className='d-flex justify-content-center align-items-center mt-1 stage4'  ><BiCheck style={{ color: 'white' }} /></p>
                            </div>
                            <hr />
                        </div>
                        <div style={{ paddingLeft: '2%', paddingRight: '3%' }} className='d-flex justify-content-between'>
                            <p style={{ color: '#262525' }} className='stage-con1'>Delivery Address</p>
                            <p style={{ color: '#262525' }} className='stage-con2' >Preview Product</p>
                            <p style={{ color: '#E4510B' }} className='stage-con3' >Payment</p>
                        </div>
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center ps-2 pe-2'>
                        <img style={{ width: '300px' }} src={sucess} alt="" />
                        <p style={{ fontSize: '36px', fontWeight: '700', lineHeight: '50px', color: '#E4510B', textAlign: 'center' }}>Thank you for ordering!</p>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <p style={{ fontSize: '24px', fontWeight: '600', font: 'Manrope', color: '#262525', opacity: '0.8' }}>Order placed for {gst?.finalAmount?.toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                            })}</p>
                            <p style={{ fontSize: '20px', fontWeight: '400', font: 'Lato', lineHeight: '32px', color: '#262525', opacity: '0.6' }} className='text-center'>
                                Your {selectedProductIds?.length} item(s) will be delivered by <br />
                                <span style={{ color: '#262525' }}>Printon soon. Visit the order page to track your products.</span>
                            </p>
                        </div>
                        <div className='d-flex mb-4'>
                            <div>
                                <Link to='/myOrder'>
                                    <button style={{ background: 'white', color: '#E4510B', fontSize: '16px', fontWeight: '700', lineHeight: '24px', border: '1px solid #E4510B', padding: '14px' }}>View Order</button>
                                </Link>
                            </div>
                            <div className='ms-3'>
                                <Link to='/products'>
                                    <button style={{ background: '#E4510B', color: 'white', fontSize: '16px', fontWeight: '700', lineHeight: '24px', border: '1px solid #E4510B', padding: '14px' }}>Continue Shopping</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div style={{ background: '#E4510B', paddingLeft: '5%', paddingRight: '5%' }}>
                <div className='footer-bottom d-lg-flex pt-2 pb-2'>
                    <div className='d-lg-flex w-50 justify-content-between'>
                        <p className='footer-bottom-head m-0 mb-2'>© 2024 printon.co.in</p>
                        <p className='footer-bottom-para me-3'><NavLink>Site Map</NavLink></p>
                        <p className='footer-bottom-para me-3'><NavLink to="/privacyPolicy">Privacy Policy</NavLink></p>
                        <p className='footer-bottom-para me-3'><NavLink>Cookie Policy</NavLink></p>
                        <p className='footer-bottom-para me-3'><NavLink to='/Terms'>Terms</NavLink></p>
                    </div>
                    <div className='footer-bottom-img flex-wrap mt-2 mt-md-0'>
                        <img src={visa} height={40} className='me-3' alt="logo" />
                        <img src={master} height={40} className='me-3' alt="logo" />
                        <img src={paypl} height={40} className='me-3' alt="logo" />
                        <img src={america} height={40} alt="logo" />
                    </div>
                </div>
                <div className='fixed-bottom footer-whatsapp right-50 ' style={{ zIndex: "6", left: "initial", marginLeft: "5px" }}>
                    <a className='whatsappLink' href={`https://wa.me/${socialMedias?.whatsApp?.whatsAppNumber}?text=${socialMedias?.whatsApp?.text}`} target='_blank'>
                        <img src={whatsapp} width="100" alt='Whatsapp' />
                    </a>
                </div>
            </div>


            {/* Add and Edit modal for Address*/}
            <Modal isOpen={addAddressModal} toggle={closeAddAddressModal} style={{ borderRadius: '15px' }} className="modal-dialog-centered modal-xs">
                <div className="d-flex align-items-center justify-content-between px-1" style={{ backgroundColor: 'white', borderBottom: '1px solid #EEEEEE', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}>
                    <h4 className="modal-title" style={{ marginTop: '23px', marginBottom: '23px', paddingLeft: '10px', fontFamily: 'roboto', color: 'black' }}>
                        {selectedAddress ? 'Edit Delivery Address' : 'Add New Delivery Address'}
                    </h4>
                    <X style={{ color: 'black', cursor: 'pointer' }} className="addmodal-close fw-normal mt-25 me-2" size={25} onClick={closeAddAddressModal} />
                </div>
                <ModalBody>
                    <Form onSubmit={handleSubmit(submitForm)}>
                        <div className='ps-3 pe-3'>
                            <div className='d-flex justify-content-between'>
                                <div className='mb-2'>
                                    <Label>First Name <span style={{ color: 'red' }}>*</span> </Label>
                                    <Controller
                                        name='firstName'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                invalid={errors.firstName && true}
                                                placeholder="Enter Your First Name"
                                            />
                                        )}
                                    />
                                    {errors.firstName && <FormFeedback>{errors.firstName.message}</FormFeedback>}
                                </div>
                                <div className='mb-2'>
                                    <Label>Last Name <span style={{ color: 'red' }}>*</span> </Label>
                                    <Controller
                                        name='lastName'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                invalid={errors.lastName && true}
                                                placeholder="Enter Your Last Name"
                                            />
                                        )}
                                    />
                                    {errors.lastName && <FormFeedback>{errors.lastName.message}</FormFeedback>}
                                </div>
                            </div>
                            <div className='mb-2'>
                                <Label>Email  <span style={{ color: 'red' }}>*</span></Label>
                                <Controller
                                    name='email'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            invalid={errors.email && true}
                                            placeholder="Enter Your Email"
                                        />
                                    )}
                                />
                                {errors.email && <FormFeedback>{errors.email.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label>Landmark  <span style={{ color: 'red' }}>*</span></Label>
                                <Controller
                                    name='landmark'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            invalid={errors.landmark && true}
                                            placeholder="Enter Landmark"
                                        />
                                    )}
                                />
                                {errors.landmark && <FormFeedback>{errors.landmark.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label>Address  <span style={{ color: 'red' }}>*</span></Label>
                                <Controller
                                    name='address'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            invalid={errors.address && true}
                                            placeholder="Enter Your Address"
                                        />
                                    )}
                                />
                                {errors.address && <FormFeedback>{errors.address.message}</FormFeedback>}
                            </div>
                            <div className='mb-2 position-relative'>
                                <Label>
                                    Pincode <span style={{ color: 'red' }}>*</span>
                                </Label>
                                <Controller
                                    name='pincode'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <>
                                            <Input
                                                type="text"
                                                {...field}
                                                onChange={(e) => handlePincodeChange(e, field)}
                                                invalid={!!errors.pincode}
                                                placeholder="Enter Pincode"
                                            />
                                            {errors.pincode && <FormFeedback>{errors.pincode.message}</FormFeedback>}
                                        </>
                                    )}
                                />

                                {/* Dropdown suggestions */}
                                {pincodeSuggestions.length > 0 && (
                                    <div className="pincode-dropdown">
                                        {pincodeSuggestions.map((suggestion, index) => (
                                            <div
                                                key={index}
                                                className="pincode-suggestion"
                                                onClick={() => handleSelectSuggestion(suggestion)}
                                            >
                                                <span>
                                                    <i className="fa fa-map-marker" aria-hidden="true"></i> {suggestion.pincode}
                                                </span>
                                                <div>
                                                    {suggestion.officeName}, {suggestion.district}, {suggestion.stateName}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* Loading spinner */}
                                {load && <div><Spinner></Spinner></div>}

                                {/* Invalid pincode message */}
                                {invalidPincodeError && <div className="text-danger">{invalidPincodeError}</div>}
                            </div>
                            <div className='mb-2'>
                                <Label>State  <span style={{ color: 'red' }}>*</span></Label>
                                <Controller
                                    name='state'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            readOnly
                                            invalid={errors.state && true}
                                            placeholder="Enter Your State"
                                        />
                                    )}
                                />
                                {errors.State && <FormFeedback>{errors.State.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label>City/Town  <span style={{ color: 'red' }}>*</span></Label>
                                <Controller
                                    name='city'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            readOnly
                                            invalid={errors.city && true}
                                            placeholder="Enter Your City"
                                        />
                                    )}
                                />
                                {errors.city && <FormFeedback>{errors.city.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label>Area<span style={{ color: 'red' }}>*</span></Label>
                                <Controller
                                    name='areaName'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            readOnly
                                            invalid={errors.areaName && true}
                                            placeholder="Enter Your Area Name"
                                        />
                                    )}
                                />
                                {errors.areaName && <FormFeedback>{errors.areaName.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label>Phone <span style={{ color: 'red' }}>*</span> </Label>
                                <Controller
                                    name='mobile'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            invalid={errors.mobile && true}
                                            placeholder="Enter Mobile Number"
                                        />
                                    )}
                                />
                                {errors.mobile && <FormFeedback>{errors.mobile.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label>Alternate Phone </Label>
                                <Controller
                                    name='alternateMobile'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            invalid={errors.alternateMobile && true}
                                            placeholder="Enter Alternate Mobile Number"
                                        />
                                    )}
                                />
                                {errors.alternateMobile && <FormFeedback>{errors.alternateMobile.message}</FormFeedback>}
                            </div>
                            <div className='mb-2 mt-3 d-flex'>
                                <Controller
                                    name='isGst'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="checkbox"
                                            {...field}
                                            invalid={errors.isGst && true}
                                            checked={field.value}
                                        />
                                    )}
                                />
                                <Label className='mt-2 ms-3'>Do you have a GSTIN</Label>
                                {errors.isGst && <FormFeedback>{errors.isGst.message}</FormFeedback>}
                            </div>

                            {watch('isGst') && (
                                <>
                                    <div className='mb-2'>
                                        <Label>GSTIN Number </Label>
                                        <Controller
                                            name='gstNo'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Input
                                                    type="text"
                                                    {...field}
                                                    invalid={errors.gstNo && true}
                                                    placeholder="Enter GSTIN Number"
                                                />
                                            )}
                                        />
                                        {errors.gstNo && <FormFeedback>{errors.gstNo.message}</FormFeedback>}
                                    </div>

                                    <div className='mb-2'>
                                        <Label>
                                            Business Name
                                            {/* The eye icon with the tooltip */}
                                            <span
                                                id="businessNameTooltip"  // Give this element an ID to associate the tooltip
                                                style={{ marginLeft: '5px', cursor: 'pointer' }}
                                            >
                                                &#9432;  {/* This represents an eye or info icon */}
                                            </span>
                                            <Tooltip
                                                anchorId="businessNameTooltip"  // Use the anchorId to connect the tooltip
                                                place="right"
                                                effect="solid"
                                                style={{ width: '300px', height: '200px', background: 'white', color: 'black', border: '1px solid black' }}
                                            >
                                                Provide the full legal entity name as per your GST Certificate or as you want to appear on the invoice. If you are a Sole Proprietor or unregistered business, provide the name you conduct business under. If you do not have a business name, enter your full name.
                                            </Tooltip>
                                        </Label>
                                        <Controller
                                            name='businessName'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Input
                                                    type="text"
                                                    {...field}
                                                    invalid={errors.businessName && true}
                                                    placeholder="Enter Business Name"
                                                />
                                            )}
                                        />
                                        {errors.businessName && <FormFeedback>{errors.businessName.message}</FormFeedback>}
                                    </div>
                                </>
                            )}

                            <div className='mb-2 mt-3 d-flex'>
                                <Controller
                                    name='defaultAddress'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="checkbox"
                                            {...field}
                                            invalid={errors.defaultAddress && true}
                                            checked={field.value}
                                        />
                                    )}
                                />
                                <Label className='mt-2 ms-3'>Set as default shipping address </Label>
                                {errors.defaultAddress && <FormFeedback>{errors.defaultAddress.message}</FormFeedback>}
                            </div>
                        </div>
                        <div style={{ justifyContent: 'center' }} className="modal-footer d-flex">
                            <Button style={{ color: '#E4510B', background: 'white', border: '1px solid #E4510B', width: '40%' }} onClick={closeAddAddressModal} >
                                Cancel
                            </Button>
                            <Button className='ms-3' type='submit' style={{ backgroundColor: "#E4510B", border: 'none', width: '40%' }} >
                                {selectedAddress ? 'Update Address' : 'Save Address'}
                            </Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>

            {/* Add modal for Cart Product add*/}
            <Modal isOpen={addProductModal} toggle={closeAddProductModal} size="lg" style={{ borderRadius: '15px' }} className="modal-dialog-centered modal-xs">

                <div className="d-flex align-items-center justify-content-between mb-1 px-1" style={{ backgroundColor: 'white', borderBottom: '1px solid #EEEEEE', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}>
                    <h4 className="modal-title" style={{ marginTop: '23px', marginBottom: '23px', paddingLeft: '10px', fontFamily: 'roboto', color: 'black' }}>
                        Add Products
                    </h4>
                    <X style={{ color: 'black', cursor: 'pointer' }} className="addmodal-close fw-normal mt-25 me-2" size={25} onClick={closeAddProductModal} />
                </div>
                <ModalBody>
                    <Card className='' style={{ border: 'none', background: 'white' }}>
                        {filteredProducts1?.length === 0 ? (
                            <p className='text-center'>There is not enough products to add from cart</p>
                        ) : (
                            filteredProducts1?.map((eachItem, i) => (
                                <div
                                    style={{ borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.05)', background: 'white' }}
                                    key={i}
                                    className='cart-card mt-4 p-3'
                                >
                                    <div>
                                        <input
                                            className='check1'
                                            type="checkbox"
                                            checked={selectedCartProductIds.includes(eachItem._id)}
                                            onChange={() => handleCheckboxChange(eachItem._id)}
                                        />
                                    </div>
                                    <div className='cart-left ms-2'>
                                        {/* <div className='cart-img d-flex align-items-center justify-content-center'>
                                            {eachItem?.customizeImages?.[0] ? (
                                                <img
                                                    alt={`product image`}
                                                    src={eachItem?.customizeImages?.[0]}
                                                    className='produ-img'
                                                />
                                            ) : (
                                                // <a href={eachItem?.file?.[0]} target='_blank'>
                                                <img
                                                    alt={`product image`}
                                                    src={eachItem?.file[0].endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : eachItem?.file[0]}
                                                    className='produ-img'
                                                />
                                                // </a>
                                            )}
                                        </div> */}
                                        <div className='cart-img d-flex align-items-center justify-content-center'>
                                            {eachItem?.customizeImages?.[0] ? (
                                                <img
                                                    alt={`product`}
                                                    src={eachItem?.customizeImages[0]?.img_url}
                                                    className='produ-img'
                                                />
                                            ) : (
                                                <>
                                                    {eachItem?.customizeImages?.length === 0 && eachItem?.file?.length === 0 ?
                                                        (
                                                            <img
                                                                alt={`product`}
                                                                src={eachItem?.product?.image}
                                                                className='produ-img'
                                                            />
                                                        ) : (
                                                            <img
                                                                alt={`product`}
                                                                src={eachItem?.file[0].endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : eachItem?.file[0]}
                                                                className='produ-img'
                                                            />
                                                        )
                                                    }
                                                </>
                                                // <a href={eachItem?.file?.[0]} target='_blank'>

                                                // </a>
                                            )}

                                        </div>
                                    </div>

                                    <div className='cart-right'>
                                        <div className='d-flex justify-content-between me-3 mt-2'>
                                            <div className='d-flex'>
                                                <h5 className='mt-1'>{eachItem?.product?.name}</h5>
                                            </div>
                                        </div>

                                        <div className='d-flex justify-content-between'>
                                            {isEdit[eachItem?._id] ? (
                                                <div className='mt-2'>
                                                    <label style={{ color: 'black', fontSize: '18px' }} className=''>Quantity </label>
                                                    <input
                                                        className='ms-2 editQuantityInput'
                                                        name='editQuantity'
                                                        value={editedQuantity[eachItem?._id] || productQ}
                                                        onChange={handleQuantityChange}
                                                        placeholder='Enter Your Quantity'
                                                        type="number"
                                                        pattern="[0-9]+"
                                                        title="Please enter only digits (0-9)"
                                                    />
                                                    <span className='ms-2' style={{ cursor: 'pointer', color: 'green' }} onClick={confirmQuantity}><Check /></span>
                                                    <span className='text-danger ms-2' style={{ cursor: 'pointer' }} onClick={closeInput}><X /></span>
                                                </div>
                                            ) : (
                                                <p className='cart-quantity mt-1'>
                                                    Quantity: <span className='text-dark'>{eachItem?.quantity}</span>
                                                </p>
                                            )}
                                        </div>

                                        {eachItem?.size && (
                                            <div>
                                                <p className='cart-quantity'>
                                                    Size: <span className='text-dark'>{eachItem?.size}</span>
                                                </p>
                                            </div>
                                        )}

                                        <div className='mt-2'>
                                            <div className='accordion-header' style={{ cursor: 'pointer' }} onClick={() => toggleAccordion(eachItem?._id)}>
                                                <div className='d-flex'>
                                                    <h6 className='p-0'>Product Specifications</h6>
                                                    <span className='ms-2 p-0'><ChevronDown /></span>
                                                </div>
                                            </div>
                                            <Collapse isOpen={openAccordion === eachItem?._id}>
                                                <div className='accordion-body'>
                                                    {eachItem?.field?.map((eachDetail, k) => (
                                                        <div className='d-flex justify-content-between' key={k}>
                                                            <p className='text-secondary'><ChevronRight /> {eachDetail?.fieldType?.name}</p>
                                                            <p className='pe-5 text-dark' style={{ fontWeight: '500' }}>- {eachDetail?.option?.name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Collapse>
                                        </div>
                                        <hr />
                                        <div className='d-flex justify-content-between'>
                                            <p className='fw-bold'>Item Total</p>
                                            <p style={{ color: '#37A003' }} className='fw-bold'>
                                                {eachItem?.amount?.toLocaleString('en-IN', {
                                                    maximumFractionDigits: 2,
                                                    style: 'currency',
                                                    currency: 'INR'
                                                })}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </Card>

                </ModalBody>
                <div style={{ justifyContent: 'center' }} className="modal-footer d-flex">
                    <Button style={{ color: '#E4510B', background: 'white', border: '1px solid #E4510B', width: '40%' }} onClick={handleCancel} >
                        Cancel
                    </Button>
                    <Button className='ms-3' type='submit' style={{ backgroundColor: "#E4510B", border: 'none', width: '40%' }} onClick={handleApply} >
                        Apply
                    </Button>
                </div>
            </Modal>

            <Modal isOpen={addCouponCodeModal} toggle={closeCouponCodeModal} size="lg" style={{ borderRadius: '15px' }} className="modal-dialog-centered modal-xs">

                <div className="d-flex align-items-center justify-content-between mb-1 px-1" style={{ backgroundColor: 'white', borderBottom: '1px solid #EEEEEE', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}>
                    <h4 className="modal-title" style={{ marginTop: '23px', marginBottom: '23px', paddingLeft: '10px', fontFamily: 'roboto', color: 'black' }}>
                        Apply Coupon
                    </h4>
                    <X style={{ color: 'black', cursor: 'pointer' }} className="addmodal-close fw-normal mt-25 me-2" size={25} onClick={closeCouponCodeModal} />
                </div>
                <ModalBody>
                    <div className=' mh-75'>

                        <div className='d-flex p-3'>
                            <input
                                className='coupon-input'
                                type="text"
                                placeholder='Enter Coupon Code'
                                value={couponCode1}
                                onChange={handleInputChange} // Convert input to uppercase
                            />
                            {CouponCodeValue !== couponCode1 || (!CouponCodeValue && !couponCode1) ? (
                                <button
                                    className='Apply-button ms-3'
                                    onClick={() => totalGstCheck(couponCode1)}>
                                    Apply
                                </button>
                            ) : (
                                <button className='Applied-button ms-3'>Applied</button>
                            )}
                        </div>
                        {couponCodeError &&
                            <p className='text-danger ps-3'>{couponCodeError}</p>
                        }
                        {couponCodeData?.map((coupon) => (
                            <Card className='coupons' style={{ backgroundColor: '#eff1f2' }} key={coupon?._id}>
                                <div className='d-block'>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <h4 className='coupon-code'>{coupon?.code}</h4>
                                        </div>
                                        <div className='d-flex'>
                                            <button
                                                className='Apply-button'
                                                onClick={() => totalGstCheck(coupon?.code, setCouponCodeValue(coupon?.code))}>
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                    <p className='coupondescripiton'>{coupon?.description}</p>
                                    {expandedCoupon === coupon?._id ? (
                                        <>
                                            {coupon?.instructions?.map((instruction, index) => (
                                                <div key={index}>
                                                    <p className='coupondescription'>{instruction?.description}</p>
                                                </div>
                                            ))}
                                            <div>
                                                <button
                                                    onClick={() => setExpandedCoupon(null)} // Collapse the expanded coupon
                                                    className='coupon'>
                                                    Read Less...
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <div>
                                            <button
                                                onClick={() => setExpandedCoupon(coupon?._id)} // Expand the clicked coupon
                                                className='coupon'>
                                                Read More...
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </Card>
                        ))}
                    </div>
                </ModalBody>
            </Modal>

            {/* To view uploaded or customized image in preview product page*/}
            <Preview modal={modal1} toggle={toggle1} cardImages={customizeImages} />

        </>
    );
}

export default PlaceOrderIndex;
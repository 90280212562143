import React, { useEffect, useState } from "react";
import Navbar from "../navbar";
import FooterSection from "../footer";
import { useNavigate } from "react-router-dom";
import { speedSale } from "../../ApiConfigs/ApiConfig";
import axios from "axios";
import { Col, Row, Spinner } from "reactstrap";
// import star from "../../assets/images/logo/Star 2.png"
// import star5 from "../../assets/images/logo/Star 5.png"
// import { FaAngleRight } from "react-icons/fa6";
import TitleComponent from "../homeNew/TitleComponent";
import ProductCard from "../homeNew/ProductCard";

function PopularProducts() {
  const [loading, setLoading] = useState(false);
  const [dataa, setData] = useState([]);

  const navigate = useNavigate();

  const additionalData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${speedSale}`);
      setData(response?.data?.result);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    additionalData();
  }, []);

  const navToHome = () => {
    navigate("/");
  };
  const navToProduct = () => {
    navigate("/products");
  };

  // const navToPage = (id) => {
  //   navigate(`/${id}`);
  // };

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  // const truncateDescription = (description, maxLength) => {
  //   return description.length > maxLength
  //     ? `${description.slice(0, maxLength)}...`
  //     : description;
  // };

  // const navToViewPage = (product_url) => {
  //   navigate(`/${product_url}`);
  // };

  return (
    <>
      <Navbar />
      {loading ? (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner color="primary">Loading...</Spinner>
        </div>
      ) : (
        <div
          className="pt-3 des1"
        >
          <p className='pt-3' style={{ cursor: 'pointer', color: '#333333', opacity: '0.8' }}><span onClick={navToHome}>Home</span> / <span style={{ color: '#E4510B' }} onClick={navToProduct}>Popular products</span></p>
          <div className=''>
            <div className="mb-3">
              <TitleComponent title={`Popular Products`} isDesc={true} desc={`Explore a wide range of categories featuring top-quality products tailored to your needs!`} />
            </div>
            <Row className="">
              {dataa?.map((eachItem, i) => (
                <Col sm={12} md={6} lg={4} xl={3} key={i}>
                  <ProductCard data={eachItem} />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      )}
      <FooterSection />
    </>
  );
}

export default PopularProducts;

import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Form, FormFeedback, Input, Label, Modal, ModalBody } from 'reactstrap';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import './index.css';
import { getOfferProduct, productCategory, product } from '../../../../ApiConfigs/ApiConfig';


function OfferProductAdd({ open, handleAdd, offerData }) {
    // const [profileImageUrl, setProfileImageUrl] = useState('');
    const [getProductData, setGetProductData] = useState([]);
    const [getData, setGetData] = useState([]);
    const [productCat, setProductCat] = useState("")
    const [selectedProduct, setSelectedProduct] = useState('')

    const formSchema = yup.object().shape({
        description: yup.string().required('Please Enter Your Offer Product Description'),
        offerPercentage: yup.string().required('Please Enter Your  Percentage'),
    });

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    // const selectedProductCategory = useWatch({
    //     control,
    //     name: 'productCategory',
    // });

    const handleReset = () => {
        reset({
            description: '',
            productCategory: '',
            product: '',
            offerPercentage: '',
        });
        // setProfileImageUrl('');
        setProductCat('')
        setSelectedProduct('')
    };

    const getproductCategory = async () => {
        try {
            const response = await axios.get(productCategory);
            setGetData(response?.data?.result);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    };

    const getProduct = async (id) => {
        try {
            const response = await axios.get(`${product}?productCategory=${id}`);
            setGetProductData(response?.data?.result);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    };

    const getOptions = getData?.map((item) => ({
        label: item?.name,
        value: item?._id,
    }));

    const getProductOptions = getProductData?.map((item) => ({
        label: item?.name,
        value: item?._id,
    }));

    const submitForm = async (data) => {
        const payload = {
            ...data,
            product: selectedProduct,
            productCategory: productCat
        }
        try {
            const response = await axios.post(getOfferProduct, payload);
            handleAdd();
            offerData();
            handleReset();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg);
        }
    };

    useEffect(() => {
        getproductCategory();
    }, []);

    useEffect(() => {
        getProduct(productCat)
    }, [productCat])

    return (
        <div className="modal-page">
            <Modal
                isOpen={open}
                toggle={handleAdd}
                className="sidebar-lg"
                modalClassName="modal-slide-in sidebar-todo-modal"
                contentClassName="p-0"
            >
                <div
                    className="d-flex align-items-center justify-content-between mb-1 px-1"
                    style={{ backgroundColor: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottom: '1px solid #EEEEEE' }}
                >
                    <h4
                        className="modal-title"
                        style={{
                            marginTop: '23px',
                            marginBottom: '23px',
                            paddingLeft: '10px',
                            fontFamily: 'roboto',
                            color: 'black',
                        }}
                    >
                        Add Offer
                    </h4>
                    <X
                        style={{ color: 'black', cursor: 'pointer' }}
                        className="addmodal-close fw-normal mt-25 me-2"
                        size={25}
                        onClick={handleAdd}
                    />
                </div>
                <ModalBody className="flex-grow-1">
                    <Form onSubmit={handleSubmit(submitForm)}>
                        <div className="mb-1 mt-1">
                            <Label className="modal-label">Select Category  <span style={{ color: 'red' }}>*</span></Label>

                            <Select
                                type="select"
                                options={getOptions}
                                onChange={(e) => setProductCat(e.value)}
                            />
                        </div>
                        {productCat && (
                            <div className="mb-1 mt-3">
                                <Label className="modal-label">Select Product </Label>
                                <Select
                                    type="select"
                                    options={getProductOptions}
                                    onChange={(e) => setSelectedProduct(e.value)}
                                />
                            </div>
                        )}
                        {!productCat && (
                            <div className="mb-1 mt-3">
                                <Label className="modal-label">Select Producte</Label>
                                <Select
                                    type="select"
                                    options={[]}
                                    onChange={() => setSelectedProduct('')}
                                />
                            </div>
                        )}



                        <div className="mb-1">
                            <Label className="modal-label">Offer product description <span style={{ color: 'red' }}>*</span></Label>
                            <Controller
                                name="description"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <Input
                                        type="text"
                                        {...field}
                                        invalid={errors.description && true}
                                        placeholder="Enter Your Offer Product Description"
                                    />
                                )}
                            />
                            {errors.description && (
                                <FormFeedback>{errors.description.message}</FormFeedback>
                            )}
                        </div>
                        <div className="mb-1">
                            <Label className="modal-label">Offer Precentage <span style={{ color: 'red' }}>*</span></Label>
                            <Controller
                                name="offerPercentage"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <Input
                                        type="number"
                                        {...field}
                                        invalid={errors.offerPercentage && true}
                                        placeholder="Enter Your Offer Percentage"
                                    />
                                )}
                            />
                            {errors.offerPercentage && (
                                <FormFeedback>{errors.offerPercentage.message}</FormFeedback>
                            )}
                        </div>
                        <div className='d-bloick mt-3'>
                            <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                            <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}>Are you sure you want to Add Offer for this Product</p>
                        </div>
                        <hr />
                        <div className='d-flex'>
                            <button style={{ border: '1px solid #E4510B', color: '#E4510B', background: 'white', borderRadius: '10px' }} className='mt-1 w-50' type='button' onClick={handleAdd}>Cancel</button>
                            <button className='mt-1 w-50 ms-2 add-modal-btn' type='submit'>Submit</button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default OfferProductAdd;

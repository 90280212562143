import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PlusCircle, Trash2, Upload, X } from 'react-feather'
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Button, Col, Form, FormFeedback, Input, Label, Row, Spinner } from 'reactstrap'
import { toast } from 'react-hot-toast';
import "./index.css";
import Select from 'react-select';
import { productCategoryApi, textPosition, viewPosition } from '../../../../ApiConfigs/ApiConfig';
import { uploadImage } from '../../upload';
import Navbar from '../../../navbar';
import { useNavigate } from 'react-router-dom';



function ProductCategoryAdd() {

    const inputRef = useRef(null);
    const inputRef1 = useRef(null);
    const navigate = useNavigate();
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [profileIconUrl, setProfileIconUrl] = useState('');
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isNameNumber, setIsNameNumber] = useState(false)
    const [image, setImage] = useState(null)
    const [icon, setIcon] = useState(null)
    // const [fieldTypeIds, setFieldTypeIds] = useState([]);


    const formSchema = yup.object().shape({

        name: yup.string().required('Please Enter Category Name').matches(/^[a-zA-Z0-9\s]+$/, 'Category Name should not contain special characters'),
        description: yup.string().required('Please Enter Your Description'),
        hsnCode: yup.string().required('Please Enter Your HSN Code'),
        minimumPrice: yup.string().required('Please Enter Your Basic Price'),

    })
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    const { fields: fieldTypesFields, append: appendFieldType, remove: removeFieldType } = useFieldArray({
        control,
        name: "positions",
    });

    const [getOptionsFieldType, setOptionsFieldType] = useState([]);
    const [selectedPositions, setSelectedPositions] = useState({});
    const [fieldOptions, setFieldOptions] = useState([]);
    // Fetch options for the Position dropdown

    const getFieldType = useCallback(async () => {
        try {
            const response = await axios.get(viewPosition);
            const optionsFieldType = response?.data?.result || [];
            const filteredOptions = optionsFieldType.filter(item => {
                return !Object.values(selectedPositions).includes(item._id);
            });
            setOptionsFieldType(filteredOptions);
        } catch (error) {
            console.error("Error fetching field types:", error);
            toast.error(error?.response?.data?.msg || "Error fetching field types.");
        }
    }, [viewPosition, selectedPositions]);

    useEffect(() => {
        getFieldType();
    }, [getFieldType]);

    const getFieldOption = async (id) => {
        try {
            const response = await axios.get(`${textPosition}?viewPosition=${id}`);
            setFieldOptions(response?.data?.result || []);
        } catch (error) {
            console.error("Error fetching TextOptions:", error);
            toast.error(error?.response?.data?.msg || "Error fetching TextOptions.");
        }
    };
    const customSide = getOptionsFieldType.map(item => ({
        label: item?.name,
        value: item?._id,
    })
    );
    const fieldOption = fieldOptions.map(item => ({
        label: item?.name,
        value: item?._id,
    })
    );

    const handlePositionChange = (selectedOption, index) => {
        const updatedSelectedPositions = { ...selectedPositions, [index]: selectedOption.value };
        setSelectedPositions(updatedSelectedPositions);
        getFieldOption(selectedOption.value);
    };
    const getFilteredOptions = (index) => {
        return customSide.filter(option => !Object.values(selectedPositions).includes(option.value) || selectedPositions[index] === option.value);
    };

    // useEffect(() => {
    //     if (fieldTypeIds.length) {
    //         let value = fieldTypeIds.map(id => id.id);
    //         // setTypeIds(value)
    //     }
    // }, [fieldTypeIds])

    const customStyles = (isMulti) => ({
        control: (provided, state) => ({
            ...provided,
            height: 'auto', // Set the height to auto
            minHeight: isMulti ? '38px' : '40px', // Set a minimum height
            padding: '5px', // Add some padding
            overflow: 'hidden', // Prevent overflow
        }),
        multiValue: (provided) => ({
            ...provided,
            maxWidth: '100%', // Allow multi values to take full width
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            overflow: 'hidden',
            whiteSpace: 'nowrap', // Prevent text wrapping
            textOverflow: 'ellipsis', // Add ellipsis for overflow text
        }),
    });

    const handleRest = () => {
        reset({
            description: "",
            name: "",

        },
            setProfileImageUrl(''),
            setProfileIconUrl('')
        )

    }

    const submitForm = async (data) => {
        if (!profileImageUrl) {
            toast.error("Please upload your image");
            return
        }
        if (!profileIconUrl) {
            toast.error("Please upload your icon");
            return
        }
        const image = profileImageUrl;
        const icon = profileIconUrl;
        const position = {
            position: data?.positions?.map((position) => ({
                viewPosition: position?.viewPosition?.value,
                textPositions: position?.textPositions?.map((textPosition) => textPosition?.value),
            })),
        };
        const payload = {
            ...data,
            customizedPrinting: isNameNumber,
            image: image,
            minimumPrice: parseFloat(data?.minimumPrice),
            icon: icon,
            positions: position?.position
        }
        delete data?.image

        try {
            const response = await axios.post(productCategoryApi, payload)
            toast.success(response?.data?.msg)
            navigate('/admin/productCategory');
            handleRest()

        } catch (error) {
            toast.error(error?.response?.data?.msg || error?.response?.data?.msg?.message)
        }
    }
    const handleProfileUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setLoader(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileImageUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setLoader(false)
            }
        }
    };

    const handleIconUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setIcon(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setLoading(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileIconUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setLoading(false)
            }
        }
    };

    return (
        <>
            <Navbar />

            <div className='pt-5 pb-5' style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                <div className='d-flex fw-medium' style={{ color: '#E4510B' }} ><p style={{ cursor: 'pointer', color: '#333333' }} onClick={() => navigate('/admin/productCategory')}>Product Category {`>`}</p> Add Product Category</div>
                <h1 className='mb-5'>Add Product Category</h1>
                <div className='modal-page'>
                    <Form onSubmit={handleSubmit(submitForm)}>
                        <Row>
                            <Col lg={6} sm={12}>
                                <div className='mb-1 mt-1'>
                                    <Label className='modal-label'>Product Category Name <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name='name'
                                        id='name'
                                        control={control}
                                        defaultValue=""

                                        render={({ field }) => (<Input type="text" {...field} invalid={errors.name && true} placeholder="Enter Category Name"

                                        />)} />

                                    {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mb-1'>
                                    <Label className='modal-label'>Product Category Description <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name='description'
                                        id='description'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input type="text" {...field} invalid={errors.description && true} placeholder="Enter Your Description" />)} />
                                    {errors.description && <FormFeedback>{errors.description.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mb-1'>
                                    <Label className='modal-label'>Product Basic Price <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        type='number'
                                        name='minimumPrice'
                                        id='minimumPrice'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                invalid={errors.minimumPrice && true}
                                                placeholder="Enter Your Basic Price"
                                                onInput={(e) => {
                                                    let inputValue = e.target.value;
                                                    inputValue = inputValue.replace(/^0+/, '');
                                                    e.target.value = inputValue.replace(/[^0-9]/g, '');
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.minimumPrice && <FormFeedback>{errors.minimumPrice.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mb-1'>
                                    <Label className='modal-label'>
                                        HSN Code <span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Controller
                                        name='hsnCode'
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: 'HSN code is required',
                                            pattern: {
                                                value: /^[0-9]{6}$/, // Ensures exactly 6 digits
                                                message: 'HSN code must be exactly 6 digits',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                style={{ height: '44px' }}
                                                type="text" // Use text to control input length easily
                                                maxLength={6} // Restrict the user to typing 6 digits
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                                                }}
                                                invalid={!!errors.hsnCode}
                                                placeholder="Enter Your HSN code"
                                            />
                                        )}
                                    />
                                    {errors.hsnCode && (
                                        <FormFeedback>{errors.hsnCode.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mt-3'>
                                    <Label className='modal-label me-2 s'>
                                        Need Customization Name/Number?{'  '}
                                    </Label>
                                    <Input
                                        type="switch"
                                        checked={isNameNumber}
                                        onClick={() => {
                                            setIsNameNumber(!isNameNumber);
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col lg={12} sm={12}>
                                <div className="mt-3">
                                    <Label className="modal-label me-2">Custom Position</Label>
                                    {fieldTypesFields.map((field, index) => (
                                        <div key={field.id} className="mb-4">
                                            <Row>
                                                <Col lg={5} sm={12}>
                                                    <Label style={{ fontWeight: 500, color: "#262525", display: "block" }}> Position </Label>
                                                    <Controller
                                                        name={`positions[${index}].viewPosition`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                styles={{ width: '300px' }}
                                                                isSearchable={true}
                                                                id={`positions-${index}`}
                                                                // options={customSide}
                                                                options={getFilteredOptions(index)}
                                                                value={customSide.find(option => option.value === field.value)}
                                                                placeholder="Select the position"
                                                                onChange={(selectedOption) => {
                                                                    handlePositionChange(selectedOption, index);
                                                                    field.onChange(selectedOption);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.positions?.[index]?.viewPosition && (<FormFeedback> {errors.positions[index]?.viewPosition.message} </FormFeedback>)}
                                                </Col>
                                                <Col lg={6} sm={11}>
                                                    <Label style={{ fontWeight: 500, color: "#262525", display: "block" }}> TextOptions </Label>
                                                    <Controller
                                                        name={`positions[${index}].textPositions`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                isSearchable={true}
                                                                styles={customStyles(true)}
                                                                options={fieldOption}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                placeholder="Select the text positions"
                                                            />
                                                        )}
                                                    />
                                                    {errors.positions?.[index]?.textPositions && (<FormFeedback> {errors.positions[index]?.textPositions.message} </FormFeedback>)}
                                                </Col>
                                                <Col lg={1} sm={1}>
                                                    <div
                                                        className="ms-3 mt-5 d-flex align-items-center justify-content-center"
                                                        style={{ cursor: "pointer", color: "#AF1B1B" }}
                                                        onClick={() => {
                                                            removeFieldType(index);
                                                            setSelectedPositions((prev) => {
                                                                const newSelected = { ...prev };
                                                                delete newSelected[index]; return newSelected;
                                                            });
                                                        }}
                                                    >
                                                        <Trash2 />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    <div className="d-flex">
                                        <Button
                                            type="button"
                                            className="overview-btn"
                                            onClick={() => appendFieldType({ viewPosition: "", textPositions: [] })}
                                        >
                                            <PlusCircle /> Add Field
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Label className='modal-label'>Product Category Image <span style={{ color: 'red' }}>*</span></Label>
                                <div className="mb-2 mt-2 ">
                                    <Controller
                                        name='image'
                                        id='image'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div>
                                                <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                    <input style={{ display: 'none' }} id="image" type="file" {...field} invalid={errors.image && true} ref={inputRef} onChange={handleProfileUpload} />
                                                    {loader ?
                                                        <Spinner />
                                                        :
                                                        <Button
                                                            style={{ backgroundColor: '#E4510B', border: 'none' }}
                                                            color="primary"
                                                            onClick={() => {
                                                                inputRef?.current?.click();
                                                            }}
                                                        >
                                                            <Upload size={15} />
                                                            <span className='ms-1'>Upload Image</span>
                                                        </Button>

                                                    }

                                                    {errors.image && <FormFeedback>{errors.image.message}</FormFeedback>}
                                                </div>
                                                {profileImageUrl && (
                                                    <div className="mt-2 d-flex">
                                                        {image && (
                                                            <>
                                                                <img
                                                                    alt="preview"
                                                                    src={profileImageUrl}
                                                                    style={{ width: '200px', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
                                                                />
                                                                <div className="image-close ms-1" style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={() => setImage(null)}>
                                                                    <X />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>

                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Label className='modal-label'>Product Category Icon <span style={{ color: 'red' }}>*</span></Label>
                                <div className="mb-5 mt-2 ">
                                    <Controller
                                        name='icon'
                                        id='icon'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div>
                                                <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                    <input style={{ display: 'none' }} id="icon" type="file" {...field} invalid={errors.icon && true} ref={inputRef1} onChange={handleIconUpload} />
                                                    {loading ?
                                                        <Spinner />
                                                        :
                                                        <Button
                                                            style={{ backgroundColor: '#E4510B', border: 'none' }}
                                                            color="primary"
                                                            onClick={() => {
                                                                inputRef1?.current?.click();
                                                            }}
                                                        >
                                                            <Upload size={15} />
                                                            <span className='ms-1'>Upload icon</span>
                                                        </Button>

                                                    }

                                                    {errors.icon && <FormFeedback>{errors.icon.message}</FormFeedback>}
                                                </div>
                                                {profileIconUrl && (
                                                    <div className="mt-2 d-flex">
                                                        {icon && (
                                                            <>
                                                                <img
                                                                    alt="preview icon"
                                                                    src={profileIconUrl}
                                                                    style={{ width: '200px', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
                                                                />
                                                                <div className="image-close ms-1" style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={() => setIcon(null)}>
                                                                    <X />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>

                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Title</Label>
                                    <Controller
                                        name='metaTitle'
                                        id='metaTitle'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="text" {...field} invalid={errors.metaTitle && true} placeholder="Enter your Meta Title" />)} />
                                    {errors.metaTitle && <FormFeedback>{errors.metaTitle.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Description</Label>
                                    <Controller
                                        name='metaDescription'
                                        id='metaDescription'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '70px' }} type="textarea" {...field} invalid={errors.metaDescription && true} placeholder="Enter your Meta Descritpion" />)} />
                                    {errors.metaDescription && <FormFeedback>{errors.metaDescription.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div className="">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Keywords</Label>
                                    <div className=" w-100">
                                        <Controller
                                            name='metaKeywords'
                                            id='metaKeywords'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (<Input style={{ height: '70px' }} type="textarea" {...field} invalid={errors.metaKeywords && true} placeholder="Enter your Meta Keywords" />)} />
                                        {errors.metaKeywords && <FormFeedback>{errors.metaKeywords.message}</FormFeedback>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className='d-flex fixed-button justify-content-end mt-2'>
                            <Button
                                className='overview-btn' type='submit'>Create Category</Button>
                        </div>
                    </Form >
                </div >
            </div>
        </>
    )
}

export default ProductCategoryAdd

import React from 'react'
import Navbar from '../../navbar'
import FooterSection from '..'
// import './index.css';
import { useNavigate } from 'react-router-dom';

function RefundPolicy() {
    const navigate = useNavigate()


    const navToHome = () => {
        navigate('/')
    }
    // const navToPrivacyPolicy = () => {
    //     navigate('/returnPolicy')
    // }


    return (
        <div>
            <Navbar />
            <div className='pt-3' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%' }}>
                <div className='d-flex fw-medium mt-4' style={{ color: '#E4510B' }} ><p style={{ cursor: 'pointer', color: '#333333' }} onClick={navToHome}>Home  {`>`}</p>Refund Policy</div>
                <div>
                    <div style={{ width: '100%' }} className='d-flex align-items-center mt-2'>
                        <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Refund Policy</h1>
                        <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                    </div>
                </div>
                <p class="privacy-para">
                    At PrintOn, we value customer satisfaction and take pride in delivering high-quality printed products. Due to the customized nature of our services, refunds are handled on a case-by-case basis. Please review our policy below to understand the conditions for refunds.</p>
                <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Eligibility for Refunds</h1>
                <div className='ms-3'>
                    <p className='privacy-para  mt-4'>
                        Refunds are only applicable in the following scenarios:</p>
                    <p class="bullet-point">
                        Defective Printing: If the product has errors caused by us, such as incorrect printing or poor quality that does not match the approved proof.
                    </p>
                    <p class="bullet-point">
                        Damaged During Transit: If the product is damaged during delivery, you must report it within [24-48 hours] of receiving the package with photographic evidence.
                    </p>
                    <p class="bullet-point">
                        Incorrect Order: If the delivered product does not match the specifications or design approved at the time of order confirmation.
                    </p>
                </div>

                <h1 className='product-overall-title' style={{ font: 'Manrope', }}> Non-Refundable Situations</h1>
                <div className='ms-3'>
                    <p class="bullet-point">
                        Custom products with errors originating from incorrect information or designs provided by the customer..
                    </p>
                    <p class="bullet-point">
                        Minor variations in color, size, or alignment that are within standard industry tolerances.
                    </p>
                    <p class="bullet-point">
                        Orders where the design proof was approved by the customer, even if there were errors in the proof.
                    </p>
                </div>

                <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Refund Processm</h1>
                <div className='ms-3'>
                    <p class="bullet-point">
                        Submit a Request: Contact us at [support@printon.co.in] within [2-3 days] of receiving your order. Provide your order ID, a detailed description of the issue, and supporting photos if applicable.
                    </p>
                    <p class="bullet-point">
                        Review: Our team will review your claim within [3-5 business days] to determine eligibility.
                    </p>
                    <p class="bullet-point">
                        Resolution: If your claim is approved, you will receive one of the following:
                    </p>
                    <p class="bullet-point">
                        A free reprint of the order.
                    </p>
                    <div className='ms-3'>
                        <p class="bullet-point">
                            A full or partial refund credited to your original payment method.
                        </p>
                        <p class="bullet-point">
                            A free reprint of your order.
                        </p>
                    </div>
                    {/* <p class="bullet-point">
                            A partial or full refund, depending on the nature of the issue.
                        </p> */}
                </div>

                <h1 className='product-overall-title' style={{ font: 'Manrope', }}> Important Notes</h1>
                <div className='ms-3'>
                    <p class="bullet-point">
                        Cancellations: Refunds for cancellations are only applicable if the order is canceled before printing begins.
                    </p>
                    <h1 className='product-overall-title' style={{ font: 'Manrope', }}> Shipping Costs</h1>
                    <p class="bullet-point">
                        Processing Time: Approved refunds will be processed within [7-10 business days]. The time it takes for the refund to appear in your account may vary depending on your bank or payment provider.
                    </p>
                    {/* <p class="bullet-point">
                            Returns or claims not meeting the criteria above will not be eligible for refunds, and shipping costs will not be refunded.
                        </p> */}
                </div>
                <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Contact Us</h1>
                <div className='ms-3'>
                    <p class="bullet-point">
                        If you have any questions or need assistance, please reach out to us at support@printon.co.in or whatsapp +919886066994.
                    </p>
                    {/* <h1 className='product-overall-title' style={{ font: 'Manrope', }}> Shipping Costs</h1>
                        <p class="bullet-point">
                            Processing Time: Approved refunds will be processed within [7-10 business days]. The time it takes for the refund to appear in your account may vary depending on your bank or payment provider.
                        </p> */}
                    {/* <p class="bullet-point">
                            Returns or claims not meeting the criteria above will not be eligible for refunds, and shipping costs will not be refunded.
                        </p> */}
                </div>
            </div>





            <FooterSection />
        </div >
    )
}

export default RefundPolicy
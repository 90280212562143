import React, { useCallback, useEffect, useRef, useState } from 'react'
import './index.css'
import { fabric } from 'fabric';
import CustomizationHeader from './components/CustomizationHeader'
import CustomizationSidebar from './components/CustomizationSidebar'
import ProductTool from './components/tools/ProductTool'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { addCart, customizeDesign, customizeImage, fileUpload, product } from '../../ApiConfigs/ApiConfig'
import ClipartTool from './components/tools/ClipartTool'
import AddTextTool from './components/tools/AddTextTool'
import UploadTool from './components/tools/UploadTool'
import NameNumberTool from './components/tools/NameNumberTool'
// import front from '../../assets/images/front.jpeg'
// import back from '../../assets/images/back.jpeg'
import toast from 'react-hot-toast';
import { ArrowRight, CornerDownLeft, CornerUpRight } from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from 'reactstrap';
import NameNumberModal from '../productView/productCustomize/NameNumberModal';
import Aos from 'aos';
import QuantitySizeStep from './components/steps/QuantitySizeStep';
import FinalReviewStep from './components/steps/FinalReviewStep';
import { LuTextCursorInput } from 'react-icons/lu';
import { RiImageAddLine, RiUploadCloud2Line } from 'react-icons/ri';
import { FaRegCommentDots } from 'react-icons/fa';
import ruller from "../../assets/images/banner/ruller.png"
import backruller from "../../assets/images/banner/ruller backside.png"

// Custom controls for delete and edit
fabric.Object.prototype.controls.deleteControl = new fabric.Control({
    x: -0.5,
    y: -0.5,
    offsetY: -20,
    cursorStyle: 'pointer',
    mouseUpHandler: (eventData, transform) => {
        const target = transform.target;
        const canvas = target.canvas;

        // Remove the object
        canvas.remove(target);
        canvas.requestRenderAll();
    },
    render: (ctx, left, top, styleOverride, fabricObject) => {
        const deleteIcon = new Image();
        deleteIcon.src = 'https://img.icons8.com/ios-filled/50/fa314a/trash.png'; // Replace with your delete icon URL
        deleteIcon.onload = () => {
            ctx.drawImage(deleteIcon, left - 10, top - 10, 20, 20);
        };
    },
});

// fabric.Object.prototype.controls.editControl = new fabric.Control({
//     x: -0.5,
//     y: -0.5,
//     offsetX: 20,
//     offsetY: -20,
//     cursorStyle: 'pointer',
//     mouseUpHandler: (eventData, transform) => {
//         const target = transform.target;

//         // Add your edit functionality here
//         alert('Edit functionality triggered for object: ' + target.type);
//     },
//     render: (ctx, left, top, styleOverride, fabricObject) => {
//         const editIcon = new Image();
//         editIcon.src = 'https://img.icons8.com/ios-filled/50/000000/edit.png'; // Replace with your edit icon URL
//         editIcon.onload = () => {
//             ctx.drawImage(editIcon, left - 10, top - 10, 20, 20);
//         };
//     },
// });

// Enable custom controls globally
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.cornerColor = 'blue';

function CustomizationNew() {
    const { id } = useParams()
    // Sidebar tab active state
    const [activeToolsTab, setActiveToolsTab] = useState('product')
    // Header tab active state
    const [activeStepsTab, setActiveStepsTab] = useState('one')
    // Product Details State
    const [productDetails, setProductDetails] = useState()
    const [isLoading, setIsLoading] = useState(false)
    // Product Color
    const [selectedColorIndex, setSelectedColorIndex] = useState(0);
    // Product Field Type and Option
    const [selectedOptions, setSelectedOptions] = useState({});
    const [clipArtSearch, setClipArtSearch] = useState('')
    const [clipArtData, setClipArtData] = useState()
    const [librarySearch, setLibrarySearch] = useState('')
    const [libraryData, setLibraryData] = useState()
    const canvasRef = useRef(null);
    const [selectedObject, setSelectedObject] = useState(null); // Track the selected object
    const [formValues, setFormValues] = useState({
        text: '',
        fontSize: 60,
        fontWeight: 'normal',
        fontFamily: "Arial, sans-serif",
        fill: '#000000',
        charSpacing: 0,
        angle: 0,
        stroke: '#000000',
        strokeWidth: 0,
    });
    const [activeSide, setActiveSide] = useState('front'); // Active side (front/back/sleeves)
    const [tshirtBackgrounds, setTshirtBackgrounds] = useState({});
    const [canvasStates, setCanvasStates] = useState({});

    const [isModifiedFront, setIsModifiedFront] = useState(false)
    const [isModifiedBack, setIsModifiedBack] = useState(false)
    const [isCanvasActive, setIsCanvasActive] = useState(false)
    const undoStack = useRef([]);
    const redoStack = useRef([]);
    const isUndoRedoAction = useRef(false);
    const [undoLength, setUndoLength] = useState(1);
    const [redoLength, setRedoLength] = useState(0);
    const [images, setImages] = useState([]);
    const [allUsedImages, setAllUsedImages] = useState([]);

    const [nameNumberValues, setNameNumberValues] = useState({
        fontFamily: 'Interstate',
        fill: '#000000', // Default font color
        fontSize: 'Large', // Default font size: 'Small' or 'Large'
    });

    const [isNameAdded, setIsNameAdded] = useState(false);
    const [isNumberAdded, setIsNumberAdded] = useState(false);
    const [isNameNumberTool, setIsNameNumberTool] = useState(false)

    // const defaultPositions = {
    //     name: { left: 180, top: 100 }, // Default position for Name
    //     number: { left: 100, top: 150 }, // Default position for Number
    // };
    const [modalName, setModalName] = useState(false);
    const [nextbtnError, setNextbtnError] = useState('');
    const [quantities, setQuantities] = useState({});
    const [sizeQuantities, setSizeQuantities] = useState([]);
    const [sizeQuantities1, setSizeQuantities1] = useState([]);
    const [modalMode, setModalMode] = useState(null);
    const toggleName = () => {
        setModalName(!modalName)
    }
    const [selectedDetails, setSelectedDetails] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState()
    const [manualQuantity, setManualQuantity] = useState()

    useEffect(() => {
        const totalQuantityValue = Object.values(quantities).reduce((acc, curr) => acc + curr, 0);
        setTotalQuantity(totalQuantityValue)
        const manualQuantityValue = sizeQuantities1.reduce((acc, item) => acc + item.quantity, 0);
        setManualQuantity(manualQuantityValue)
    }, [quantities, sizeQuantities1])

    const handleManualQuantityChange = (size, value) => {
        const quantity = Number(value) || 0; // Convert to number or default to 0

        // Update the sizeQuantities array
        setSizeQuantities1(prevSizeQuantities => {
            const existingEntry = prevSizeQuantities.find(entry => entry.size === size);
            if (existingEntry) {
                // Update existing entry
                return prevSizeQuantities.map(entry =>
                    entry.size === size ? { ...entry, quantity } : entry
                );
            } else {
                // If not found, add new entry
                return [...prevSizeQuantities, { size, quantity }];
            }
        });
    };

    useEffect(() => {
        const calculateSizeQuantities = (sizeQuantities) => {
            const sizeCounts = {};

            sizeQuantities.forEach(({ size }) => {
                sizeCounts[size] = (sizeCounts[size] || 0) + 1;
            });

            return sizeCounts;
        };
        const calculatedQuantities = calculateSizeQuantities(sizeQuantities);
        setQuantities(calculatedQuantities);
        if (sizeQuantities.length > 0) {
            setNextbtnError('')
        }
    }, [sizeQuantities]);



    useEffect(() => {
        if (!isNameAdded && !isNumberAdded) {
            setQuantities({});
            setSizeQuantities([]);
            setSizeQuantities1([]);
            setNextbtnError('')
        }
    }, [isNameAdded, isNumberAdded])

    const handleNextStepFun = () => {
        setIsModifiedFront(true)
        setIsModifiedBack(true)
        const canvas = canvasRef.current;
        const jsonState = canvas.toJSON();
        setCanvasStates((prevStates) => ({
            ...prevStates,
            [activeSide]: jsonState, // Save the state of the current side
        }));

        if (isNameAdded || isNumberAdded) {
            if (sizeQuantities.length > 0) {
                handleActiveStepsTab('two')
            } else {
                setActiveToolsTab('namesAndNumbers')
                setNextbtnError('Please enter Names/Numbers list')
            }
        }
        if (!isNameAdded && !isNumberAdded) {
            handleActiveStepsTab('two')
        }
    }

    // Function to update the new state based on selectedOptions
    useEffect(() => {
        const updatedDetails = productDetails?.fieldType?.map((field) => {
            const selectedOptionId = selectedOptions[field.name._id]; // Get the selected option ID for the field
            const selectedOption = field.options.find((option) => option._id === selectedOptionId); // Find the matching option

            return {
                fieldName: field.name.name, // Field name
                selectedOption: selectedOption ? selectedOption.name : null, // Option name if found
            };
        });

        setSelectedDetails(updatedDetails);
    }, [selectedOptions, productDetails]);


    // STATE UPDATES
    const handleActiveToolsTab = (tab) => {
        setActiveToolsTab(tab)
        setActiveStepsTab('one')
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.discardActiveObject(); // Deselect all objects
            canvas.renderAll(); // Re-render the canvas to reflect changes
        }
    }
    const handleActiveStepsTab = (tab) => {
        setActiveStepsTab(tab)
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.discardActiveObject(); // Deselect all objects
            canvas.renderAll(); // Re-render the canvas to reflect changes
        }
    }
    const handleColorSelect = (index) => {
        setSelectedColorIndex(index)
    }
    const handleOptionChange = async (fieldType, selectedOption) => {
        setSelectedOptions(prevState => {
            const updatedOptions = {
                ...prevState,
                [fieldType]: selectedOption
            };
            // Convert updatedOptions to an array with fieldType included
            const optionsArray = Object.entries(updatedOptions).map(([fieldType, option]) => ({
                fieldType,
                option
            }));

            // Store as an array in localStorage
            localStorage.setItem('selectedOptions', JSON.stringify(optionsArray));
            return updatedOptions;
        });
    };

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    // STARTS - MANAGE ALL USEEFFECT 

    // GET - Product Details
    useEffect(() => {
        const productData = async () => {
            setIsLoading(true)
            try {
                const response = await axios.get(`${product}/${id}`);
                setProductDetails(response?.data?.result);

                // if (productData?.customizeImages && productData?.customizeImages?.length > 0) {
                //   setMainImage(productData.customizeImages[0]?.images[0]?.image);
                //   setSelectedImageIndex(0);
                // }
                // Transform the API response to create `tshirtBackgrounds` and initial `canvasStates`
                const backgrounds = {};
                const initialStates = {};

                response?.data?.result?.customizeImages?.[0]?.images.forEach((image) => {
                    const sideName = image.name.name.toLowerCase().replace(/\s/g, ''); // e.g., "Front" -> "front"
                    backgrounds[sideName] = image.image;
                    initialStates[sideName] = null; // Initialize canvas state for this side
                });

                setTshirtBackgrounds(backgrounds);
                setCanvasStates(initialStates);

                // Set default side (e.g., "front" if it exists)
                if (backgrounds.front) {
                    setActiveSide('front');
                }
                setIsLoading(false)
            } catch (error) {
                console.error('Error fetching product data:', error);
            } finally {
                setIsLoading(false)
            }
        };
        productData();
    }, [id]);



    // GET field options from local and manage the initial options value
    useEffect(() => {
        // Retrieve and parse options from localStorage
        const storedOptions = localStorage.getItem('selectedOptions');
        let parsedOptions = [];
        try {
            parsedOptions = storedOptions ? JSON.parse(storedOptions) : [];
        } catch (error) {
            console.error('Error parsing selectedOptions from localStorage', error);
        }
        // If parsedOptions contains data, set it to selectedOptions state
        if (parsedOptions.length > 0) {
            const optionsObject = parsedOptions.reduce((acc, { fieldType, option }) => {
                acc[fieldType] = option;
                return acc;
            }, {});
            setSelectedOptions(optionsObject);
        } else {
            // Otherwise, set the first option from each fieldType, if available
            if (productDetails?.fieldType) {
                const initialOptions = productDetails?.fieldType?.reduce((acc, eachItem) => {
                    const fieldType = eachItem?.name?._id;
                    const firstOption = eachItem?.options[0]?._id;
                    if (fieldType && firstOption) {
                        acc[fieldType] = firstOption;
                    }
                    return acc;
                }, {});

                setSelectedOptions(initialOptions || {});

                // Store initial options in localStorage
                const optionsArray = Object.entries(initialOptions || {}).map(([fieldType, option]) => ({
                    fieldType,
                    option,
                }));
                localStorage.setItem('selectedOptions', JSON.stringify(optionsArray));
                localStorage.setItem('quantity', JSON.stringify(productDetails?.quantityBasedAmount[0]?.to));
                if (productDetails?.customizeImageSize?.length > 0) {
                    localStorage.setItem('customizeImageSize', JSON.stringify(productDetails?.customizeImageSize[0]));
                }
            }
        }
    }, [productDetails]);

    useEffect(() => {
        const backgrounds = {};
        const initialStates = {};
        if (productDetails) {
            productDetails?.customizeImages?.[selectedColorIndex]?.images.forEach((image) => {
                const sideName = image.name.name.toLowerCase().replace(/\s/g, ''); // e.g., "Front" -> "front"
                backgrounds[sideName] = image.image;
                initialStates[sideName] = null; // Initialize canvas state for this side
            });
            setTshirtBackgrounds(backgrounds);
            setCanvasStates(initialStates);
        }
    }, [selectedColorIndex])

    // GET Selected Color from Local
    useEffect(() => {
        const selectedColorIndex = localStorage.getItem("selectedColorIndex");

        // Parse it as a number or default to 0 if it doesn't exist
        const colorIndex = selectedColorIndex !== null ? parseInt(selectedColorIndex, 10) : 0;

        // Check if customizeImages exist and then apply the selected color index
        if (productDetails?.customizeImages && productDetails?.customizeImages.length > 0) {
            handleColorSelect(colorIndex);
        }
    }, [productDetails]);

    const getClipArtData = useCallback(async () => {
        try {
            const response = await axios.get(`${customizeDesign}?search=${clipArtSearch}`);
            setClipArtData(response?.data?.result);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    }, [clipArtSearch]);  // Dependency on clipArtSearch

    const getLibraryData = useCallback(async () => {
        try {
            const response = await axios.get(`${customizeImage}?search=${librarySearch}`);
            setLibraryData(response?.data?.result);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    }, [librarySearch]);  // Dependency on librarySearch

    useEffect(() => {
        getClipArtData();
        getLibraryData();
    }, [getClipArtData, getLibraryData]);

    const resetForm = () => {
        setFormValues({
            text: '',
            fontSize: 60,
            fontWeight: 'normal',
            fontFamily: 'Arial, sans-serif',
            fill: '#000000',
            charSpacing: 0,
            angle: 0,
            stroke: '#000000',
            strokeWidth: 0,
        });
    };


    useEffect(() => {
        // Ensure the DOM is ready and canvas is available
        const canvasElement = document.getElementById('canvas');
        if (!canvasElement) {
            console.error('Canvas element not found!');
            return;
        }

        // Initialize Fabric.js canvas
        const canvas = new fabric.Canvas('canvas', {
            width: 512,
            height: 579,
            backgroundColor: '#fff',
        });
        canvasRef.current = canvas;

        // Function to load and set the background image
        const loadBackgroundImage = (imageUrl) => {
            if (!imageUrl) {
                console.error('Background image URL is not defined!');
                return;
            }

            const cacheBustedUrl = `${imageUrl}?t=${new Date().getTime()}`;
            fabric.Image.fromURL(
                cacheBustedUrl,
                (img) => {
                    if (canvasRef.current) {
                        canvas.setBackgroundImage(
                            img,
                            () => {
                                canvas.renderAll();
                            },
                            {
                                scaleX: canvas.width / img.width,
                                scaleY: canvas.height / img.height,
                            }
                        );
                    } else {
                        console.warn('Canvas instance disposed before background image was set.');
                    }
                },
                { crossOrigin: 'Anonymous' } // Handle cross-origin images
            );
        };

        // Load the initial background image (front side)
        loadBackgroundImage(tshirtBackgrounds.front);

        // Function to update state on canvas changes
        const updateStateOnCanvasChange = () => {
            if (canvasRef.current) {
                // Get all image objects from the canvas
                const currentImages = canvas.getObjects()
                    .filter((obj) => obj.type === 'image')
                    .map((img) => ({
                        id: img.id, // Ensure the image has a unique ID
                        url: img.getSrc(),
                    }));

                // Update the `images` state to keep it in sync
                setImages(currentImages);

                // Update undo and redo stack lengths for UI (if applicable)
                setUndoLength(undoStack.current.length);
                setRedoLength(redoStack.current.length);
            }
        };

        // Function to save the current state for undo/redo
        const saveState = () => {
            if (!isUndoRedoAction.current && canvasRef.current) {
                const currentState = {
                    canvasJSON: canvasRef.current.toJSON(),
                    colorIndex: selectedColorIndex, // Save current color index
                    activeSide: activeSide, // Save the current active side for redo
                };
                undoStack.current.push(currentState);
                redoStack.current = []; // Clear redo stack after a new action

                updateStateOnCanvasChange(); // Sync images and other state
            }
        };

        // Update form values on selection created or updated
        const updateFormValues = (selectedObject) => {
            if (selectedObject?.type === 'textbox') {
                // Skip updating form values if the text is "NAME" or "00"
                if (selectedObject.text === "NAME" || selectedObject.text === "00") {
                    setActiveToolsTab('namesAndNumbers')
                    return;
                }

                const newFormValues = {
                    text: selectedObject.text || '',
                    fontSize: selectedObject.fontSize || 60,
                    fontWeight: selectedObject.fontWeight || 'normal',
                    fontFamily: selectedObject.fontFamily || 'Arial, sans-serif',
                    fill: selectedObject.fill || '#000000',
                    charSpacing: selectedObject.charSpacing || 0,
                    angle: selectedObject.angle || 0,
                    stroke: selectedObject.stroke || '#000000',
                    strokeWidth: selectedObject.strokeWidth || 0,
                };
                setFormValues(newFormValues);
                setSelectedObject(selectedObject); // Update selected object state
                setActiveToolsTab('addText');
            }
        };


        const updateUploadTool = (selectedObject) => {
            if (selectedObject?.type === 'image') {
                setSelectedObject(selectedObject); // Update selected object state
                setActiveToolsTab('upload');
            }
        }

        // Handle selection created
        canvas.on('selection:created', (e) => {
            setIsCanvasActive(true)
            setActiveStepsTab('one')
            const selected = e.selected[0];
            updateFormValues(selected);
            updateUploadTool(selected);
        });

        // Handle selection updated
        canvas.on('selection:updated', (e) => {
            setIsCanvasActive(true)
            setActiveStepsTab('one')
            const selected = e.selected[0];
            updateFormValues(selected);
            updateUploadTool(selected);
        });

        // Handle object deselection
        canvas.on('selection:cleared', () => {
            setSelectedObject(null);
            resetForm();
            setIsCanvasActive(false)
        });

        // Handle canvas click
        const handleCanvasClick = () => {
            setActiveStepsTab('one');
        };

        // Listen for `mouse:down` event on the canvas
        canvas.on('mouse:down', handleCanvasClick);

        // Listen to various events on the canvas to track changes
        canvas.on('object:added', saveState);
        canvas.on('object:modified', saveState);
        canvas.on('object:removed', saveState);

        // Cleanup on component unmount
        return () => {
            if (canvasRef.current) {
                canvas.dispose();
                canvasRef.current = null; // Clear the reference
            }
        };
    }, [tshirtBackgrounds.front]);

    // ENDS - MANAGE ALL USEEFFECT 



    // Function to add text to the canvas
    const addOrUpdateText = (text, styles) => {
        if (activeSide === 'front') {
            setIsModifiedFront(true)
        }
        if (activeSide === 'back') {
            setIsModifiedBack(true)
        }
        if (selectedObject) {
            // Update the selected text
            selectedObject.set({
                text,
                ...styles,
            });
            selectedObject.set({
                width: selectedObject.calcTextWidth() + 20, // Adjust the width dynamically
                textAlign: 'center', // Align text in the middle of the textbox
            });

            // Center the text horizontally
            selectedObject.set({
                left: (canvasRef.current.width - selectedObject.width) / 2,
            });

            canvasRef.current.renderAll(); // Re-render canvas
        } else {
            // Add new text
            const {
                fontSize,
                fontWeight,
                fontFamily,
                fill,
                charSpacing,
                angle,
                stroke,
                strokeWidth,
            } = styles;

            const newText = new fabric.Textbox(text, {
                fontSize,
                fontWeight,
                fontFamily,
                fill,
                charSpacing,
                angle,
                stroke,
                strokeWidth,
                textAlign: 'center', // Center the text within the textbox
            });

            // Dynamically adjust the textbox width
            newText.set({
                width: newText.calcTextWidth() + 20,
            });

            // Center the textbox horizontally
            newText.set({
                left: (canvasRef.current.width - newText.width) / 2, // Horizontally center the text
                top: 115,
            });

            canvasRef.current.add(newText);
            canvasRef.current.setActiveObject(newText);
        }
    };


    const updateActiveObject = (key, value) => {
        const canvas = canvasRef.current; // Reference to the Fabric.js canvas
        const activeObject = canvas.getActiveObject();

        if (activeObject && activeObject.type === 'textbox') {
            // Update the corresponding property of the active object
            activeObject.set(key, value);
            canvas.renderAll(); // Re-render the canvas to reflect changes
        }
    };



    const addImageToCanvas = (imageFile) => {
        const canvas = canvasRef.current;
        const reader = new FileReader();

        reader.onload = () => {
            fabric.Image.fromURL(reader.result, (img) => {
                img.set({
                    left: 100,
                    top: 100,
                    scaleX: 0.5,
                    scaleY: 0.5,
                });
                canvas.add(img);
                canvas.renderAll();
            });
        };

        reader.readAsDataURL(imageFile); // Read file and trigger `onload`
    };

    const addImageUrlToCanvas = (imgUrl) => {
        if (!canvasRef.current) {
            console.error('Canvas is not initialized!');
            return;
        }

        const cacheBustedUrl = `${imgUrl}?t=${new Date().getTime()}`;
        // Load the image from the URL and add it to the canvas
        fabric.Image.fromURL(
            cacheBustedUrl,
            (img) => {
                // Scale the image to fit within the canvas, or customize as needed
                const scaleX = canvasRef.current.width / img.width / 2;
                const scaleY = canvasRef.current.height / img.height / 2;
                img.set({
                    left: 50, // Set initial x position
                    top: 50,  // Set initial y position
                    scaleX: scaleX < 1 ? scaleX : 1, // Prevent scaling beyond original size
                    scaleY: scaleY < 1 ? scaleY : 1,
                });

                // Add the image to the canvas
                canvasRef.current.add(img);

                // Render the canvas to display the image
                canvasRef.current.renderAll();
            },
            { crossOrigin: 'Anonymous' } // Handle cross-origin images
        );
    };

    // Callback for handling multiple file uploads
    const handleFileUpload = (files) => {
        Array.from(files).forEach((file) => {
            if (file.type.startsWith('image/')) {
                addImageToCanvas(file); // Add each image to the canvas
            } else {
                console.error('Invalid file type:', file.type);
            }
        });
    };

    const deleteActiveObject = () => {
        const canvas = canvasRef.current; // Assuming your canvas reference is stored here

        // Get the active object
        const activeObject = canvas.getActiveObject();

        if (activeObject) {
            // Remove the active object from the canvas
            canvas.remove(activeObject);
            canvas.discardActiveObject(); // Clear the active object after removal
            canvas.renderAll(); // Re-render the canvas to reflect the changes
        } else {
        }
    };

    // Function to unselect all objects on the canvas
    const unselectAllObjects = () => {
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.discardActiveObject(); // Deselect all objects
            canvas.renderAll(); // Re-render the canvas to reflect changes
        }
    };
    const downloadCanvas = () => {
        const dataURL = canvasRef.current.toDataURL({
            format: 'png',
            quality: 1,
        });
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'design.png';
        link.click();
    };

    const handleSideSwitch = (side) => {
        // Save current canvas state
        const canvas = canvasRef.current;
        const jsonState = canvas.toJSON();
        setCanvasStates((prevStates) => ({
            ...prevStates,
            [activeSide]: jsonState, // Save the state of the current side
        }));

        // Load the new side
        canvas.clear();
        setActiveSide(side);



        // Set the background image for the new side
        const backgroundImageUrl = tshirtBackgrounds[side];
        if (backgroundImageUrl) {
            const cacheBustedUrl = `${backgroundImageUrl}?t=${new Date().getTime()}`;
            fabric.Image.fromURL(
                cacheBustedUrl,
                (img) => {
                    canvas.setBackgroundImage(
                        img,
                        canvas.renderAll.bind(canvas),
                        {
                            scaleX: canvas.width / img.width,
                            scaleY: canvas.height / img.height,
                        }
                    );
                },
                { crossOrigin: 'Anonymous' }
            );
        }
        // Load saved state for the new side
        if (canvasStates[side]) {
            canvas.loadFromJSON(canvasStates[side], () => {
                canvas.renderAll();
            });
        }
    };


    // Handle Undo
    const handleUndo = () => {
        if (undoStack.current.length > 1) {
            // Pop the current state and push it into the redo stack
            const currentState = undoStack.current.pop();
            redoStack.current.push({
                canvasJSON: currentState.canvasJSON,
                colorIndex: selectedColorIndex,
                activeSide: activeSide, // Save the current active side for redo
            });

            // Get the previous state
            const previousState = undoStack.current[undoStack.current.length - 1];
            isUndoRedoAction.current = true;

            // Restore the canvas and update active side
            canvasRef.current.loadFromJSON(previousState.canvasJSON, () => {
                // Restore object custom properties (like `id`)
                canvasRef.current.getObjects().forEach((obj) => {
                    if (!obj.id) {
                        obj.id = uuidv4();
                    }
                });

                // Update selected color index and active side
                setSelectedColorIndex(previousState.colorIndex);
                setActiveSide(previousState.activeSide); // Update the tab state

                canvasRef.current.renderAll();
                isUndoRedoAction.current = false;

                // Update stack lengths
                setUndoLength(undoStack.current.length);
                setRedoLength(redoStack.current.length);

                // Sync images state
                const currentImages = canvasRef.current
                    .getObjects()
                    .filter((obj) => obj.type === 'image')
                    .map((img) => ({
                        id: img.id,
                        url: img.getSrc(),
                    }));

                setImages(currentImages);
            });
        } else {
            toast.error('No more states to undo.');
        }
    };

    // Handle Redo
    const handleRedo = () => {
        if (redoStack.current.length > 0) {
            // Pop the last redo state and push the current state into the undo stack
            const lastRedoState = redoStack.current.pop();
            undoStack.current.push({
                canvasJSON: canvasRef.current.toJSON(),
                colorIndex: selectedColorIndex,
                activeSide: activeSide, // Save the current active side for undo
            });

            isUndoRedoAction.current = true;

            // Restore the canvas and update active side
            canvasRef.current.loadFromJSON(lastRedoState.canvasJSON, () => {
                // Restore object custom properties (like `id`)
                canvasRef.current.getObjects().forEach((obj) => {
                    if (!obj.id) {
                        obj.id = uuidv4();
                    }
                });

                // Update selected color index and active side
                setSelectedColorIndex(lastRedoState.colorIndex);
                setActiveSide(lastRedoState.activeSide); // Update the tab state

                canvasRef.current.renderAll();
                isUndoRedoAction.current = false;

                // Update stack lengths
                setUndoLength(undoStack.current.length);
                setRedoLength(redoStack.current.length);

                // Sync images state
                const currentImages = canvasRef.current
                    .getObjects()
                    .filter((obj) => obj.type === 'image')
                    .map((img) => ({
                        id: img.id,
                        url: img.getSrc(),
                    }));

                setImages(currentImages);
            });
        } else {
            toast.error('No more states to redo.');
        }
    };



    const resetCanvas = () => {
        const confirmReset = window.confirm('Are you sure you want to start over? This will erase all changes.');
        if (!confirmReset) return;

        if (canvasRef.current) {
            // Clear the canvas
            canvasRef.current.clear();

            // Reset the undo and redo stacks
            undoStack.current = [];
            redoStack.current = [];
            setUndoLength(1); // Reset undo length
            setRedoLength(0); // Reset redo length
            setImages([])
            resetForm()

            // Reapply the background image (if applicable)
            if (tshirtBackgrounds.front) {
                fabric.Image.fromURL(tshirtBackgrounds.front, (img) => {
                    canvasRef.current.setBackgroundImage(
                        img,
                        canvasRef.current.renderAll.bind(canvasRef.current),
                        {
                            scaleX: canvasRef.current.width / img.width,
                            scaleY: canvasRef.current.height / img.height,
                        }
                    );
                    // Save the initial state after resetting
                    undoStack.current.push(canvasRef.current.toJSON());
                    setUndoLength(undoStack.current.length);
                });
            } else {
                console.error('T-shirt background image URL is not defined!');
            }
        }
    };

    const onImageAdd = (url) => {
        if (!canvasRef.current) return;

        if (activeSide === 'front') {
            setIsModifiedFront(true)
        }
        if (activeSide === 'back') {
            setIsModifiedBack(true)
        }

        const cacheBustedUrl = `${url}?t=${new Date().getTime()}`;
        fabric.Image.fromURL(
            cacheBustedUrl,
            (img) => {
                const uniqueId = uuidv4(); // Assign a unique ID to the image
                img.id = uniqueId;

                // Set image scaling and position
                const canvasWidth = canvasRef.current.width;
                const canvasHeight = canvasRef.current.height;

                // Ensure the image fits within the canvas and scales proportionally
                const maxWidth = canvasWidth * 0.4; // 40% of canvas width
                const maxHeight = canvasHeight * 0.4; // 40% of canvas height

                if (img.width > maxWidth || img.height > maxHeight) {
                    const scaleFactor = Math.min(
                        maxWidth / img.width,
                        maxHeight / img.height
                    );
                    img.scale(scaleFactor); // Scale the image proportionally
                }

                // Center the image horizontally
                img.set({
                    left: (canvasWidth - img.getScaledWidth()) / 2,
                    top: 115,
                    originX: 'left',
                    originY: 'top',
                });

                // Add the image to the canvas
                canvasRef.current.add(img);
                canvasRef.current.renderAll(); // Re-render the canvas
            },
            { crossOrigin: 'Anonymous' } // Handle cross-origin images
        );
        setAllUsedImages((prevImages) => [...prevImages, url]);
    };


    // Delete an image from the canvas
    const deleteImageFromCanvas = (id) => {
        if (!canvasRef.current) return;

        const objects = canvasRef.current.getObjects();
        const objectToRemove = objects.find((obj) => obj.id === id);

        if (objectToRemove) {
            canvasRef.current.remove(objectToRemove); // Remove the object
            canvasRef.current.renderAll(); // Re-render the canvas
        }
    };

    const updateImagesState = () => {
        if (!canvasRef.current) return;

        const objects = canvasRef.current.getObjects();
        const currentImages = objects
            .filter((obj) => obj.type === 'image')
            .map((img) => ({ id: img.id, url: img.getSrc() }));

        setImages(currentImages); // Update the `images` state
    };

    const handleAddName = (checked) => {
        setIsNameAdded(checked);
        if (activeSide === 'front') {
            setIsModifiedFront(true)
        }
        if (activeSide === 'back') {
            setIsModifiedBack(true)
        }
        const canvas = canvasRef.current;
        if (checked) {
            const existingName = canvas
                .getObjects()
                .find((obj) => obj.text === 'NAME');
            if (!existingName) {
                const nameText = new fabric.Textbox('NAME', {
                    fontFamily: nameNumberValues.fontFamily,
                    fill: nameNumberValues.fill,
                    fontSize: nameNumberValues.fontSize === 'Small' ? 45 : 55,
                    left: (canvas.width - 100) / 2, // Horizontally center the text
                    top: 95, // Adjust the top position as needed
                    originX: 'center', // Ensure text is horizontally centered
                });
                canvas.add(nameText);
                nameText.left = canvas.width / 2; // Ensure the object remains centered
            }
        } else {
            const nameObj = canvas
                .getObjects()
                .find((obj) => obj.text === 'NAME');
            if (nameObj) canvas.remove(nameObj);
        }
        canvas.renderAll();
    };

    const handleAddNumber = (checked) => {
        setIsNumberAdded(checked);
        if (activeSide === 'front') {
            setIsModifiedFront(true)
        }
        if (activeSide === 'back') {
            setIsModifiedBack(true)
        }
        const canvas = canvasRef.current;
        if (checked) {
            const existingNumber = canvas
                .getObjects()
                .find((obj) => obj.text === '00');
            if (!existingNumber) {
                const numberText = new fabric.Textbox('00', {
                    fontFamily: nameNumberValues.fontFamily,
                    fill: nameNumberValues.fill,
                    fontSize: nameNumberValues.fontSize === 'Small' ? 160 : 190,
                    left: (canvas.width - 100) / 2, // Horizontally center the text
                    top: 140, // Adjust the top position as needed
                    originX: 'center', // Ensure text is horizontally centered
                });
                canvas.add(numberText);
                numberText.left = canvas.width / 2; // Ensure the object remains centered
            }
        } else {
            const numberObj = canvas
                .getObjects()
                .find((obj) => obj.text === '00');
            if (numberObj) canvas.remove(numberObj);
        }
        canvas.renderAll();
    };




    const handleFontFamilyChange = (fontFamily) => {
        setNameNumberValues((prev) => ({ ...prev, fontFamily }));

        const canvas = canvasRef.current;

        canvas.getObjects().forEach((obj) => {
            if (obj.text === 'NAME' || obj.text === '00') {
                obj.set({ fontFamily });
            }
        });
        canvas.renderAll();
    };
    const handleFontColorChange = (color) => {
        setNameNumberValues((prev) => ({ ...prev, fill: color }));

        const canvas = canvasRef.current;

        canvas.getObjects().forEach((obj) => {
            if (obj.text === 'NAME' || obj.text === '00') {
                obj.set({ fill: color });
            }
        });
        canvas.renderAll();
    };
    const handleFontSizeChange = (size) => {
        setNameNumberValues((prev) => ({ ...prev, fontSize: size }));

        const canvas = canvasRef.current;

        canvas.getObjects().forEach((obj) => {
            if (obj.text === 'NAME' || obj.text === '00') {
                const newFontSize = size === 'Small' ? (obj.text === 'NAME' ? 70 : 80) : (obj.text === 'NAME' ? 160 : 200);

                // Update font size
                obj.set({
                    fontSize: newFontSize,
                });

                // obj.set({
                //     left: (canvas.width - 100) / 2,
                // });
            }
        });

        canvas.renderAll();
    };



    // const updateTextSize = (size) => {
    //     const activeObject = canvasRef.current.getActiveObject();
    //     if (activeObject && activeObject.type === "textbox") {
    //         activeObject.fontSize = size === "Small" ? 30 : 60;
    //         canvasRef.current.renderAll();
    //     }
    // };

    // const updateFontFamily = (fontFamily) => {
    //     const activeObject = canvasRef.current.getActiveObject();
    //     if (activeObject && activeObject.type === "textbox") {
    //         activeObject.fontFamily = fontFamily;
    //         canvasRef.current.renderAll();
    //     }
    // };
    // const updateFontColor = (color) => {
    //     const activeObject = canvasRef.current.getActiveObject();
    //     if (activeObject && activeObject.type === "textbox") {
    //         activeObject.fill = color;
    //         canvasRef.current.renderAll();
    //     }
    // };


    const productSize = [
        ...(productDetails?.customizeImageSize ?? [])
    ]

    const handleEnterNameClick = () => {
        setModalName(true); // Open the modal

        if (isNameAdded && isNumberAdded) {
            setModalMode('nameAndNumber');
        } else if (isNameAdded) {
            setModalMode('name');
        } else if (isNumberAdded) {
            setModalMode('number');
        }
    };


    const getToolComponent = () => {
        switch (activeToolsTab) {
            case 'products':
                return (
                    <ProductTool
                        isLoading={isLoading}
                        data={productDetails}
                        selectedColorIndex={selectedColorIndex}
                        handleColorSelect={handleColorSelect}
                        handleOptionChange={handleOptionChange}
                    />);
            case 'clipArt':
                return (
                    <ClipartTool
                        handleActiveToolsTab={handleActiveToolsTab}
                        clipArtData={clipArtData}
                        libraryData={libraryData}
                        addImageToCanvas={onImageAdd}
                    />
                );
            case 'addText':
                return (
                    <AddTextTool
                        isEdit={selectedObject}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        updateActiveObject={updateActiveObject}
                        onSubmit={addOrUpdateText}
                        handleActiveToolsTab={handleActiveToolsTab}
                        unselect={unselectAllObjects}
                        deleteActiveObject={deleteActiveObject}
                    />
                );
            case 'upload':
                return (
                    <UploadTool
                        unselect={unselectAllObjects}
                        isEdit={selectedObject}
                        handleActiveToolsTab={handleActiveToolsTab}
                        onImageAdd={onImageAdd}
                        images={images}
                        setImages={setImages}
                        deleteImageFromCanvas={deleteImageFromCanvas}
                    />
                );
            case 'namesAndNumbers':
                return (
                    <NameNumberTool
                        handleActiveToolsTab={handleActiveToolsTab}
                        isNameNumberTool={isNameNumberTool}
                        setIsNameNumberTool={setIsNameNumberTool}
                        handleAddName={handleAddName}
                        handleAddNumber={handleAddNumber}
                        activeSide={activeSide}
                        nameNumberValues={nameNumberValues}
                        isNameAdded={isNameAdded}
                        isNumberAdded={isNumberAdded}
                        handleFontColorChange={handleFontColorChange}
                        handleFontFamilyChange={handleFontFamilyChange}
                        handleFontSizeChange={handleFontSizeChange}
                        handleEnterNameClick={handleEnterNameClick}
                        sizeQuantities={sizeQuantities}
                        nextbtnError={nextbtnError}
                    />
                );
            default:
                return (
                    <ProductTool
                        data={productDetails}
                        selectedColorIndex={selectedColorIndex}
                        handleColorSelect={handleColorSelect}
                        handleOptionChange={handleOptionChange}
                    />);
        }
    };

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const isLogin = localStorage.getItem('role')
    const isToken = localStorage.getItem('token')
    const navigate = useNavigate()

    const saveAndUploadAllSides = async () => {
        if (!canvasRef.current) return;

        const uploadUrlArray = []; // Array to store uploaded URLs
        const canvas = canvasRef.current;

        for (const [side, backgroundUrl] of Object.entries(tshirtBackgrounds)) {
            // Save the current side's state before switching
            const jsonState = canvas.toJSON();
            setCanvasStates((prevStates) => ({
                ...prevStates,
                [activeSide]: jsonState, // Save the current side
            }));

            // Switch to the next side
            handleSideSwitch(side);

            await delay(2000);

            // Generate the canvas image
            const dataUrl = canvas.toDataURL({ format: 'png', quality: 1 });

            // Convert Data URL to a file
            const blob = await fetch(dataUrl).then((res) => res.blob());
            const file = new File([blob], `${side}-canvas.png`, { type: 'image/png' });

            // Prepare FormData for upload
            const formData = new FormData();
            formData.append('file', file);

            // Upload the canvas image to the API
            try {
                const response = await axios.post(fileUpload, formData);
                const uploadedImgUrl = response?.data?.result[0]?.location;

                // Add the uploaded URL to the array
                uploadUrlArray.push({ side, img_url: uploadedImgUrl });
            } catch (error) {
                console.error(`Failed to upload ${side} canvas:`, error);
                toast.error(`Failed to upload ${side} canvas`);
            }
        }

        return uploadUrlArray;
    };

    const handleSaveAndUpload = async () => {
        setIsLoading(true)
        const uploadedCanvasImages = await saveAndUploadAllSides();

        // Retrieve and parse options from localStorage
        const storedOptions = localStorage.getItem('selectedOptions');
        let parsedOptions = [];

        try {
            parsedOptions = storedOptions ? JSON.parse(storedOptions) : [];
        } catch (error) {
            console.error('Error parsing selectedOptions from localStorage', error);
            parsedOptions = [];
        }

        // Ensure parsedOptions is an array
        if (!Array.isArray(parsedOptions)) {
            console.error('parsedOptions is not an array');
            parsedOptions = [];
        }

        // Convert parsedOptions into a format that matches your payload
        const optionsMap = parsedOptions.reduce((acc, { fieldType, option }) => {
            acc[fieldType] = option;
            return acc;
        }, {});

        const rawImages = productDetails?.customizeImages?.[selectedColorIndex]?.images

        const payload = {
            product: productDetails?._id,
            sizeWithQuantity: sizeQuantities?.length > 0 ? (sizeQuantities) : (sizeQuantities1),
            dependencyImages: {
                mainImages: rawImages?.map((item) => (
                    item?.image
                )),
                otherImages: allUsedImages,
            },
            field: productDetails?.fieldType?.map((eachItem) => ({
                fieldType: eachItem?.name?._id,
                option: optionsMap[eachItem?.name?._id] || null,
            })),
            customizeImages: uploadedCanvasImages,
        };

        if (isLogin === 'USER') {
            try {
                const response = await axios.post(addCart, payload);
                toast.success(response?.data?.msg);
                localStorage.removeItem('AddCart');
                setIsLoading(false)
                navigate('/myCart');
                localStorage.removeItem('selectedOptions');
                localStorage.removeItem('quantity');
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            }
        } else {
            // toggle();
            alert('You are not signed in as a User');
        }

    };

    const updateOverlayImage = (side) => {
        if (!canvasRef.current) return;
        const canvas = canvasRef.current;

        if (isCanvasActive) {
            let overlayImageUrl = '';

            if (side === 'front') {
                overlayImageUrl = ruller
            }
            if (side === 'back') {
                overlayImageUrl = backruller
            }

            fabric.Image.fromURL(
                overlayImageUrl,
                (img) => {
                    // Scale the overlay image to match the canvas size
                    img.scaleToWidth(canvas.width);
                    img.scaleToHeight(canvas.height);

                    // Set the overlay image
                    canvas.setOverlayImage(
                        img,
                        canvas.renderAll.bind(canvas), // Ensure the canvas re-renders
                        {
                            opacity: 0.8, // Adjust transparency if needed
                        }
                    );
                },
                { crossOrigin: 'Anonymous' } // Handle cross-origin images if required
            );
        } else {
            // Remove the overlay image if isCanvasActive is false
            canvas.setOverlayImage(null, canvas.renderAll.bind(canvas));
        }
    };

    // Call this function whenever the side or canvas updates
    useEffect(() => {
        updateOverlayImage(activeSide); // Update overlay based on active side
    }, [activeSide, isCanvasActive]); // Run this effect when activeSide or isCanvasActive changes





    return (
        <div className='customization-con'>
            {/* Header Component for Customization */}
            <CustomizationHeader activeTab={activeStepsTab} handleActiveTab={handleActiveStepsTab} />
            {/* Sidebar Component for Customization */}
            <CustomizationSidebar activeTab={activeToolsTab} handleActiveTab={handleActiveToolsTab} handleSideSwitch={handleSideSwitch} activeSide={activeSide} />

            {/* Customization */}
            <div className='customization-container'>
                <div className='d-flex h-100 transition-eff'>
                    {activeStepsTab === 'one' && (
                        <div className='tools-container ps-5 p-4'>
                            {isLoading ? (
                                <div className='d-flex justify-content-center align-items-center h-75'>
                                    <Spinner>
                                        Loading...
                                    </Spinner>
                                </div>
                            ) : (
                                getToolComponent()
                            )}
                        </div>
                    )}
                    <div className='customization-portion'>
                        {isLoading ? (
                            <Spinner>
                                Loading...
                            </Spinner>
                        ) : (
                            <>
                                <div className='position-relative'>
                                    <canvas id="canvas"></canvas>
                                    <div className='quick-access-front-canvas'>
                                        {activeSide === 'front' && !isModifiedFront && (
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <button style={{ borderRadius: '20px', border: '1px solid #e44324', fontSize: '12px' }} className='btn  btn-light mb-2  text-capitalize ' onClick={() => handleActiveToolsTab('addText')}><LuTextCursorInput size={15} /> ADD TEXT</button>
                                                <button style={{ borderRadius: '20px', border: '1px solid #e44324', fontSize: '12px' }} className='btn  btn-light mb-2 text-capitalize' onClick={() => handleActiveToolsTab('upload')}><RiUploadCloud2Line size={15} /> UPLOAD ART</button>
                                                <button style={{ borderRadius: '20px', border: '1px solid #e44324', fontSize: '12px' }} className='btn  btn-light mb-2 text-capitalize' onClick={() => handleActiveToolsTab('clipArt')}><RiImageAddLine size={15} /> ADD CLIPART</button>
                                            </div>
                                        )}

                                    </div>
                                    <div className='quick-access-back-canvas'>
                                        {activeSide === 'back' && !isModifiedBack && (
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <button style={{ borderRadius: '20px', border: '1px solid #e44324', fontSize: '12px' }} className='btn  btn-light mb-2 text-capitalize' onClick={() => handleActiveToolsTab('upload')}><RiUploadCloud2Line size={15} /> UPLOAD ART</button>
                                                {productDetails?.productCategory?.customizedPrinting &&
                                                    <button style={{ borderRadius: '20px', border: '1px solid #e44324', fontSize: '12px' }} className='btn  btn-light mb-2 text-capitalize' onClick={() => handleActiveToolsTab('namesAndNumbers')}><FaRegCommentDots size={15} /> NAMES & NUMBERS</button>
                                                }
                                            </div>
                                        )}
                                    </div>

                                    {/* {activeSide === 'front' && isCanvasActive && (
                                        <img className='ruller-img-canvas' src={ruller} />
                                    )}
                                    {activeSide === 'back' && isCanvasActive && (
                                        <img className='ruller-img-canvas' src={backruller} />
                                    )} */}

                                </div>
                                {activeStepsTab === 'one' && (
                                    <>
                                        <div className="toolbar-undo-redo">
                                            <div className='d-flex flex-column gap-3'>
                                                <button className='undo-redo-btn shadow-sm' onClick={handleUndo} disabled={undoLength <= 1} ><CornerDownLeft size={15} /> UNDO</button>
                                                <button className='undo-redo-btn shadow-sm' onClick={handleRedo} disabled={redoLength === 0} ><CornerUpRight size={15} /> REDO</button>
                                                <button className='undo-redo-btn shadow-sm' onClick={resetCanvas} disabled={undoLength === 1}> START <br /> OVER</button>
                                            </div>
                                        </div>
                                        <div className='front-back-tab'>
                                            <div className='d-flex flex-column gap-3'>
                                                {Object.keys(tshirtBackgrounds).map((side) => (
                                                    <div onClick={() => handleSideSwitch(side)} key={side} className={`front-back-con shadow-sm ${activeSide === side ? 'front-back-con-active' : ''}`}>
                                                        <img draggable={false} className='tab-img-front-back' src={tshirtBackgrounds[side]} />
                                                        <p className='text-center p-0 m-0' >
                                                            {side.charAt(0).toUpperCase() + side.slice(1)}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className='next-btn-con'>
                                            <button onClick={handleNextStepFun} className='next-step-btn'>NEXT STEP <ArrowRight size={20} /></button>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    {activeStepsTab === 'two' && (
                        <div className={`step-container-1 ${activeStepsTab === 'two' ? 'step-container-1-active' : ''}  p-4`}>
                            {activeStepsTab === 'two' && (
                                <QuantitySizeStep
                                    data={productDetails}
                                    selectedColorIndex={selectedColorIndex}
                                    productSize={productSize}
                                    quantities={quantities}
                                    sizeQuantities={sizeQuantities}
                                    sizeQuantities1={sizeQuantities1}
                                    toggleName={toggleName}
                                    handleManualQuantityChange={handleManualQuantityChange}
                                    setActiveStepsTab={setActiveStepsTab}
                                    totalQuantity={totalQuantity}
                                    manualQuantity={manualQuantity}
                                    handleSideSwitch={handleSideSwitch}
                                />
                            )}
                        </div>
                    )}
                    {activeStepsTab === 'three' && (
                        <div className={`step-container-1 ${activeStepsTab === 'three' ? 'step-container-1-active' : ''}  p-4`}>
                            <FinalReviewStep
                                data={productDetails}
                                setActiveStepsTab={setActiveStepsTab}
                                selectedDetails={selectedDetails}
                                sizeQuantities={sizeQuantities}
                                sizeQuantities1={sizeQuantities1}
                                quantities={quantities}
                                totalQuantity={totalQuantity}
                                manualQuantity={manualQuantity}
                                selectedColorIndex={selectedColorIndex}
                                handleSaveAndUploadAllCanvasStates={handleSaveAndUpload}
                                isLoading={isLoading}
                            />
                        </div>

                    )}
                </div >
            </div >
            <NameNumberModal
                openModal={modalName}
                handleModal={toggleName}
                color={selectedColorIndex}
                values={productSize}
                name={productDetails}
                onSave={setSizeQuantities}
                modalMode={modalMode}
                fontStyle={nameNumberValues.fontFamily}
                textColor={nameNumberValues.fill}
            />
        </div >
    )
}

export default CustomizationNew

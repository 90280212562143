import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { productCategory } from '../../ApiConfigs/ApiConfig';
import axios from 'axios'
import { Col, Row, Spinner } from 'reactstrap';
import Navbar from '../navbar';
import FooterSection from '../footer';
// import { FaAngleRight } from "react-icons/fa6";
import toast from 'react-hot-toast';
import ProductCategoryCard from '../homeNew/ProductCategoryCard';
import TitleComponent from '../homeNew/TitleComponent';

const AllCategory = () => {
    const [loading, setLoading] = useState(false);
    const [dataa, setData] = useState([]);
    const navigate = useNavigate()

    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(productCategory);
            setData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        additionalData();
    }, []);

    const navToHome = () => {
        navigate('/');
    };

    const navToProduct = () => {
        navigate('/products');
    };

    // const navToViewPage = (product_url) => {
    //     navigate(`/${product_url}/all`);
    // };

    // const [showFullDescription, setShowFullDescription] = useState(false);

    // const toggleDescription = () => {
    //     setShowFullDescription(!showFullDescription);
    // };

    // const truncateDescription = (description, wordLimit) => {
    //     const words = description?.split(' ');
    //     if (words.length > wordLimit) {
    //         return words.slice(0, wordLimit).join(' ') + '...';
    //     }
    //     return description;
    // };
    return (
        <>
            <Navbar />
            {loading ? (
                <div style={{ height: "80vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner color="primary">
                        Loading...
                    </Spinner>
                </div>
            ) : (
                <div className=' des1 pt-3'>
                    <p className='pt-3' style={{ cursor: 'pointer', color: '#333333', opacity: '0.8' }}><span onClick={navToHome}>Home</span> / <span style={{ color: '#E4510B' }} onClick={navToProduct}>All Category</span></p>
                    <div className="mb-3">
                        <TitleComponent title={`All Category`} isDesc={true} desc={`Explore a wide range of categories featuring top-quality products tailored to your needs!`} />
                    </div>
                    <Row className="">
                        {dataa?.map((eachItem, i) => (
                            <Col key={i} xl={3} lg={4} md={6} sm={12}>
                                <ProductCategoryCard data={eachItem} />
                            </Col>
                        ))}
                    </Row>
                </div>
            )}
            <FooterSection />
        </>
    )
}

export default AllCategory
import React, { useCallback, useEffect, useState } from 'react'
import {
    Card, Col, Row,
    Collapse,
    Modal,
    ModalBody,
    Button,
    Spinner
} from 'reactstrap'
import { cart, cartCount, GstTotal, removeCart } from '../../ApiConfigs/ApiConfig';
import axios from 'axios'
import './index.css'
import { ArrowRight, Check, ChevronDown, ChevronRight, Edit3, ExternalLink, Eye, X } from 'react-feather';
import { useNavigate } from 'react-router';
import emptyCart from '../../assets/images/banner/carttt (1).png'
import { Link } from 'react-router-dom';
import Navbar from '../navbar';
import toast from 'react-hot-toast';
import FooterSection from '../footer';
import Preview from '../myOrder/preview';
// import categoryLeft from "../../assets/images/banner/Category-left.png";
// import categoryRight from "../../assets/images/banner/Category-right.png";
import { IoTrashOutline } from "react-icons/io5";
import TitleComponent from '../homeNew/TitleComponent';



function MyCartIndex() {

    const [loading, setLoading] = useState(false);
    const [dataa, setData] = useState([]);

    const cartId = dataa?._id
    const [openAccordion, setOpenAccordion] = useState(null);
    const [openAccordion1, setOpenAccordion1] = useState(null);
    const [modal, setModal] = useState(false);
    const [customizeImages, setCustomizeImages] = useState([]);
    const products = dataa?.products || [];
    const [selectedProductIds, setSelectedProductIds] = useState(
        products.length > 0 && products[0]?._id ? [products[0]._id] : []
    );

    const [gst, setGst] = useState();
    const navigate = useNavigate()
    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };
    const toggleAccordion1 = (id) => {
        setOpenAccordion1(openAccordion1 === id ? null : id);
    };
    const numberOfItem = dataa?.products?.length;

    const token = localStorage.getItem('token'); // Extract the token

    // Use useCallback to memoize the function
    const additionalData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${cart}`);
            setData(response?.data?.result);
        } catch (error) {
            console.error(error); // Add error handling
        } finally {
            setLoading(false);
        }
    }, []);// Add `cart` as a dependency if it's dynamic or external

    useEffect(() => {
        if (token) {
            additionalData();
        }
    }, [token, additionalData]);

    useEffect(() => {
        const totalGstCheck = async () => {
            if (selectedProductIds?.length > 0) {
                const payload = {
                    products: selectedProductIds,
                };

                try {
                    setLoading(true);
                    const response = await axios.post(`${GstTotal}`, payload);
                    setGst(response?.data?.result);
                } catch (error) {
                    console.error('Error fetching GST:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        totalGstCheck(); // call the GST check function when the page loads
    }, [selectedProductIds]);
    // const location = useLocation();

    useEffect(() => {
        const handleNavigation = (event) => {
            if (event.state === null) {
                navigate('/');
            }
        };

        window.addEventListener('popstate', handleNavigation);

        return () => {
            window.removeEventListener('popstate', handleNavigation);
        };
    }, [navigate]);

    // const orderNowFunc = () => {

    //     navigate(`/placeOrder/${cartId}`)

    // }

    // const deleteProductCart = async (id) => {
    //     try {
    //         const response = await axios.delete(`${cart}/${cartId}?product=${id}`)

    //         additionalData()
    //     } catch (error) {
    //     }
    // }

    const navToProductView = (id) => {
        navigate(`/${id}`)
    }



    const [deleteData, setDeleteData] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);


    const openDeleteModal = (id) => {
        setDeleteData(id);

        setDeleteModal(true);
    }
    const closeDeleteModal = () => {
        setDeleteModal(false);
    }
    const handleConfirmDelete = async () => {
        try {
            const response = await axios.delete(`${removeCart}/${cartId}?product=${deleteData}`)
            closeDeleteModal()
            additionalData()
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }

    }


    const [productNewId, setId] = useState('')
    const [productQ, setQuantity] = useState()



    const [isEdit, setIsEdit] = useState({});
    const [editedQuantity, setEditedQuantity] = useState({});

    const editQuantity = (id, quantity) => {
        setIsEdit((prevIsEdit) => ({
            ...prevIsEdit,
            [id]: true,
        }));
        setId(id);
        setQuantity(quantity);
    };

    const handleQuantityChange = async (e, productNewId) => {
        const value = e.target.value;
        if (!value.includes('+') && !value.includes('-')) {
            setEditedQuantity((prevEditedQuantity) => ({
                ...prevEditedQuantity,
                [productNewId]: '',
            }));

            setTimeout(() => {
                setEditedQuantity((prevEditedQuantity) => ({
                    ...prevEditedQuantity,
                    [productNewId]: value,
                }));
            }, 0);  // Use setTimeout to ensure the input box is cleared before updating
        }
    };

    const confirmQuantity = async () => {
        const payload = {
            product: productNewId,
            quantity: editedQuantity[productNewId] || productQ,
        };

        try {
            const response = await axios.post(`${cartCount}/${cartId}`, payload);
            setIsEdit((prevIsEdit) => ({
                ...prevIsEdit,
                [productNewId]: false,
            }));
            additionalData();
            toast.success(response?.data?.msg);
        } catch (error) {
        }
    };

    const closeInput = () => {
        setIsEdit(false)
    }



    // const [newQuantity, setNewQuantity] = useState('')
    // const [newId, setNewId] = useState('')

    // const handleQuantityChangeNew = (e, id) => {
    //     const value = e.target.value;
    //     setNewQuantity(value)
    //     setNewId(id)
    // }

    // const confirmQuantityNew = async () => {
    //     const payload = {
    //         product: newId,
    //         quantity: newQuantity,
    //     };

    //     try {
    //         const response = await axios.post(`${cartCount}/${cartId}`, payload);
    //         additionalData();
    //         toast.success(response?.data?.msg);
    //     } catch (error) {
    //     }
    // };

    const toggle = () => setModal(!modal);


    // const viewCustom = (item) => {

    //     if (item?.customizeImages?.length > 0) {
    //         setCustomizeImages(item?.customizeImages);
    //         toggle()
    //     } else {
    //         setCustomizeImages(item?.file);
    //         toggle()
    //     }

    // };

    const viewCustom = (item) => {
        // Log the item for debugging

        // Check if item is defined and if required properties exist
        if (item) {
            // Handle condition when 'customizeImages' array has more than 1 item
            if (Array.isArray(item?.customizeImages) && item?.customizeImages.length > 1) {
                setCustomizeImages(item?.customizeImages?.map((customImage) => customImage?.img_url)); // Set 'customizeImages' state
                toggle(); // Toggle modal or action
            }
            // Handle condition when 'file' array has items
            else if (Array.isArray(item?.file) && item?.file.length > 0) {
                setCustomizeImages(item?.file); // Set 'customizeImages' state
                toggle(); // Toggle modal or action
            }
            // Handle condition when 'product.image' is available
            else if (item?.product?.image) {
                setCustomizeImages([item?.product?.image]); // Ensure it's in an array
                toggle(); // Toggle modal or action
            }
            else {
                // Fallback if no images or files are found
            }
        } else {
            // Handle case where 'item' is undefined or null
            console.error("Invalid item passed to viewCustom");
        }
    };

    // const handleCheckboxChange = (productId) => {
    //     setSelectedProductIds(prevSelectedIds => {
    //         if (prevSelectedIds.includes(productId)) {
    //             // If product ID is already selected, remove it from the list
    //             return prevSelectedIds.filter(id => id !== productId);
    //         } else {
    //             // If product ID is not selected, add it to the list
    //             return [...prevSelectedIds, productId];
    //         }
    //     });        
    // };

    useEffect(() => {
        if (dataa?.products?.length) {
            // Prepopulate all product IDs in the selectedProductIds state
            // setSelectedProductIds(dataa.products.map(product => product._id));
            setSelectedProductIds([dataa.products[0]._id]);
        }
    }, [dataa?.products]);


    const handleCheckboxChange = (id) => {
        setSelectedProductIds(prevSelectedProductIds =>
            prevSelectedProductIds.includes(id)
                ? prevSelectedProductIds.filter(productId => productId !== id) // Remove ID if already selected
                : [...prevSelectedProductIds, id] // Add ID if not selected
        );
    };


    // const selectedTotalAmount = products
    //     .filter(product => selectedProductIds.includes(product._id))
    //     .reduce((total, product) => total + dataa?.totalAmount.price, 0);
    // const selectedTotalAmount = products
    //     .filter(product => selectedProductIds.includes(product._id)) // Filter only selected products
    //     .reduce((total, product) => total + product.amount, 0);


    const orderNowFunc = () => {
        // Navigate to the checkout page and pass the selectedProductIds
        navigate(`/placeOrder/${cartId}`, { state: { selectedProductIds } });
    };

    return (
        <>

            <Navbar cartData={dataa} cartRefetch={additionalData} />
            <div className='pppaa' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '10%', background: '#FAFAFA' }}>
                {
                    loading ? (
                        <div style={{ height: '310px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spinner
                                color="primary"
                                size=""
                            >
                                Loading...
                            </Spinner>
                        </div>
                    ) :
                        (<>
                            {numberOfItem ? (
                                <div className='pt-5'>
                                    <div>
                                        <TitleComponent title={`My Cart`} isDesc={true} desc={`Welcome to your Cart! Here, you can review all the custom products you’ve selected before completing your purchase. Whether you’re customizing apparel, accessories, or home decor, your Cart holds everything you’ve chosen, making it easy to finalize your order.`} />
                                    </div>
                                    <div className='d-flex cent mt-3'>
                                        <h4>My Cart (<span>{numberOfItem}</span> item)</h4>
                                        {/* <hr className='ms-3 hrrr1' style={{ height: '2px', width: '85%', background: 'black' }} /> */}
                                    </div>

                                    <Row>
                                        <Col sm={12} lg={8}>
                                            <Card className='' style={{ border: 'none', background: '#FAFAFA' }}>
                                                {/* <h5 style={{ backgroundColor: 'rgb(237, 237, 237)' }} className='p-3 cart-title m-0'>All Jobs - {numberOfItem} items</h5> */}
                                                {dataa?.products?.map((eachItem, i) => (
                                                    <div style={{ borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.05)', background: 'white' }} key={i} className='cart-card mt-4 p-3'>
                                                        <div>
                                                            <input
                                                                className='check1'
                                                                type="checkbox"
                                                                checked={selectedProductIds.includes(eachItem._id)}
                                                                onChange={() => handleCheckboxChange(eachItem._id)}
                                                            />
                                                        </div>
                                                        <div className='cart-left ms-3'>
                                                            <div className='cart-img d-flex align-items-center justify-content-center'>
                                                                {eachItem?.customizeImages?.[0] ? (
                                                                    <img
                                                                        alt={`product`}
                                                                        src={eachItem?.customizeImages?.[0]?.img_url}
                                                                        className='produ-img'
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {eachItem?.customizeImages?.length === 0 && eachItem?.file?.length === 0 ?
                                                                            (
                                                                                <img
                                                                                    alt={`product`}
                                                                                    src={eachItem?.product?.image}
                                                                                    className='produ-img'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    alt={`product`}
                                                                                    src={eachItem?.file[0].endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : eachItem?.file[0]}
                                                                                    className='produ-img'
                                                                                />
                                                                            )
                                                                        }
                                                                    </>
                                                                    // <a href={eachItem?.file?.[0]} target='_blank'>

                                                                    // </a>
                                                                )}

                                                            </div>
                                                            <div className='d-flex mt-2' style={{ alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                                                <span style={{ cursor: 'pointer' }} onClick={() => viewCustom(eachItem)}><Eye style={{ color: '#E4510B' }} /> Preview</span>
                                                            </div>
                                                        </div>

                                                        <div className='cart-right  '>
                                                            <div className='d-flex justify-content-between me-3 mt-2'>
                                                                <div className='d-flex'>
                                                                    <h5 className='mt-1'>{eachItem?.product?.name}</h5>
                                                                    <span className='ms-2' style={{ cursor: 'pointer', color: '#e6703a' }} onClick={() => navToProductView(eachItem?.product?._id)}><ExternalLink /></span>
                                                                </div>
                                                                <div className='d-flex justify-content-center align-items-center' style={{ background: '#E425251A', opacity: '0.9', width: '50px', height: '50px', borderRadius: '5px' }}>
                                                                    <span style={{ cursor: 'pointer' }} onClick={() => openDeleteModal(eachItem?._id)}><IoTrashOutline style={{ color: '#E42525' }} size={25} /></span>
                                                                </div>
                                                            </div>

                                                            <div className='d-flex justify-content-between'>
                                                                {isEdit[eachItem?._id] ? (
                                                                    <>
                                                                        <div style={{ marginTop: '-10px', marginBottom: '15px' }} className=''>
                                                                            <label
                                                                                style={{ color: '#262525', fontSize: '16px', fontWeight: '400' }}
                                                                                className=''
                                                                            >
                                                                                Quantity
                                                                            </label>
                                                                            <input
                                                                                className='ms-2 editQuantityInput'
                                                                                name='editQuantity'
                                                                                value={editedQuantity[eachItem?._id] || productQ}
                                                                                onChange={(e) => handleQuantityChange(e, eachItem?._id)}
                                                                                placeholder='Enter Your Quantity'
                                                                                type="number"
                                                                                pattern="[0-9]+"
                                                                                title="Please enter only digits (0-9)"
                                                                            />

                                                                            <span className='ms-2' style={{ cursor: 'pointer', color: 'green' }} onClick={() => confirmQuantity(eachItem?._id)}><Check /></span>
                                                                            <span className='text-danger ms-2' style={{ cursor: 'pointer' }} onClick={closeInput}><X /></span>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>

                                                                        <p style={{ color: '#262525' }} className=' cart-quantity'>
                                                                            Quantity: <span className='text-dark'>{eachItem?.quantity}</span>
                                                                            {!eachItem?.sizeWithQuantity?.length > 0 &&
                                                                                <span
                                                                                    style={{ color: '#e6703a', cursor: 'pointer' }}
                                                                                    onClick={() => editQuantity(eachItem?._id, eachItem?.quantity)}
                                                                                >
                                                                                    <Edit3 />
                                                                                </span>

                                                                            }
                                                                        </p>
                                                                    </>
                                                                )}
                                                            </div>
                                                            {!eachItem?.sizeWithQuantity?.length > 0 &&
                                                                <>
                                                                    {eachItem?.size &&
                                                                        <div>
                                                                            <p className="cart-quantity">
                                                                                Size: <span className="text-dark">{eachItem.size}</span>
                                                                            </p>
                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                            {/* {eachItem?.sizeWithQuantity?.length > 0 ? (
                                                                <div>
                                                                    <p className="cart-quantity">
                                                                        Size with quantity:
                                                                        <span className='ms-2'>
                                                                            {eachItem?.sizeWithQuantity?.map((item, index) => (
                                                                                <span key={index} className="text-dark">
                                                                                    {item.size} - {item.quantity}
                                                                                    {index < eachItem.sizeWithQuantity.length - 1 && ", "}
                                                                                </span>
                                                                            ))}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {eachItem?.size &&
                                                                        <div>
                                                                            <p className="cart-quantity">
                                                                                Size: <span className="text-dark">{eachItem.size}</span>
                                                                            </p>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )} */}
                                                            {eachItem?.sizeWithQuantity?.length > 0 &&
                                                                <>
                                                                    {eachItem?.sizeWithQuantity[0]?.quantity ? (
                                                                        <div className='mt-2'>
                                                                            <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                                                <div className='d-flex' onClick={() => toggleAccordion1(eachItem?._id)}>
                                                                                    <h6 className=' p-0'>Size with Quantity</h6>
                                                                                    <span className='ms-2 p-0'><ChevronDown /></span>
                                                                                </div>
                                                                            </div>
                                                                            <Collapse isOpen={openAccordion1 === eachItem?._id}>
                                                                                <div className='accordion-body'>
                                                                                    <div className='mt-2 mb-2'>
                                                                                        <table className="table">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>S. No</th>
                                                                                                    <th>Size</th>
                                                                                                    <th>Quantity</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {eachItem?.sizeWithQuantity?.map((item, index) => (
                                                                                                    <tr key={index}>
                                                                                                        <td>{index + 1}</td>
                                                                                                        <td>{item.size || 'N/A'}</td>
                                                                                                        <td>{item.quantity || 'N/A'}</td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </Collapse>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='mt-2'>
                                                                            <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                                                <div className='d-flex' onClick={() => toggleAccordion1(eachItem?._id)}>
                                                                                    <h6 className=' p-0'>Names & Numbers</h6>
                                                                                    <span className='ms-2 p-0'><ChevronDown /></span>
                                                                                </div>
                                                                            </div>
                                                                            <Collapse isOpen={openAccordion1 === eachItem?._id}>
                                                                                <div className='accordion-body'>
                                                                                    <div className='mt-2 mb-2'>
                                                                                        <table className="table">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>S. No</th>
                                                                                                    <th>Name</th>
                                                                                                    <th>Number</th>
                                                                                                    <th>Size</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {eachItem?.sizeWithQuantity?.map((item, index) => (
                                                                                                    <tr key={index}>
                                                                                                        <td>{index + 1}</td>
                                                                                                        <td>{item.name || 'N/A'}</td>
                                                                                                        <td>{item.number || 'N/A'}</td>
                                                                                                        <td>{item.size || 'N/A'}</td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </Collapse>
                                                                        </div>
                                                                    )}

                                                                </>
                                                            }
                                                            <div className='mt-2'>
                                                                <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                                    <div className='d-flex' onClick={() => toggleAccordion(eachItem?._id)}>
                                                                        <h6 className=' p-0'>Product Specifications</h6>
                                                                        <span className='ms-2 p-0'><ChevronDown /></span>
                                                                    </div>
                                                                </div>
                                                                <Collapse isOpen={openAccordion === eachItem?._id}>
                                                                    <div className='accordion-body'>
                                                                        <div>
                                                                            {/* <p style={{ color: '#e6703a' }}>Edit Details</p> */}

                                                                            {eachItem?.field?.map((eachDetail, k) => (
                                                                                <div className='d-flex justify-content-between' key={k}>
                                                                                    <p className='text-secondary'><ChevronRight /> {eachDetail?.fieldType?.name}</p>
                                                                                    <p className='pe-5 text-dark' style={{ fontWeight: '500' }}>- {eachDetail?.option?.name}</p>
                                                                                </div>
                                                                            ))}

                                                                        </div>
                                                                    </div>
                                                                </Collapse>
                                                            </div>
                                                            <hr />
                                                            <div className='d-flex justify-content-between'>
                                                                <p className='fw-bold'>Item Total</p>
                                                                <p style={{ color: '#37A003' }} className='fw-bold'>{eachItem?.amount?.toLocaleString('en-IN', {
                                                                    maximumFractionDigits: 2,
                                                                    style: 'currency',
                                                                    currency: 'INR'
                                                                })}</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Card>
                                        </Col>
                                        <Col className='mt-4 check' sm={12} lg={4}>
                                            <Card style={{ border: 'none' }} className='mt-4 mt-lg-0 '>
                                                <div className='cart-summary p-3'>
                                                    <h5>Order Summary</h5>
                                                    <hr className='p-0 m-0 mb-3' />
                                                    <div className='d-flex justify-content-between'>
                                                        <p className='cart-summary-para'>Item Subtotal</p>
                                                        <p>{gst?.totalAmount.toLocaleString('en-IN', {
                                                            maximumFractionDigits: 2,
                                                            style: 'currency',
                                                            currency: 'INR'
                                                        })}</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className='cart-summary-para'>GST Value</p>
                                                        <p>{gst?.totalGst.toLocaleString('en-IN', {
                                                            maximumFractionDigits: 2,
                                                            style: 'currency',
                                                            currency: 'INR'
                                                        })}</p>
                                                    </div>
                                                    <hr className='p-0 m-0 mb-3' />
                                                    <div className='d-flex justify-content-between'>
                                                        <h6>Estimated Total <span style={{ color: '#888888' }}>(inclusive of all tax)</span></h6>
                                                        <h5 style={{ color: '#37A003' }} className='fw-bold ms-3'> {gst?.totalAfterGst?.toLocaleString('en-IN', {
                                                            maximumFractionDigits: 2,
                                                            style: 'currency',
                                                            currency: 'INR'
                                                        })}</h5>
                                                    </div>
                                                    <div className='mt-4'>
                                                        <button className='order-now-btn' disabled={!selectedProductIds?.length > 0} onClick={orderNowFunc}>
                                                            Continue to Checkout
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* <div className='cart-summary-voucher p-4 mb-3'>
                                                    <p className='cart-summary-para p-0 m-0'><Tag /> Vouchers are now moved to checkout</p>
                                                </div> */}
                                            </Card>
                                        </Col>
                                    </Row>



                                    <Modal isOpen={deleteModal} toggle={closeDeleteModal} className="modal-dialog-centered modal-xs">
                                        <ModalBody style={{ fontSize: '20px', textAlign: 'center', paddingTop: '30px', fontWeight: '400' }}>Are you sure you want to delete this Item?</ModalBody>
                                        <div style={{ justifyContent: 'center' }} className="modal-footer">
                                            <Button style={{ backgroundColor: "#E4510B", border: 'none' }} onClick={handleConfirmDelete} >
                                                Confirm
                                            </Button>
                                            <Button color="secondary" onClick={closeDeleteModal} >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Modal>
                                </div>
                            ) : (
                                <>

                                    <div className='d-flex justify-content-center'>

                                        <img src={emptyCart} className="img-fuild empty-card-img" alt="empty-cart" />
                                    </div>
                                    <h4 className='text-center m-0 p-0 cart-empty-para'>This is embarrassing!<br />Your cart is empty at the moment.</h4>
                                    <div className='d-flex justify-content-center mt-3'>
                                        <Link to='/products'>
                                            <button className='success-modal-btn'>Continue Shopping <ArrowRight size={17} /></button>
                                        </Link>
                                    </div>
                                </>
                            )}
                        </>)

                }
                <Preview modal={modal} toggle={toggle} cardImages={customizeImages} />
            </div >
            <FooterSection />
        </>
    )
}

export default MyCartIndex
import React, { useState } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';

function ProductCard({ data }) {
    const navigate = useNavigate();
    const [hoveredIndex, setHoveredIndex] = useState(0);
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = (index) => {
        setIsHover(true)
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(0);
        setIsHover(false)
    };

    const handleNavigation = () => {
        if (data?.customizeImages.length > 0) {
            navigate(`/${data?.product_url}?color=${hoveredIndex}`)
        } else {
            navigate(`/${data?.product_url}`)
        }
    }

    return (

        <div
            onClick={handleNavigation}
            className='cursor-pointer product-card-new shadow-sm mb-3 mb-lg-5'
        >
            {isHover ? (
                <img
                    className='img-fluid rounded-4 '
                    src={data?.customizeImages[hoveredIndex]?.images[0]?.image}
                    alt="Product"
                />
            ) : (
                <img
                    className='img-fluid rounded-4'
                    src={data?.image}
                    alt="Default Product"
                />
            )}
            <div className='product-card-new-btm p-3 pt-5'>
                <div className='d-flex align-items-center gap-1'>
                    <img
                        height={16}
                        src={`https://printontesting.s3.ap-south-1.amazonaws.com/printon/common_20241118164415138/Star%205.png`}
                        alt="Star"
                    />
                    <p className='p-0 m-0'>4.1</p>
                </div >
                <h1 className='fs-5 m-0 p-0 mt-1'>{data?.name}</h1>
                {/* <p className='m-0 p-0 mt-1'>
                    {data?.fullDescription
                        ? data.fullDescription.length > 35
                            ? `${data.fullDescription.substring(0, 35)}...`
                            : data.fullDescription
                        : "No description available"}
                </p> */}
                <div className='d-flex align-items-center flex-wrap gap-2 mt-2'>
                    {data?.customizeImages?.map((color, k) => (
                        <div
                            key={k}
                            className="color-bar"
                            style={{ backgroundColor: color.colourCode }}
                            onMouseEnter={() => handleMouseEnter(k)}
                            onMouseLeave={handleMouseLeave}
                        >
                        </div>
                    ))}
                </div>
            </div >
        </div >
    );
}

export default ProductCard;

import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { Trash2, X } from 'react-feather'
import { Input } from 'reactstrap'

function AddTextTool(props) {
    const { handleActiveToolsTab, formValues, setFormValues, onSubmit, updateActiveObject, isEdit, unselect, deleteActiveObject } = props
    const inputRef = useRef(null); // Create a ref for the input field


    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            const textLength = formValues.text?.length || 0;
            inputRef.current.setSelectionRange(textLength, textLength);
        }
    }, [formValues.text]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedValue =
            name === 'fontSize' || name === 'charSpacing' || name === 'strokeWidth' || name === 'angle'
                ? parseInt(value, 10)
                : value;

        // Update form values
        setFormValues((prev) => ({
            ...prev,
            [name]: updatedValue,
        }));

        // Dynamically update the active object on canvas
        if (updateActiveObject) {
            updateActiveObject(name, updatedValue);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formValues.text.trim()) {
            onSubmit(formValues.text, formValues);
        }
    };

    return (
        <div>
            <div>
                <h1 className='tool-title'>TEXT EDITOR</h1>
                <div className='d-flex justify-content-between align-items-center'>
                    <h1 className='tool-sub-title'>{isEdit ? 'Edit Your Text' : 'Add New Text'}</h1>
                    {isEdit ? (
                        <span onClick={unselect} className='cursor-action'><X /></span>
                    ) : (
                        <span onClick={() => handleActiveToolsTab('product')} className='cursor-action'><X /></span>
                    )}
                </div>
                <div className='tool-bar-line mt-2'></div>
                <form className='mt-4' onSubmit={handleSubmit}>
                    <div className='mb-3'>
                        <Input
                            placeholder='Begin Typing...'
                            id="exampleText"
                            name="text"
                            type="textarea"
                            className="input-custom-placeholder"
                            rows='3'
                            value={formValues.text}
                            onChange={handleChange}
                            innerRef={inputRef}
                        />
                    </div>
                    <div className='mb-3 d-flex align-items-center gap-3'>
                        <label className='text-editor-label'>Font</label>
                        <Input type='select' name="fontFamily" value={formValues.fontFamily} onChange={handleChange}>
                            <option value="Arial, sans-serif" style={{ fontFamily: 'Arial, sans-serif' }}>Arial (Standard)</option>
                            <option value="Interstate" style={{ fontFamily: 'Interstate' }}>Interstate</option>
                            <option value="Verdana, sans-serif" style={{ fontFamily: 'Verdana, sans-serif' }}>Verdana (Clear & Simple)</option>
                            <option value='"Times New Roman", serif' style={{ fontFamily: '"Times New Roman", serif' }}>Times New Roman (Classic)</option>
                            <option value="Impact" style={{ fontFamily: 'Impact' }}>Impact (Bold & Strong)</option>
                            <option value='"Bebas Neue", sans-serif' style={{ fontFamily: '"Bebas Neue", sans-serif' }}>Bebas Neue (Bold Block)</option>
                            <option value="Futura, sans-serif" style={{ fontFamily: 'Futura, sans-serif' }}>Futura (Retro)</option>
                            <option value="Helvetica, sans-serif" style={{ fontFamily: 'Helvetica, sans-serif' }}>Helvetica (Minimalistic)</option>
                            <option value="Rockwell, serif" style={{ fontFamily: 'Rockwell, serif' }}>Rockwell (Vintage)</option>
                            <option value='"Streetbrush", cursive' style={{ fontFamily: '"Streetbrush", cursive' }}>Streetbrush (Graffiti)</option>
                            <option value="Teko, sans-serif" style={{ fontFamily: 'Teko, sans-serif' }}>Teko (Modern 3D)</option>
                            <option value="Pacifico, cursive" style={{ fontFamily: 'Pacifico, cursive' }}>Pacifico (Cursive)</option>
                            <option value="Varsity, sans-serif" style={{ fontFamily: 'Varsity, sans-serif' }}>Varsity (Collegiate)</option>
                            <option value='"Neon 80s", sans-serif' style={{ fontFamily: '"Neon 80s", sans-serif' }}>Neon 80s (Glow)</option>
                            <option value="Montserrat, sans-serif" style={{ fontFamily: 'Montserrat, sans-serif' }}>Montserrat (Geometric)</option>
                            <option value='"Playfair Display", serif' style={{ fontFamily: '"Playfair Display", serif' }}>Playfair Display (Elegant)</option>
                            <option value="Raleway, sans-serif" style={{ fontFamily: 'Raleway, sans-serif' }}>Raleway (Light & Sleek)</option>
                            <option value="Lobster, cursive" style={{ fontFamily: 'Lobster, cursive' }}>Lobster (Cursive & Fun)</option>
                            <option value="Cinzel, serif" style={{ fontFamily: 'Cinzel, serif' }}>Cinzel (Classic Engraving)</option>
                            <option value="Oswald, sans-serif" style={{ fontFamily: 'Oswald, sans-serif' }}>Oswald (Condensed)</option>
                            <option value="Anton, sans-serif" style={{ fontFamily: 'Anton, sans-serif' }}>Anton (Bold Modern)</option>
                            <option value='"Brush Script MT", cursive' style={{ fontFamily: '"Brush Script MT", cursive' }}>Brush Script MT (Handwritten)</option>
                            <option value='"Comic Sans MS", cursive' style={{ fontFamily: '"Comic Sans MS", cursive' }}>Comic Sans MS (Playful)</option>
                            <option value='"Dancing Script", cursive' style={{ fontFamily: '"Dancing Script", cursive' }}>Dancing Script (Playful & Elegant)</option>
                            <option value="Creepster, cursive" style={{ fontFamily: 'Creepster, cursive' }}>Creepster (Spooky)</option>
                            <option value='"Luckiest Guy", cursive' style={{ fontFamily: '"Luckiest Guy", cursive' }}>Luckiest Guy (Comic Style)</option>
                            <option value='"Amatic SC", cursive' style={{ fontFamily: '"Amatic SC", cursive' }}>Amatic SC (Hand-drawn)</option>
                            <option value="Roboto, sans-serif" style={{ fontFamily: 'Roboto, sans-serif' }}>Roboto (Modern Sans-Serif)</option>
                            <option value="Blackout Midnight, sans-serif" style={{ fontFamily: 'Blackout Midnight, sans-serif' }}>Blackout Midnight (Futuristic)</option>
                        </Input>
                    </div>
                    <div className='mb-3 d-flex align-items-center gap-3'>
                        <label className='text-editor-label'>Color</label>
                        <Input
                            id="fill"
                            name="fill"
                            type="color"
                            value={formValues.fill}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='mb-3 d-flex align-items-center gap-3'>
                        <label className='text-editor-label'>Style</label>
                        <Input type='select' name="fontWeight" value={formValues.fontWeight} onChange={handleChange}>
                            <option value="normal">Normal</option>
                            <option value="bold">Bold</option>
                            <option value="lighter">Lighter</option>
                            <option value="100">Thin (100)</option>
                            <option value="200">Extra Light (200)</option>
                            <option value="300">Light (300)</option>
                            <option value="400">Regular (400)</option>
                            <option value="500">Medium (500)</option>
                            <option value="600">Semi Bold (600)</option>
                            <option value="700">Bold (700)</option>
                            <option value="800">Extra Bold (800)</option>
                            <option value="900">Black (900)</option>
                        </Input>
                    </div>
                    <div className='mb-3 d-flex align-items-center gap-3'>
                        <label className='text-editor-label'>Size</label>
                        {/* Range Slider */}
                        <Input
                            id="fontSize"
                            name="fontSize"
                            type="range"
                            value={formValues.fontSize}
                            onChange={handleChange}
                            min="10"
                            max="100"
                            className="me-2"
                        />

                        {/* Number Input */}
                        <Input
                            type="number"
                            name="fontSize"
                            value={formValues.fontSize}
                            onChange={handleChange}
                            min="10"
                            max="100"
                            className="number-input no-arrows"
                        />

                    </div>



                    <div className='mb-3 d-flex align-items-center gap-3'>
                        <label className='text-editor-label'>Spacing</label>
                        {/* Range Slider */}
                        <Input
                            id="charSpacing"
                            name="charSpacing"
                            type="range"
                            value={formValues.charSpacing}
                            onChange={handleChange}
                            min="10"
                            max="500"
                            className="me-2"
                        />
                        <Input
                            type="number"
                            name="charSpacing"
                            value={formValues.charSpacing}
                            onChange={handleChange}
                            min="0"
                            className="number-input no-arrows"
                        />
                    </div>
                    <div className='mb-3 d-flex align-items-center gap-3'>
                        <label className='text-editor-label'>Rotate</label>
                        {/* Range Slider */}
                        <Input
                            id="angle"
                            name="angle"
                            type="range"
                            value={formValues.angle}
                            onChange={handleChange}
                            min="0"
                            max="360"
                            className="me-2"
                        />
                        <Input
                            type="number"
                            name="angle"
                            value={parseInt(formValues.angle)}
                            onChange={handleChange}
                            min="0"
                            max="360"
                            className="number-input no-arrows"
                        />
                    </div>
                    {/* <div className='mb-3 d-flex align-items-center gap-3'>
                        <label className='text-editor-label'>Outline Color</label>
                        <Input
                            id="stroke"
                            name="stroke"
                            type="color"
                            value={formValues.stroke}
                            onChange={handleChange}
                        />
                    </div> */}
                    {/* <div className='mb-3 d-flex align-items-center gap-3'>
                        <label className='text-editor-label'>Outline Size</label>
                        <Input
                            id="strokeWidth"
                            name="strokeWidth"
                            type="range"
                            value={formValues.strokeWidth}
                            onChange={handleChange}
                            min="10"
                            max="50"
                            className="me-2"
                        />
                        <Input
                            type="number"
                            name="strokeWidth"
                            value={formValues.strokeWidth}
                            onChange={handleChange}
                            min="0"
                            max="10"
                        />
                    </div> */}
                    {!isEdit ? (
                        <button className={`text-add-btn ${formValues.text === '' ? 'text-add-btn-unactive' : ''}`} disabled={formValues.text === ''} type="submit">Add New Text</button>
                    ) : (
                        <button onClick={deleteActiveObject} className={`text-add-btn d-flex justify-content-center align-items-center`}><Trash2 className='me-2' size={20} />  Delete Text</button>
                    )}
                </form>
            </div >
        </div >
    )
}

export default AddTextTool

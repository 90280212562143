import React, { useEffect, useState } from 'react'
import './index.css'
import { useNavigate } from 'react-router-dom'
import { product } from '../../ApiConfigs/ApiConfig';
import axios from 'axios'
import { Col, Row, Spinner } from 'reactstrap';
import Navbar from '../navbar';
import FooterSection from '../footer';
import { ArrowRight } from "react-feather";
import TitleComponent from '../homeNew/TitleComponent';
import ProductCard from '../homeNew/ProductCard';

function AllProduct() {

    // Product Get
    const [loading, setLoading] = useState(false);
    const [dataa, setData] = useState([]);
    const [dataa1, setData1] = useState([]);
    const navigate = useNavigate()

    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${product}`);
            // setData(response?.data?.result);
            const productsWithOffer = response?.data?.result?.filter(product => product.offer);
            setData1(productsWithOffer)
            setData(response?.data?.result);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        additionalData();
    }, []);

    const navToHome = () => {
        navigate('/');
    };

    const navToProduct = () => {
        navigate('/products');
    };

    return (
        <>
            <Navbar />
            {loading ? (
                <div style={{ height: "80vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner color="primary">
                        Loading...
                    </Spinner>
                </div>
            ) : (
                <div className='d-block des1 pt-3'>
                    <div className='d-block'>
                        <p className='pt-3' style={{ cursor: 'pointer', color: '#333333', opacity: '0.8' }}><span onClick={navToHome}>Home</span> / <span style={{ color: '#E4510B' }} onClick={navToProduct}>Products</span></p>
                    </div>
                    {dataa1?.length > 0 &&
                        <>
                            <div className='d-block'>
                                {/* <p className='pt-3' style={{ cursor: 'pointer', color: '#333333', opacity: '0.8' }}><span onClick={navToHome}>Home</span> / <span style={{ color: '#E4510B' }} onClick={navToProduct}>Products</span></p> */}
                                <div className="mb-3">
                                    <TitleComponent title={`Offer Products`} isDesc={true} desc={`Explore a wide range of categories featuring top-quality products tailored to your needs!`} />
                                </div>
                            </div>
                            <div className=''>
                                <Row className="">
                                    {dataa1?.map((eachItem, i) => (
                                        <Col sm={12} md={6} lg={4} xl={3} key={i}>
                                            <ProductCard data={eachItem} />
                                        </Col>
                                    ))}
                                </Row>
                                <div className='d-flex justify-content-center align-items-center mt-3'>
                                    <button onClick={() => navigate('/offerProducts')} className='explore-btn'>Explore All <ArrowRight size={18} /></button>
                                </div>
                            </div>
                        </>
                    }
                    <div className='mt-5'>
                        {/* <p className='pt-3' style={{ cursor: 'pointer', color: '#333333', opacity: '0.8' }}><span onClick={navToHome}>Home</span> / <span style={{ color: '#E4510B' }} onClick={navToProduct}>Products</span></p> */}
                        <div className="mb-3">
                            <TitleComponent title={`All Products`} isDesc={true} desc={`Explore a wide range of categories featuring top-quality products tailored to your needs!`} />
                        </div>
                        <Row className="">
                            {dataa?.map((eachItem, i) => (
                                <Col sm={12} md={6} lg={4} xl={3} key={i}>
                                    <ProductCard data={eachItem} />
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            )}
            {/* <InteractiveHome /> */}
            <FooterSection />
        </>
    )
}

export default AllProduct;

import React, { useState } from 'react'
import { FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import cancelOrderImg from "../../../assets/images/banner/Cancel-order.png"
import "./index.css"
import axios from 'axios'
import { cancelOrder } from '../../../ApiConfigs/ApiConfig'
import toast from 'react-hot-toast'

function CancelModal({ openModal, handleClose, data, refetch, id }) {

    const [confirmOrder, setConfirmOrder] = useState(false)
    const [reason, setReason] = useState('')
    const [comments, setComments] = useState('')

    const toggleModal = () => {
        handleClose()
        setConfirmOrder(false)
    }

    const handleSubmit = async () => {
        const payload = {
            cancelReason: reason,
            description: comments
        }
        try {
            const response = await axios.post(`${cancelOrder}/${id}`, payload)
            refetch()
            handleClose()
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    }

    return (
        <div>
            <Modal isOpen={openModal} toggle={toggleModal} className="modal-dialog-centered modal-lg" style={{ border: 'none' }}>
                <ModalHeader toggle={toggleModal} className="">
                    Confirm Cancellation
                </ModalHeader>
                <ModalBody className='p-0 m-0'>
                    {!confirmOrder ? (
                        <div>
                            <div className='d-flex align-items-center'>
                                <div className='w-50 mx-auto'>
                                    <img alt='' src={cancelOrderImg} className='cancelOrderImg  mx-auto' />
                                </div>
                                <div className='w-50 p-4'>
                                    <p className='fw-bold'>Hey, Wait!!</p>
                                    <p>If you cancel now, you may not be able to avail this deal again. Do you still want to cancel?</p>
                                </div>
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center gap-4 w-100 px-4 pb-3'>
                                <button className='confirm-cancel-btn w-50'
                                    onClick={() => setConfirmOrder(true)}>Cancel Order</button>
                                <button className='order-change-btn w-50'
                                    onClick={toggleModal}>Dont't Cancel</button>
                            </div>
                        </div>
                    ) : (
                        <div className=''>
                            <FormGroup className='px-4 pt-4'>
                                <Label for="exampleSelect">
                                    Reason for Cancellation <span className='text-danger'>*</span>
                                </Label>
                                <Input
                                    id="exampleSelect"
                                    name="select"
                                    type="select"
                                    onChange={(e) => setReason(e.target.value)}
                                >
                                    <option>Select One</option>
                                    {data?.map((item) => (
                                        <option key={item?._id} value={item?._id}>{item?.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup className='px-4 '>
                                <Label for="exampleSelect">
                                    Comments <span className='text-danger'>*</span>
                                </Label>
                                <Input
                                    id="exampleText"
                                    name="text"
                                    type="textarea"
                                    onChange={(e) => setComments(e.target.value)}
                                />
                            </FormGroup>
                            <hr />
                            <div className='w-100 d-flex justify-content-end align-items-center pb-2 px-4'>
                                <button className={`confirm-cancel-btn !ms-auto`}
                                    disabled={reason === '' || comments === ''}
                                    onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </div>
    )
}

export default CancelModal

import React from 'react';
import { X } from 'react-feather';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import { toast } from 'react-hot-toast';
import { metaData } from '../../../../ApiConfigs/ApiConfig';


function MetaDetailsAdd({ open, handleAdd, offerData }) {


    const formSchema = yup.object().shape({
        title: yup
            .string()
            .required('Please Enter Your Meta Title'),
        description: yup.string().required('Please Enter Your Meta Description'),
        keywords: yup.string().required('Please Enter Your Meta Keywords'),
    });

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    // const selectedProductCategory = useWatch({
    //     control,
    //     name: 'productCategory',
    // });

    const handleReset = () => {
        reset({
            description: '',
            keywords: '',
            title: ''
        });
    };

    const submitForm = async (data) => {
        const payload = {
            ...data,
        };
        try {
            const response = await axios.post(metaData, payload);
            handleAdd();
            offerData();
            handleReset();
            toast.success(response?.data?.msg);
        } catch (error) {
            console.error("Error:", error);
            toast.error(
                error?.response?.data?.msg?.message ||
                error?.response?.data?.msg ||
                "An error occurred."
            );
        }
    };

    return (
        <div className="modal-page">
            <Modal
                isOpen={open}
                toggle={handleAdd}
                className="sidebar-lg"
                modalClassName="modal-slide-in sidebar-todo-modal"
                contentClassName="p-0"
            >
                <div
                    className="d-flex align-items-center justify-content-between mb-1 px-1"
                    style={{ backgroundColor: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottom: '1px solid #EEEEEE' }}
                >
                    <h4
                        className="modal-title"
                        style={{
                            marginTop: '23px',
                            marginBottom: '23px',
                            paddingLeft: '10px',
                            fontFamily: 'roboto',
                            color: 'black',
                        }}
                    >
                        Add Meta Details
                    </h4>
                    <X
                        style={{ color: 'black', cursor: 'pointer' }}
                        className="addmodal-close fw-normal mt-25 me-2"
                        size={25}
                        onClick={handleAdd}
                    />
                </div>
                <ModalBody className="flex-grow-1">
                    <Form onSubmit={handleSubmit(submitForm)}>
                        <Row>
                            <Col lg={12} sm={12}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Path</Label>
                                    <Controller
                                        name='path'
                                        id='path'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="text" {...field} invalid={errors.path && true} placeholder="Enter your Meta Title" />)} />
                                    {errors.path && <FormFeedback>{errors.path.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={12} sm={12}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Title</Label>
                                    <Controller
                                        name='title'
                                        id='title'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="text" {...field} invalid={errors.title && true} placeholder="Enter your Meta Title" />)} />
                                    {errors.title && <FormFeedback>{errors.title.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={12} sm={12}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Description</Label>
                                    <Controller
                                        name='description'
                                        id='description'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '70px' }} type="textarea" {...field} invalid={errors.description && true} placeholder="Enter your Meta Descritpion" />)} />
                                    {errors.description && <FormFeedback>{errors.description.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={12} sm={12}>
                                <div className="">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Keywords</Label>
                                    <div className=" w-100">
                                        <Controller
                                            name='keywords'
                                            id='keywords'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (<Input style={{ height: '70px' }} type="textarea" {...field} invalid={errors.keywords && true} placeholder="Enter your Meta Keywords" />)} />
                                        {errors.keywords && <FormFeedback>{errors.keywords.message}</FormFeedback>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className='d-bloick mt-3'>
                            <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                            <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}>Are you sure you want to Add Meta Details</p>
                        </div>
                        <hr />
                        <div className='d-flex'>
                            <button style={{ border: '1px solid #E4510B', color: '#E4510B', background: 'white', borderRadius: '10px' }} className='mt-1 w-50' type='button' onClick={handleAdd}>Cancel</button>
                            <button className='mt-1 w-50 ms-2 add-modal-btn' type='submit'>Add Meta Details</button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default MetaDetailsAdd;

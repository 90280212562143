import React, { useEffect, useState } from 'react'
import { ArrowLeft, ArrowRight, ChevronDown, ChevronLeft, X } from 'react-feather'
import './index.css'

function QuantitySizeStep(props) {
    const { toggleName, data, handleSideSwitch, selectedColorIndex, sizeQuantities, productSize, sizeQuantities1, quantities, handleManualQuantityChange, setActiveStepsTab, totalQuantity, manualQuantity } = props
    const [isAccordianActive, setIsAccordianActive] = useState(true)
    const [isErrorMsgs, setIsErrorMsg] = useState()

    const handleNextStep = () => {
        if (sizeQuantities.length > 0) {
            setActiveStepsTab('three')
        } else {
            if (manualQuantity > 0) {
                setActiveStepsTab('three')
            } else {
                setIsErrorMsg('Please Enter Quantity')
            }
        }
    }

    useEffect(() => {
        if (sizeQuantities1?.length > 0) {
            setIsErrorMsg('')
        }
    }, [sizeQuantities1])
    return (
        <div>
            <h1 className='tool-title'>QUANTITY & SIZES</h1>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center gap-2'>
                    <span onClick={() => setActiveStepsTab('one')} className='cursor-action'><ArrowLeft /></span>
                    <h1 className='tool-sub-title'>Choose Quantity & Sizes</h1>
                </div>
                <span onClick={() => setActiveStepsTab('one')} className='cursor-action'><X /></span>
            </div>
            <p className='tool-desc'>Enter quantities to calculate the price. Save money by increasing quantity and reducing ink colors in your design.</p>
            <div className='tool-bar-line mt-2'></div>
            <div className='mt-4'>
                <div className='d-flex justify-content-between align-items-center gap-2'>
                    <div className='d-flex gap-2'>
                        <div className='tool-product-img-con'>
                            <img className='img-fluid tool-product-img' src={data?.customizeImages?.[selectedColorIndex]?.images?.[0]?.image} alt='product-image' />
                        </div>
                        <div>
                            <h1 className='tool-product-name'>{data?.name}</h1>
                            <div className='d-flex align-items-center gap-4'>
                                <div className='d-flex align-items-center gap-1'>
                                    <div
                                        className={`${selectedColorIndex ? 'clr-con-active' : 'clr-con'}`}
                                    >
                                        <div
                                            style={{ backgroundColor: data?.customizeImages?.[selectedColorIndex]?.colourCode, width: '100%', height: '100%', borderRadius: '50%' }}></div>
                                    </div>
                                    <span>{data?.customizeImages?.[selectedColorIndex]?.colourCode}</span>
                                </div>
                                <div>|</div>
                                <div style={{ fontWeight: '600' }}>
                                    {sizeQuantities.length > 0 ? (`${totalQuantity} items`) : (`${manualQuantity} items`)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {isAccordianActive ? (
                            <span className='cursor-action' onClick={() => setIsAccordianActive(false)}><ChevronDown /></span>
                        ) : (
                            <span className='cursor-action' onClick={() => setIsAccordianActive(true)}><ChevronLeft /></span>
                        )}
                    </div>
                </div>
                <div className='tool-bar-line mt-3 mb-3'></div>
                {isAccordianActive && (
                    <div>
                        {data?.customizeImageSize?.length > 0 && (
                            <>
                                {sizeQuantities.length > 0 && (
                                    <p onClick={toggleName} className='upload-img-label text-decoration-underline'>Edit Names List</p>
                                )}
                                <div className='d-flex flex-wrap'>
                                    {productSize?.map((size) => (
                                        <>
                                            {sizeQuantities.length > 0 ? (
                                                <div className="d-block mt-2" key={size}>
                                                    <p
                                                        style={{ cursor: 'pointer' }}
                                                        className={`ms-3 me-3 size d-flex justify-content-center align-items-center ${true ? 'selected-size' : ''
                                                            }`}
                                                        onClick={toggleName}
                                                    >
                                                        {size}
                                                    </p>
                                                    <div onClick={toggleName} style={{ height: '100px' }}>
                                                        <input
                                                            type="text"
                                                            value={sizeQuantities.length > 0 ? (quantities[size] > 0 ? quantities[size] : '-') : quantities[size] || ''}
                                                            readOnly={sizeQuantities.length > 0}
                                                            // onChange={(e) => handleManualQuantityChange(size, e.target.value)}
                                                            style={{ width: '50px', height: '50px', marginLeft: '10px', borderRadius: '8px', textAlign: 'center' }}
                                                            placeholder="Qty"
                                                        />
                                                    </div>
                                                </div>
                                            ) : (

                                                <div className="d-block mt-2" key={size}>
                                                    <p
                                                        style={{ cursor: 'pointer' }}
                                                        className={`ms-3 me-3 size d-flex justify-content-center align-items-center ${true ? 'selected-size' : ''
                                                            }`}
                                                    // onClick={() => handleSizeClick1(size)}
                                                    >
                                                        {size}
                                                    </p>
                                                    <div style={{ height: '100px' }}>
                                                        <input
                                                            type="text"
                                                            value={!sizeQuantities.length > 0 ? (sizeQuantities1.find(item => item.size === size)?.quantity || '') : ''}
                                                            // readOnly={sizeQuantities.length > 0}
                                                            onChange={(e) => handleManualQuantityChange(size, e.target.value)}
                                                            style={{ width: '50px', height: '50px', marginLeft: '10px', borderRadius: '8px', textAlign: 'center' }}
                                                            placeholder="Qty"
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                        </>
                                    ))}
                                </div>
                                <div className='tool-bar-line mt-3 mb-3'></div>
                            </>
                        )}
                    </div>
                )}
                <div className='d-flex justify-content-center align-items-center mt-4'>
                    <div>
                        <button onClick={handleNextStep} className='next-step-btn-1'>CALCULATE PRICING <ArrowRight size={20} /></button>
                        {isErrorMsgs && (
                            <p className='text-danger mt-1'>*{isErrorMsgs}</p>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default QuantitySizeStep

import React, { useEffect, useState } from 'react'
import Navbar from '../navbar'
import FooterSection from '../footer'
import { topProductsAuth } from '../../ApiConfigs/ApiConfig';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Spinner } from 'reactstrap';
import TitleComponent from '../homeNew/TitleComponent';
import ProductCard from '../homeNew/ProductCard';

function TopProductsList() {
    const [loading, setLoading] = useState(false);
    const [dataa, setData] = useState([]);
    const navigate = useNavigate()

    const topProductData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(topProductsAuth);
            setData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        topProductData()
    }, [])

    const navToHome = () => {
        navigate('/')
    }
    return (
        <div>
            <Navbar />
            <div>
                {loading ? (
                    <div style={{ height: "80vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner
                            color="primary"

                        >
                            Loading...
                        </Spinner>
                    </div>
                ) : (
                    <div className='pt-3' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%' }}>
                        <p className='pt-3' style={{ cursor: 'pointer', color: '#333333', opacity: '0.8' }}><span onClick={navToHome}>Home</span> / <span style={{ color: '#E4510B' }}>Top Products</span></p>
                        <div className=''>
                            <div className="mb-3">
                                <TitleComponent title={`Top Products`} isDesc={true} desc={`Explore a wide range of categories featuring top-quality products tailored to your needs!`} />
                            </div>
                            <Row className="">
                                {dataa?.map((eachItem, i) => (
                                    <Col sm={12} md={6} lg={4} xl={3} key={i}>
                                        <ProductCard data={eachItem} />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                )}
            </div>
            <FooterSection />
        </div>
    )
}

export default TopProductsList

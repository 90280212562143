import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import "../index.css";
import { Button, FormFeedback, Form, Input, Label, Row, Col, Spinner, Card } from 'reactstrap';
import * as yup from "yup";
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlusCircle, Trash2, X } from 'react-feather';
import { productOG, productCategory, productApi, position, fieldOption, getFieldTypeOptions } from '../../../../ApiConfigs/ApiConfig';
import { multiImageUpload, uploadImage } from '../../upload/index';
// import RichTextEditor from 'react-rte';
import { fieldTypeApiNew } from '../../../../ApiConfigs/ApiConfig';
import Navbar from '../../../navbar';
import { useNavigate, useParams } from 'react-router-dom';
import { RiImageAddFill } from 'react-icons/ri';
import he from 'he';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { TagsInput } from 'react-tag-input-component';

function EditProductIndex({ faq }) {
    const navigate = useNavigate()
    const { id } = useParams()
    const [getEditData, setGetEditData] = useState()
    const [getData, setGetData] = useState([])
    const [editorValue, setEditorValue] = useState('');
    // const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue());
    // const [fieldTypeData, setFieldTypeData] = useState([])
    // const inputRef = useRef(null);
    // const [galleryImages, setGalleryImages] = useState([]);
    const imageRef = useRef(null);
    const imageRef1 = useRef(null);
    const imageRefs = useRef([]);
    const toolbarOptions = [['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']]
    const module = {
        toolbar: toolbarOptions,
    }
    // const [imageUpload, setImageUpload] = useState([])
    const [image, setImage] = useState(null);
    const [icon, setIcon] = useState(null);
    const [loads, setloads] = useState(false)
    const [loader, setLoader] = useState(false)
    const [getOptionsFieldType, setOptionsFieldType] = useState([])
    const [feildOption, setFieldOption] = useState([])
    const [position1, setPosition1] = useState([])
    const [fieldTypeIds, setFieldTypeIds] = useState([]);
    const [typeIds, setTypeIds] = useState([])
    // const [quantityBasedAmountFields, setQuantityBasedAmountFields] = useState([]);
    const [isKg, setIsKg] = useState('kg')
    const [showCustomUpload, setShowCustomUpload] = useState(false);
    // const [selectedValue, setSelectedValue] = useState(null);
    // const [selectedTypes, setSelectedTypes] = useState([]);

    const [loaders, setloaders] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [profileImages, setProfileImages] = useState([]);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [profileIconUrl, setProfileIconUrl] = useState('');
    const [profileImages, setProfileImages] = useState([]);
    // const [customImages, setCustomImages] = useState([]);


    // const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    // const [selectedCustomImageIndex, setSelectedCustomImageIndex] = useState(null);
    const inputRef1 = useRef(null);
    // const inputRef2 = useRef(null);
    // const [metaKeywords, setMetaKeywords] = useState([]);
    const [customizeImageSize, setCustomizeImageSize] = useState([]);

    // const handleMetaKeywordsChange = (keywords) => setMetaKeywords(keywords);
    const handleSizeChange = (ImageSize) => setCustomizeImageSize(ImageSize);


    const formSchema = yup.object().shape({
        // productName: yup.mixed().required('Please Enter Your productCategory '),
        name: yup.string().required('Please Enter Your productName').matches(/^[a-zA-Z0-9\s]+$/, 'Product Name should not contain special characters'),
        fullDescription: yup.string().required('Please Enter Your fullDescription'),
        metaTitle: yup
            .string()
            .required('Please Enter Your Meta Title'),
        // .matches(/^[a-zA-Z0-9\s|\\-]+$/, 'Meta Name should only contain letters, numbers, spaces, and the special characters "|", "-", and "\\"'),
        metaDescription: yup.string().required('Please Enter Your Meta Description'),
        // shortDescription: yup.string().required('Please Enter Your shortDescription'),
    })

    const {
        reset,
        control,
        handleSubmit,
        // getValues,
        setValue,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });


    const submitForm = async (data) => {
        delete data?.img_url
        delete data?.galleryImages

        // const customImage = {
        //     customizeImages: customImages?.map((el, i) => ({
        //         name: data?.customizeImages[i]?.name,
        //         image: customImages[i]?.image || '', 
        //     }))
        // }

        // const customImage = {
        //     customizeImages: colorProducts.map(colorProduct => ({
        //         colourCode: colorProduct.colourCode,
        //         images: colorProduct.images.map(imageData => ({
        //             name: imageData.name,
        //             image: imageData.image
        //         }))
        //     }))
        // };
        const customImage = {
            customizeImages: colorProducts.map(colorProduct => ({
                colourCode: colorProduct.colourCode,
                images: colorProduct.images.map(imageData => ({
                    name: imageData?.name,
                    image: imageData?.image
                }))
            }))
        };

        const quantityBasedAmount = {
            quantityBasedAmount: data?.quantityBasedAmount?.map(quantityAmount => ({
                from: parseFloat(quantityAmount?.from),
                to: parseFloat(quantityAmount?.to),
                amount: parseFloat(quantityAmount?.amount),
                type: quantityAmount?.type?.value
            }))
        }

        const fieldType = {
            fieldType: data?.fieldType?.map(type => ({
                name: type?.name?.value, // Access the value of the name
                options: type?.options?.map(option => option?.value) // Directly return the value of the option
            }))
        };


        const payload = {
            ...data,
            amount: parseFloat(data.amount),
            maximunQuantity: parseFloat(data.maximunQuantity),
            minimunQuantity: parseFloat(data.minimunQuantity),
            productWeight: parseFloat(data.productWeight),
            noShipChargeAbove: parseFloat(data.noShipChargeAbove),
            // productWeight: parseFloat(data?.productWeight),
            galleryImages: profileImages,
            fieldType: fieldType?.fieldType,
            customizeImages: data?.customizeProduct ? customImage.customizeImages : [],
            image: profileImageUrl,
            icon: profileIconUrl,
            metaKeywords: data?.metaKeywords,
            customizeImageSize: customizeImageSize,
            weightType: isKg,
            productCategory: data?.productCategory?.value,
            quantityType: data?.quantityType?.value,
            quantityBasedAmount: quantityBasedAmount?.quantityBasedAmount
        };

        try {
            const response = await axios.put(`${productApi}/${getEditData._id}`, payload)
            navigate('/admin/product')
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg)
        }

    }

    // const removeImage = () => {
    //     setImage(null);
    // };
    const handleImageUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setLoader(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileImageUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setLoader(false)
            }
        }
    };

    const handleIconUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setIcon(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setloads(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileIconUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setloads(false)
            }
        }
    };




    const handleRemoveImage = (index) => {
        const updatedImages = [...profileImages];
        updatedImages.splice(index, 1);
        setProfileImages(updatedImages);
        // setSelectedImageIndex(null);
    };

    // const handleRemoveCustomImage = (index) => {
    //     const updatedImages = [...customImages];
    //     updatedImages.splice(index, 1);
    //     setCustomImages(updatedImages);
    //     setSelectedCustomImageIndex(null);
    // };

    const handleProfileUpload = async (e) => {
        if (e.target.files) {
            try {
                setLoading(true);
                const formData = new FormData();

                for (let i = 0; i < e.target.files.length; i++) {
                    formData.append('files', e.target.files[i]);
                }

                const uploadData = await multiImageUpload(formData);

                if (uploadData && uploadData.result && uploadData.result.length) {
                    const uploadedImages = uploadData.result.map(file => file.location);

                    setProfileImages(prevImages => [...prevImages, ...uploadedImages]);
                }
            } catch (error) {
                toast.error('Something went wrong...');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleCustomImageUpload = async (e, colorIndex, imageIndex) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const localImageUrl = URL.createObjectURL(file); // Create a local URL for the image

            try {
                setloaders(true);
                const formData = new FormData();
                formData.append('file', file);

                // Perform the image upload to your server
                const uploadData = await uploadImage(formData);

                if (uploadData && uploadData.result && uploadData.result.length) {
                    const uploadedImageUrl = uploadData.result[0].location;

                    // Set both the local image URL and the uploaded image URL
                    const updatedColorProducts = [...colorProducts];
                    updatedColorProducts[colorIndex].images[imageIndex] = {
                        name: updatedColorProducts[colorIndex].images[imageIndex]?.name || '',
                        image: uploadedImageUrl,
                        localImageUrl, // Use localImageUrl to preview before upload completes
                    };

                    setColorProducts(updatedColorProducts);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setloaders(false);
            }
        }
    };

    const options = getData?.map((item) =>
    (
        {
            label: item?.name,
            value: item?._id
        }))


    const getProduct = async () => {
        try {
            const response = await axios.get(productCategory)
            setGetData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    const getFieldType = async (id) => {
        try {
            await axios.get(fieldTypeApiNew)
            // setFieldTypeData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    const getFieldType1 = useCallback(async (id, ind) => {
        try {
            let payload = [];
            if (id) {
                // Use forEach here, since you're not using the return value of map
                typeIds.forEach((val) => {
                    if (val.toString() !== id.toString()) {
                        payload.push(val);
                    }
                });
            } else {
                payload = typeIds;
            }
            const response = await axios.post(`${getFieldTypeOptions}`, { fieldType: payload });
            const optionsFieldType = response?.data?.result?.map((item) => ({
                label: item?.name,
                value: item?._id,
            }));
            setOptionsFieldType(optionsFieldType);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    }, [typeIds]); // Add dependencies that affect the function

    useEffect(() => {
        getFieldType1();
    }, [getFieldType1, typeIds]);

    const editData = useCallback(async () => {
        try {
            const response = await axios.get(`${productOG}/${id}`);
            setGetEditData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    }, [id]);

    useEffect(() => {
        getProduct();
        editData();
        // getFieldType();
    }, [editData]);

    // const handleRadioChange = (value) => {
    //     setSelectedValue(value);
    // };

    const options1 = [
        {
            label: "Inclusive",
            value: "inclusive"
        },
        {
            label: "Exclusive",
            value: "exclusive"
        }
    ];

    useEffect(() => {
        if (getEditData) {
            // Map and format field types and their options
            // const formattedFieldTypes = getEditData?.fieldType?.map(field => ({
            //     name: { label: field.name.name, value: field.name._id }, // Set name with label and value
            //     options: field.options?.map(option => ({ label: option.name, value: option._id })) || [], // Map options to label and value
            // })) || [];

            const formattedFieldTypes = getEditData?.fieldType?.map((field) => {
                // Ensure each field has a valid name and options; otherwise, provide defaults
                const fieldName = field.name
                    ? { label: field.name.name, value: field.name._id }
                    : { label: '', value: '' }; // Default name

                const fieldOptions = field.options?.map((option) => ({
                    label: option.name,
                    value: option._id,
                })) || []; // Default options as an empty array

                return { name: fieldName, options: fieldOptions };
            }) || [];

            const formattedQuantityBasedAmount = getEditData?.quantityBasedAmount?.map(amountData => ({
                from: amountData.from || '', // Ensure 'from' is populated or empty
                to: amountData.to || '', // Ensure 'to' is populated or empty
                amount: amountData.amount || '', // Ensure 'amount' is populated or empty
                type: { label: amountData.type, value: amountData.type }, // Map type as a Select option
            })) || [];

            const decodedFullDescription = he.decode(getEditData?.fullDescription || '');


            const isCustomizeProductTrue = getEditData?.customizeProduct?.toString() === 'true';
            setShowCustomUpload(isCustomizeProductTrue);

            reset({
                name: getEditData?.name,
                fieldType: formattedFieldTypes,
                quantityBasedAmount: formattedQuantityBasedAmount,
                amount: getEditData?.amount,
                quantityType: { label: getEditData?.quantityType, value: getEditData?.quantityType },
                minimunQuantity: getEditData?.minimunQuantity,
                maximunQuantity: getEditData?.maximunQuantity,
                upload: getEditData?.upload || false, // Ensure boolean is converted to string
                customizeProduct: getEditData?.customizeProduct || false,
                productWeight: getEditData?.productWeight,
                noShipChargeAbove: getEditData?.noShipChargeAbove,
                productCategory: { label: getEditData?.productCategory?.name, value: getEditData?.productCategory?._id },
                fullDescription: getEditData?.fullDescription,
                shortDescription: getEditData?.shortDescription,
                // product_url: getEditData?.product_url,
                gstType: getEditData?.gstType,
                length: getEditData?.length,
                width: getEditData?.width,
                height: getEditData?.height,
                gstPercentage: getEditData?.gstPercentage,
                // hsnCode: getEditData?.hsnCode,
                productOverview: getEditData?.productOverview?.map(item => ({ description: item.description })) || [],
                description: getEditData?.description?.map(item => ({ description: item.description })) || [],
                metaTitle: getEditData?.metaTitle,
                metaKeywords: getEditData?.metaKeywords,
                metaDescription: getEditData?.metaDescription,
            });

            // // Set other states outside of form data
            // if (getEditData?.fullDescription) {
            //     const inputValue = getEditData?.fullDescription;
            //     let editorContent;

            //     if (isHtml(inputValue)) {
            //         const htmlContent = inputValue;
            //         const decodedHtml = htmlContent.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
            //         const plainText = htmlToText(decodedHtml);
            //         editorContent = plainText;
            //     } else {
            //         // If input is plain text, use it directly
            //         editorContent = inputValue;
            //     }

            //     setEditorValue(RichTextEditor.createValueFromString(editorContent, 'html')); // Set content in editor
            // }
            // setEditorValue(RichTextEditor.createValueFromString(getEditData?.fullDescription, 'markdown'));
            setProfileImages(getEditData?.galleryImages || []);
            setEditorValue(decodedFullDescription);
            // setQuantityBasedAmountFields(getEditData?.quantityBasedAmount || []);
            // setCustomImages(getEditData?.customizeImages || []);
            setIsKg(getEditData?.weightType || []);
            // setImageUpload(getEditData?.image);
            setProfileImageUrl(getEditData?.image);
            setProfileIconUrl(getEditData?.icon);
            setImage(getEditData?.image);
            setIcon(getEditData?.icon);
            // setMetaKeywords(getEditData?.metaKeywords);
            setCustomizeImageSize(getEditData?.customizeImageSize);

            // Format and set custom image products
            const formattedColorProducts = getEditData?.customizeImages?.map(item => ({
                colourCode: item.colourCode || '',
                images: item.images?.map(image => ({
                    // name: image.name?.name || '',
                    name: image.name?._id || '',
                    image: image.image || ''
                })) || []
            })) || [];

            setColorProducts(formattedColorProducts);
        }
    }, [getEditData, reset, setValue, setProfileImages, setIsKg, setProfileImageUrl, setProfileIconUrl, setImage, setIcon, setCustomizeImageSize]);

    // const isHtml = (str) => {
    //     const pattern = /<\/?[a-z][\s\S]*>/i; // Regex to detect HTML tags
    //     return pattern.test(str);
    // };

    const { fields: fieldTypesFields, append: appendFieldType, remove: removeFieldType } = useFieldArray({
        control,
        name: "fieldType",
    });

    useEffect(() => {
        async function fetchCustomSide() {
            try {
                const response = await axios.get(position);
                setPosition1(response?.data?.result)
                // const stateOptions = response.data.result.map((state) => ({
                //     label: state.state,
                //     value: state.state,
                // }));
                // setStates(stateOptions);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        }
        fetchCustomSide();
    }, []);

    const { fields: quantityBasedAmountFields, append: appendQuantityBasedAmount, remove: removeQuantityBasedAmount } = useFieldArray({
        control,
        name: "quantityBasedAmount",
    });

    const quantityBasedAmountType = [
        {
            name: "Recommended",
            value: "recemended"
        },
        {
            name: "General",
            value: "general"
        },
        {
            name: "BestPrice",
            value: "bestprice"
        },
        {
            name: "BulckPrice",
            value: "bulckprice"
        },

    ]

    const quantityBasedType = quantityBasedAmountType?.map((item) => (
        {
            label: item?.name,
            value: item?.value
        }
    ))

    const getFieldOption = async (id) => {
        try {
            const response = await axios.get(`${fieldOption}?fieldType=${id}`)
            setFieldOption(response?.data?.result)
        } catch (error) {

        }
    }

    const fieldoption = feildOption?.map((item) => (
        {
            label: item?.name,
            value: item?._id
        }
    ))

    const getDatas = async (id, index) => {

        if (!fieldTypeIds.length) {
            setFieldTypeIds([{ index: index, id }]);
        } else if (fieldTypeIds[index]?.index === index) {
            setFieldTypeIds(prevState => {
                const updatedState = [...prevState];
                updatedState[index] = { index: index, id };
                return updatedState;
            });
        } else {
            fieldTypeIds.push({ index, id })
            // /(prevState => [...prevState, index, id]);
        }
    };

    // useEffect(() => {
    //     if (getEditData?.fieldType) {
    //         const formattedFieldTypes = getEditData.fieldType.map(field => ({
    //             name: {
    //                 value: field.name._id,
    //                 label: field.name.name,
    //             },
    //             options: field.options.map(option => ({
    //                 value: option._id,
    //                 label: option.name,
    //             }))
    //         }));

    //         reset({
    //             fieldType: formattedFieldTypes // Set the fieldType in the form
    //         });
    //     }
    // }, [getEditData, reset]);


    const quantityTypeData = [
        {
            name: "dropdown",
            value: "dropdown"
        },
        {
            name: "manual",
            value: "manual"
        }
    ]

    const quantityTypeDrop = quantityTypeData?.map((item) => (
        {
            label: item?.name,
            value: item?.name
        }
    ))

    const customSide = position1?.map((item) =>
    (
        {
            label: item?.name,
            value: item?._id
        }))

    const handleTypeChange = (selectedOption, index) => {
        setValue(`fieldType[${index}].options`, []);
        getFieldOption(selectedOption?.value);
        getDatas(selectedOption?.value, index);
    };


    const [colorProducts, setColorProducts] = useState([
        {
            colourCode: '',
            images: [{ name: '', image: '' }]
        }
    ]);


    const navToProductView = () => {
        if (getEditData?.product_url) {
            navigate(`/${getEditData.product_url}`);
        } else {
            console.error("Product URL is not defined.");
        }
    };

    const getUsedOptions = (colorIndex) => {
        const used = colorProducts[colorIndex].images.map((img) => img.name).filter(Boolean); // Get all selected names for the current color
        return used;
    };

    const filterOptions = (colorIndex, imageIndex) => {
        const usedOptions = getUsedOptions(colorIndex);
        return customSide.filter((option) => !usedOptions.includes(option.value)); // Exclude already selected options
    };
    // const filterOptions = (colorIndex, imageIndex) => {
    //     const usedOptions = getUsedOptions(colorIndex);
    //     return customSide.filter(
    //         (option) => !usedOptions.includes(option.value) || option.value === colorProducts[colorIndex].images[imageIndex].name
    //     );
    // };


    return (
        <>
            <Navbar />
            <div className='pt-5 pb-5' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%' }}>
                <div className='d-flex fw-medium' style={{ color: '#E4510B' }} ><p style={{ cursor: 'pointer', color: '#333333' }} onClick={() => navigate('/admin/product')}>Product  {`>`}</p> Edit Product</div>
                <h1 className='mb-5'>Edit Product</h1>
                {/* <div className='d-flex' style={{ cursor: 'pointer' }} onClick={() => navigate('/admin/product')}><ArrowLeft /><p>Back ProductPage</p> </div>
                <h1 className='text-center pb-5'>Edit Product</h1> */}
                <Form onSubmit={handleSubmit(submitForm)}>
                    <Card className='p-4'>
                        <h1 className='fs-3 mb-4'>Product Details</h1>
                        <Row >
                            {/* <Col sm={12} md={6} lg={6}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Choose Category</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='productCategory'
                                        id='productCategory'
                                        control={control}
                                        defaultValue={{ label: getEditData?.productCategory?.name, value: getEditData?.productCategory?._id }} // Set default correctly
                                        render={({ field }) => (
                                            <Select
                                                isSearchable={false}
                                                {...field}
                                                options={options} // Make sure this contains the necessary category options
                                                invalid={errors.productCategory && true}
                                            />
                                        )}
                                    />
                                    {errors.productCategory && <FormFeedback>{errors.productCategory.message}</FormFeedback>}
                                </div>
                            </Col> */}
                            <Col sm={12} md={6} lg={6}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Choose Category</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='productCategory'
                                        id='productCategory'
                                        control={control}
                                        defaultValue={{ label: getEditData?.productCategory?.name, value: getEditData?.productCategory?._id }}
                                        render={({ field }) => (
                                            <Select
                                                isSearchable={true}
                                                {...field}
                                                options={options}
                                                menuPortalTarget={document.body} // Render dropdown outside its container
                                                styles={{
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure dropdown appears above other elements
                                                    control: (base, state) => ({
                                                        ...base,
                                                        borderColor: errors.productCategory ? 'red' : base.borderColor,
                                                    }),
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.productCategory && (
                                        <FormFeedback style={{ display: 'block' }}>
                                            {errors.productCategory.message}
                                        </FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>New Product Name</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='name'
                                        id='name'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="text" {...field}
                                            invalid={errors.name && true}
                                            placeholder="Enter new product name" />)}
                                        onInput={(e) => {
                                            const inputValue = e.target.value;
                                            if (inputValue.length === 1) {
                                                e.target.value = inputValue.replace(/[^A-Za-z]/g, '');
                                            } else {
                                                e.target.value = inputValue.replace(/[^A-Za-z0-9]/g, '');
                                            }
                                        }}
                                    />
                                    {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                                </div>
                            </Col>
                            {/* <Col sm={12} md={12} lg={12}>
                                <div className='mb-3 s'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Full Description</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='fullDescription'
                                        id='fullDescription'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input type="textarea" rows={5} cols={60} {...field} invalid={errors.fullDescription && true} placeholder="Enter Full Description" />)}
                                    />
                                    {errors.fullDescription && <FormFeedback>{errors.fullDescription.message}</FormFeedback>}
                                </div>
                            </Col> */}
                            <Col sm={12} md={12} lg={12}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Full Description</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='fullDescription'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <div>
                                                <ReactQuill
                                                    modules={module}
                                                    theme="snow"
                                                    value={editorValue}
                                                    onChange={(value) => {
                                                        setEditorValue(value);
                                                        field.onChange(value); // Pass updated value to react-hook-form
                                                    }}
                                                    placeholder="Enter Full Description"
                                                    style={{ minHeight: '200px' }}
                                                />
                                                {errors.fullDescription && (
                                                    <FormFeedback style={{ display: 'block' }}>
                                                        {errors.fullDescription.message}
                                                    </FormFeedback>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <div className='mb-3'>
                                    <Controller
                                        name='description'
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <div>
                                                <Row>
                                                    {field.value.map((item, index) => (
                                                        <Col key={index} sm={12} md={6} lg={4}>
                                                            <div className='mb-3 d-flex flex-column'>
                                                                <Label style={{ fontWeight: "500", color: '#262525' }}>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <div>Description {index + 1}</div>
                                                                        <div className='justify-content-end ms-2 mt-1'
                                                                            color='danger'
                                                                            size='sm'
                                                                            style={{ cursor: 'pointer', color: '#AF1B1B' }}
                                                                            onClick={() => {
                                                                                const updatedValues = [...field.value];
                                                                                updatedValues.splice(index, 1);
                                                                                field.onChange(updatedValues);
                                                                            }}
                                                                        >
                                                                            <Trash2 />
                                                                        </div>
                                                                    </div>
                                                                </Label>
                                                                <Input
                                                                    type="textarea" rows={5} cols={60}
                                                                    name={`description[${index}].description`}
                                                                    value={field.value[index]?.description || ''}
                                                                    invalid={errors.description && errors.description[index] && errors.description[index].description && true}
                                                                    placeholder={`Enter product description`}
                                                                    onChange={(e) => {
                                                                        const updatedValues = [...field.value];
                                                                        updatedValues[index] = { description: e.target.value };
                                                                        field.onChange(updatedValues);
                                                                    }}
                                                                />
                                                                {errors.description && errors.description[index] && errors.description[index].description && (
                                                                    <FormFeedback>{errors.description[index].description.message}</FormFeedback>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                                <Button
                                                    className='overview-btn'
                                                    size='sm'
                                                    onClick={() => {
                                                        field.onChange([...field.value, { description: '' }]);
                                                    }}
                                                >
                                                    <PlusCircle /> Add Description
                                                </Button>
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Product Overview</h1>
                        <Row >
                            <Col sm={12} md={12} lg={12}>
                                <div className='mb-3'>
                                    <Controller
                                        name='productOverview'
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <div>
                                                <Row>
                                                    {field.value.map((item, index) => (
                                                        <Col key={index} sm={12} md={6} lg={4}>
                                                            <div className='mb-3 d-flex flex-column'>
                                                                <Label style={{ fontWeight: "500", color: '#262525' }}>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <div>Product Overview {index + 1}</div>
                                                                        <div className='justify-content-end ms-2 mt-1'
                                                                            color='danger'
                                                                            size='sm'
                                                                            style={{ cursor: 'pointer', color: '#AF1B1B' }}
                                                                            onClick={() => {
                                                                                const updatedValues = [...field.value];
                                                                                updatedValues.splice(index, 1);
                                                                                field.onChange(updatedValues);
                                                                            }}
                                                                        >
                                                                            <Trash2 />
                                                                        </div>
                                                                    </div>
                                                                </Label>
                                                                <Input
                                                                    type="textarea" rows={5} cols={60}
                                                                    name={`productOverview[${index}].description`}
                                                                    value={field.value[index]?.description || ''}
                                                                    invalid={errors.productOverview && errors.productOverview[index] && errors.productOverview[index].description && true}
                                                                    placeholder={`Enter product overview`}
                                                                    onChange={(e) => {
                                                                        const updatedValues = [...field.value];
                                                                        updatedValues[index] = { description: e.target.value };
                                                                        field.onChange(updatedValues);
                                                                    }}
                                                                />
                                                                {errors.productOverview && errors.productOverview[index] && errors.productOverview[index].description && (
                                                                    <FormFeedback>{errors.productOverview[index].description.message}</FormFeedback>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                                <Button
                                                    className='overview-btn'
                                                    size='sm'
                                                    onClick={() => {
                                                        field.onChange([...field.value, { description: '' }]);
                                                    }}
                                                >
                                                    <PlusCircle /> Add Overview
                                                </Button>
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className='mt-2 mb-2' style={{ width: '100%', height: '1px', backgroundColor: '#DFDFDF' }}></div>
                        <h1 className='fs-3 mb-4 mt-4'>Upload Images</h1>
                        <Row>

                            <Col sm={12} md={6} lg={4}>
                                <div className="mb-3">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Product Icon</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='icon'
                                        id='icon'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="icon"
                                                    type="file"
                                                    {...field}
                                                    accept=".jpeg,.png,.jpg,.jfif"
                                                    invalid={errors.icon && true}
                                                    ref={imageRef1}
                                                    onChange={handleIconUpload}
                                                />
                                                {loads ? (
                                                    <Spinner color='primary' />
                                                ) : (
                                                    <Card style={{ width: "100%", height: "160px", justifyContent: "center", alignItems: "center", border: '1px dashed #E4510B' }} onClick={() => imageRef1?.current?.click()}>
                                                        {icon ? (
                                                            <>
                                                                {/* <img
                                                                    alt="uploaded icon"

                                                                    src={icon}
                                                                    style={{ width: '140px', objectFit: 'cover' }}
                                                                />
                                                                <div
                                                                    className='image-remove'
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '5px',
                                                                        right: '1px',
                                                                        cursor: 'pointer',
                                                                        color: 'red',
                                                                    }}
                                                                    onClick={() => setIcon(null)}                                                                    >
                                                                    <X />
                                                                </div> */}
                                                                <div className="m-2" style={{ position: 'relative' }}>
                                                                    <img
                                                                        alt="uploaded"
                                                                        src={icon}
                                                                        style={{
                                                                            width: '100px',
                                                                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                    <div
                                                                        className='image-remove'
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '5px',
                                                                            right: '1px',
                                                                            cursor: 'pointer',
                                                                            color: 'red',
                                                                        }}
                                                                        onClick={() => setIcon(null)}                                                                >
                                                                        <X />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className='text-primary text-center'>
                                                                <h1 style={{ color: "primary" }}><RiImageAddFill /></h1>
                                                                <p>*Upload your product icon</p>
                                                            </div>
                                                        )}
                                                    </Card>

                                                )}
                                                {errors.image && <FormFeedback>{errors.image.message}</FormFeedback>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <div className="mb-3">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Product Image</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='image'
                                        id='image'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="image"
                                                    type="file"
                                                    {...field}
                                                    accept=".jpeg,.png,.jpg,.jfif"
                                                    invalid={errors.image && true}
                                                    ref={imageRef}
                                                    onChange={handleImageUpload}
                                                />
                                                {loader ? (
                                                    <Spinner color='primary' />
                                                ) : (
                                                    <Card style={{ width: "100%", height: "160px", border: '1px dashed #E4510B', justifyContent: "center", alignItems: "center" }} onClick={() => imageRef?.current?.click()}>
                                                        {image ? (
                                                            <>
                                                                {/* <img
                                                                    alt="uploaded"

                                                                    src={image}
                                                                    style={{ width: '140px', objectFit: 'cover' }}
                                                                />
                                                                <div
                                                                    className='image-remove'
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '5px',
                                                                        right: '1px',
                                                                        cursor: 'pointer',
                                                                        color: 'red',
                                                                    }}
                                                                    onClick={() => setImage(null)}                                                                    >
                                                                    <X />
                                                                </div> */}
                                                                <div className="m-2" style={{ position: 'relative' }}>
                                                                    <img
                                                                        alt="uploaded"
                                                                        src={image}
                                                                        style={{
                                                                            width: '100px',
                                                                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                    <div
                                                                        className='image-remove'
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '5px',
                                                                            right: '1px',
                                                                            cursor: 'pointer',
                                                                            color: 'red',
                                                                        }}
                                                                        onClick={() => setImage(null)}                                                                >
                                                                        <X />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className='text-primary text-center'>
                                                                <h1 style={{ color: "primary" }}><RiImageAddFill /></h1>
                                                                <p>*Upload your Cover Images</p>
                                                            </div>
                                                        )}
                                                    </Card>

                                                )}
                                                {errors.image && <FormFeedback>{errors.image.message}</FormFeedback>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={4} >
                                <Label style={{ fontWeight: "500", color: '#262525' }}>
                                    <div className='d-flex'>
                                        <div>Product Gallery Image</div>
                                    </div>
                                </Label>
                                <Card className='p-3' style={{ display: 'flex', border: '1px dashed #E4510B', width: "100%", justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="">
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {profileImages.map((image, index) => (
                                                <div key={index} className="m-2" style={{ position: 'relative' }}>
                                                    <img
                                                        alt={`preview ${index + 1}`}
                                                        src={image}
                                                        style={{
                                                            width: '100px',
                                                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                            cursor: 'pointer',
                                                        }}
                                                    // onClick={() => setSelectedImageIndex(index)}
                                                    />
                                                    <div className="cursor-pointer image-close" style={{ position: 'absolute', top: '1px', right: '1px', cursor: 'pointer' }} onClick={() => handleRemoveImage(index)}>
                                                        <X style={{ width: '16px', height: '16px' }} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                                            <input style={{ display: 'none' }} type="file" ref={inputRef1} accept=".jpeg,.png,.jpg,.jfif" onChange={handleProfileUpload} multiple />
                                            {loading ? (
                                                <Spinner />
                                            ) : (
                                                <div className='text-center text-primary' onClick={() => inputRef1?.current?.click()}>
                                                    {profileImages.length === 0 ? (
                                                        <>
                                                            <h1> <RiImageAddFill /></h1>
                                                            <p>*Upload your Gallery Images</p>
                                                            <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h1> <RiImageAddFill /></h1>
                                                            <p>*Add more images</p>
                                                            <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card>
                                {/* <Card className='p-3 mt-1 mt-md-3' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "400px" }}>
                                <div className="">
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {profileImages?.map((image, index) => (
                                            <div key={index} className="m-2">
                                                <div className='d-flex'>
                                                    <img
                                                        alt={`preview image ${index + 1}`}
                                                        src={image}
                                                        style={{
                                                            width: '100px',
                                                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => setSelectedImageIndex(index)}
                                                    />
                                                    <div className="cursor-pointer image-close" style={{ cursor: 'pointer' }} onClick={() => handleRemoveImage(index)}>
                                                        <X />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                                        <input style={{ display: 'none' }} type="file" ref={inputRef1} accept=".jpeg,.png,.jpg" onChange={handleProfileUpload} multiple />
                                        {loading ? (
                                            <Spinner />
                                        ) : (
                                            <div className='text-center text-primary' onClick={() => inputRef1?.current?.click()}>

                                                {profileImages.length === 0 ? (
                                                    <>
                                                        <h1> <RiImageAddFill /></h1>
                                                        <p>*Upload your Gallery Images</p>
                                                        <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h1> <RiImageAddFill /></h1>
                                                        <p>*Add more images</p>
                                                        <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>

                                                    </>
                                                )}

                                            </div>
                                        )}
                                    </div>

                                </div>
                            </Card> */}
                            </Col>
                        </Row>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>GST Details</h1>
                        <Row >
                            <Col sm={12} md={6} lg={4}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>GSTIN Type</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='gstType'
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                isSearchable={false}
                                                value={options1.find(option => option.value === value) || null} // Map selected value
                                                onChange={(selectedOption) => onChange(selectedOption.value)} // Ensure correct value is set
                                                options={options1}
                                                isInvalid={errors.gstType} // Adjust for consistent error handling
                                            />
                                        )}
                                    />
                                    {errors.gstType && <FormFeedback>{errors.gstType.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <div className="mb-3 ms-2">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>GSTIN Percentage</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='gstPercentage'
                                        id='gstPercentage'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input style={{ height: '44px' }} type="number"  {...field} invalid={errors.gstPercentage && true} placeholder="Enter Your GST Percentage" />
                                        )} />
                                    {errors.gstPercentage && (
                                        <FormFeedback>{errors.gstPercentage.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            {/* <Col sm={12} md={6} lg={4}>
                                <div className="mb-3">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>HSN Code</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='hsnCode'
                                        id='hsnCode'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input style={{ height: '44px' }} type="number"  {...field} invalid={errors.hsnCode && true} placeholder="Enter Your HSN code" />
                                        )} />
                                    {errors.hsnCode && (
                                        <FormFeedback>{errors.hsnCode.message}</FormFeedback>
                                    )}
                                </div>
                            </Col> */}
                        </Row>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Product Fields</h1>
                        {fieldTypesFields.map((field, index) => (
                            <div key={field.id}>
                                <Row>
                                    <Col sm={12} md={5}>
                                        <div className='mb-3'>
                                            <Label style={{ fontWeight: "500", color: '#262525' }}>Type</Label>
                                            <Controller
                                                name={`fieldType[${index}].name`}
                                                id='options'
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        isSearchable={false}
                                                        id='fieldType'
                                                        options={getOptionsFieldType}
                                                        value={field.value}
                                                        onChange={(selectedOption) => {
                                                            getFieldOption(selectedOption.value);
                                                            field.onChange(selectedOption);
                                                            getDatas(selectedOption.value, index)
                                                            handleTypeChange(selectedOption, index)
                                                        }}
                                                    />
                                                )} />

                                        </div>
                                    </Col>
                                    <Col sm={5} md={5}>
                                        <div className='mb-3'>
                                            <Label style={{ fontWeight: "500", color: '#262525' }}>Options</Label>
                                            <Controller
                                                name={`fieldType[${index}].options`}
                                                id='options'
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field }) => (
                                                    <Select
                                                        styles={{ width: '100%', height: '100%' }}
                                                        {...field}
                                                        options={fieldoption}
                                                        isMulti={true}
                                                        closeMenuOnSelect={false}
                                                        isSearchable={false}
                                                        invalid={errors.fieldType && true}
                                                    />
                                                )}
                                            />
                                            {errors.fieldType && errors.fieldType[index]?.options && (
                                                <FormFeedback>{errors.fieldType[index]?.options.message}</FormFeedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col sm={2} md={2}>
                                        <div className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3' style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={(e) => {
                                            getFieldType(typeIds[index], index);
                                            removeFieldType(index)
                                        }}>
                                            <Trash2 />
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        ))}
                        <div className='d-flex'>
                            <Button type="button" className='overview-btn' onClick={() => appendFieldType({ name: '', options: [] })}>
                                <PlusCircle /> Add Field
                            </Button>
                        </div>
                        <div className='mt-2 mb-2 mt-4' style={{ width: '100%', height: '1px', backgroundColor: '#DFDFDF' }}></div>
                        <h1 className='fs-3 mb-4 mt-4'>Product Specifications</h1>
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Product Basic Amount</Label>
                                    <Controller
                                        name='amount'
                                        id='amount'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="number" {...field} invalid={errors.amount && true} placeholder="Enter your Product Basic Amount" />)} />
                                    {errors.amount && <FormFeedback>{errors.amount.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Quantity Type</Label>
                                    <Controller
                                        name='quantityType'
                                        id='quantityType'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                isSearchable={false}
                                                type="select"
                                                {...field}
                                                options={quantityTypeDrop}
                                                invalid={errors.quantityType && true} >
                                            </Select>
                                        )} />
                                    {errors.quantityType && <FormFeedback>{errors.quantityType.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={3} >
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Minimum Quantity</Label>
                                    <Controller
                                        name='minimunQuantity'
                                        id='minimunQuantity'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="number"  {...field} invalid={errors.minimunQuantity && true} placeholder="Enter Minimun Quantity" />)} />
                                    {errors.minimunQuantity && <FormFeedback>{errors.minimunQuantity.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={3} >
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Maximum Quantity</Label>
                                    <Controller
                                        name='maximunQuantity'
                                        id='maximumQuantity'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="number" {...field} invalid={errors.maximunQuantity && true} placeholder="Enter Maximun Quantity" />)} />
                                    {errors.maximunQuantity && <FormFeedback>{errors.maximunQuantity.message}</FormFeedback>}
                                </div>
                            </Col>

                            <Col sm={12} md={12} lg={6}>
                                <div className="col-span-12 md:col-span-12 lg:col-span-6 intro-y">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Size</Label>
                                    <div className="mt-1">
                                        <TagsInput
                                            placeHolder="Enter Product size"
                                            onChange={handleSizeChange}
                                            value={customizeImageSize}
                                        />
                                        <span>Press enter the size of the product</span>
                                        {errors.customizeImageSize && (
                                            <div className="mt-2 text-danger">
                                                {errors.customizeImageSize.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4} md={4} lg={6}>
                                <div className="mb-1">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Product Weight</div>
                                        </div>
                                    </Label>
                                    <div className='d-flex'>
                                        <div>
                                            <Controller
                                                name='productWeight'
                                                id='productWeight'
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <Input style={{ height: '44px', width: '300px' }} type="number"  {...field} invalid={errors.productWeight && true} placeholder="Enter Your Product Weight" />
                                                )} />
                                            {errors.productWeight && (
                                                <FormFeedback>{errors.productWeight.message}</FormFeedback>
                                            )}

                                        </div>
                                        <div className='d-flex kg-gm-con'>
                                            <div onClick={() => setIsKg('kg')} className={`${isKg === 'kg' ? 'active-con' : ''} con-width`}>
                                                <h1 className='fs-6'>kg</h1>
                                            </div>
                                            <div onClick={() => setIsKg('gm')} className={`${isKg === 'gm' ? 'active-con' : ''} con-width`}>
                                                <h1 className='fs-6'>gm</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4} md={4} lg={6}>
                                <div className="mb-3">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>No Shipping Charge Above (Amount)</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='noShipChargeAbove'
                                        id='noShipChargeAbove'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input style={{ height: '44px' }} type="number"  {...field} invalid={errors.noShipChargeAbove && true} placeholder="Enter Your amount" />
                                        )} />
                                    {errors.noShipChargeAbove && (
                                        <FormFeedback>{errors.noShipChargeAbove.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col sm={2} md={2} lg={2}>
                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label className='w-100' style={{ fontWeight: "500", color: '#262525' }}>
                                        Length
                                    </Label>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <Controller
                                            name='length'
                                            control={control}
                                            defaultValue='' // Ensure it's a number
                                            render={({ field }) => (
                                                <Input
                                                    className='w-100'
                                                    {...field}
                                                    type="number"
                                                    style={{
                                                        paddingRight: '40px',
                                                        borderRadius: '5px',
                                                        border: '1px solid #DFDFDF',
                                                        background: 'white',
                                                    }}
                                                    invalid={errors.length && true}
                                                    placeholder="Enter Length"
                                                    onChange={(e) => field.onChange(parseFloat(e.target.value))} // Convert to number
                                                />
                                            )}
                                        />
                                        {errors.length && <FormFeedback>{errors.length.message}</FormFeedback>}
                                        <span
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                color: '#999999',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            cm
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={2} md={2} lg={2}>
                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label className='w-100' style={{ fontWeight: "500", color: '#262525' }}>
                                        Width
                                    </Label>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <Controller
                                            name='width'
                                            control={control}
                                            defaultValue='' // Ensure it's a number
                                            render={({ field }) => (
                                                <Input
                                                    className='w-100'
                                                    {...field}
                                                    type="number"
                                                    style={{
                                                        paddingRight: '40px',
                                                        borderRadius: '5px',
                                                        border: '1px solid #DFDFDF',
                                                        background: 'white',
                                                    }}
                                                    invalid={errors.width && true}
                                                    placeholder="Enter width"
                                                    onChange={(e) => field.onChange(parseFloat(e.target.value))} // Convert to number
                                                />
                                            )}
                                        />
                                        {errors.width && <FormFeedback>{errors.width.message}</FormFeedback>}
                                        <span
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                color: '#999999',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            cm
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={2} md={2} lg={2}>
                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label className='w-100' style={{ fontWeight: "500", color: '#262525' }}>
                                        Height
                                    </Label>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <Controller
                                            name='height'
                                            control={control}
                                            defaultValue='' // Ensure it's a number
                                            render={({ field }) => (
                                                <Input
                                                    className='w-100'
                                                    {...field}
                                                    type="number"
                                                    style={{
                                                        paddingRight: '40px',
                                                        borderRadius: '5px',
                                                        border: '1px solid #DFDFDF',
                                                        background: 'white',
                                                    }}
                                                    invalid={errors.height && true}
                                                    placeholder="Enter Height"
                                                    onChange={(e) => field.onChange(parseFloat(e.target.value))} // Convert to number
                                                />
                                            )}
                                        />
                                        {errors.height && <FormFeedback>{errors.height.message}</FormFeedback>}
                                        <span
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                color: '#999999',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            cm
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Customization Details</h1>
                        <Row>
                            <Col sm={12}>
                                <div className='mb-1 d-flex flex-column'>
                                    <Label style={{ marginBottom: '5px', paddingRight: "10px", fontWeight: "500", color: '#262525' }}>
                                        Upload Users Design
                                    </Label>
                                    <Controller
                                        name='upload'
                                        id='upload'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div className="d-flex">
                                                <div>
                                                    <label
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '5px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '3px',
                                                            backgroundColor: field.value === true ? '#007bff' : 'transparent',
                                                            color: field.value === true ? '#fff' : '#000',
                                                            width: "100px",
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            {...field}
                                                            checked={field.value === true}
                                                            value={true}
                                                            onChange={() => {
                                                                field.onChange(true); // Set value explicitly as boolean `true`
                                                            }}
                                                        />
                                                        <span className='ml-1'>True</span>
                                                    </label>
                                                </div>
                                                <div className='ms-3'>
                                                    <label
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '5px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '3px',
                                                            backgroundColor: field.value === false ? '#007bff' : 'transparent',
                                                            color: field.value === false ? '#fff' : '#000',
                                                            width: "100px",
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            {...field}
                                                            checked={field.value === false}
                                                            value={false}
                                                            onChange={() => {
                                                                field.onChange(false); // Set value explicitly as boolean `false`
                                                            }}
                                                        />
                                                        <span className='ml-1'>False</span>
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    />
                                    {errors.upload && <FormFeedback>{errors.upload.message}</FormFeedback>}
                                </div>
                            </Col>
                            <div className='mt-2 mb-2' style={{ width: '100%', height: '1px', backgroundColor: '#DFDFDF' }}></div>
                            <Col sm={12}>
                                <div className='mb-1 d-flex flex-column'>
                                    <Label style={{ marginBottom: '5px', paddingRight: "10px", fontWeight: "500", color: '#262525' }}>
                                        Customization
                                    </Label>
                                    <Controller
                                        name='customizeProduct'
                                        id='customizeProduct'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div className="d-flex">
                                                <div>
                                                    <label
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '5px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '3px',
                                                            backgroundColor: field.value === true ? '#007bff' : 'transparent',
                                                            color: field.value === true ? '#fff' : '#000',
                                                            width: "100px",
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            {...field}
                                                            value={true}
                                                            checked={field.value === true}
                                                            onChange={(e) => {
                                                                field.onChange(true); // Set the value in react-hook-form
                                                                setShowCustomUpload(true); // Set to true when this option is selected
                                                            }}
                                                        />
                                                        <span className='ml-1'>True</span>
                                                    </label>
                                                </div>
                                                <div className='ms-3'>
                                                    <label
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '5px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '3px',
                                                            backgroundColor: field.value === false ? '#007bff' : 'transparent',
                                                            color: field.value === false ? '#fff' : '#000',
                                                            width: "100px",
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            {...field}
                                                            value={false}
                                                            checked={field.value === false}
                                                            onChange={(e) => {
                                                                field.onChange(false); // Set the value in react-hook-form
                                                                setShowCustomUpload(false); // Set to false when this option is selected
                                                            }}
                                                        />
                                                        <span className='ml-1'>False</span>
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    />
                                    {errors.customizeProduct && <FormFeedback>{errors.customizeProduct.message}</FormFeedback>}
                                </div>
                            </Col>
                            {showCustomUpload && (
                                <Col sm={12} md={6} lg={12}>
                                    <h1 className='fs-3 mb-4 mt-4'>Upload Customization Image</h1>

                                    {colorProducts.map((colorProduct, colorIndex) => (
                                        <div key={colorIndex} className='mt-3'>
                                            <Row className='mb-3'>
                                                <Col sm={12} md={6} lg={6}>
                                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Color Code</Label>
                                                    {/* <div className='d-flex'>
                                                        <Input
                                                            type="text"
                                                            style={{ height: '44px' }}
                                                            value={colorProduct.colourCode}
                                                            onChange={(e) => {
                                                                const updatedColorProducts = [...colorProducts];
                                                                updatedColorProducts[colorIndex].colourCode = e.target.value;
                                                                setColorProducts(updatedColorProducts);
                                                            }}
                                                            placeholder='Enter Color Code'
                                                        />
                                                        <div
                                                            className='ms-2'
                                                            style={{ cursor: 'pointer', color: "#AF1B1B" }}
                                                            onClick={() => {
                                                                const updatedColorProducts = colorProducts.filter((_, i) => i !== colorIndex);
                                                                setColorProducts(updatedColorProducts);
                                                            }}
                                                        >
                                                            <Trash2 />
                                                        </div>
                                                    </div> */}
                                                    <div className="position-relative d-flex" style={{ height: "44px" }}>
                                                        {/* Input Box */}
                                                        <Input
                                                            type="text"
                                                            style={{ height: "44px", paddingRight: "50px" }} // Leave space for color picker
                                                            value={colorProduct.colourCode}
                                                            onChange={(e) => {
                                                                const updatedColorProducts = [...colorProducts];
                                                                updatedColorProducts[colorIndex].colourCode = e.target.value;
                                                                setColorProducts(updatedColorProducts);
                                                            }}
                                                            placeholder="Enter Color Code"
                                                        />
                                                        {/* Color Picker */}
                                                        <input
                                                            type="color"
                                                            id={`colorPicker-${colorIndex}`}
                                                            style={{
                                                                position: "absolute",
                                                                right: "50px", // Position it inside the input box
                                                                top: "50%",
                                                                transform: "translateY(-50%)",
                                                                width: "40px",
                                                                height: "40px",
                                                                border: "none",
                                                                cursor: "pointer",
                                                            }}
                                                            value={colorProduct.colourCode} // Sync with input value
                                                            onChange={(e) => {
                                                                const updatedColorProducts = [...colorProducts];
                                                                updatedColorProducts[colorIndex].colourCode = e.target.value; // Set the selected color
                                                                setColorProducts(updatedColorProducts);
                                                            }}
                                                        />
                                                        <div
                                                            className="ms-2"
                                                            style={{ cursor: "pointer", color: "#AF1B1B" }}
                                                            onClick={() => {
                                                                const updatedColorProducts = colorProducts.filter((_, i) => i !== colorIndex);
                                                                setColorProducts(updatedColorProducts);
                                                            }}
                                                        >
                                                            <Trash2 />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {colorProduct.images.map((imageData, imageIndex) => (
                                                <div key={imageIndex} className='mt-3'>
                                                    <Row className='d-flex justify-content-center'>
                                                        <Col sm={12} md={5}>
                                                            <div className='mb-3'>
                                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Name</Label>
                                                                <Controller
                                                                    name="imageName"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            isSearchable={true}
                                                                            id="fieldTypes"
                                                                            options={filterOptions(colorIndex, imageIndex)} // Filtered options
                                                                            value={customSide.find((option) => option.value === imageData.name)} // Map ID to label
                                                                            onChange={(e) => {
                                                                                const updatedColorProducts = [...colorProducts];
                                                                                updatedColorProducts[colorIndex].images[imageIndex].name = e.value; // Store the selected ID
                                                                                setColorProducts(updatedColorProducts);
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={3} md={3} lg={3}>
                                                            <div className='mb-3'>
                                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Image</Label>
                                                                <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                                    <input
                                                                        style={{ display: 'none' }}
                                                                        type="file"
                                                                        accept=".jpeg,.png,.jpg,.jfif"
                                                                        ref={el => (imageRefs.current[colorIndex * 100 + imageIndex] = el)}
                                                                        onChange={(e) => handleCustomImageUpload(e, colorIndex, imageIndex)}
                                                                    />
                                                                    {loaders ? (
                                                                        <Spinner color='primary' />
                                                                    ) : (
                                                                        <Card
                                                                            style={{ width: "100px", height: "100px", justifyContent: "center", alignItems: "center" }}
                                                                            onClick={() => imageRefs.current[colorIndex * 100 + imageIndex]?.click()}
                                                                        >
                                                                            {imageData.image ? (
                                                                                <>
                                                                                    <img
                                                                                        alt="uploaded"
                                                                                        src={imageData.image}
                                                                                        style={{ width: '60px', objectFit: 'cover' }}
                                                                                    />
                                                                                    <div
                                                                                        className='image-remove'
                                                                                        style={{
                                                                                            position: 'absolute',
                                                                                            top: '5px',
                                                                                            right: '1px',
                                                                                            cursor: 'pointer',
                                                                                            color: 'red',
                                                                                        }}
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation(); // Prevent triggering the file input click
                                                                                            const updatedColorProducts = [...colorProducts];
                                                                                            updatedColorProducts[colorIndex].images[imageIndex].image = '';
                                                                                            setColorProducts(updatedColorProducts);
                                                                                        }}
                                                                                    >
                                                                                        <X />
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <div className='text-primary d-flex align-items-center w-75 h-75'>
                                                                                    <div className='text-center'>
                                                                                        <h1 style={{ color: "primary" }}><RiImageAddFill size={20} /></h1>
                                                                                        <p style={{ fontSize: '12px' }}>*Upload your Custom Image</p>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Card>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col sm={2} md={2} lg={2}>
                                                            <div
                                                                className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3'
                                                                style={{ cursor: 'pointer', color: "#AF1B1B" }}
                                                                onClick={() => {
                                                                    const updatedColorProducts = [...colorProducts];
                                                                    updatedColorProducts[colorIndex].images = updatedColorProducts[colorIndex].images.filter((_, i) => i !== imageIndex);
                                                                    setColorProducts(updatedColorProducts);
                                                                }}
                                                            >
                                                                <Trash2 />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                </div>
                                            ))}

                                            <div className='d-flex'>
                                                <Button
                                                    type="button"
                                                    className='overview-btn'
                                                    onClick={() => {
                                                        const updatedColorProducts = [...colorProducts];
                                                        updatedColorProducts[colorIndex].images.push({ name: '', image: '' });
                                                        setColorProducts(updatedColorProducts);
                                                    }}
                                                >
                                                    <PlusCircle /> Add Custom Image
                                                </Button>
                                            </div>
                                            <hr />
                                        </div>
                                    ))}

                                    <div className='d-flex mt-3'>
                                        <Button
                                            type="button"
                                            className='overview-btn'
                                            onClick={() => setColorProducts([...colorProducts, { colourCode: '', images: [{ name: '', image: '' }] }])}
                                        >
                                            <PlusCircle /> Add Color Product
                                        </Button>
                                    </div>
                                </Col>
                            )}

                        </Row>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Quantity Based Amount</h1>

                        <div className='mt-3'>
                            {quantityBasedAmountFields.map((field, index) => (
                                <div key={field.id}>
                                    <Row>
                                        <Col sm={12} md={12} lg={5}>
                                            <div className="mb-3">
                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Quantity From</Label>
                                                <Controller
                                                    name={`quantityBasedAmount[${index}].from`}
                                                    id="from"
                                                    control={control}
                                                    defaultValue=''  // Default value is 0 (as a number)
                                                    render={({ field }) => (
                                                        <Input
                                                            type="number"
                                                            style={{ height: '44px' }}
                                                            {...field}
                                                            invalid={errors.from && true}
                                                            placeholder="Enter Quantity From"
                                                            onChange={(e) => {
                                                                const value = e.target.value ? Number(e.target.value) : '';  // Convert the value to a number
                                                                field.onChange(value);  // Pass the value to react-hook-form
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors.from && <FormFeedback>{errors.from.message}</FormFeedback>}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={5}>
                                            <div className="mb-3">
                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Quantity To</Label>
                                                <Controller
                                                    name={`quantityBasedAmount[${index}].to`}
                                                    id="to"
                                                    control={control}
                                                    defaultValue=''  // Default value as a number (0)
                                                    render={({ field }) => (
                                                        <Input
                                                            style={{ height: '44px' }}
                                                            type="number"
                                                            {...field}
                                                            invalid={errors.to && true}
                                                            placeholder="Enter Quantity To"
                                                            onChange={(e) => {
                                                                const value = e.target.value ? Number(e.target.value) : '';  // Convert the value to a number
                                                                field.onChange(value);  // Pass the value to react-hook-form
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors.to && <FormFeedback>{errors.to.message}</FormFeedback>}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={5}>
                                            <div className="mb-3">
                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Amount</Label>
                                                <Controller
                                                    name={`quantityBasedAmount[${index}].amount`}
                                                    id="amount"
                                                    control={control}
                                                    defaultValue=''  // Default value as a number (0)
                                                    render={({ field }) => (
                                                        <Input
                                                            style={{ height: '44px' }}
                                                            type="number"
                                                            {...field}
                                                            invalid={errors.amount && true}
                                                            placeholder="Enter Your Amount"
                                                            onChange={(e) => {
                                                                const value = e.target.value ? Number(e.target.value) : '';  // Convert the value to a number
                                                                field.onChange(value);  // Pass the value to react-hook-form
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors.amount && <FormFeedback>{errors.amount.message}</FormFeedback>}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={12} lg={5}>
                                            <div className='mb-3'>
                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Type</Label>
                                                <Controller
                                                    name={`quantityBasedAmount[${index}].type`}
                                                    id='type'
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            isSearchable={false}
                                                            options={quantityBasedType}
                                                            invalid={errors.form && true}

                                                        />
                                                    )}
                                                />
                                                {errors.type && errors.type[index]?.name && (
                                                    <FormFeedback>{errors.type[index]?.name.message}</FormFeedback>
                                                )}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={2}>
                                            <div className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3' style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={() => { removeQuantityBasedAmount(index); }}>
                                                <Trash2 />
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                </div>
                            ))}
                            <div className='d-flex py-3'>
                                <Button type="button" className='overview-btn' onClick={() => appendQuantityBasedAmount({})}>
                                    <PlusCircle /> Add Field
                                </Button>
                            </div>
                        </div>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Meta Details</h1>
                        <Row>
                            <Col sm={12} md={12} lg={6}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Title</Label>
                                    <Controller
                                        name='metaTitle'
                                        id='metaTitle'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="text" {...field} invalid={errors.metaTitle && true} placeholder="Enter your Meta Title" />)} />
                                    {errors.metaTitle && <FormFeedback>{errors.metaTitle.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={6}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Description</Label>
                                    <Controller
                                        name='metaDescription'
                                        id='metaDescription'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="textarea" {...field} invalid={errors.metaDescription && true} placeholder="Enter your Meta Descritpion" />)} />
                                    {errors.metaDescription && <FormFeedback>{errors.metaDescription.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={6}>
                                <div className="">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Keywords</Label>
                                    <div className=" w-100">
                                        {/* <TagsInput
                                            classNames='w-100'
                                            placeHolder="Enter Company Meta Keywords"
                                            onChange={handleMetaKeywordsChange}
                                            value={metaKeywords}
                                        />
                                        <span className='text-secondary ' style={{ fontSize: '13px' }}>Press enter to add Meta Keywords</span>
                                        {errors.metaKeywords && (
                                            <div className="mt-2 text-danger">
                                                {errors.metaKeywords.message}
                                            </div>
                                        )} */}
                                        <Controller
                                            name='metaKeywords'
                                            id='metaKeywords'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (<Input style={{ height: '70px' }} type="textarea" {...field} invalid={errors.metaKeywords && true} placeholder="Enter your Meta Keywords" />)} />
                                        {errors.metaKeywords && <FormFeedback>{errors.metaKeywords.message}</FormFeedback>}
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={6}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Product URL</Label>
                                    <Input
                                        style={{ height: '44px' }}
                                        type="textarea"
                                        value={getEditData?.product_url}
                                        readOnly
                                        invalid={errors.product_url && true}
                                        placeholder="Enter Product URL"
                                    />
                                </div>
                            </Col>

                        </Row>
                    </Card>
                    {/* <Row >
                        <Col sm={6} md={6}>
                            <div className='mb-2'>
                                <Label>Product URL</Label>
                                <Controller
                                    name='product_url'
                                    id='product_url'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (<Input type="text" rows={5} cols={60} {...field} invalid={errors.product_url && true} placeholder="Enter Product URL" />)} />
                                {errors.product_url && <FormFeedback>{errors.product_url.message}</FormFeedback>}
                            </div>
                        </Col>
                        {/* <Col sm={4} md={6}>
                            <div className='mb-2'>
                                <Label>Product Name</Label>
                                <Controller
                                    name='name'
                                    id='name'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (<Input type="text" {...field} invalid={errors.name && true} placeholder="Enter Name" />)} />
                                {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                            </div>
                        </Col>

                        <Col sm={6} md={6}>
                            <div className='mb-2'>
                                <Label>Product Description</Label>
                                <Controller
                                    name='fullDescription'
                                    id='fullDescription'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (<Input type="text" rows={5} cols={60} {...field} invalid={errors.fullDescription && true} placeholder="Enter fullDescription" />)} />
                                {errors.fullDescription && <FormFeedback>{errors.fullDescription.message}</FormFeedback>}
                            </div>
                        </Col> */}
                    {/*<Col sm={12} md={12} lg={6}>
                            <div className='mb-3 '>
                                <Label style={{ fontWeight: "bolder" }}>Meta Title</Label>
                                <Controller
                                    name='metaTitle'
                                    id='metaTitle'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (<Input type="text" {...field} invalid={errors.metaTitle && true} placeholder="Enter your Meta Title" />)} />
                                {errors.metaTitle && <FormFeedback>{errors.metaTitle.message}</FormFeedback>}
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                            <div className='mb-3 '>
                                <Label style={{ fontWeight: "bolder" }}>Meta Description</Label>
                                <Controller
                                    name='metaDescription'
                                    id='metaDescription'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (<Input type="textarea" {...field} invalid={errors.metaDescription && true} placeholder="Enter your Meta Descritpion" />)} />
                                {errors.metaDescription && <FormFeedback>{errors.metaDescription.message}</FormFeedback>}
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                            <div className="col-span-12 md:col-span-12 lg:col-span-6 intro-y">
                                <Label>Meta Keywords</Label>
                                <div className="mt-2">
                                    <TagsInput
                                        placeHolder="Enter Company Meta Keywords"
                                        onChange={handleMetaKeywordsChange}
                                        value={metaKeywords || []}
                                    />
                                    <span>Press enter to add Meta Keywords</span>
                                    {errors.metaKeywords && (
                                        <div className="mt-2 text-danger">
                                            {errors.metaKeywords.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                            <div className="col-span-12 md:col-span-12 lg:col-span-6 intro-y">
                                <Label>Size</Label>
                                <div className="mt-2">
                                    <TagsInput
                                        placeHolder="Enter Product size"
                                        onChange={handleSizeChange}
                                        value={customizeImageSize}
                                    />
                                    <span>Press enter the size of the product</span>
                                    {errors.customizeImageSize && (
                                        <div className="mt-2 text-danger">
                                            {errors.customizeImageSize.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                        {/* <Col sm={6} md={6}>
                            <div className='mb-2'>
                                <Label>Short Description</Label>
                                <Controller
                                    name='shortDescription'
                                    id='shortDescription'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (<Input type="text" rows={5} cols={60} {...field} invalid={errors.shortDescription && true} placeholder="Enter shortDescription" />)} />
                                {errors.shortDescription && <FormFeedback>{errors.shortDescription.message}</FormFeedback>}
                            </div>
                        </Col> */}

                    {/*<Col sm={12} md={6} lg={6}>
                            <div className='d-flex'>
                                <div> <b>Custom Images :</b></div>
                            </div>

                            {colorProducts.map((colorProduct, colorIndex) => (
                                <div key={colorIndex} className='mt-3'>
                                    <Row className='mb-3'>
                                        <Col sm={12} md={6} lg={6}>
                                            <Label>Color Code</Label>
                                            <Input
                                                type="text"
                                                value={colorProduct.colourCode}
                                                onChange={(e) => {
                                                    const updatedColorProducts = [...colorProducts];
                                                    updatedColorProducts[colorIndex].colourCode = e.target.value;
                                                    setColorProducts(updatedColorProducts);
                                                }}
                                                placeholder='Enter Color Code'
                                            />
                                        </Col>
                                        <Col sm={2} lg={2} md={2}>
                                            <div
                                                className='mt-0 mb-5 pt-0 mt-lg-3 mb-lg-3 pt-lg-3'
                                                style={{ cursor: 'pointer', color: "#AF1B1B" }}
                                                onClick={() => {
                                                    const updatedColorProducts = colorProducts.filter((_, i) => i !== colorIndex);
                                                    setColorProducts(updatedColorProducts);
                                                }}
                                            >
                                                <Trash2 />
                                            </div>
                                        </Col>
                                    </Row>
                                    {colorProduct.images.map((imageData, imageIndex) => (
                                        <div key={imageIndex} className='mt-3'>
                                            <Row className='d-flex justify-content-center'>
                                                <Col sm={5} md={5} lg={5}>
                                                    <div className='mb-3'>
                                                        <Label>Name</Label>
                                                        <Input
                                                            type='text'
                                                            value={imageData.name}
                                                            placeholder='Enter Custom Image Name'
                                                            onChange={(e) => {
                                                                const updatedColorProducts = [...colorProducts];
                                                                updatedColorProducts[colorIndex].images[imageIndex].name = e.target.value;
                                                                setColorProducts(updatedColorProducts);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={3} md={3} lg={3}>
                                                    <div className='mb-3'>
                                                        <Label style={{ fontWeight: "bolder" }}>Image</Label>
                                                        <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                            <input
                                                                style={{ display: 'none' }}
                                                                type="file"
                                                                accept=".jpeg,.png,.jpg,.jfif"
                                                                ref={el => (imageRefs.current[colorIndex * 100 + imageIndex] = el)}
                                                                onChange={(e) => handleCustomImageUpload(e, colorIndex, imageIndex)}
                                                            />
                                                            <Card
                                                                style={{ width: "100px", height: "100px", justifyContent: "center", alignItems: "center" }}
                                                                onClick={() => imageRefs.current[colorIndex * 100 + imageIndex]?.click()}
                                                            >
                                                                {imageData.image ? (
                                                                    <>
                                                                        <img
                                                                            alt="uploaded image"
                                                                            src={imageData.image}
                                                                            style={{ width: '60px', objectFit: 'cover' }}
                                                                        />
                                                                        <div
                                                                            className='image-remove'
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '5px',
                                                                                right: '1px',
                                                                                cursor: 'pointer',
                                                                                color: 'red',
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation(); // Prevent triggering the file input click
                                                                                const updatedColorProducts = [...colorProducts];
                                                                                updatedColorProducts[colorIndex].images[imageIndex].image = '';
                                                                                setColorProducts(updatedColorProducts);
                                                                            }}
                                                                        >
                                                                            <X />
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className='text-primary d-flex align-items-center w-75 h-75'>
                                                                        <div className='text-center'>
                                                                            <h1 style={{ color: "primary" }}><RiImageAddFill size={20} /></h1>
                                                                            <p style={{ fontSize: '12px' }}>*Upload your Custom Image</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col sm={2} md={2} lg={2}>
                                                    <div
                                                        className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3'
                                                        style={{ cursor: 'pointer', color: "#AF1B1B" }}
                                                        onClick={() => {
                                                            const updatedColorProducts = [...colorProducts];
                                                            updatedColorProducts[colorIndex].images = updatedColorProducts[colorIndex].images.filter((_, i) => i !== imageIndex);
                                                            setColorProducts(updatedColorProducts);
                                                        }}
                                                    >
                                                        <Trash2 />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </div>
                                    ))}

                                    <div className='d-flex'>
                                        <Button
                                            type="button"
                                            className='overview-btn'
                                            onClick={() => {
                                                const updatedColorProducts = [...colorProducts];
                                                updatedColorProducts[colorIndex].images.push({ name: '', image: '' });
                                                setColorProducts(updatedColorProducts);
                                            }}
                                        >
                                            <PlusCircle /> Add Custom Image
                                        </Button>
                                    </div>
                                    <hr />
                                </div>
                            ))}

                            <div className='d-flex mt-3'>
                                <Button
                                    type="button"
                                    className='overview-btn'
                                    onClick={() => setColorProducts([...colorProducts, { colourCode: '', images: [{ name: '', image: '' }] }])}
                                >
                                    <PlusCircle /> Add Color Product
                                </Button>
                            </div>
                        </Col>
                    </Row> */}
                    <div className="d-flex fixed-button justify-content-end">
                        <div className="d-flex mt-3">
                            <Button className="overview-btn" type="submit">
                                Submit
                            </Button>
                        </div>
                        <div className="d-flex ms-3 mt-3">
                            <Button className="perview-btn" onClick={navToProductView} type="button">
                                Preview
                            </Button>
                        </div>
                    </div>
                </Form >
            </div >
            {/* <FooterSection /> */}
        </>
    )
}

export default EditProductIndex
import React, { useCallback, useEffect, useState } from 'react'
import Navbar from '../../navbar'
import FooterSection from '../../footer'
import './index.css'
import DataTable from 'react-data-table-component'
import { getOfferProduct, offerPro } from '../../../ApiConfigs/ApiConfig'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Plus, RotateCcw } from 'react-feather'
import { Button, Modal, ModalBody, Spinner } from 'reactstrap'
import OfferProductEdit from './editProductModal'
import OfferProductAdd from './addProductModal'
import ReactPaginate from 'react-paginate';
// import Select from 'react-select';
// import { GrPowerReset } from "react-icons/gr";
import { useNavigate } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'
import { BiEditAlt } from 'react-icons/bi'
import { GoTrash } from 'react-icons/go'
import TitleComponent from '../../homeNew/TitleComponent'

export const truncateDescription = (description, maxLength) => {
    return description.length > maxLength
        ? `${description.slice(0, maxLength)}...`
        : description;
};

export const customStyles = {
    headCells: {
        style: {
            backgroundColor: '#FCEDE6',
            color: '#333333',
            fontWeight: '500',
            fontSize: '16px',
        },
    },
};

function OfferProduct() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [deleteData, setDeleteData] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [editData, setEditData] = useState([])
    const [editModal, setEditModal] = useState(false)
    const handleAdd = () => setAddModal(!addModal)
    const [addModal, setAddModal] = useState(false);
    // const [resetKey, setResetKey] = useState(0);
    const [sorts, setSorts] = useState('')
    const rowsPerPage = 10;
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchKey] = useState('');


    // product overall get
    const offerData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${offerPro}?search=${searchKey}&currentPage=${currentPage > 0 ? currentPage - 1 : currentPage}&perPage=${rowsPerPage}&sortBy=${sorts}`);
            setData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    }, [searchKey, currentPage, rowsPerPage, sorts]);

    // Extract token into a variable so it's stable and doesn't cause unnecessary renders
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token) {
            offerData();  // Call offerData if token exists
        }
    }, [token, offerData]);

    useEffect(() => {
        offerData(searchKey)

    }, [sorts, searchKey, currentPage, rowsPerPage, offerData])

    // product delete

    const openDeleteModal = (id) => {
        setDeleteData(id);
        setDeleteModal(true);
    }
    const closeDeleteModal = () => {
        setDeleteModal(false);
    }
    const handleConfirmDelete = async () => {
        try {
            const response = await axios.delete(`${getOfferProduct}/${deleteData}`)
            closeDeleteModal()
            offerData()
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }



    // product edit

    const openEditModal = (id) => {
        getEditModal(id)
        setEditModal(true);
    };

    const closeEditModal = () => {
        setEditModal(false);
    };

    const getEditModal = async (id) => {
        try {
            const response = await axios.get(`${getOfferProduct}/${id}`)
            setEditData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }

    }

    const [showFullDescription, setShowFullDescription] = useState(false);

    const handleReset = () => {
        setSearchKey('');
        setSorts('');
        offerData();
    };

    const additionalTable = [
        {
            name: 'S No',
            minWidth: '50px',
            cell: (row, i) =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{(i + 1).toString().padStart(2, '0')}</p>
                </>

        },
        // {
        //     name: 'Image',
        //     sortable: false,
        //     minWidth: '150px',
        //     cell: row => <img src={row?.product?.image} height={100} width={150} alt="" style={{ padding: '5px' }} />
        // },
        {
            name: 'Product',
            sortable: false,
            minWidth: '250px',
            cell: row =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{row?.product?.name}</p>
                </>
        },
        {
            name: 'Product Image & Description',
            sortable: false,
            minWidth: '400px',
            cell: row =>
                <>
                    <div className='d-flex'>
                        <img className='d-flex align-items-center' src={row?.product?.image} height={60} width={60} alt="" style={{ padding: '5px', borderRadius: '5px' }} />
                        <span className='mt-2 mb-2' style={{ cursor: 'pointer' }}>
                            {truncateDescription(row?.description, 40)}</span>
                    </div>
                </>,
        },
        {
            name: 'Offer Price',
            sortable: false,
            minWidth: '80px',
            cell: row =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{row?.price}</p>
                </>
        },
        {
            name: 'Offer Percentage',
            sortable: false,
            minWidth: '80px',
            cell: row =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{row?.offerPercentage}</p>
                </>
        },
        {
            name: "Action",
            minWidth: "200px",
            cell: (row) => (
                <>
                    <button className='ms-2' style={{ background: '#EEEEEE', border: 'none', width: '40px', height: '40px' }} onClick={() => openEditModal(row?._id)}>
                        <BiEditAlt color='#4E4E4E' size={25} />
                    </button>
                    <button className='ms-2' style={{ background: '#D5383826', border: 'none', width: '40px', height: '40px' }} onClick={() => openDeleteModal(row?._id)}>
                        <GoTrash color='#E42525' size={25} />
                    </button>
                </>

            )

        }
    ]

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const CustomPagination = (pageCount) => {
        return (
            <>
                <ReactPaginate
                    nextLabel="Next"
                    breakLabel="..."
                    previousLabel="Prev"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePagination}
                    containerClassName="pagination justify-content-end p-1"
                    activeClassName="active"
                    pageClassName="page-item"
                    breakClassName="page-item"
                    pageLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    forcePage={currentPage - 1}
                />
            </>
        );
    };


    return (
        <div>
            <Navbar />
            <div className='pt-5' style={{ marginLeft: '8%', marginRight: '8%', paddingBottom: '10%' }}>
                <TitleComponent
                    title={`Offer Product Management`}
                    isDesc={true}
                    desc={`Highlight special offers and promotions on your products. Add or update discounted items, manage offer details, and attract customers with exclusive deals.`}
                />
                <div>
                    <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Offer Products</span></p>
                </div>
                <div className='mt-4 order-table-con  shadow-sm'>
                    <div className='d-flex align-items-center justify-content-between p-3'>
                        <div className='d-flex align-items-center'>
                            <div className='red-line me-2'></div>
                            <h1 className='p-0 m-0 h5'>Offer Product List</h1>
                        </div>
                        <span onClick={handleReset} className='cursor-pointer'><RotateCcw color='#E4510B' /></span>
                    </div>
                    <div className='line-hr-new'></div>
                    <div className='p-3'>
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <div className='d-flex gap-3'>
                                <div className='d-flex flex-column'>
                                    <label style={{ color: '#333333' }} className='fw-medium'>Search</label>
                                    <input
                                        type='text'
                                        className='search-order'
                                        placeholder='Search by category name'
                                        value={searchKey}
                                        onChange={(e) => setSearchKey(e.target.value)}
                                    />
                                </div>
                                <div className='d-flex'>
                                    <div className='d-flex flex-column'>
                                        <label style={{ color: '#333333' }} className='fw-medium'>Sort By</label>
                                        <select
                                            className='select-order'
                                            value={sorts}
                                            onChange={(e) => setSorts(e.target.value)}
                                        >
                                            <option value='latest'>Latest</option>
                                            <option value='oldest'>Oldest</option>
                                            {/* Add more options as needed */}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <button className='productCategoryBtn mt-3' onClick={handleAdd}><Plus size={20} /> Add Product</button>
                        </div>
                        {loading ? (
                            <div style={{ height: "50vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Spinner
                                    color="primary"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <DataTable
                                pagination
                                paginationServer
                                noHeader
                                highlightOnHover
                                fixedHeader
                                fixedHeaderScrollHeight='130vh'
                                data={data?.rows}
                                columns={additionalTable}
                                paginationDefaultPage={currentPage}
                                customStyles={customStyles}
                                paginationComponent={() => CustomPagination(data?.pagination?.pages)}
                            />
                        )}
                    </div>
                </div>

                {/* add modal */}
                <OfferProductAdd open={addModal} handleAdd={handleAdd} offerData={offerData} />

                {/* edit modal */}
                <OfferProductEdit open={editModal} handleEdit={closeEditModal} editData={editData} offerData={offerData} />
                {/* delete modal */}
                <Modal isOpen={deleteModal} toggle={closeDeleteModal} className="modal-dialog-centered modal-xs">
                    <ModalBody style={{ fontSize: '20px', textAlign: 'center', paddingTop: '30px', fontWeight: '400' }}>Are you sure you want to delete this Offer Product?</ModalBody>
                    <div style={{ justifyContent: 'center' }} className="modal-footer">
                        <Button style={{ backgroundColor: "#E4510B", border: 'none' }} onClick={handleConfirmDelete} >
                            Confirm
                        </Button>
                        <Button color="secondary" onClick={closeDeleteModal} >
                            Cancel
                        </Button>
                    </div>
                </Modal>
            </div>


            <FooterSection />
        </div>
    )
}

export default OfferProduct
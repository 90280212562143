import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ChangeAddress, profile, shippingAddress } from '../../../ApiConfigs/ApiConfig'
import axios from 'axios'
import * as Yup from 'yup';
import { FaChevronDown } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const ChangeModel = ({ openAddressModal, handleAddressClose, productId, refetch }) => {

    const [getProfile, setProfile] = useState('')
    const [showAll, setShowAll] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [addAddressModal, setAddAddressModal] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);

    const SignupSchema = Yup.object().shape({
        firstName: Yup.string().required('Enter your first name'),
        lastName: Yup.string().required('Enter your last name'),
        state: Yup.string().required('Enter your state'),
        city: Yup.string().required('Enter your city'),
        address: Yup.string().required('Enter your address'),
        landmark: Yup.string().required('Enter your landmark'),
        pincode: Yup.string().required('Enter your pincode'),
        mobile: Yup.string().required('Enter your mobile number'),
        alternateMobile: Yup.string().required('Enter your alternate mobile number'),
        defaultAddress: Yup.boolean(),
    });

    const {
        // handleSubmit,
        // setValue,
        // control,
        formState: { },
    } = useForm({
        mode: 'onChange', resolver: yupResolver(SignupSchema), defaultValues: {
            firstName: '',
            lastName: '',
            state: '',
            city: '',
            landmark: '',
            address: '',
            pincode: '',
            mobile: '',
            alternateMobile: '',
            defaultAddress: false,
        }
    });

    useEffect(() => {
        myProfileDatails()
    }, [])

    const myProfileDatails = async () => {
        try {
            const response = await axios.get(profile)
            setProfile(response?.data?.result)
        } catch (error) {

        }
    }

    const displayedAddresses = showAll ? getProfile?.shippingAddress : getProfile?.shippingAddress?.slice(0, 4);
    // const [confirmOrder, setConfirmOrder] = useState(false)

    const toggleModal = () => {
        handleAddressClose()
        // setConfirmOrder(false)
    }

    const openAddAddressModal = () => {
        // setSelectedAddress(null);
        setAddAddressModal(true);
    }

    // const submitForm = async (data) => {
    //     const payload = {
    //         firstName: data.firstName,
    //         lastName: data.lastName,
    //         state: data.state,
    //         city: data.city,
    //         address: data.address,
    //         landmark: data.landmark,
    //         pincode: data.pincode,
    //         mobile: data.mobile,
    //         alternateMobile: data.alternateMobile,
    //         defaultAddress: data.defaultAddress ? true : false
    //     };

    //     try {
    //         if (selectedAddress) {
    //             // Edit existing address
    //             const response = await axios.put(
    //                 `${shippingAddress}/${getProfile?._id}`,
    //                 payload,
    //                 {
    //                     params: { address_id: selectedAddress?._id }
    //                 }
    //             );
    //             toast.success("Address updated successfully!");
    //         } else {
    //             // Add new address
    //             const response = await axios.post(`${shippingAddress}/${getProfile?._id}`, payload);
    //             toast.success("Address added successfully!");
    //         }

    //         // closeAddAddressModal();
    //         setAddAddressModal(false);

    //         myProfileDatails(); // Refresh the profile details
    //     } catch (error) {
    //         toast.error("Failed to submit address");
    //     }
    // };

    const handleRadioChange = (index, id) => {
        setSelectedIndex(index);
        setSelectedAddressId(id);
    };

    const updateAddress = async () => {
        try {
            const response = await axios.post(`${ChangeAddress}/${productId}?shipping=${selectedAddressId}`, {})
            toast.success("Address Changed successfully!")
            refetch()
            toggleModal();

            // setCancelData(response?.data?.result)
        } catch (error) {
            // toast.error(error?.response?.data?.msg);
            toast.error(error?.response?.data?.msg);
        }
    };

    return (
        <div>
            {openAddressModal && (
                <Modal isOpen={openAddressModal} toggle={toggleModal} className="modal-dialog-centered modal-lg" style={{ border: 'none' }}>
                    <ModalHeader toggle={toggleModal} className="">
                        Change Delivery Address
                    </ModalHeader>
                    <ModalBody className='p-0 m-0'>
                        <>
                            <div className='mt-2' style={{ padding: '2%' }}>
                                <div style={{ paddingLeft: '3%', paddingRight: '3%', paddingTop: '3%', border: '1px solid #F0F0F0', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.05)' }}>
                                    <div style={{ borderBottom: '1px solid #F0F0F0', paddingBottom: '20px' }}>
                                        <p style={{ color: '#2B2B2D', fontSize: '20px', lineHeight: '24px', fontWeight: '700' }}>Delivery Address</p>
                                    </div>
                                    {displayedAddresses?.map((address, index) => (
                                        <div key={address?._id} style={{ borderBottom: '1px solid #F0F0F0' }}>
                                            <div className='d-flex pe-4 mt-4'>
                                                <div>
                                                    <input
                                                        className='radio mt-1 me-3'
                                                        type="radio"
                                                        name="address"
                                                        checked={selectedIndex === index}
                                                        onChange={() => handleRadioChange(index, address._id)}
                                                    />
                                                </div>
                                                <div>
                                                    <h5>{address?.firstName} {address?.lastName}, {address?.mobile}</h5>
                                                    <p className=' d-flex align-items-center' style={{ fontSize: '16px', fontWeight: '400', lineHeight: '20px' }}>
                                                        {address?.address},{address?.landmark},{address?.city},{address?.state}-{address?.pincode}
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <div className='d-flex justify-content-end mb-2'>
                                                <button
                                                    style={{ border: 'none', background: 'white', color: '#E4510B', marginTop: '-10px' }}
                                                    onClick={() => openEditModal(address)}
                                                >
                                                    <BiEditAlt size={25} style={{ color: '#E4510B' }} />
                                                </button>
                                                <button
                                                    style={{ border: 'none', background: 'white', color: '#E4510B', marginTop: '-10px' }}
                                                    onClick={() => handleAddressDelete(address?._id)}
                                                >
                                                    <RiDeleteBin6Line size={25} style={{ color: '#E4510B' }} />
                                                </button>
                                            </div> */}
                                        </div>
                                    ))}

                                    {getProfile?.shippingAddress?.length > 4 && (
                                        <div className='d-flex align-items-center mt-3 ms-2'>
                                            <p style={{ color: '#E4510B', cursor: 'pointer', fontSize: '20px', fontWeight: '500' }} onClick={() => setShowAll(!showAll)}>
                                                <FaChevronDown style={{ color: '#E4510B' }} className='me-5' />
                                                {showAll ? 'Hide addresses' : 'View all addresses'}
                                            </p>
                                        </div>
                                    )}

                                </div>
                                <Link style={{ textDecoration: 'none' }} to="/MyProfile">
                                    <div className='mt-4 d-flex ' style={{ border: '1px solid #F0F0F0', borderRadius: '15px', paddingLeft: '3%', height: '60px' }}>
                                        <div style={{ cursor: 'pointer' }} className='mt-3' onClick={openAddAddressModal}>
                                            <p style={{ fontSize: '20px', color: '#E4510B', fontWeight: '500', lineHeight: '20px' }}><FaPlus style={{ color: '#E4510B' }} className='me-4' /> Add a new address</p>
                                        </div>
                                    </div>
                                </Link>

                            </div>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <div className='d-flex justify-content-end'>
                            <Button style={{ background: 'rgb(228, 81, 11)', border: 'none' }} onClick={updateAddress}>Update Address</Button>
                            <Button onClick={toggleModal} className='ms-3' style={{ color: 'black', background: 'white' }}>Cancel</Button>
                        </div>
                    </ModalFooter>
                </Modal>
            )}
        </div>
    )
}

export default ChangeModel